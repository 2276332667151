import './components/web-font/fontello/scss/fontello.scss'
import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.scss';
import './themes/generated/theme.additional.scss';
import './themes/customized/dx-styles.scss';
import './themes/customized/override.base.scss';
import './themes/customized/main.scss';
import './themes/customized/icon.scss';
import './themes/customized/font.scss';
import './utils/mousetrap-settings';

import React from 'react';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import { HashRouter as Router } from 'react-router-dom';

//language
import { locale, loadMessages } from 'devextreme/localization';
import enMessages from './lang/en.json';
import msMessages from './lang/ms.json';
import zhCHSMessages from './lang/zhCHS.json';
import zhCHTMessages from './lang/zhCHT.json';
import { useDevConfig } from './dev/useDevConfig';
import { FlashMsgProvider } from './components/modal-dialog/FlashMsgProvider';
import { ErrorPopupProvider } from './components/modal-dialog/ErrorPopupProvider';
import { ConfirmationPopupProvider } from './components/modal-dialog/ConfirmationPopupProvider';
import { OverlayBlockProvider } from './components/modal-dialog/OverlayBlockProvider';
import { UserAccessProvider } from './contexts/UserAccess';
import Mousetrap from 'mousetrap';

const mousetrapMain = new Mousetrap(); // register module main events here
const mousetrapSecondary = new Mousetrap(); // register second level popup events here
const mousetrapTertiary = new Mousetrap(); // register third level popup events here

// global scope data
const appData = {
    PIDateRange: null,
    PIDateType: null,
    PITagValue: null,
};

function App() {
    const { user, loading } = useAuth();

    if (loading) {
        return <LoadPanel visible={true} />;
    }

    if (user) {
        return <Content />;
    }

    return <UnauthenticatedContent />;
}

export default function () {
    const screenSizeClass = useScreenSizeClass();

    // Dev Config
    // useDevConfig();

    //language
    initMessages();
    locale(getLocale());
    function getLocale() {
        const locale = sessionStorage.getItem("locale");
        return locale != null ? locale : "en";
    }
    //function setLocale(locale) {
    //  sessionStorage.setItem("locale", locale);
    //}
    function initMessages() {
        loadMessages(enMessages);
        loadMessages(msMessages);
        loadMessages(zhCHSMessages);
        loadMessages(zhCHTMessages);
    }

    return (
        <Router>
            <AuthProvider>
                <NavigationProvider>
                    <OverlayBlockProvider>
                        <FlashMsgProvider>
                            <ErrorPopupProvider>
                                <ConfirmationPopupProvider>
                                    <UserAccessProvider>
                                        <div className={`app ${screenSizeClass}`}>
                                            <App />
                                        </div>
                                    </UserAccessProvider>
                                </ConfirmationPopupProvider>
                            </ErrorPopupProvider>
                        </FlashMsgProvider>
                    </OverlayBlockProvider>
                </NavigationProvider>
            </AuthProvider>
        </Router>
    );
}

export { appData, mousetrapMain, mousetrapSecondary, mousetrapTertiary };