import React, { useCallback, useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import Form from 'devextreme-react/form';
import { Item } from 'devextreme-react/select-box';
import { Button } from 'devextreme-react/button';
import TextBox1 from '../../../components/text-box/text-box-1';
import TextBox2 from '../../../components/text-box/text-box-2';

//components
import { Singleselectiondropdowntable, Singleselectiondropdown, CalenderRange, ListingHeaderTagBox } from '../../../components/index';

//cookies
import { CompanyID, SearchText, DefaultName } from '../../../utils/default-cookies';
import './listing-header.scss';
import { formatMessage } from 'devextreme/localization';

//default
import { DocumentType } from '../../../utils/constant-document-type';
import { SettingType } from '../../../utils/constant-setting-type';

const ListingHeaderAuditLog = forwardRef((props, ref) => {
    const { onCompanyChanged, onCalendarRangeChanged, onSettingChanged, onCodeChange, onSearchValueChange, onFilterClick, onDocNoChange, onUserChange, dataSource_UserID, documentNo} = props;
    var searchBox = React.createRef();

    const calendarRangeRef = useRef();
    const portalRef = useRef(null);

    const [searchValue, setSearchValue] = useState(SearchText());
    const [isDateRangeValid, setIsDateRangeValid] = useState(true);
    const [isUserValueValid, setIsUserValueValid] = useState(true);
    const [isDocNoValid, setIsDocNoValid] = useState(props.documentNo === "" ? false : true);

    useEffect(() => {
       if(documentNo) setIsDocNoValid(true);
       else setIsDocNoValid(false);
    }, [documentNo])

    // company
    const onCompanyValueChanged = useCallback((selection, displayProp, valueProp) => {
        //setCompanyAIGValue(selection[displayProp]);
        onCompanyChanged(selection[valueProp]);
    }, [onCompanyChanged]);

    //date range
    const onSelectDate = useCallback((fromDate, toDate, valid) => {
        setIsDateRangeValid(valid);

        if (valid) {
            onCalendarRangeChanged(fromDate, toDate);
        }
    }, [onCalendarRangeChanged]);

    // users
    const onUserValueChange = useCallback((userIDArray) => {

        if(userIDArray.length === 0) {
            setIsUserValueValid(false);
        }
        else {
            setIsUserValueValid(true);
        }

        var selectedUser = userIDArray;

        var userIDList = [];
        for (var i = 0; i < dataSource_UserID.length; i++) {

            for (var j = 0; j < selectedUser.length; j++) {
                if (dataSource_UserID[i].US_UserID === selectedUser[j]) {
                    userIDList.push(dataSource_UserID[i].US_ID);
                }
            }
        }
        onUserChange(userIDList, userIDArray);
    },[dataSource_UserID, onUserChange]);

    //setting - for master data
    const onSettingValueChanged = useCallback((value) => {
        onSettingChanged(value)
    }, [onSettingChanged]);

    //item code
    const onCodeValueChange = useCallback((value) => {
        onCodeChange(value);
    }, [onCodeChange]);

    // Document Type
    const onDocumentTypeValueChange = (value) => {
        var selectedDocumentType = value;

        var documentTypeIDList = [];
        for (var i = 0; i < props.dataSource_DocType.length; i++) {
            for (var j = 0; j < selectedDocumentType.length; j++) {
                if (props.dataSource_DocType[i].DT_Description === selectedDocumentType[j]) {
                    documentTypeIDList.push(props.dataSource_DocType[i].DT_ID);
                }
            }
        }
        props.onDocTypeChange(documentTypeIDList, value);
    }

    // Document No
    const onDocNoValueChange = useCallback((value) => {
        if(value) setIsDocNoValid(true);
        else setIsDocNoValid(false);
        onDocNoChange(value);
    }, [onDocNoChange]);

    useImperativeHandle(
        ref,
        () => ({
            getCalendarRangeValue() {
                let dateRange = calendarRangeRef.current.getCalendarRangeValue();
                return dateRange;
            },
            clearText() {
                searchBox.clear();
            },
            setFocus() {
                searchBox.setFocus();
            }
        }),
    )

    const onFilterButtonClick = useCallback(() => {
        onFilterClick();
    }, [onFilterClick]);

    const onTbvalueChange = useCallback((value) => {
        setSearchValue(value);
        onSearchValueChange(value);
    }, [onSearchValueChange]);

    return (
        <>
            <div className="portalRef dx-dropdowneditor-overlay" ref={portalRef} />

            <Form colCount={8}>
                <Item colSpan={2}>
                    <Singleselectiondropdowntable
                        dataSource={props.dataSource_Companies}
                        defaultValue={CompanyID()}
                        defaultName={DefaultName()}
                        placeholder={props.placeholder}
                        caption={formatMessage('Company')}
                        valueProp={'CO_ID'}
                        codeProp={'CO_Code'}
                        nameProp={'CO_Name'}
                        displayProp={'IAG_Description'}
                        onSelectionChanged={onCompanyValueChanged}
                    />
                </Item>

                <Item colSpan={2}>
                    <CalenderRange
                        caption={formatMessage('DateRange')}
                        id={"calendar-dropdown-box"}
                        className={"filter-drop-down"}
                        parentCallback={onSelectDate}
                        dateFormat={props.defaultDateFormat}
                        ref={calendarRangeRef}
                        portalRef={portalRef}
                    />
                </Item>

                <Item colSpan={2}>
                    <ListingHeaderTagBox
                        caption={formatMessage('UserID')}
                        dataSource={dataSource_UserID}
                        defaultValue={props.selectedUsername}
                        value={props.selectedUsername}
                        textField={'dx-fieldset-text'}
                        inputField={'dx-fieldset'}
                        valueExpr={'US_UserID'}
                        displayExpr={'US_UserID'}
                        parentCallback={onUserValueChange}
                    />
                </Item>

                <Item colSpan={2}></Item>

                <Item colSpan={2}>
                    {props.displayDocColumn ?
                        <Singleselectiondropdown
                            dataSource={DocumentType}
                            defaultValue={'Purchase Invoice'}
                            caption={formatMessage('DocumentType')}
                            onSelectionChanged={onDocumentTypeValueChange}
                        />
                        :
                        <Singleselectiondropdown
                            dataSource={SettingType}
                            defaultValue={'Package Item'}
                            caption={formatMessage('Settings')}
                            parentCallback={onSettingValueChanged} />
                    }
                </Item>

                <Item colSpan={2} visible={props.displayDocColumn}>
                    {props.displayDocColumn ?
                        <TextBox1
                            caption={formatMessage('DocumentNo')}
                            value={props.documentNo}
                            displayStar={true}
                            onTbvalueChange={onDocNoValueChange}
                            stylingMode={'outlined'}
                            placeholder={'Input Document No.'}
                            showRequired={props.showDocNoRequired}>
                        </TextBox1>
                        :
                        <></>}
                </Item>

                <Item colSpan={2}>
                    <TextBox1
                        caption={formatMessage('Code')}
                        value={props.itemCode}
                        displayStar={props.displayDocColumn ? false : true}
                        onTbvalueChange={onCodeValueChange}
                        stylingMode={'outlined'}
                        placeholder={'Input Code'}
                        showRequired={props.showCodeRequired}>
                    </TextBox1>
                </Item>

                <Item colSpan={1}>
                    <div className="dx-fieldset">
                        <div className="dx-field">
                            <div className="dx-field-value-2" >
                                <Button
                                    icon={"normalize-icon glyphicon icon-filter-icon"}
                                    type="default"
                                    stylingMode={"contained"}
                                    text={formatMessage('Filter')}
                                    className={"filter-btn"}
                                    onClick={onFilterButtonClick}
                                    disabled={!isDateRangeValid || !isUserValueValid || !isDocNoValid} />
                            </div>
                        </div>
                    </div>
                </Item>

                <Item colSpan={3}></Item>

                <Item colSpan={8}>
                    <hr className="purchase-invoice-line-separator" />
                </Item>

                <Item colSpan={4}>
                    <TextBox2
                        placeHolderText={formatMessage('SearchPlaceholderText')}
                        buttonStyle={'search-word'}
                        buttonText={formatMessage('Search')}
                        value={searchValue}
                        onTbvalueChange={onTbvalueChange}
                        visible={true}
                        ref={(ref) => searchBox = ref} />
                </Item>

            </Form>
        </>
    )
});

export default ListingHeaderAuditLog;