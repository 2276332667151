import React, { useState, useEffect, useRef, useCallback } from "react";
import Form from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import Toolbar, { Item } from "devextreme-react/toolbar";
import ReactTooltip from "react-tooltip";
import { Button } from "devextreme-react/button";
import { DefaultName } from "../../utils/default-cookies";
import { useMediaQuery } from "react-responsive";
import { formatMessage } from "devextreme/localization";
import { getPostMessageObj } from '../../utils/iframe-func'
import { mousetrapMain } from "../../App";
import "./toolbar.scss";

const Toolbar1 = (props) => {
    const {
        saveCallback,
        goBack,
        openNew,
        displaySaveBtn,
        displayBackBtn,
        displayAddNewBtn,
    } = props;

    const [state, setState] = useState({
        docInfoPopUpVisible: false,
    });
    const isNotMobile = useMediaQuery({ minWidth: 880 });

    const [moreOptionsDropdown, setMoreOptionsDropdownOpen] = useState(false);
    const [postPreviewDropdownOpen, setPostPreviewDropdownOpen] = useState(false);
    const [copyFromDropdownOpen, setCopyFromDropdownOpen] = useState(false);
    const [previewReportDropdownOpen, setPreviewReportDropdownOpen] =
        useState(false);
    const [previewReportDownIcon, setPreviewReportDownIcon] = useState(false);
    const [copyFromDownIcon, setCopyFromDownIcon] = useState(false);
    const [postPreviewDownIcon, setPostPreviewDownIcon] = useState(false);
    const [moreOptionsDownIcon, setMoreOptionsDownIcon] = useState(false);

    const outsideClick = useRef(null);
    const postPreviewOutsideClick = useRef(null);
    const copyFromOutsideClick = useRef(null);
    const previewReportOutsideClick = useRef(null);
    useOutsideAlerter(outsideClick);
    usePostPreviewOutsideAlerter(postPreviewOutsideClick);
    useCopyFromOutsideAlerter(copyFromOutsideClick);
    usePreviewReportOutsideAlerter(previewReportOutsideClick);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleOutsideClick(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setMoreOptionsDownIcon(false);
                    setMoreOptionsDropdownOpen(false);
                }
            }

            document.addEventListener("click", handleOutsideClick);
        }, [ref]);
    }

    function usePostPreviewOutsideAlerter(ref) {
        useEffect(() => {
            function handleOutsideClick(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setPostPreviewDownIcon(false);
                    setPostPreviewDropdownOpen(false);
                }
            }

            document.addEventListener("click", handleOutsideClick);
        }, [ref]);
    }

    function useCopyFromOutsideAlerter(ref) {
        useEffect(() => {
            function handleOutsideClick(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setCopyFromDownIcon(false);
                    setCopyFromDropdownOpen(false);
                }
            }

            document.addEventListener("click", handleOutsideClick);
        }, [ref]);
    }

    function usePreviewReportOutsideAlerter(ref) {
        useEffect(() => {
            function handleOutsideClick(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setPreviewReportDownIcon(false);
                    setPreviewReportDropdownOpen(false);
                }
            }

            document.addEventListener("click", handleOutsideClick);
        }, [ref]);
    }

    const saveBtnClick = useCallback(() => {
        saveCallback();
    }, [saveCallback])

    const backBtnClick = useCallback(() => {
        goBack();
    }, [goBack])

    const addNewBtnClick = useCallback(() => {
        openNew();
    }, [openNew])

    const clickDocumentInfo = () => {
        setMoreOptionsDropdownOpen((prevState) => !prevState);
        showInfo();
    };

    const clickAuditLog = () => {
        setMoreOptionsDropdownOpen((prevState) => !prevState);
        props.openAuditLog(props.auditLogURL);
    };

    const clickDocumentMapping = () => {
        setMoreOptionsDropdownOpen((prevState) => !prevState);
        // call alaya accounting api to get DocumentMappingActors
        props.openDocumentMapping(props.docID, props.documentNo.replace("-", "_"));
    };

    const clickDocumentActionOptions = () => {
        setMoreOptionsDropdownOpen((prevState) => !prevState);
        window.parent.postMessage(getPostMessageObj(props.documentActionURL, "Document Action"), "*");
    };

    const clickPrintBarCode = () => {
        setMoreOptionsDropdownOpen((prevState) => !prevState);
        window.parent.postMessage(getPostMessageObj(props.printBarcodeURL, "Print BarCode(" + props.documentNo.replace("-", "_") + ")"), "*");
    };

    const clickACPosting = () => {
        setMoreOptionsDropdownOpen((prevState) => !prevState);
    };

    const clickPostAndNew = () => {
        setPostPreviewDropdownOpen((prevState) => !prevState);
        props.postAndNewFn();
    };

    const clickPostDocumentAction = () => {
        setPostPreviewDropdownOpen((prevState) => !prevState);
        props.postDocumentActionFn();
    };

    const clickPurchaseOrder = () => {
        setCopyFromDropdownOpen((prevState) => !prevState);
        props.openCopyFromPopUp("PO");
    };

    const clickGoodsReceive = () => {
        setCopyFromDropdownOpen((prevState) => !prevState);
        props.openCopyFromPopUp("GR")
    };

    const clickPurchaseReserveInvoice = () => {
        setCopyFromDropdownOpen((prevState) => !prevState);
    };

    const clickPurchaseInvoice = () => {
        setCopyFromDropdownOpen((prevState) => !prevState);
    };

    const clickReportFormat = () => {
        setPreviewReportDropdownOpen((prevState) => !prevState);
        props.reportFormatFn();
    };

    const hideInfo = () => {
        setState({
            docInfoPopUpVisible: false,
        });
    };

    const toggle = () => {
        setMoreOptionsDownIcon(!moreOptionsDownIcon);
        setMoreOptionsDropdownOpen((prevState) => !prevState);
    };

    function showInfo() {
        setState({
            docInfoPopUpVisible: true,
        });
    }

    const postPreviewToggle = () => {
        setPostPreviewDownIcon(!postPreviewDownIcon);
        setPostPreviewDropdownOpen((prevState) => !prevState);
    };

    const copyFromToggle = () => {
        setCopyFromDownIcon(!copyFromDownIcon);
        setCopyFromDropdownOpen((prevState) => !prevState);
    };

    const previewReportToggle = () => {
        setPreviewReportDownIcon(!previewReportDownIcon);
        setPreviewReportDropdownOpen((prevState) => !prevState);
    };

    const onShowing = () => {
        mousetrapMain.pause();
    }

    const onShown = () => {
        mousetrapMain.pause();
    }

    const onHidden = () => {
        mousetrapMain.unpause();
    }

    useEffect(() => {
        // register alt events
        if (displaySaveBtn) mousetrapMain.bind("alt+s", saveBtnClick);
        if (displayBackBtn) mousetrapMain.bind("alt+b", backBtnClick);
        if (displayAddNewBtn) mousetrapMain.bind("alt+f1", addNewBtnClick);

        return () => {
            mousetrapMain.unpause();
            
            if (displaySaveBtn) mousetrapMain.unbind("alt+s");
            if (displayBackBtn) mousetrapMain.unbind("alt+b");
            if (displayAddNewBtn) mousetrapMain.unbind("alt+f1");
        };
    }, [saveBtnClick, backBtnClick, addNewBtnClick, displaySaveBtn, displayBackBtn, displayAddNewBtn]);

    return (
        <React.Fragment>
            <Popup
                className="item-popup"
                visible={state.docInfoPopUpVisible}
                onShowing={onShowing}
                onShown={onShown}
                onHiding={hideInfo}
                onHidden={onHidden}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showTitle={true}
                title={formatMessage("DocumentInfo")}
                width={"auto"}
                height={"auto"}
            >
                <Form colCount={2}>
                    <Item colSpan={1}>
                        <div className="header-container">
                            <div className="doc-info-header">
                                <label>{formatMessage("Company")}</label>
                            </div>
                            <br />
                            <div className="doc-info-title">{DefaultName()}</div>
                        </div>
                    </Item>
                    <Item colSpan={1}>
                        <div className="header-container">
                            <div className="doc-info-header">
                                <label>{formatMessage("AssociatedCompany")}</label>
                            </div>
                            <br />
                            <div className="doc-info-title">
                                {props.displayIAGDescription}
                            </div>
                        </div>
                    </Item>
                    <Item colSpan={1}>
                        <div className="header-container">
                            <div className="doc-info-header">
                                <label>{formatMessage("CreatedBy")}</label>
                            </div>
                            <br />
                            <div className="doc-info-title">{props.displayCreatedBy}</div>
                        </div>
                    </Item>
                    <Item colSpan={1}>
                        <div className="header-container">
                            <div className="doc-info-header">
                                {formatMessage("CreatedDateandTime")}
                            </div>
                            <br />
                            <div className="doc-info-title">{props.displayCreatedDate}</div>
                        </div>
                    </Item>
                    <Item colSpan={1} visible={props.showFiscal}>
                        <div className="header-container">
                            <div className="doc-info-header">
                                <label>{formatMessage("FiscalYear")}</label>
                            </div>
                            <br />
                            <div className="doc-info-title">{props.displayFiscalYear}</div>
                        </div>
                    </Item>
                    <Item colSpan={1} visible={props.showFiscal}>
                        <div className="header-container">
                            <div className="doc-info-header">
                                {formatMessage("FiscalPeriod")}
                            </div>
                            <br />
                            <div className="doc-info-title">{props.displayFiscalPeriod}</div>
                        </div>
                    </Item>
                </Form>
            </Popup>
            <Form colCount={16}>
                <Item colSpan={16}>
                    <Toolbar className="toolbar-cus">
                        <Item visible={!!displayBackBtn} location="before">
                            <div
                                data-tip={formatMessage("BackAltB")}
                                data-for="backBtn"
                                className="toolbarBtn"
                            >
                                <Button
                                    id="backBtn"
                                    text={isNotMobile ? formatMessage("Back") : ""}
                                    stylingMode="text"
                                    style={{
                                        width: isNotMobile ? "unset" : 36,
                                        padding: isNotMobile ? "0 6px" : 0,
                                    }}
                                    onClick={backBtnClick}
                                    type="normal"
                                    icon="normalize-icon dx-icon-back"
                                    className={
                                        isNotMobile
                                            ? "toolbarBtn-content"
                                            : "toolbarBtn-content-mobile"
                                    }
                                ></Button>

                                <ReactTooltip
                                    id="backBtn"
                                    place="bottom"
                                    effect="solid"
                                    arrowColor="#474751"
                                />
                            </div>
                        </Item>

                        <Item visible={!!props.displayPostPreview} location="before">
                            <div
                                data-tip={formatMessage("PostPreview")}
                                data-for="postPreview"
                                ref={postPreviewOutsideClick}
                            >
                                <button className="toolbarBtn" id="dropDownBtn">
                                    <div
                                        style={{
                                            width: isNotMobile ? "unset" : 36,
                                            paddingLeft: isNotMobile ? 10 : 4,
                                        }}
                                        className="toolbarBtn-content"
                                        onClick={props.postPreviewFn}
                                    >
                                        <i
                                            className={`toolbarBtn-icon normalize-icon icon-post-preview-icon ${isNotMobile
                                                ? "icon-post-preview-icon-not-mobile"
                                                : "icon-post-preview-icon-mobile"
                                                }`}
                                        />
                                        {isNotMobile && (
                                            <i className="toolbarBtn-text">
                                                {formatMessage("PostPreview")}
                                            </i>
                                        )}
                                    </div>
                                    {isNotMobile && (
                                        <div
                                            className="toolbarBtn-content"
                                            onClick={postPreviewToggle}
                                        >
                                            <i
                                                className={`toolbarBtn-icon normalize-icon icon-down-icon ${postPreviewDownIcon === true
                                                    ? "down-icon-transform"
                                                    : ""
                                                    }`}
                                            />
                                        </div>
                                    )}
                                </button>
                                {!isNotMobile && (
                                    <ReactTooltip
                                        id="postPreview"
                                        place="bottom"
                                        effect="solid"
                                        arrowColor="#474751"
                                    />
                                )}

                                {postPreviewDropdownOpen && (
                                    <div className="drop-down-list post-preview-drop-down-list">
                                        {!!props.displayPostNew && (
                                            <button
                                                onClick={clickPostAndNew}
                                                className="document-info-style post-and-new"
                                            >
                                                <i className="icon-post-and-new" />
                                                <i className="document-info-btn">
                                                    {formatMessage("PostAndNew")}
                                                </i>
                                            </button>
                                        )}

                                        <button
                                            onClick={clickPostDocumentAction}
                                            className="document-info-style post-and-document-action"
                                        >
                                            <i className="icon-post-and-document-action" />
                                            <i className="document-info-btn">
                                                {formatMessage("PostDocumentAction")}
                                            </i>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </Item>

                        <Item visible={!!props.displayPostBtn} location="before">
                            <button
                                data-tip={formatMessage("Post")}
                                data-for="post"
                                className="toolbarBtn"
                                id="postBtn"
                            >
                                <div
                                    style={{ width: isNotMobile ? "unset" : 36 }}
                                    onClick={props.postFn}
                                    className="toolbarBtn-content"
                                >
                                    <i className="toolbarBtn-icon normalize-icon icon-post-icon" />
                                    {isNotMobile && (
                                        <i className="toolbarBtn-text">{formatMessage("Post")}</i>
                                    )}
                                </div>
                                {!isNotMobile && (
                                    <ReactTooltip
                                        id="post"
                                        place="bottom"
                                        effect="solid"
                                        arrowColor="#474751"
                                    />
                                )}
                            </button>
                        </Item>

                        <Item visible={!!displaySaveBtn} location="before">
                            <div
                                data-tip={formatMessage("SaveAltS")}
                                data-for="saveBtn"
                                className="toolbarBtn"
                            >
                                <Button
                                    id="saveBtn"
                                    text={
                                        isNotMobile
                                            ? formatMessage(props.saveBtnText || "Save")
                                            : ""
                                    }
                                    stylingMode="text"
                                    style={{
                                        width: isNotMobile ? "unset" : 36,
                                        padding: isNotMobile ? "0 6px" : 0,
                                    }}
                                    onClick={saveBtnClick}
                                    type="normal"
                                    icon="normalize-icon dx-icon-save"
                                    className="toolbarBtn-content"
                                ></Button>

                                <ReactTooltip
                                    id="saveBtn"
                                    place="bottom"
                                    effect="solid"
                                    arrowColor="#474751"
                                />
                            </div>
                        </Item>

                        <Item visible={!!props.displayCopyFrom} location="before">
                            <div ref={copyFromOutsideClick}>
                                <button
                                    data-tip={formatMessage(props.copyBtnText || "Copy")}
                                    data-for="copyBtn"
                                    onClick={copyFromToggle}
                                    className="toolbarBtn"
                                    id="dropDownBtn"
                                    style={{
                                        width: isNotMobile ? "unset" : 48,
                                    }}
                                >
                                    <div
                                        className="toolbarBtn-content"
                                        style={{
                                            width: isNotMobile ? "unset" : 48,
                                            padding: "0 6px",
                                            paddingLeft: isNotMobile ? 10 : 6,
                                        }}
                                    >
                                        <i className="toolbarBtn-icon normalize-icon icon-copy-icon" />
                                        {isNotMobile && (
                                            <>
                                                <i className="toolbarBtn-text">
                                                    {formatMessage(props.copyBtnText || "Copy")}
                                                </i>
                                                <i
                                                    className={`toolbarBtn-icon normalize-icon icon-down-icon ${copyFromDownIcon === true
                                                        ? "down-icon-transform"
                                                        : ""
                                                        }`}
                                                />
                                            </>
                                        )}
                                    </div>
                                    <ReactTooltip
                                        id="copyBtn"
                                        place="bottom"
                                        effect="solid"
                                        arrowColor="#474751"
                                    />
                                </button>

                                {copyFromDropdownOpen && (
                                    <div className="drop-down-list">
                                        {!!props.displayCopyFromPO && (
                                            <button
                                                onClick={clickPurchaseOrder}
                                                className="document-info-style"
                                            >
                                                <i className="icon-purchase-order" />
                                                <i className="document-info-btn">
                                                    {formatMessage("PurchaseOrder")}
                                                </i>
                                            </button>
                                        )}
                                        {!!props.displayCopyFromPGR && (
                                            <button
                                                onClick={clickGoodsReceive}
                                                className="document-info-style"
                                            >
                                                <i className="icon-goods-receive" />
                                                <i className="document-info-btn">
                                                    {formatMessage("GOODRECEIVE")}
                                                </i>
                                            </button>
                                        )}
                                        {!!props.displayCopyFromPRI && (
                                            <button
                                                onClick={clickPurchaseReserveInvoice}
                                                className="document-info-style"
                                            >
                                                <i className="icon-purchase-reserve-invoice" />
                                                <i className="document-info-btn">
                                                    {formatMessage("PurchaseReserveInvoice")}
                                                </i>
                                            </button>
                                        )}
                                        {!!props.displayCopyFromPI && (
                                            <button
                                                onClick={clickPurchaseInvoice}
                                                className="document-info-style"
                                            >
                                                <i className="icon-purchase-invoice" />
                                                <i className="document-info-btn">
                                                    {formatMessage("PurchaseInvoice")}
                                                </i>
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Item>

                        <Item visible={!!displayAddNewBtn} location="before">
                            <div
                                data-tip={formatMessage("ClearAltF1")}
                                data-for="newBtn"
                                className="toolbarBtn"
                            >
                                <Button
                                    id="newBtn"
                                    text={isNotMobile ? formatMessage("New1") : ""}
                                    stylingMode="text"
                                    style={{
                                        width: isNotMobile ? "unset" : 36,
                                        padding: isNotMobile ? "0 6px" : 0,
                                    }}
                                    onClick={addNewBtnClick}
                                    type="normal"
                                    icon={`normalize-icon dx-icon-doc ${isNotMobile ? "dx-icon-doc-not-mobile" : ""
                                        }`}
                                    className="toolbarBtn-content"
                                ></Button>

                                <ReactTooltip
                                    id="newBtn"
                                    place="bottom"
                                    effect="solid"
                                    arrowColor="#474751"
                                />
                            </div>
                        </Item>

                        <Item visible={!!props.displayPreviewReport} location="before">
                            <div
                                data-tip={formatMessage("PreviewReport")}
                                data-for="previewReport"
                                ref={previewReportOutsideClick}
                            >
                                <button className="toolbarBtn" id="dropDownBtn">
                                    <div
                                        style={{
                                            width: isNotMobile ? "unset" : 36,
                                            paddingLeft: isNotMobile ? 10 : 4,
                                        }}
                                        className="toolbarBtn-content"
                                        onClick={props.previewReportFn}
                                    >
                                        <i
                                            className={`toolbarBtn-icon normalize-icon icon-preview-report-icon ${isNotMobile
                                                ? "icon-preview-report-icon-not-mobile"
                                                : "icon-preview-report-icon-mobile"
                                                }`}
                                        />
                                        {isNotMobile && (
                                            <i className="toolbarBtn-text">
                                                {formatMessage("PreviewReport")}
                                            </i>
                                        )}
                                    </div>
                                    {isNotMobile && (
                                        <div
                                            className="toolbarBtn-content"
                                            onClick={previewReportToggle}
                                        >
                                            <i
                                                className={`toolbarBtn-icon normalize-icon icon-down-icon ${previewReportDownIcon === true
                                                    ? "down-icon-transform"
                                                    : ""
                                                    }`}
                                            />
                                        </div>
                                    )}
                                </button>
                                {!isNotMobile && (
                                    <ReactTooltip
                                        id="previewReport"
                                        place="bottom"
                                        effect="solid"
                                        arrowColor="#474751"
                                    />
                                )}

                                {previewReportDropdownOpen ? (
                                    <div className="drop-down-list">
                                        <button
                                            onClick={clickReportFormat}
                                            className="document-info-style"
                                        >
                                            <i className="icon-report-format" />
                                            <i className="document-info-btn">
                                                {formatMessage("ReportFormat")}
                                            </i>
                                        </button>
                                    </div>
                                ) : null}
                            </div>
                        </Item>

                        <Item visible={!!props.displayMoreOptions} location="before">
                            <div ref={outsideClick}>
                                <button
                                    onClick={toggle}
                                    className="toolbarBtn"
                                    id="dropDownBtn"
                                    style={{
                                        width: isNotMobile ? "unset" : 43,
                                    }}
                                >
                                    <div
                                        className="toolbarBtn-content"
                                        style={{
                                            width: isNotMobile ? "unset" : 43,
                                            padding: "0 6px",
                                            paddingLeft: isNotMobile ? 10 : 6,
                                        }}
                                    >
                                        <i className="toolbarBtn-icon normalize-icon icon-more-options" />
                                        {isNotMobile && (
                                            <>
                                                <i className="toolbarBtn-text more-options-btn">
                                                    {formatMessage("MoreOptions")}
                                                </i>
                                                <i
                                                    className={`toolbarBtn-icon normalize-icon icon-down-icon ${moreOptionsDownIcon === true
                                                        ? "down-icon-transform"
                                                        : ""
                                                        }`}
                                                />
                                            </>
                                        )}
                                    </div>
                                </button>

                                {moreOptionsDropdown && (
                                    <div className="drop-down-list">
                                        {!!props.displayAuditLog && (
                                            <button
                                                onClick={clickAuditLog}
                                                className="document-info-style"
                                            >
                                                <i className="icon-audit-log" />
                                                <i className="document-info-btn">
                                                    {formatMessage("AuditLog")}
                                                </i>
                                            </button>
                                        )}
                                        {!!props.displayDocumentMapping && (
                                            <button
                                                onClick={clickDocumentMapping}
                                                className="document-info-style document-mapping"
                                            >
                                                <i className="icon-document-mapping-1" />
                                                <i className="document-info-btn">
                                                    {formatMessage("DocumentMapping")}
                                                </i>
                                            </button>
                                        )}
                                        {!!props.displayDocumentAction && (
                                            <button
                                                onClick={clickDocumentActionOptions}
                                                className="document-info-style document-action"
                                            >
                                                <i className="icon-document-action" />
                                                <i className="document-info-btn">
                                                    {formatMessage("DocumentActionOptions")}
                                                </i>
                                            </button>
                                        )}
                                        {!!props.displayACPosting && (
                                            <button
                                                onClick={clickACPosting}
                                                className="document-info-style ac-posting"
                                            >
                                                <i className="icon-ac-posting" />
                                                <i className="document-info-btn">
                                                    {formatMessage("ACPosting")}
                                                </i>
                                            </button>
                                        )}
                                        {!!props.displayPrintBarCode && (
                                            <button
                                                onClick={clickPrintBarCode}
                                                className="document-info-style print-barcode"
                                            >
                                                <i className="icon-print-barcode" />
                                                <i className="document-info-btn">
                                                    {formatMessage("PrintBarcode")}
                                                </i>
                                            </button>
                                        )}
                                        <button
                                            onClick={clickDocumentInfo}
                                            className="document-info-style"
                                        >
                                            <i className="icon-document-info" />
                                            <i className="document-info-btn">
                                                {formatMessage("DocumentInfo")}
                                            </i>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </Item>
                    </Toolbar>
                </Item>
            </Form>
        </React.Fragment>
    );
};

export default Toolbar1;
