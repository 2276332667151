import React, { useRef } from 'react'
import { Row, Col } from "antd";
import { Field } from "formik";
import { DateSinglePickerInput, DropdownInput, NumberInput, TextInput } from '../../../../fields';
import { Button } from 'devextreme-react';
import { formatMessage } from 'devextreme/localization';
import { cloneDeep, isEmpty } from "lodash";

const SectionSngDetails = (props) => {
    const { dateFormat, itemColorConfig, onGenerateClick } = props;

    const colorConfig = useRef(initColorConfig());
    const portalRef = useRef(null);

    function initColorConfig() {
        if (isEmpty(itemColorConfig)) return {};

        const itemColorConfigCopy = cloneDeep(itemColorConfig);
        itemColorConfigCopy?.dataSource?.shift();

        return {
            dataSource: itemColorConfigCopy.dataSource,
            headerColumn: [itemColorConfig.columns[0].caption, itemColorConfig.columns[1].caption],
            dataColumn: [itemColorConfig.columns[0].dataField, itemColorConfig.columns[1].dataField],
            displayColumn:itemColorConfig.columns[1].dataField,
            filtervalue: [itemColorConfig.columns[0].dataField, itemColorConfig.columns[1].dataField],
            getColumnValue: itemColorConfig.keyExpr,
        };
    }

    return (
        <Row gutter={30}>
            <div className="portalRef dx-dropdowneditor-overlay" ref={portalRef} />

            <Col xs={24} md={12} lg={6}>
                <Field
                    // Styling
                    className="input-w input-align-left"
                    // Labels
                    label={formatMessage("ExpiryDate")}
                    name={"expiryDate"}
                    // Formatting
                    format={dateFormat}
                    // Others
                    component={DateSinglePickerInput}
                    portalRef={portalRef}
                />
            </Col>
            <Col xs={24} md={12} lg={6}>
                <Field
                    // Styling
                    className="input-w input-align-left"
                    // Labels
                    label={'Manufacturer Date'} // [Translation] Update Translation
                    name={"manufacturerDate"}
                    // Formatting
                    format={dateFormat}
                    // Others
                    component={DateSinglePickerInput}
                    portalRef={portalRef}
                />
            </Col>
            <Col xs={24} md={12} lg={6}>
                <Field
                    // Styling
                    className="input-w input-align-left"
                    // Labels
                    label={formatMessage('Color')}
                    name={"color"}
                    placeholder={"Select Color"} // [Translation] Update Translation
                    // Date Source
                    {...colorConfig.current}
                    // Others
                    component={DropdownInput}
                    suffixIcon={<></>}
                />
            </Col>
            <Col xs={24} md={12} lg={6}>
                <Field
                    // Styling
                    className="input-w input-align-left"
                    // Labels
                    label={formatMessage('Reference')}
                    name={"reference"}
                    placeholder={formatMessage('Reference')}
                    // Others
                    component={TextInput}
                />
            </Col>
            <Col xs={24} md={12} lg={6}>
                <Field
                    // Styling
                    className="input-w input-align-left"
                    // Labels
                    label={formatMessage('Remarks')}
                    name={"remarks"}
                    placeholder={formatMessage('Remarks')}
                    // Others
                    component={TextInput}
                />
            </Col>
            <Col xs={24} md={12} lg={6}>
                <Field
                    // Styling
                    className="input-w input-align-left"
                    // Labels
                    label={'Total Unit'} // [Translation] Update Translation
                    name={"totalUnit"}
                    placeholder={"Total Unit"} // [Translation] Update Translation
                    // Formatting
                    step={0}
                    precision={0}
                    min={0}
                    max={999999999999}
                    // Others
                    component={NumberInput}
                />
            </Col>
            <Col xs={24} md={12} lg={6}>
                <Button
                    hoverStateEnabled={true}
                    activeStateEnabled={false}
                    focusStateEnabled={false}
                    className="sng-btn"
                    text={"Generate"} // [Translation] Update Translation
                    type="default"
                    stylingMode="contained"
                    onClick={onGenerateClick}
                />
            </Col>
        </Row>
    )
}

export default SectionSngDetails