import { useEffect, useRef } from "react";

export function useOutsideClick(active, callback) {
    const ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        if (active) {
            document.addEventListener("mousedown", handler);
            document.addEventListener("keydown", handler);
        }

        return () => {
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("keydown", handler);
        };
    }, [active, callback]);

    return ref;
}