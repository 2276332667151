import { useCallback, useState } from 'react'
import { cloneDeep } from "lodash";

const usePopup = (config) => {
    const { defaultVisible } = config;

    const [popupData, setPopupData] = useState({});
    const [visible, setVisible] = useState(defaultVisible);
    const [isInactive, setIsInactive] = useState(!defaultVisible);

    const showPopup = useCallback((data) => {
        setPopupData(cloneDeep(data));
        setVisible(true);
        setIsInactive(false);
    }, [])

    const onHiding = () => {
        setVisible(false);
    }

    const onHidden = () => {
        setIsInactive(true);
    }

    return {
        popupProps: { popupData, visible, onHiding, onHidden },
        isInactive,
        showPopup,
    }
}

export default usePopup