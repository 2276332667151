import moment from "moment";

export function isValidDate(dateObject) {
    return new Date(dateObject).toString() !== 'Invalid Date';
}

export const getLocalTimeString = (date, updateTimeToNow) => {
    const newDate = new Date(date);

    if (!isValidDate(newDate)) return "";

    const getDate = (date) => {
        const day = ("0" + moment(date).get("date")).slice(-2);
        return day;
    };

    const getMonth = (date) => {
        const month = ("0" + (moment(date).get("month") + 1)).slice(-2);
        return month;
    };

    const getTime = () => {
        const date = updateTimeToNow ? new Date() : newDate;
        return date.toLocaleTimeString('en-GB');
    }

    const localDateString = `${newDate.getFullYear()}-${getMonth(newDate)}-${getDate(newDate)}T${getTime()}`;

    return localDateString;
};

export const getRangeLocalTimeString = (offsetDays = 0, initialDate = moment()) => {
    // returns range of dates based on initialDate and offsetDays
    const date = initialDate.clone();
    const dateWithOffset = initialDate.clone().add(offsetDays, "days");

    if (offsetDays >= 0) {
        // postive offset
        const todaylocalDateString = getLocalTimeString(date.set({ "hour": 0, "minute": 0, "second": 0 }).toDate());
        const todayWithOffsetlocalDateString = getLocalTimeString(dateWithOffset.set({ "hour": 23, "minute": 59, "second": 59 }).toDate());
        return [todaylocalDateString, todayWithOffsetlocalDateString];
    } else {
        // negative offset
        const todaylocalDateString = getLocalTimeString(date.set({ "hour": 23, "minute": 59, "second": 59 }).toDate());
        const todayWithOffsetlocalDateString = getLocalTimeString(dateWithOffset.set({ "hour": 0, "minute": 0, "second": 0 }).toDate());
        return [todayWithOffsetlocalDateString, todaylocalDateString];
    }
}

export function toFixed(number, minPrecision = 0, maxPrecision = 0) {
    // source: https://dfkaye.com/posts/2021/07/17/fixing-number.tofixed/
    return number?.toLocaleString(undefined, {
        minimumFractionDigits: minPrecision,
        maximumFractionDigits: Math.max(minPrecision, maxPrecision)
    }).replaceAll(',', '');
}

export function toFixedNumber(number, minPrecision = 0) {
    // source: https://dfkaye.com/posts/2021/07/17/fixing-number.tofixed/
    const result = number?.toLocaleString(undefined, {
        minimumFractionDigits: minPrecision,
        maximumFractionDigits: minPrecision
    }).replaceAll(',', '');

    return Number(result);
}

export function isNumeric(str) {
    if (typeof str !== "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
}