import React from "react";
import { Input } from "antd";
import { FormItem } from "../forms";

import "./styles/TextInput.scss";
import useFormikValidateOnError from "../../hooks/useFormikValidateOnError";

export const TextInput = (props) => {
    const {
        disabled = false,
        placeholder = "",
        className = "input-lg",
        field,
        form,
        onChangeCallback,
        onPressEnterCallback,
        onEnterClear,
        maxLength = 100,
        label,
        validateOnError = true,
    } = props;

    useFormikValidateOnError(field, form, validateOnError);

    const onChange = (option) => {
        form.setFieldValue(field.name, option.target.value).then(function () {
            onChangeCallback && onChangeCallback(option.target.value);
        })
    }

    const onPressEnter = (option) => {
        onPressEnterCallback && onPressEnterCallback(option.target.value);
        if (onEnterClear) form.setFieldValue(field.name, null);
    }

    return (
        <FormItem field={field} {...props}>
            <Input
                {...field}
                className={className}
                size="large"
                placeholder={placeholder || label}
                disabled={disabled}
                maxLength={maxLength}
                onChange={onChange}
                onPressEnter={onPressEnter}
                autoComplete="off"
            />
        </FormItem>
    );
};
