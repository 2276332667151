import React, { memo } from 'react'
import { Input } from "antd";

const RadioInput = (props) => {
    const {
        id,
        radioSelected,
        radioDisabled,
        radioOnSelect: _radioOnSelect,
        inputValue,
        inputOnChange: _inputOnChange,
        inputMaxLength = 100,
        placeholder,
    } = props;

    function radioOnSelect() {
        _radioOnSelect?.({ id, value: !radioSelected });
    }

    function inputOnChange(event) {
        _inputOnChange?.({ id, value: event.target.value });
    }

    return (
        <div className="sng-radio-input-container">
            <div
                className={`sng-radio-container sng-radio-container-${radioSelected && "selected"} sng-radio-container-${radioDisabled && "disabled"}`}
                onClick={radioOnSelect}
            >
                <div className="sng-radio-btn">
                    <div className="sng-radio-btn-dot" />
                </div>
            </div>
            <div className="sng-input-container">
                <Input
                    className="input-w input-align-left"
                    size="large"
                    value={inputValue}
                    onChange={inputOnChange}
                    placeholder={placeholder}
                    disabled={false}
                    maxLength={inputMaxLength}
                    autoComplete="off"
                />
            </div>
        </div>
    )
}

export default memo(RadioInput)