import React, { useCallback, useEffect, useRef, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom';
import { DataGridDefault } from '../..';
import { formatMessage } from 'devextreme/localization';
import { Column, Editing, Summary, TotalItem } from 'devextreme-react/data-grid';
import { DefaultPager } from '../../../utils/default-data-grid-settings';
import { EditBtn } from '../..';
import PopupDefault from './popup-default';
import useFlashMsg from '../../modal-dialog/useFlashMsg';
import EditCellNumber from '../../data-grid/custom-cell/edit-cell-number';
import EditCellDropdown from '../../data-grid/custom-cell/edit-cell-dropdown';
import EditCellTextArea from '../../data-grid/custom-cell/edit-cell-textarea';
import SectionRemarks from './features/section-remarks';
import SectionInput from './features/section-input';
import { DecimalPoints, Token } from '../../../utils/default-cookies';
import { GetPackageChildItem } from '../../../api/purchase-invoice-details';
import { getErrorCode, setErrorPopUpMsg } from '../../../utils/error-popup-http-error-msg';
import { cloneDeep, isEmpty } from "lodash";
import { FormikDefault } from '../../forms/formik-default';
import { useErrorPopupContext } from '../../modal-dialog/ErrorPopupProvider';
import usePopup from '../../../hooks/usePopup';
import BatchItemPopup from './batch-item-popup';
import SerializedItemPopup from './serialized-item-popup';
import { ItemType } from '../../../utils/constant-item-type';
import { getEditCellProps } from '../../data-grid/custom-cell/util/edit-cell-util';
import { checkInvalidCell, checkInvalidRow } from '../../data-grid/data-grid-util';
import { useUserAccessContext } from '../../../contexts/UserAccess';

const PackageItemPopup = (props) => {
    const {
        submitCallback,
        cancelCallback,
        iAGID,
        userProfile,
        visible,
        onHiding,
        onHidden
    } = props;

    const {
        mainItem,
        subItemList: _subItemList,
        deleteOnCancel,
        _usedSerialNumSetList,
        _deletedSerialNumSetList,
    } = props.popupData;

    const {
        PID_GroupID: groupID,
        PID_ItemID: itemID,
        PID_Remarks: remarks,
        PID_Qty: totalPackage
    } = mainItem;

    const [subItemList, setSubItemList] = useState([]);

    const formikRef = useRef(null);
    const apiLoaded = useRef(false);
    const dataGridRef = useRef(null);
    const portalRef = useRef(null);
    const totalPackageRef = useRef(totalPackage); // number input total package

    const usedSerialNumSetList = useRef(_usedSerialNumSetList);
    const deletedSerialNumSetList = useRef(_deletedSerialNumSetList);

    const binLocationConfig = useRef({
        columns: [
            { dataField: "Code", caption: formatMessage("Code") },
            { dataField: "Description", caption: formatMessage("Description") },
        ],
        keyExpr: "ID",
        displayExpr: "Code",
    }); // BinLocation Dropdown DataGrid Config

    const precisionFormat = useRef({
        type: 'fixedPoint',
        precision: DecimalPoints() > 0 ? DecimalPoints() : 2,
    })

    const showErrorPopup = useErrorPopupContext();
    const { userAccess } = useUserAccessContext();
    const { flashMsg, showFlashMsg } = useFlashMsg();

    const { popupProps: batchItemProps, isInactive: isBatchItemInactive, showPopup: showBatchItemPopup } = usePopup({ defaultVisible: false });
    const { popupProps: serializedItemProps, isInactive: isSerializedItemInactive, showPopup: showSerializedItemPopup } = usePopup({ defaultVisible: false });
    const popupKey = useRef(0);

    // #region Util
    function getDataGrid() {
        return dataGridRef?.current?.instance;
    }

    const showItemTypePopup = useCallback((rowData) => {
        const itemType = rowData.PID_ItemTypeID;
        const itemID = rowData.PID_ItemID;

        const item = subItemList.find((item) => item.PID_ItemID === itemID); // get item from state as rowData might not be updated (repaintChangesOnly)
        const requiredQty = item.PID_Qty * totalPackageRef.current;

        const popupData = { mainItem: item, subItemList: [], requiredQty };

        popupKey.current += 1;

        switch (itemType) {
            case ItemType.BATCH_ITEM: {
                showBatchItemPopup(popupData);
                break;
            }
            case ItemType.SERIALIZED_ITEM: {
                const _usedSerialNumSet = usedSerialNumSetList.current[rowData.PID_ItemID] || new Set();
                const _deletedSerialNumSet = deletedSerialNumSetList.current[rowData.PID_ItemID] || new Set();
                showSerializedItemPopup({ ...popupData, _usedSerialNumSet, _deletedSerialNumSet });
                break;
            }
            case ItemType.STOCK_SN_AND_BATCH_ITEM: {
                const _usedSerialNumSet = usedSerialNumSetList.current[rowData.PID_ItemID] || new Set();
                const _deletedSerialNumSet = deletedSerialNumSetList.current[rowData.PID_ItemID] || new Set();
                const hasBatchNo = true;
                showSerializedItemPopup({ ...popupData, _usedSerialNumSet, _deletedSerialNumSet, hasBatchNo });
                break;
            }
            default: {
                break;
            }
        }
    }, [showBatchItemPopup, showSerializedItemPopup, subItemList])

    const editSubItemByItemID = (itemID, editedSubItem) => {
        const subItemIndex = subItemList.findIndex(subItem => subItem.PID_ItemID === itemID);
        if (subItemIndex === -1) return false;

        const subItemListCopy = cloneDeep(subItemList);
        subItemListCopy[subItemIndex] = { ...subItemListCopy[subItemIndex], ...editedSubItem, IsAlertRow: false };

        setSubItemList(subItemListCopy);
    }
    // #endregion Util

    // #region Events
    const itemTypePopupSubmit = ({ itemID, editedMainItem: editedSubItem }) => {
        unstable_batchedUpdates(function () {
            editSubItemByItemID(itemID, editedSubItem);
        })
    }

    const serializedItemPopupSubmit = ({ itemID, editedMainItem: editedSubItem }) => {
        unstable_batchedUpdates(function () {
            usedSerialNumSetList.current[itemID] = editedSubItem.itemDetail.usedSerialNumSet; // update usedSerialNumSet
            deletedSerialNumSetList.current[itemID] = editedSubItem.itemDetail.deletedSerialNumSet; // update deletedSerialNumSet

            editSubItemByItemID(itemID, editedSubItem);
        })
    }

    function onTotalPackageChange(value) {
        totalPackageRef.current = value;

        subItemList.forEach(item => {
            // make subItem that has deeper subItem as IsAlertRow
            switch (item.PID_ItemTypeID) {
                case ItemType.SERIALIZED_ITEM:
                case ItemType.STOCK_SN_AND_BATCH_ITEM:
                case ItemType.BATCH_ITEM:
                    item.IsAlertRow = true;
                    return;
                default:
                    return;
            }
        });

        getDataGrid()?.refresh(true); // refresh data grid to update data rows & summary
    }

    async function onClickEdit(event) {
        const errors = await formikRef.current.validateForm();
        if (!isEmpty(errors)) return;

        showItemTypePopup(event.data);
    }

    async function onSubmit(values, actions) {
        await getDataGrid()?.refresh(true); // refresh data grid to get updated data rows

        const total = getDataGrid()?.getTotalSummaryValue("_TOTALQTY");

        if (checkInvalidRow(subItemList)) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: "ContainInvalidRow"
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            actions.setSubmitting(false);
            return false;
        } else if (await checkInvalidCell(subItemList)) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: "ContainInvalidCell"
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            actions.setSubmitting(false);
            return false;
        } else if (!total) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: "PleaseInsertAtLeastOneQuantity"
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            actions.setSubmitting(false);
            return false;
        } else {
            const totalPackage = values.totalPackage;
            const remarks = values.remarks;
            const totalUnitCost = getDataGrid()?.getTotalSummaryValue("_TOTALCOST") / totalPackage;
            const itemDetail = {
                usedSerialNumSetList: usedSerialNumSetList.current,
                deletedSerialNumSetList: deletedSerialNumSetList.current,
            };

            const editedMainItem = { ...mainItem, PID_Remarks: remarks, PID_UnitPrice: totalUnitCost, PID_Qty: totalPackage, itemDetail };
            const editedSubItem = subItemList;

            submitCallback({ editedMainItem, editedSubItem });
            actions.setSubmitting(false);
            return true;
        }
    }

    function onCancel() {
        if (deleteOnCancel) {
            cancelCallback?.(groupID);
        }
    }
    // #endregion Events

    // #region Data Util
    function initData(subItemList) {
        unstable_batchedUpdates(function () {
            setSubItemList(subItemList);
            apiLoaded.current = true;
        })
    }
    // #endregion Data Util

    // #region Data Fetch API
    const fetchInitialData = useCallback(() => {
        if (!Token()) return;

        setTimeout(() => {
            getDataGrid()?.beginCustomLoading();
        }, 0);

        GetPackageChildItem(Token(), itemID).then((data) => {
            if (typeof data === "string" && data.includes("Error")) {
                const errorCode = getErrorCode(data);
                const errorMsg = setErrorPopUpMsg(errorCode);

                showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            } else {
                const subItemListCopy = cloneDeep(_subItemList);

                subItemListCopy.forEach(subItem => {
                    const itemIndex = data.findIndex(childItem => childItem.PI_ChildItemID === subItem.PID_ItemID);

                    if (itemIndex === -1) {
                        subItem.editPermission = {};
                        subItem.PID_Qty = 0;
                    } else {
                        subItem.editPermission = data[itemIndex].AllowEditButton;
                        subItem.PID_Qty = data[itemIndex].PI_Qty;
                    }
                });

                initData(subItemListCopy);
            }
        }).finally(() => {
            getDataGrid()?.endCustomLoading();
        })
    }, [_subItemList, itemID, showErrorPopup]);
    // #endregion Data Fetch API

    // #region DataGrid configurations
    const onRowPrepared = useCallback((event) => {
        if (event.rowType === "data") {
            if (event.data.IsAlertRow && !event.rowElement.classList.contains('alert-row')) {
                event.rowElement.classList.add('alert-row');
            }
        }
    }, [])
    // #endregion DataGrid configurations

    // #region UseEffect
    useEffect(() => {
        if (!isEmpty(_subItemList) && !_subItemList[0].editPermission) {
            fetchInitialData();
        } else {
            initData(_subItemList);
        }
    }, [_subItemList, fetchInitialData]);
    // #endregion UseEffect

    // #region Render
    function calculateTotalCost(rowData) {
        return rowData?.PID_UnitPrice * rowData.PID_Qty * totalPackageRef.current;
    }

    function calculateTotalQty(rowData) {
        return rowData?.PID_Qty * totalPackageRef.current;
    }

    function editBtnCell(cellData) {
        const isEditable = cellData.data?.editPermission?.PI_Edit;

        return (
            <EditBtn
                {...cellData}
                onClick={onClickEdit}
                enabled={isEditable}
            />
        );
    }

    function binLocationCell(cellData, dataGridConfig) {
        const { info, ...editCellProps } = getEditCellProps(cellData);

        return (
            <EditCellDropdown
                {...editCellProps}
                dataSource={info.data?.BinLoc}
                {...dataGridConfig}
                portalRef={portalRef}
                isEditable
            />
        )
    }

    function qtyCell(cellData) {
        const { info, ...editCellProps } = getEditCellProps(cellData);
        const isEditable = info.data?.editPermission?.PI_Qty;

        return (
            <EditCellNumber
                {...editCellProps}
                isEditable={isEditable}
                isRequired
                min={1}
            />
        )
    }

    function remarksCell(cellData) {
        const { info, ...editCellProps } = getEditCellProps(cellData);

        return (
            <EditCellTextArea
                {...editCellProps}
                portalRef={portalRef}
                isEditable
            />
        )
    }
    // #endregion Render

    return (
        <FormikDefault
            initialValues={{
                remarks: remarks,
                totalPackage: totalPackage || null,
            }}
            onSubmit={onSubmit}
            formikRef={formikRef}
        >
            <PopupDefault
                className="purchase-invoice-popup"
                title={formatMessage("PackageItem")}
                visible={visible}
                onHiding={onHiding}
                onHidden={onHidden}
                cancelForm={onCancel}
                apiLoaded={apiLoaded.current}
                formikRef={formikRef}
            >
                <div className="portalRef dx-dropdowneditor-overlay" ref={portalRef} />

                {flashMsg}

                {
                    isBatchItemInactive ? null :
                        <BatchItemPopup
                            {...batchItemProps}
                            key={`ItemPopup-Batch-${popupKey.current}`}
                            submitCallback={itemTypePopupSubmit}
                            iAGID={iAGID}
                            docType={"PI"}
                            userProfile={userProfile}
                        />
                }

                {
                    isSerializedItemInactive ? null :
                        <SerializedItemPopup
                            {...serializedItemProps}
                            key={`ItemPopup-Serialized-${popupKey.current}`}
                            submitCallback={serializedItemPopupSubmit}
                            iAGID={iAGID}
                            docType={"PI"}
                            userProfile={userProfile}
                        />
                }

                <SectionInput
                    // Labels
                    label={formatMessage('TotalPackage')}
                    name="totalPackage"
                    placeholder={"Enter Total Package"} // [Translation] Update Translation
                    // Events
                    onChangeCallback={onTotalPackageChange}
                    // Others
                    required
                    disabled={!apiLoaded.current}
                />

                <div className="module-data-grid-popup" style={{ marginTop: "16px" }}>
                    <DataGridDefault
                        // Styling
                        className='dx-datagrid-items datagrid-normalize'
                        // DevExtreme DataGrid Configurations
                        dataSource={subItemList}
                        keyExpr="PID_Item.IM_Code"
                        allowDisplayColumnChooser={userAccess.allowDisplayColumnChooser}
                        allowExportGrid={userAccess.allowExportGrid}
                        allowRestoreLayout={userAccess.allowRestoreLayout}
                        allowedPageSizes={DefaultPager.allowedPageSizes_details}
                        defaultPageSize={DefaultPager.defaultPageSize_details}
                        onRowPrepared={onRowPrepared}
                        noDataText={formatMessage("dxDataGrid-noDataText")}
                        focusedRowEnabled={true}
                        // Custom Configurations
                        dataGridRef={dataGridRef}
                        showMsgHandler={showFlashMsg}
                        exportFileName="PackageItemPopup"
                    >
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                        />
                        <Column
                            visibleIndex={0}
                            fixedPosition={'left'}
                            fixed={true}
                            width={50}
                            minWidth={50}
                            allowExporting={false}
                            allowFiltering={false}
                            allowHeaderFiltering={false}
                            allowHiding={false}
                            allowReordering={false}
                            allowResizing={false}
                            allowSorting={false}
                            showInColumnChooser={false}
                            cssClass="column-normalize"
                            caption={null}
                            cellRender={editBtnCell}
                            dataField={'_TOTAL'} // dummy dataField for summary
                        />
                        <Column
                            caption={formatMessage('ItemCode')}
                            dataField={'PID_Item.IM_Code'}
                        />
                        <Column
                            caption={formatMessage('Description')}
                            dataField={'PID_Item.IM_Description'}
                        />
                        <Column
                            caption={formatMessage("BinLocation")}
                            dataField={"PID_BinLocationID"}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={(cellData) => binLocationCell(cellData, binLocationConfig.current)}
                            editCellComponent={(cellData) => binLocationCell(cellData, binLocationConfig.current)}
                            dataType="string"
                            minWidth={60}
                            editCellDisplayValue="editCell.binLocation"
                            calculateDisplayValue="editCell.binLocation"
                        />
                        <Column
                            caption={formatMessage('SmallCapitalQty')}
                            dataField={'PID_Qty'}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={qtyCell}
                            editCellComponent={qtyCell}
                            dataType="number"
                        />
                        <Column
                            caption={formatMessage('UnitCost')}
                            dataField={'PID_UnitPrice'}
                            format={precisionFormat.current}
                        />
                        <Column
                            caption={formatMessage('TotalCost')}
                            dataField={'_TOTALCOST'} // dummy dataField for summary
                            calculateCellValue={calculateTotalCost}
                            format={precisionFormat.current}
                        />
                        <Column
                            caption={formatMessage('TotalQty')}
                            dataField={'_TOTALQTY'} // dummy dataField for summary
                            calculateCellValue={calculateTotalQty}
                        />
                        <Column
                            caption={formatMessage("Remarks")}
                            dataField={"PID_Remarks"}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={remarksCell}
                            editCellComponent={remarksCell}
                            minWidth={60}
                            maxWidth={200}
                        />

                        <Summary>
                            <TotalItem
                                summaryType="count"
                                showInColumn="_TOTAL"
                                displayFormat={`${formatMessage("Total")}:`}
                                cssClass="summary-overflow"
                                skipEmptyValues
                            />
                            <TotalItem
                                summaryType="sum"
                                column="_TOTALCOST"
                                displayFormat="{0}"
                                valueFormat={precisionFormat.current}
                            />
                            <TotalItem
                                summaryType="sum"
                                column="_TOTALQTY"
                                displayFormat="{0}"
                            />
                        </Summary>
                    </DataGridDefault>
                </div>

                <SectionRemarks />

            </PopupDefault>
        </FormikDefault>
    )
}

export default PackageItemPopup