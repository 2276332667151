import React from "react";
import { Form } from "antd";
import { get } from "lodash";

export const FormItem = (props) => {
    const {
        required = false,
        children,
        label,
    } = props;

    const hasError = get(props.form?.errors, props.field?.name);

    const labelItem = (
        <div style={{ width: "100%", textAlign: "left" }}>
            {required && <span style={{ color: "red" }}>* </span>}
            <span>{label}</span>
            {(required && hasError) && <span className="required-field">Required</span>}
        </div>
    );

    return (
        <Form.Item
            label={labelItem}
            wrapperCol={{ span: 24 }}
            colon={false}
        >
            {children}
        </Form.Item>
    );
};
