import { GET } from './request-options'

export const GetUserSearchPreferenceByUserID = (token, userID, tableName) =>
{
  return fetch(`${process.env.REACT_APP_API_SEARCH_PREFERENCE}UserID=${userID}&tableName=${tableName}&UI-Culture=en-US`, GET(token))
  .then(handleErrors)
  .then(response => response.json())
  .then(data =>{
    return data.Obj.UserSearchPreference;
  })
  .catch((error) => {console.log(error); return error.toString();});  
}

function handleErrors(response) {
    if (!response.ok) throw new Error(response.status);
    return response;
}