import React from 'react';
import Cookies from 'universal-cookie';
import { DefaultPager } from '../../../utils/default-data-grid-settings';
import { Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import './styles/purchase-invoice.scss';

//components
import { ListingHeaderDocument } from '../../../layouts/index.js'
import Flash from '../../../components/message/flash'
import ErrorPopUp from '../../../components/pop-up/error-popup';

//default 
import { CompanyID, UserID, ClientID, AdminYN, SetCookies, SetDecimalPoints, SetDefaultName, DefaultName, Token, SetCompanyID, SetSearchText } from '../../../utils/default-cookies';
import { DefaultSMIPI, DefaultSMIPIDetail } from '../../../utils/default-smi';
import { DefaultAdminAccess } from '../../../utils/default-admin-access';
import { checkHTTPResponseCode } from '../../../utils/error-popup-http-error-msg';

//language
import { formatMessage } from 'devextreme/localization';

//api
import { GetPurchaseInvoiceHDRByDateType_Date, CancelPurchaseInvoice } from '../../../api/purchase-invoice'
import { GetAllCompaniesIAGByUser, GetUserGroupAccessRightsByModuleItem } from './../../inventory-setting/package-item/package-item-services';
import { GetUserLayout } from '../../../api/user-preferred-layout';

//iframe
import { getPostMessageObj, getReactParameters } from '../../../utils/iframe-func'

//DataGridDefault
import { CopyBtn, CustomSearch, customSearchRenderer, DataGridDefault, DeleteBtn, EditBtn, RowNumCell } from '../../../components';

import DeletePopUp from '../../../components/pop-up/delete-popup';
import { filterBySearchText } from '../../../components/data-grid/data-grid-util';
import { headerNewBtnRenderer } from '../../../components/data-grid/custom-cell/header-new-btn';
import { appData, mousetrapMain } from '../../../App';
import { InitialSelectedDocStatus } from '../../../utils/default-doc-status';
import { DefaultPIDateTypeValue } from '../../../utils/default-date-type';
import { getRangeLocalTimeString } from "../../../utils/common-function";

const cookies = new Cookies();

class PurchaseInvoice extends React.Component {
    constructor(props) {
        super(props);

        //component instance
        this.dataGridRef = React.createRef();
        this.searchBoxRef = React.createRef(null);

        //pop up flash msg
        this.onFlashMessageTimer = this.onFlashMessageTimer.bind(this);
        this.showMsgHandler = this.showMsgHandler.bind(this);

        // handle state
        this.handlePurchaseInvoice = this.handlePurchaseInvoice.bind(this);
        this.handleCompanies = this.handleCompanies.bind(this);
        this.handleErrorPopUp = this.handleErrorPopUp.bind(this);
        this.selectValueChangeHandler = this.selectValueChangeHandler.bind(this);   //Company dropdown variable
        this.tagValueChangeHandler = this.tagValueChangeHandler.bind(this);      //Status multi-selection variable
        this.dateTypeChangeHandler = this.dateTypeChangeHandler.bind(this);      //Date Type dropdown variable
        this.searchValueChangeHandler = this.searchValueChangeHandler.bind(this);
        this.calendarRangeChangeHandler = this.calendarRangeChangeHandler.bind(this); //date range array

        //data grid rendering
        this.buttonGroup = this.buttonGroup.bind(this);
        this.statusButton = this.statusButton.bind(this);

        // post message from alaya c#
        this.receiveMessage = this.receiveMessage.bind(this);

        // buttons
        this.filterHandler = this.filterHandler.bind(this);
        this.analysisHandler = this.analysisHandler.bind(this);
        this.docActionHandler = this.docActionHandler.bind(this);
        this.onClickAddNewItem = this.onClickAddNewItem.bind(this);
        this.docNoLinkClick = this.docNoLinkClick.bind(this);
        this.vendorLinkClick = this.vendorLinkClick.bind(this);
        this.deletePopupReject = this.deletePopupReject.bind(this);

        // data grid command buttons
        this.onClickEdit = this.onClickEdit.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onClickCopy = this.onClickCopy.bind(this);

        // data grid keyboard inputs
        this.onKeyDownDataGrid = this.onKeyDownDataGrid.bind(this);
        this.onKeyEnter = this.onKeyEnter.bind(this);
        this.onKeyDelete = this.onKeyDelete.bind(this);

        // data grid utility
        this.deletePopupAccept = this.deletePopupAccept.bind(this);
        this.fetchDataAPI = this.fetchDataAPI.bind(this);
        this.quickSearchMouseTrap = null;

        this.state = {
            purchaseInvoice: {},
            companies: [],
            searchText: '',
            allowDelete: false,
            allowCopy: false,
            allowAdd: false,
            allowViewDtl: false,
            allowExportGrid: false,
            allowDisplayColumnChooser: false,
            allowRestoreLayout: false,
            allowSaveGridLayout: false,
            buttonMode: true,
            displayUnableCancelPopUp: false,
            displayDeleteConfirmationDialog: false,
            errorPopUpSubMsg: '',

            //flash msg
            emptyMsg: '',
            displayFlashMessageDialog: false,
            status: 'success',
            size: 'flash-message-success-container',

            //Error pop up
            displayErrorPopUp: false,
            errorTitle: "",
            setErrorTitle: "",

            preferedLayout: [],
            format: { type: 'fixedPoint', precision: 2 }, // DocumentAmount always 2 decimal points

            noDataText: formatMessage('Loading'),
        };

        appData.PITagValue = appData.PITagValue || InitialSelectedDocStatus;
        appData.PIDateType = appData.PIDateType || DefaultPIDateTypeValue;
        appData.PIDateRange = appData.PIDateRange || getRangeLocalTimeString(-7);
    }

    //component instance
    get dataGrid() {
        // `current.instance` points to the UI component instance
        return this.dataGridRef.current.instance;
    }

    //#region flash msg
    onFlashMessageTimer(e) {
        if (this.state.displayFlashMessageDialog === true) {
            setTimeout(() => this.setState({ displayFlashMessageDialog: false }), 1000)
        }
    }

    showMsgHandler(params, value, size) {
        if (params !== '') {
            this.setState({ emptyMsg: params, displayFlashMessageDialog: true, status: value, size: size });
        }
        else {
            this.setState({ emptyMsg: '', displayFlashMessageDialog: false, status: value, size: size });
        }
    }

    closeFlashMessageCallback = () => {
        this.setState(state => ({
            displayFlashMessageDialog: !state.displayFlashMessageDialog
        }))
    }
    //#endregion flash msg

    //#region set state/variable
    handlePurchaseInvoice = (result) => {
        this.setState({ purchaseInvoice: result });
    }

    handleCompanies = (result) => {
        this.setState({ companies: result });
    }

    handleStates = (companies, purchaseInvoice, UserAccessControl, DetailPageUserAccessControl, preferedLayout) => {
        this.setState({
            companies: companies,
            purchaseInvoice: purchaseInvoice,
            allowDelete: UserAccessControl.length > 0 ? UserAccessControl[0].Delete : false,
            allowCopy: UserAccessControl.length > 0 ? UserAccessControl[0].Copy : false,
            allowAdd: UserAccessControl.length > 0 ? UserAccessControl[0].Add : false,
            allowViewDtl: DetailPageUserAccessControl.length > 0 ? DetailPageUserAccessControl[0].SaveEdit : false,
            allowExportGrid: UserAccessControl.length > 0 ? UserAccessControl[0].ExportGrid : false,
            allowDisplayColumnChooser: UserAccessControl.length > 0 ? UserAccessControl[0].GridColumnChooser : false,
            allowRestoreLayout: UserAccessControl.length > 0 ? UserAccessControl[0].RestoreGridLayout : false,
            allowSaveGridLayout: UserAccessControl.length > 0 ? UserAccessControl[0].SaveGridLayout : false,
            preferedLayout: preferedLayout,
            noDataText: formatMessage('dxDataGrid-noDataText'),
        });
    }

    handleErrorPopUp = () => {
        this.setState(state => ({
            displayErrorPopUp: !state.displayErrorPopUp
        }))
    }

    handleUnableCancelPopUpCallBack = () => {
        this.setState(state => ({
            displayUnableCancelPopUp: !state.displayUnableCancelPopUp
        }))
    }

    selectValueChangeHandler = (event) => {
        SetCompanyID(cookies, event);
    }

    searchValueChangeHandler = (event) => {
        SetSearchText(cookies, event);
        this.setState({ searchText: event }, () => {
            filterBySearchText(this.state.searchText, this.dataGrid);
        });
    }

    tagValueChangeHandler = (event) => {
        appData.PITagValue = event;
    }

    dateTypeChangeHandler = (event) => {
        appData.PIDateType = event;
    }

    calendarRangeChangeHandler = (fromDate, toDate) => {
        appData.PIDateRange = [fromDate, toDate];
    }
    //#endregion set state/variable

    //#region  data grid rendering
    // data grid render #1
    buttonGroup(cellData) {
        const isEditEnabled = this.state.allowViewDtl === true;
        const isDeleteEnabled = this.state.allowDelete === true && cellData.data.Status !== "CANCEL" && !cellData.data.IsKnockOff;
        const isCopyEnabled = this.state.allowCopy === true;

        return (
            <div className='icon-row-flex'>
                <div>
                    <EditBtn
                        {...cellData}
                        onClick={this.onClickEdit}
                        enabled={isEditEnabled}
                    />
                </div>
                <div>
                    <DeleteBtn
                        {...cellData}
                        onClick={this.onClickDelete}
                        enabled={isDeleteEnabled}
                    />
                </div>
                <div>
                    <CopyBtn
                        {...cellData}
                        onClick={this.onClickCopy}
                        enabled={isCopyEnabled}
                    />
                </div>
            </div>
        )
    }

    // data grid render #3
    statusButton(cellData) {
        let colorCSS;

        switch (cellData.value) {
            case 'POSTED':
                colorCSS = "green"
                break;
            case 'CANCEL':
                colorCSS = "red"
                break;
            case 'DRAFT':
                colorCSS = "orange"
                break;
            default:
                colorCSS = ""
        }

        return (
            <div className="status-container">
                <div className={colorCSS}>
                    <CustomSearch
                        {...cellData}
                        searchText={this.state.searchText}
                    />
                </div>
            </div>
        );
    }
    //#endregion  data grid rendering

    componentDidMount() {
        window.addEventListener("message", this.receiveMessage, false);
        document.addEventListener('keydown', this.onFlashMessageTimer, false);
        document.addEventListener('mousedown', this.onFlashMessageTimer, false);

        mousetrapMain.bind('enter', this.onKeyEnter);
        mousetrapMain.bind("ins", this.onClickAddNewItem);
        mousetrapMain.bind('del', this.onKeyDelete);

        if (Token()) {
            this.fetchDataAPI();
        } else {
            // prompt Alaya 1.0 to re-postMessage for session data after receiveMessage eventListener is registered
            // to handle issue where initial postMessage is sent before eventListener is registered (iOS and macOS)
            window.parent.postMessage(getReactParameters(), "*");
        }
    }

    componentWillUnmount() {
        //remove event listener
        window.removeEventListener("message", this.receiveMessage, false);
        document.removeEventListener('keydown', this.onFlashMessageTimer, false);
        document.removeEventListener('mousedown', this.onFlashMessageTimer, false);

        mousetrapMain.unbind('enter');
        mousetrapMain.unbind("ins");
        mousetrapMain.unbind('del');
    }

    receiveMessage(event) {
        if (event?.data?.accessToken && !Token()) {
            // set cookies
            SetCookies(cookies, event.data);

            // fetch API
            this.fetchDataAPI();
        }
    }

    async fetchDataAPI() {
        const [dataSource_GetPurchaseInvoice, dataSource_GetAllCompanyIAG, UserAccessControl, DetailPAgeUserAccessControl, UserPreferedLayout] = await Promise.all([
            GetPurchaseInvoiceHDRByDateType_Date(Token(), CompanyID(), appData.PIDateType, appData.PIDateRange[0], appData.PIDateRange[1], true, UserID(), appData.PITagValue, ''),
            GetAllCompaniesIAGByUser(Token(), ClientID(), UserID(), AdminYN()),
            AdminYN() === "true" ? "" : GetUserGroupAccessRightsByModuleItem(Token(), UserID(), DefaultSMIPI.moduleURL, CompanyID()),
            AdminYN() === "true" ? "" : GetUserGroupAccessRightsByModuleItem(Token(), UserID(), DefaultSMIPIDetail.moduleURL, CompanyID()),
            GetUserLayout(Token(), UserID(), DefaultSMIPI.moduleURL, DefaultSMIPI.controlID)
        ])

        if (typeof dataSource_GetPurchaseInvoice === "string" && dataSource_GetPurchaseInvoice.includes("Error")) {
            const responseMsg = checkHTTPResponseCode(dataSource_GetPurchaseInvoice);
            this.setState({
                errorTitle: responseMsg.title,
                errorSubTitle: responseMsg.subtitle,
                displayErrorPopUp: true,
                noDataText: formatMessage('dxDataGrid-noDataText'),
            })
        }
        else {
            var currentCompanyData = dataSource_GetAllCompanyIAG.find(x => x.CO_ID === CompanyID());
            SetDefaultName(cookies, currentCompanyData.CO_Name);
            SetDecimalPoints(cookies, currentCompanyData.IAG_ItemRounding);

            this.handleStates(
                dataSource_GetAllCompanyIAG,
                dataSource_GetPurchaseInvoice,
                AdminYN() === "true" ? DefaultAdminAccess : UserAccessControl.Query_UserModuleItemAccessSimplified,
                AdminYN() === "true" ? DefaultAdminAccess : DetailPAgeUserAccessControl.Query_UserModuleItemAccessSimplified,
                UserPreferedLayout,
            );
        }
    }

    //#region buttons
    async filterHandler(event) {

        const [dataSource_GetPurchaseInvoice] = await Promise.all([
            GetPurchaseInvoiceHDRByDateType_Date(Token(), CompanyID(), appData.PIDateType, appData.PIDateRange[0], appData.PIDateRange[1], false, UserID(), appData.PITagValue, '')
        ])

        if (typeof dataSource_GetPurchaseInvoice === "string" && dataSource_GetPurchaseInvoice.includes("Error")) {
            const responseMsg = checkHTTPResponseCode(dataSource_GetPurchaseInvoice);
            this.setState({
                errorTitle: responseMsg.title,
                errorSubTitle: responseMsg.subtitle,
                displayErrorPopUp: true
            })
        }
        else {

            var currentCompanyData = this.state.companies.find(x => x.CO_ID === CompanyID());
            SetDefaultName(cookies, currentCompanyData.CO_Name);
            SetDecimalPoints(cookies, currentCompanyData.IAG_ItemRounding);

            this.handlePurchaseInvoice(dataSource_GetPurchaseInvoice);
        }
    }

    analysisHandler(event) {
        this.openSourceTab(this.state.purchaseInvoice.LinkBtnUrlAnalysis + ';Purchase Invoice Analysis');
    }

    docActionHandler(event) {
        this.openSourceTab(this.state.purchaseInvoice.LinkBtnUrlDocumentAction + ';Document Action');
    }

    onClickAddNewItem() {
        if (this.state.allowAdd) {
            const data = { PIH_ID: 0 };
            this.redirectDetailPage(data, false);
        }
    }

    docNoLinkClick(data) {
        this.openSourceTab(data.LinkBtnUrlDocumentNo);
    }

    vendorLinkClick(data) {
        this.openSourceTab(data.LinkBtnUrlVendor);
    }
    //#endregion

    //#region command buttons functions
    onClickEdit(event) {
        if (this.state.allowViewDtl === true) {
            this.redirectDetailPage(event.data, false);
        }
    }

    onClickDelete() {
        if (this.state.allowDelete === true) {
            this.setState({ displayDeleteConfirmationDialog: true });
        }
    }

    onClickCopy(data) {
        if (this.state.allowViewDtl === true) {
            this.redirectDetailPage(data, true);
        }
    }
    //#endregion

    //#region keyboard input functions
    async onKeyDownDataGrid(data) {
        if (data.event && data.event.key === "Enter") {
            this.onKeyEnter();
        } else if (data.event && data.event.key === "Delete") {
            this.onKeyDelete();
        }
    }

    async onKeyEnter() {
        if (this.state.allowViewDtl === true) {
            const data = await this.getFocusedRowData();
            if (data) this.redirectDetailPage(data, false);
        }
    }

    async onKeyDelete() {
        const data = await this.getFocusedRowData();
        if (!data) return;

        const isDeleteEnabled = this.state.allowDelete === true && data.Status !== "CANCEL" && !data.IsKnockOff;

        if (isDeleteEnabled) {
            this.setState({ displayDeleteConfirmationDialog: true });
        }
    }

    //#endregion

    //#region utility
    getFocusedRowData() {
        try {
            const focusedRowKey = this.dataGrid.option("focusedRowKey");
            return this.dataGrid.byKey(focusedRowKey).then(
                (data) => { return data },
                (error) => { /* focusedRow not found */ }
            );
        } catch (error) {
            // focusedRow not found
        }
    }

    redirectDetailPage = (data, isCopyDoc) => {
        if (!data) return;

        const id = data.PIH_ID;

        if (id === undefined) return;

        if (this.state.allowViewDtl === true) {
            //Set company ID to int
            let objCompany = this.state.companies.find(c => c.CO_ID === CompanyID());
            var iag = objCompany.CO_IAG;
            var iag_desp = objCompany.IAG_Description;

            const pathname = "/purchase-invoice-details";
            const queryString = `?${encodeURIComponent(`id=${id}&iag=${iag}&iag_desp=${iag_desp}&isCopyDoc=${isCopyDoc}`)}`;

            this.props.history.push({
                pathname: pathname,
                search: queryString,
                state: { fromListing: true }
            });
        }
        else if (this.state.allowViewDtl === false && this.state.saveEdit !== false) {
            const pathname = "/access-denied";

            this.props.history.push({
                pathname: pathname,
            });
        }
    }

    openSourceTab(url) {
        var urlArray = url.split(';');
        if (urlArray.length === 2)
            window.parent.postMessage(getPostMessageObj(urlArray[0], urlArray[1]), "*");
        else if (urlArray.length === 1)
            window.parent.postMessage(getPostMessageObj(urlArray[0], 'Purchase Invoice'), "*");
    }

    async deletePopupAccept() {
        const data = await this.getFocusedRowData();
        if (!data) return;

        const result = await CancelPurchaseInvoice(Token(), data.PIH_ID, CompanyID(), UserID(), ClientID(), data.PostingDate);

        const errorCallback = () => {
            this.setState({
                displayDeleteConfirmationDialog: false
            });

            this.handleErrorPopUp();
        }

        const successCallback = () => {
            const dataObj = { ...this.state.purchaseInvoice }
            let dataArray = dataObj.data;

            dataArray = dataArray.reduce((array, element) => {
                if (element.PIH_ID !== data.PIH_ID) {
                    array.push({ ...element, RowNum: array.length + 1 });
                }
                return array;
            }, [])

            dataObj.data = dataArray;

            this.setState({
                purchaseInvoice: dataObj,
                displayDeleteConfirmationDialog: false
            });
        }

        const failureCallback = () => {
            this.setState({
                errorPopUpSubMsg: result.Message,
                displayDeleteConfirmationDialog: false
            });

            this.handleUnableCancelPopUpCallBack();
        }

        if (result === 'Error: 401') {
            errorCallback();
        } else if (result.Success) {
            successCallback();
        } else {
            failureCallback();
        }

        this.searchBoxRef.current.setFocus();
    }

    deletePopupReject() {
        this.setState({ displayDeleteConfirmationDialog: false });
        this.searchBoxRef.current.setFocus();
    }
    //#endregion

    render() {
        return (
            <React.Fragment>
                <ErrorPopUp
                    parentCallback={this.handleErrorPopUp}
                    visible={this.state.displayErrorPopUp}
                    title={this.state.errorTitle}
                    subTitle={this.state.errorSubTitle}
                />

                <ErrorPopUp
                    parentCallback={this.handleUnableCancelPopUpCallBack}
                    visible={this.state.displayUnableCancelPopUp}
                    title={'UnabletoCancelThisDocument'}
                    subTitle={this.state.errorPopUpSubMsg}
                />

                <DeletePopUp
                    parentCallback={this.deletePopupAccept}
                    hideDeletePopup={this.deletePopupReject}
                    visible={this.state.displayDeleteConfirmationDialog}
                />

                <div className="module-navbar">
                    <Flash
                        parentCallback={this.closeFlashMessageCallback}
                        message={this.state.emptyMsg}
                        visible={this.state.displayFlashMessageDialog}
                        severity={this.state.status}
                        container={this.state.size} />
                </div>

                <div className="module-wrapper">
                    <div className="package-item-header-title">
                        <ListingHeaderDocument
                            placeHolderText={formatMessage('SearchPlaceholderText')}
                            buttonText={formatMessage('Search')}
                            captionDropdown={formatMessage('Company')}
                            captionDropdown2={formatMessage('DateType')}
                            captionTagbox={formatMessage('Status')}
                            captionTextbox={formatMessage('AssociatedItemGroup')}
                            buttonText2={formatMessage('Analysis')}
                            buttonText3={formatMessage('DocumentAction')}
                            buttonText4={formatMessage('Filter')}
                            dataSource={this.state.companies}
                            buttonMode={this.state.buttonMode}
                            onCalBtnClick={this.onCalBtnClick}
                            defaultValue={CompanyID()}
                            defaultName={DefaultName()}
                            codeProp={'CO_Code'}
                            nameProp={'CO_Name'}
                            valueProp={'CO_ID'}
                            displayProp={'IAG_Description'}
                            onFilterClick={this.filterHandler}
                            onAnalysisBtnClick={this.analysisHandler}
                            onDocActionBtnClick={this.docActionHandler}
                            onSelectValueChange={this.selectValueChangeHandler}
                            onTagValueChange={this.tagValueChangeHandler}
                            onDateTypeValueChange={this.dateTypeChangeHandler}
                            onSearchValueChange={this.searchValueChangeHandler}
                            onCalendarRangeChange={this.calendarRangeChangeHandler}
                            defaultStart={appData.PIDateRange[0]}
                            defaultEnd={appData.PIDateRange[1]}
                            defaultDateFormat={this.state.purchaseInvoice.US_DateFormat}
                            searchBoxRef={this.searchBoxRef}
                        />
                    </div>

                    <div className="module-data-grid">
                        <DataGridDefault
                            allowDisplayColumnChooser={this.state.allowDisplayColumnChooser}
                            allowExportGrid={this.state.allowExportGrid}
                            allowedPageSizes={DefaultPager.allowedPageSizes_listing}
                            allowRestoreLayout={this.state.allowRestoreLayout}
                            allowSaveGridLayout={this.state.allowSaveGridLayout}
                            className='dx-datagrid-items'
                            dataGridRef={this.dataGridRef}
                            dataSource={this.state.purchaseInvoice.data}
                            defaultPageSize={DefaultPager.defaultPageSize_listing}
                            defaultSMI={DefaultSMIPI}
                            focusedRowEnabled={true}
                            keyExpr="PIH_ID"
                            noDataText={this.state.noDataText}
                            onKeyDown={this.onKeyDownDataGrid}
                            onRowDblClick={this.onClickEdit}
                            preferedLayout={this.state.preferedLayout}
                            showMsgHandler={this.showMsgHandler}
                            exportFileName="PurchaseInvoiceListing"
                            mousetrap={mousetrapMain}
                        >
                            <Column
                                visibleIndex={0}
                                fixedPosition={'left'}
                                fixed={true}
                                width={110}
                                minWidth={110}
                                allowExporting={false}
                                allowFiltering={false}
                                allowHeaderFiltering={false}
                                allowHiding={false}
                                allowReordering={false}
                                allowResizing={false}
                                allowSorting={false}
                                showInColumnChooser={false}
                                cssClass="column-normalize column-header-normalize"
                                caption={null}
                                headerCellRender={(cellData) => headerNewBtnRenderer({ cellData, onClick: this.onClickAddNewItem, enabled: this.state.allowAdd })}
                                cellRender={this.buttonGroup}
                                dataField={'_TOTAL'} // dummy dataField for summary
                            />

                            <Column
                                visibleIndex={1}
                                fixedPosition={'left'}
                                fixed={true}
                                alignment={'left'}
                                width={60}
                                minWidth={60}
                                allowFiltering={false}
                                allowHeaderFiltering={false}
                                allowReordering={false}
                                allowResizing={false}
                                allowSorting={false}
                                caption={'No.'}
                                dataField={"RowNum"}
                                cellRender={RowNumCell}
                            />

                            <Column
                                dataField={'PIH_ID'}
                                width={120}
                                minWidth={60}
                                showInColumnChooser={false}
                                visible={false}
                            />

                            <Column
                                dataField={'DocumentDate'}
                                caption={formatMessage('DocDate')}
                                width={180}
                                minWidth={60}
                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                                dataType="date"
                                format={this.state.purchaseInvoice.US_DateFormat}
                            />

                            <Column
                                dataField={'DocumentNo'}
                                caption={formatMessage('DocNo')}
                                minWidth={60}
                                cellRender={(cellData) => customSearchRenderer({ cellData, link: true, searchText: this.state.searchText, onClick: this.docNoLinkClick })}
                                hasCustomSearch={true}
                            />

                            <Column
                                dataField={'SupplierInvNo'}
                                caption={formatMessage('SupplierInvNo')}
                                minWidth={60}
                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                            />

                            <Column
                                dataField={'Vendor'}
                                caption={formatMessage('Vendor')}
                                minWidth={60}
                                cellRender={(cellData) => customSearchRenderer({ cellData, link: true, searchText: this.state.searchText, onClick: this.vendorLinkClick })}
                                hasCustomSearch={true}
                            />

                            <Column
                                dataField={'DocumentAmount'}
                                caption={formatMessage('DocAmount')}
                                format={this.state.format}
                                minWidth={60}
                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                            />

                            <Column
                                dataField={'Status'}
                                caption={formatMessage('Status')}
                                cssClass={"status-container-column"}
                                minWidth={115}
                                cellRender={this.statusButton}
                                hasCustomSearch={true}
                            />

                            <Column
                                dataField={'CreatedDate'}
                                caption={formatMessage('CreatedDate')}
                                width={180}
                                minWidth={60}
                                visible={false}
                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                                dataType="date"
                                format={this.state.purchaseInvoice.US_DateFormat}
                            />

                            <Column
                                dataField={'CreatedBy'}
                                caption={formatMessage('CreatedBy')}
                                width={180}
                                minWidth={60}
                                visible={false}
                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                            />

                            <Column
                                dataField={'Currency'}
                                caption={formatMessage('Currency')}
                                width={180}
                                minWidth={60}
                                visible={false}
                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                            />

                            <Column
                                dataField={'Description'}
                                caption={formatMessage('Description')}
                                width={180}
                                minWidth={60}
                                visible={false}
                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                            />

                            <Column
                                dataField={'Division'}
                                caption={formatMessage('Division')}
                                width={180}
                                minWidth={60}
                                visible={false}
                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                            />

                            <Column
                                dataField={'PostingDate'}
                                caption={formatMessage('PostingDate')}
                                width={180}
                                minWidth={60}
                                visible={false}
                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                                dataType="date"
                                format={this.state.purchaseInvoice.US_DateFormat}
                            />

                            <Column
                                dataField={'PurchaseAgent'}
                                caption={formatMessage('PurchaseAgent')}
                                width={180}
                                minWidth={60}
                                visible={false}
                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                            />

                            <Column
                                dataField={'PurchaseOrg'}
                                caption={formatMessage('PurchaseOrg')}
                                width={180}
                                minWidth={60}
                                visible={false}
                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                            />

                            <Summary>
                                <TotalItem
                                    summaryType="count"
                                    showInColumn="_TOTAL"
                                    displayFormat={`${formatMessage("Records")}: {0}`}
                                    cssClass="summary-overflow"
                                    skipEmptyValues
                                />
                                <TotalItem
                                    name={"totalAmount"}
                                    summaryType={"sum"}
                                    valueFormat={this.state.format}
                                    displayFormat={"{0}"}
                                    column={"DocumentAmount"}
                                />
                            </Summary>
                        </DataGridDefault>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PurchaseInvoice;
