import React from 'react';
import Cookies from 'universal-cookie';
import { DefaultPager } from '../../../utils/default-data-grid-settings';
import { Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import './audit-log.scss';

//components
import { ListingHeaderAuditLog } from '../../../layouts/index';
import Flash from '../../../components/message/flash';
import ConfirmationPopUp from '../../../components/pop-up/confirmation-popup';
import ErrorPopUp from '../../../components/pop-up/error-popup';

//default 
import { CompanyID, UserID, ClientID, AdminYN, SetCookies, SetDecimalPoints, SetDefaultName, Token, SetCompanyID, SetSearchText } from '../../../utils/default-cookies';
import { checkHTTPResponseCode } from '../../../utils/error-popup-http-error-msg';
import { DefaultSMIAuditLog } from '../../../utils/default-smi';

//language
import { formatMessage } from 'devextreme/localization';

//api
import { GetAllCompaniesIAGByUser, GetAllUser, GetAuditLogSettingType, GetAuditLogSettingTypeCount, GetAuditLogDocumentType, GetAuditLogDocumentTypeCount } from './audit-log-service'
import { GetUserLayout } from '../../../api/user-preferred-layout';
import { GetUserGroupAccessRightsByModuleItem } from '../package-item/package-item-services';

//DataGridDefault
import { customSearchRenderer, DataGridDefault } from '../../../components';

//MouseTrap
import { filterBySearchText } from '../../../components/data-grid/data-grid-util';

//export to excel
import 'jspdf-autotable';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { mousetrapMain } from '../../../App';
import { getReactParameters } from '../../../utils/iframe-func';

const cookies = new Cookies();

class AuditLog extends React.Component {
    constructor(props) {
        super(props);

        //component instance
        this.dataGridRef = React.createRef();
        this.auditLogListingRef = React.createRef();

        //pop up flash msg
        this.onFlashMessageTimer = this.onFlashMessageTimer.bind(this);
        this.showMsgHandler = this.showMsgHandler.bind(this);

        // handle state
        this.handleCompanies = this.handleCompanies.bind(this);
        this.selectValueChangeHandler = this.selectValueChangeHandler.bind(this);
        this.searchValueChangeHandler = this.searchValueChangeHandler.bind(this);

        //data grid rendering
        this.onCellPrepared = this.onCellPrepared.bind(this);

        // post message from alaya c#
        this.receiveMessage = this.receiveMessage.bind(this);
        this.fetchDataAPI = this.fetchDataAPI.bind(this);

        // buttons
        this.filterHandler = this.filterHandler.bind(this);
        this.setSelectedUser = this.setSelectedUser.bind(this);

        this.onCodeChange = this.onCodeChange.bind(this);
        this.onDocNoChange = this.onDocNoChange.bind(this);

        this.handleDownloadConfirmationDialog = this.handleDownloadConfirmationDialog.bind(this);
        this.handleConfirmationPopUpCallBack = this.handleConfirmationPopUpCallBack.bind(this);
        this.handleErrorPopUp = this.handleErrorPopUp.bind(this);
        this.detectPressKey = this.detectPressKey.bind(this);

        this.state = {

            // audit log type = document type or setting type
            isSettingType: false,
            isDocType: false,

            companies: [],
            code: '',
            documentNo: '',
            searchText: '',
            buttonMode: true,

            //users
            users: [],
            selectedUserID: [],
            selectedUsername: [],
            defaultDateFormat: undefined,

            //setting type
            auditLogSetting: [],
            allSetting: [],
            showCodeRequired: false,

            //doc type
            docType: [],
            selectedDocTypeID: [],
            selectedDocTypeName: [],
            showDocNoRequired: false,

            //flash msg
            emptyMsg: '',
            displayFlashMessageDialog: false,
            status: 'success',
            size: 'flash-message-success-container',

            //Error pop up
            displayErrorPopUp: false,
            errorTitle: "",
            setErrorTitle: "",

            //calendar range
            dateRange: [],

            // data grid context menu
            allowExportGrid: false,
            allowDisplayColumnChooser: false,
            allowRestoreLayout: false,
            allowSaveLayout: false,
            preferedLayout: [],

            //data grid export file name
            exportFileName: '',

            displayConfirmationPopUp: false,
            isYesBtnFocus: true,
        }
    }

    //component instance
    get dataGrid() {
        // `current.instance` points to the UI component instance
        return this.dataGridRef.current.instance;
    }

    //#region check api response
    setErrorPopUpState = (title, subtitle, visible) => {
        this.setState({
            auditLogSetting: [],
            errorTitle: title,
            errorSubTitle: subtitle,
            displayErrorPopUp: visible
        })
    }

    isResponseSuccess = (values) => {
        if (typeof values === "string" && values.includes("Error")) {
            const responseMsg = checkHTTPResponseCode(values);
            this.setErrorPopUpState(responseMsg.title, responseMsg.subtitle, true);
        }
        else if (values?.Result === false) {
            this.setErrorPopUpState("ErrorEncountered", values.Message, true);
        }
        else
            return true;
    }

    //#endregion

    //#region flash msg
    onFlashMessageTimer() {
        if (this.state.displayFlashMessageDialog === true) {
            setTimeout(() => this.setState({ displayFlashMessageDialog: false }), 1000)
        }
    }

    showMsgHandler(params, value, size) {
        if (params !== '') {
            this.setState({ emptyMsg: params, displayFlashMessageDialog: true, status: value, size: size });
        }
        else {
            this.setState({ emptyMsg: '', displayFlashMessageDialog: false, status: value, size: size });
        }
    }

    closeFlashMessageCallback = () => {
        this.setState(state => ({
            displayFlashMessageDialog: !state.displayFlashMessageDialog
        }))
    }

    //#endregion flash msg

    //#region get datagrid Data

    async getAuditLogCount() {
        let result;
        if (this.state.isSettingType && this.state.code) {
            result = Promise.resolve(GetAuditLogSettingTypeCount(Token(), CompanyID(), UserID(), this.state.code, this.state.dateRange[0], this.state.dateRange[1]));
        }
        else if (this.state.isDocType && this.state.documentNo) {
            result = Promise.resolve(GetAuditLogDocumentTypeCount(Token(), CompanyID(), this.state.selectedUserID, this.state.code, this.state.dateRange[0], this.state.dateRange[1], this.state.documentNo));
        }

        result?.then((values) => {
            if (this.isResponseSuccess(values))
                this.handleAuditLogCount(values?.Obj?.Query_AuditLogSettingCount)

        });
    }

    async getAuditLogList() {

        let result;
        if (this.state.isSettingType) {
            result = Promise.resolve(GetAuditLogSettingType(Token(), CompanyID(), this.state.selectedUserID, this.state.code, this.state.dateRange[0], this.state.dateRange[1]));
        }
        else {
            result = Promise.resolve(GetAuditLogDocumentType(Token(), CompanyID(), this.state.selectedUserID, this.state.code, this.state.dateRange[0], this.state.dateRange[1], this.state.documentNo));
        }

        result.then((values) => {
            if (this.isResponseSuccess(values))
                this.setState({ auditLogSetting: values.Query_AuditLogSetting });

        });

    }
    //#endregion

    //#region set state/variable

    handleCompanies = (result) => {
        this.setState({ companies: result });
    }

    handleUserID = (result) => {
        var name = [];

        var data = result.find(x => x.US_ID === UserID());
        name.push(data.US_UserID)
        this.setState({ users: result, selectedUsername: name, defaultDateFormat: data.US_DateFormat });
    }

    handleAuditLogCount = (result) => {
        if (result.Total > 500) {
            this.handleDownloadConfirmationDialog();
        }
        else if (result.Total > 0) {
            this.getAuditLogList();
        }
        else if (result.Total === 0) {
            this.setState({ auditLogSetting: [] });
        }
    }

    handleStates = (companies, user, auditLogSettingCount, docType, allSetting, preferedLayout, UserAccessControl) => {

        this.handleUserID(user);
        this.handleAuditLogCount(auditLogSettingCount);

        this.setState({
            companies: companies,
            docType: docType,
            allSetting: allSetting,
            preferedLayout: preferedLayout,
            allowDisplayColumnChooser: AdminYN() === "true" ? true : UserAccessControl.Query_UserModuleItemAccessSimplified[0].GridColumnChooser,
            allowExportGrid: AdminYN() === "true" ? true : UserAccessControl.Query_UserModuleItemAccessSimplified[0].ExportGrid,
            allowRestoreLayout: AdminYN() === "true" ? true : UserAccessControl.Query_UserModuleItemAccessSimplified[0].RestoreGridLayout,
            allowSaveGridLayout: AdminYN() === "true" ? true : UserAccessControl.Query_UserModuleItemAccessSimplified[0].SaveGridLayout
        });
    }

    handleErrorPopUp = () => {
        this.setState(state => ({
            displayErrorPopUp: !state.displayErrorPopUp
        }))
    }

    handleConfirmationPopUpCallBack = () => {
        this.getAuditLogExcel();
    }

    handleDownloadConfirmationDialog = () => {
        this.setState(state => ({
            displayConfirmationPopUp: !state.displayConfirmationPopUp
        }))
    }

    selectValueChangeHandler = (event) => {
        SetCompanyID(cookies, event);
    }

    searchValueChangeHandler = (event) => {
        SetSearchText(cookies, event);
        this.setState({ searchText: event }, () => {
            filterBySearchText(this.state.searchText, this.dataGrid);
        });
    }

    calendarRangeChangeHandler = (fromDate, toDate) => {
        this.setState({ dateRange: [fromDate, toDate] });
    }

    //#region user tag box call back
    setSelectedUser = (idArray, nameArray) => {
        var valueString = JSON.stringify(idArray).replace(/]|[[]/g, '');

        this.setState({ selectedUserID: valueString, selectedUsername: nameArray });
    }

    //#endregion

    //#region document type tag box call back
    setSelectedDocType = (idArray, nameArray) => {
        var valueString = JSON.stringify(idArray).replace(/]|[[]/g, '');

        this.setState({ selectedDocTypeID: valueString, selectedDocTypeName: nameArray });
    }

    //#endregion

    onCodeChange = (value) => {
        this.setState({ code: value });
        this.setState({ showCodeRequired: !value });
    }

    onDocNoChange = (value) => {
        this.setState({ documentNo: value });
        this.setState({ showDocNoRequired: !value });
    }

    detectPressKey(event) {
        if (event.keyCode === 9) {
            this.setState(state => ({
                isYesBtnFocus: !state.isYesBtnFocus
            }))
        }
    }


    //#endregion set state/variable

    //#region  data grid rendering
    onCellPrepared(e) {
        if (e.rowType === "totalFooter") {
            if (e.column.type === "buttons") {
                const summaryValue = e.component.getTotalSummaryValue("totalCount");
                // create the required HTML element with 'summaryValue' and append it to 'e.cellElement'
                const span = document.createElement("span");
                span.className = "dx-datagrid-summary-item"
                span.innerHTML = formatMessage("Records") + ": " + summaryValue;
                e.cellElement.appendChild(span);
            }
            if (e.column.dataField === "A") {
                e.cellElement.innerHTML = "";
            }
        }
        if (e.rowType === "totalAmount" && e.summaryItems.length) {
            e.cellElement.innerText = "Sum for " + e.data.key + ": $" + e.summaryItems[0].value;
        }
    }
    //#endregion

    //#region get info from url
    geturlInfo(e) {
        const location = e?.currentTarget?.location?.href || window.location.href;
        var parentInfo = (new URL(location.replace('#', ''))).searchParams;

        var code = parentInfo.get('code')?.replace("_", "-");
        var docNo = parentInfo.get('docno')?.replace("_", "-");
        var companyID = parentInfo.get('companyID');
        var isSetting = parentInfo.get('isSetting');
        var isDocType = parentInfo.get('isDocType')

        const checkIsUndefined = () => {
            isSetting = isSetting ? true : false;
            isDocType = isDocType ? true : false;
            code = code ? code : '';
        }

        checkIsUndefined();
        this.setState({ isSettingType: isSetting, isDocType: isDocType, code: code, documentNo: docNo, selectedUserID: UserID() });

        if (companyID) {
            SetCompanyID(cookies, companyID);
        }
    }

    //#endregion

    componentDidMount() {
        window.addEventListener("message", this.receiveMessage, false);
        document.addEventListener('keydown', this.onFlashMessageTimer, false);
        document.addEventListener("keydown", this.detectPressKey, false);
        document.addEventListener('mousedown', this.onFlashMessageTimer, false);

        // wait for auditLogListingRef to render and setState dateRange
        setTimeout(() => {
            if (this.auditLogListingRef.current) {
                const dateRange = this.state.dateRange.length === 0 ? this.auditLogListingRef.current.getCalendarRangeValue() : this.state.dateRange;
                this.setState({ dateRange: dateRange });
            }
        }, 0);

        if (Token()) {
            this.geturlInfo();
            this.fetchDataAPI();
        } else {
            // prompt Alaya 1.0 to re-postMessage for session data after receiveMessage eventListener is registered
            // to handle issue where initial postMessage is sent before eventListener is registered (iOS and macOS)
            window.parent.postMessage(getReactParameters(), "*");
        }
    }

    componentWillUnmount() {
        //remove event listener
        window.removeEventListener("message", this.receiveMessage, false);
        document.removeEventListener('keydown', this.onFlashMessageTimer, false);
        document.removeEventListener("keydown", this.detectPressKey, false);
        document.removeEventListener('mousedown', this.onFlashMessageTimer, false);
    }

    receiveMessage(event) {
        if (event?.data?.accessToken && !Token()) {
            // set cookies
            SetCookies(cookies, event.data);

            // to get id from url, searchParams not working when got hashtag in url
            this.geturlInfo(event);

            // fetch API
            this.fetchDataAPI();
        }
    }

    async fetchDataAPI() {
        const [dataSourceCompany, dataSourceUser, UserPreferedLayout, UserAccessControl] = await Promise.all([
            GetAllCompaniesIAGByUser(Token(), ClientID(), UserID(), AdminYN()),
            GetAllUser(Token(), UserID()),
            GetUserLayout(Token(), UserID(), DefaultSMIAuditLog.moduleURL, DefaultSMIAuditLog.controlID),
            GetUserGroupAccessRightsByModuleItem(Token(), UserID(), DefaultSMIAuditLog.moduleURL, CompanyID())
        ])

        if (this.isResponseSuccess(dataSourceCompany) || this.isResponseSuccess(dataSourceUser) || this.isResponseSuccess(UserPreferedLayout) || this.isResponseSuccess(UserAccessControl)) {
            const currentCompanyData = dataSourceCompany.find(x => x.CO_ID === CompanyID());
            SetDefaultName(cookies, currentCompanyData.CO_Name);
            SetDecimalPoints(cookies, currentCompanyData.IAG_ItemRounding);

            this.handleUserID(dataSourceUser);

            this.setState({
                companies: dataSourceCompany,
                preferedLayout: UserPreferedLayout,
                allowDisplayColumnChooser: AdminYN() === "true" ? true : UserAccessControl.Query_UserModuleItemAccessSimplified[0].GridColumnChooser,
                allowExportGrid: AdminYN() === "true" ? true : UserAccessControl.Query_UserModuleItemAccessSimplified[0].ExportGrid,
                allowRestoreLayout: AdminYN() === "true" ? true : UserAccessControl.Query_UserModuleItemAccessSimplified[0].RestoreGridLayout,
                allowSaveGridLayout: AdminYN() === "true" ? true : UserAccessControl.Query_UserModuleItemAccessSimplified[0].SaveGridLayout
            });
        }

        this.getAuditLogCount();
    }

    //get audit log excel data
    async getAuditLogExcel() {

        let filename;
        let result;
        if (this.state.isSettingType) {
            filename = 'Audit Log ' + this.state.code;
            result = Promise.resolve(GetAuditLogSettingType(Token(), CompanyID(), this.state.selectedUserID, this.state.code, this.state.dateRange[0], this.state.dateRange[1]));
        }
        else {
            filename = 'Audit Log ' + this.state.documentNo;
            result = Promise.resolve(GetAuditLogDocumentType(Token(), CompanyID(), this.state.selectedUserID, this.state.code, this.state.dateRange[0], this.state.dateRange[1], this.state.documentNo));
        }

        result.then((values) => {
            if (this.isResponseSuccess(values)) {
                const newArray = values.Query_AuditLogSetting.map(item => {
                    return {
                        "Date & Time": item.LogDatetime,
                        Code: item.Code,
                        "Event Type": item.EventType,
                        "Column Name": item.ColumnName,
                        Description: item.Description,
                        "User ID": item.UserID
                    };
                });

                this.exportGridXLSX(newArray, filename);
                this.handleDownloadConfirmationDialog();
            }
        });
    }

    exportGridXLSX = (csvData, fileName) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        var today = new Date();

        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + '-' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + fileExtension);

    }

    //#region buttons
    async filterHandler() {
        //check required field
        if (this.state.isDocType && !this.state.documentNo) {
            this.setState({ showDocNoRequired: true });
            return;
        }

        if (this.state.isSettingType && !this.state.code) {
            this.setState({ showCodeRequired: true });
            return;
        }



        this.getAuditLogCount();
    }
    //#endregion

    render() {

        return (
            <React.Fragment>
                <div className="audit-log-navbar">
                    <Flash
                        parentCallback={this.closeFlashMessageCallback}
                        message={this.state.emptyMsg}
                        visible={this.state.displayFlashMessageDialog}
                        severity={this.state.status}
                        container={this.state.size} />

                    <ErrorPopUp
                        parentCallback={this.handleErrorPopUp}
                        visible={this.state.displayErrorPopUp}
                        title={this.state.errorTitle}
                        subTitle={this.state.errorSubTitle}
                    />

                    <ConfirmationPopUp
                        yesBtnCallback={this.handleConfirmationPopUpCallBack}
                        hideConfirmationPopUp={this.handleDownloadConfirmationDialog}
                        visible={this.state.displayConfirmationPopUp}
                        content={["DataIsTooLarge", "DoYouWantToExportToExcel"]} />
                </div>



                <div className="module-wrapper">
                    <div className="package-item-header-title">
                        <ListingHeaderAuditLog
                            displayDocColumn={this.state.isDocType}
                            dataSource_Companies={this.state.companies}
                            dataSource_UserID={this.state.users}
                            dataSource_DocType={this.state.docType}
                            selectedUsername={this.state.selectedUsername}
                            selectedDocType={this.state.selectedDocTypeName}
                            itemCode={this.state.code}
                            documentNo={this.state.documentNo}
                            dataSource_AllSetting={this.state.allSetting}
                            buttonMode={this.state.buttonMode}
                            defaultDateFormat={this.state.defaultDateFormat}
                            onSearchValueChange={this.searchValueChangeHandler}
                            onCompanyChanged={this.selectValueChangeHandler}
                            onCalendarRangeChanged={this.calendarRangeChangeHandler}
                            onUserChange={this.setSelectedUser}
                            //onDocTypeChange={this.setSelectedDocType}
                            //onSettingChanged={}
                            onCodeChange={this.onCodeChange}
                            onDocNoChange={this.onDocNoChange}
                            onFilterClick={this.filterHandler}
                            showCodeRequired={this.state.showCodeRequired}
                            showDocNoRequired={this.state.showDocNoRequired}
                            ref={this.auditLogListingRef}
                        />
                    </div>

                    <div className="dx-fieldset">
                        <div className="dx-field">
                            <div className="dx-field-value">
                                <DataGridDefault
                                    allowDisplayColumnChooser={this.state.allowDisplayColumnChooser}
                                    allowExportGrid={this.state.allowExportGrid}
                                    allowedPageSizes={DefaultPager.allowedPageSizes_listing}
                                    allowRestoreLayout={this.state.allowRestoreLayout}
                                    allowSaveGridLayout={this.state.allowSaveGridLayout}
                                    className='dx-datagrid-items'
                                    dataGridRef={this.dataGridRef}
                                    dataSource={this.state.auditLogSetting}
                                    defaultPageSize={DefaultPager.defaultPageSize_listing}
                                    defaultSMI={DefaultSMIAuditLog}
                                    focusedRowEnabled={true}
                                    keyExpr="UniqueId"
                                    noDataText={formatMessage('dxDataGrid-noDataText')}
                                    onCellPrepared={this.onCellPrepared}
                                    //onKeyDown={this.onKeyDownDataGrid}
                                    preferedLayout={this.state.preferedLayout}
                                    showMsgHandler={this.showMsgHandler}
                                    exportFileName="AuditLog"
                                    mousetrap={mousetrapMain}
                                >
                                    <Column
                                        dataField={'LogDatetime'}
                                        caption={formatMessage('LogDateTime')}
                                        width={200}
                                        cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                        hasCustomSearch={true}
                                        dataType="date"
                                        format={this.state.defaultDateFormat + " HH:mm:ss"}
                                    />

                                    <Column
                                        dataField={'Code'}
                                        caption={formatMessage('Code')}
                                        minWidth={60}
                                        cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                        hasCustomSearch={true}
                                    />

                                    <Column
                                        dataField={'EventType'}
                                        caption={formatMessage('EventType')}
                                        minWidth={60}
                                        cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                        hasCustomSearch={true}
                                    />

                                    <Column
                                        dataField={'ColumnName'}
                                        caption={formatMessage('ColumnName')}
                                        minWidth={60}
                                        cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                        hasCustomSearch={true}
                                    />

                                    <Column
                                        dataField={'Description'}
                                        caption={formatMessage('Description')}
                                        minWidth={60}
                                        cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                        hasCustomSearch={true}
                                    />

                                    <Column
                                        dataField={'UserID'}
                                        caption={formatMessage('UserID')}
                                        minWidth={60}
                                        cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                        hasCustomSearch={true}
                                    />

                                    <Summary>
                                        <TotalItem
                                            name="totalCount"
                                            summaryType="count"
                                            displayFormat="Rec: {0}"
                                            showInColumn="LogDatetime" />
                                    </Summary>

                                </DataGridDefault>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default AuditLog;