import React from "react";
import Form from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import { Item } from "devextreme-react/select-box";
import { Button } from "devextreme-react";
import { formatMessage } from "devextreme/localization";
import TextBox from 'devextreme-react/text-box';
import { ToolbarItem } from 'devextreme-react/data-grid';

//default 
import { CompanyID, UserID, Token, ReportURL } from '../../utils/default-cookies';
import { checkHTTPResponseCode } from '../../utils/error-popup-http-error-msg';
import { ReportFormatDataLimit } from '../../utils/default-data-limit';

//api
import { UpdateReportFormatDesp, CopyReportFormat } from '../../api/report-format';
import { mousetrapTertiary } from "../../App";

const reportSaveAction = "Save";
const reportCopyAction = "COPY";

class EditRptFormatPopUp extends React.Component {
    constructor(props) {
        super(props);

        this.saveBtn = React.createRef();

        this.onShowing = this.onShowing.bind(this);
        this.onShown = this.onShown.bind(this);
        this.onHiding = this.onHiding.bind(this);
        this.onHidden = this.onHidden.bind(this);

        this.onKeyEscape = this.onKeyEscape.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);

        this.onChangedHandler = this.onChangedHandler.bind(this);

        this.callUpdateApi = this.callUpdateApi.bind(this);
        this.callCopyApi = this.callCopyApi.bind(this);

        this.state = {
            defaultValue: '',
        }

        this.buttonOptions = {
            type: 'close',
            icon: 'close',
            tabIndex: -1,
            onClick: this.onHiding
        };
    }

    onChangedHandler(event) {
        this.setState({
            defaultValue: event.value,
        });
    }

    componentDidUpdate(prevProps) {

        if (prevProps.defaultValue !== this.props.defaultValue) {
            this.setState({
                defaultValue: this.props.defaultValue,
            });
        }

    }

    componentWillUnmount() {
        mousetrapTertiary.unbind("esc");
    }

    onShowing() {
        mousetrapTertiary.bind('esc', this.onKeyEscape);
    }

    onShown() {
        this.saveBtn.current.instance.focus(); // focus on save button
    }

    onHiding() {
        this.props.hidePopup();
    }

    onHidden() {
        mousetrapTertiary.unbind("esc");
    }

    onKeyEscape(e) {
        if (!this.props.visible) return;

        // close popup on escape key
        this.props.hidePopup();
    }

    //#region rename & copy  report format
    callUpdateApi(ID, reportFormatDesp) {
        const result = Promise.resolve(
            UpdateReportFormatDesp(Token(), UserID(), ID, reportFormatDesp)
        );

        result.then((values) => {
            if (typeof values === "string" && values.includes("Error")) {
                const responseMsg = checkHTTPResponseCode(values);
                this.setState({
                    errorTitle: responseMsg.title,
                    errorSubTitle: responseMsg.subtitle,
                    displaySessionPopUp: true
                })
            } else {
                if (values) {
                    this.props.hidePopup();
                    //refresh parent
                    this.props.refreshParentData();

                }
                else {
                    //set error pop up
                }

            }
        });
    }

    callCopyApi(ID, reportFormatDesp) {
        const result = Promise.resolve(
            CopyReportFormat(Token(), CompanyID(), this.props.docID, ID, ReportURL(), this.props.pageName, reportFormatDesp)
        );

        result.then((values) => {
            if (typeof values === "string" && values.includes("Error")) {
                const responseMsg = checkHTTPResponseCode(values);
                this.setState({
                    errorTitle: responseMsg.title,
                    errorSubTitle: responseMsg.subtitle,
                    displaySessionPopUp: true
                })
            } else {
                if (values) {
                    this.props.hidePopup();
                    //refresh parent
                    this.props.refreshParentData();

                }
                else {
                    //set error pop up
                }
            }
        });
    }



    onButtonClick(e) {
        if (this.props.buttonText === formatMessage(reportSaveAction)) {
            this.callUpdateApi(this.props.defaultID, this.state.defaultValue)
        }
        else if (this.props.buttonText === formatMessage(reportCopyAction)) {
            this.callCopyApi(this.props.defaultID, this.state.defaultValue);
        }

    }

    //#endregion

    render() {
        return (
            <Popup
                position={'center'}
                className="item-popup-center rename-popup-center"
                showTitle={true}
                title={this.props.title}
                onShowing={this.onShowing}
                onShown={this.onShown}
                onHiding={this.onHiding}
                onHidden={this.onHidden}
                visible={this.props.visible}
                dragEnabled={false}
                closeOnOutsideClick={true}
                width={"auto"}
                height={"auto"}
                tabIndex={-1}
                onKeyDown={this.onKeyDown}
                showCloseButton={false}
            >
                <ToolbarItem
                    widget="dxButton"
                    location="after"
                    options={this.buttonOptions}
                />

                <Form colCount={4} >
                    <Item colSpan={4}>
                        <div className="edit-rpt-format-caption">
                            <span style={{ color: "red" }}>* </span>
                            <span>{"Report Format Name"}</span>
                            {this.state.defaultValue.length === 0 && <span className="required-field">Required</span>}
                        </div>

                        <div className="edit-rpt-format-textbox">
                            <TextBox
                                className="mousetrap edit-rpt-text-box"
                                value={this.state.defaultValue}
                                stylingMode={'outlined'}
                                visible={true}
                                mode={"text"}
                                onValueChanged={this.onChangedHandler}
                                //disabled={this.state.buttonText === formatMessage(reportCopyAction)}
                                maxLength={ReportFormatDataLimit.desp}
                            >
                            </TextBox>
                        </div>
                    </Item>
                </Form>
                <div className="left-btn-container-no-cover" >
                    <Button
                        className="cancel-btn"
                        text={formatMessage("Cancel")}
                        type="normal"
                        stylingMode="contained"
                        onClick={this.onHiding}
                    />
                </div>
                <div className="right-btn-container-no-cover" >
                    <Button
                        className="primary-btn"
                        text={this.props.buttonText}
                        onClick={this.onButtonClick}
                        type="default"
                        stylingMode="contained"
                        ref={this.saveBtn}
                    />
                </div>

            </Popup>
        );
    }
}

export default EditRptFormatPopUp;
