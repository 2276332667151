import React from 'react'
import { Row, Col } from "antd";
import { Field } from "formik";
import { Button } from 'devextreme-react';
import { TextInput } from '../../../../fields';
import { validator } from '../../../../../utils/form-validator';
import { formatMessage } from 'devextreme/localization';
import { isCopyFromUI } from '../../util/item-type-popup-util';

const SectionSerializedDetails = (props) => {
    const {
        onSerialNumSubmit,
        onSelectSubmit,
        onClickGenerate,
        disabled,
        hasBatchNo,
        interfaceType,
    } = props;

    function getColSpan(hasBatchNo) {
        if (hasBatchNo) {
            return {
                batchNo: { xs: 24, md: 7, lg: 8 },
                serialNum: { xs: 24, md: 7, lg: 8 },
                addBtn: { xs: 24, md: 3, lg: 2 },
                generateBtn: { xs: 24, md: 7, lg: 6 },
                selectBtn: { xs: 24, md: 10, lg: 8 },
            }
        } else {
            return {
                batchNo: { xs: 0 },
                serialNum: { xs: 24, md: 12 },
                addBtn: { xs: 24, md: 4 },
                generateBtn: { xs: 24, md: 8 },
                selectBtn: { xs: 24, md: 12 },
            }
        }
    }

    function getRowGutter(hasBatchNo) {
        if (hasBatchNo) {
            return { xs: 30, md: 15, lg: 30 }
        } else {
            return { xs: 30, md: 30, lg: 30 }
        }
    }

    function renderButtons() {
        if (isCopyFromUI(interfaceType.current)) {
            return (
                <Col {...getColSpan(hasBatchNo).selectBtn}>
                    <Button
                        hoverStateEnabled={true}
                        activeStateEnabled={false}
                        focusStateEnabled={false}
                        className="section-input-btn section-input-btn-left"
                        text="Select" // [Translation] Update Translation
                        type="default"
                        stylingMode="contained"
                        onClick={onSelectSubmit}
                        disabled={disabled}
                    />
                </Col>
            )
        } else {
            return (
                <>
                    <Col {...getColSpan(hasBatchNo).addBtn}>
                        <Button
                            hoverStateEnabled={true}
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            className="section-input-btn section-input-btn-left"
                            text={formatMessage("Add")}
                            type="default"
                            stylingMode="contained"
                            onClick={onSerialNumSubmit}
                            disabled={disabled}
                        />
                    </Col>
                    <Col {...getColSpan(hasBatchNo).generateBtn}>
                        <Button
                            hoverStateEnabled={true}
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            className="section-input-btn"
                            text={"Generate Serial No."} // [Translation] Update Translation
                            type="default"
                            stylingMode="contained"
                            onClick={onClickGenerate}
                            disabled={disabled}
                        />
                    </Col>
                </>
            )
        }
    }

    return (
        <Row gutter={{ ...getRowGutter(hasBatchNo) }}>
            {
                hasBatchNo &&
                <Col {...getColSpan(hasBatchNo).batchNo}>
                    <Field
                        // Styling
                        className="input-w input-align-left"
                        // Labels
                        label={'Batch No.'} // [Translation] Update Translation
                        name="batchNo"
                        placeholder={'Batch No.'} // [Translation] Update Translation
                        // Others
                        component={TextInput}
                        disabled={disabled}
                        validate={validator.notEmpty}
                        required
                    />
                </Col>
            }
            <Col {...getColSpan(hasBatchNo).serialNum}>
                <Field
                    // Styling
                    className="input-w input-align-left"
                    // Labels
                    label={"Serial No."} // [Translation] Update Translation
                    name="serialNum"
                    placeholder={"Serial Number"} // [Translation] Update Translation
                    // Events
                    onPressEnterCallback={isCopyFromUI(interfaceType.current) ? onSelectSubmit : onSerialNumSubmit}
                    // Others
                    component={TextInput}
                    disabled={disabled}
                />
            </Col>
            {renderButtons()}
        </Row>
    )
}

export default SectionSerializedDetails