import { POST } from './request-options'

export const GetListItemCostByWareHouseBasedOnCostingMethod = (token, companyID, warehouseID, qty, docType, customerVendorID, itemObj) => {
    const param = `companyID=${companyID}&warehouseID=${warehouseID}&qty=${qty}&docType=${docType}&customerVendorID=${customerVendorID}&UI-Culture=en-US`;
    const PostOptions = POST(token);

    //convert array to string
    const itemID = itemObj.itemID ? itemObj.itemID.toString() : "";
    const itemUOMID = itemObj.itemUOMID ? itemObj.itemUOMID.toString() : "";

    PostOptions.body = JSON.stringify({
        "itemID": itemID,
        "itemUOMID": itemUOMID,
    });

    return fetch(`${process.env.REACT_APP_API_ITEM_MASTER_ITEMS_COST}${param}`, PostOptions)
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            if (data.Result)
                return data;
            else
                return data;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

const handleErrors = (response) => {
    if (!response.ok) throw new Error(response.status);
    return response;
}
