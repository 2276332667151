import React from 'react';
import Form from 'devextreme-react/form';
import { Item } from 'devextreme-react/select-box';
import { Button } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import '../pop-up/popup.scss';
import { Popup } from 'devextreme-react/popup';
import ErrorPopUp from './error-popup';
import ScrollView from 'devextreme-react/scroll-view';

//#region customized 
//components
import TextBox2 from '../text-box/text-box-2';
import Flash from '../message/flash'

//layouts
import { ListingHeaderTagBox } from '../tagbox';

//default 
import { DefaultPager } from '../../utils/default-data-grid-settings';
import { UserID, Token } from '../../utils/default-cookies';
import { checkHTTPResponseCode } from '../../utils/error-popup-http-error-msg';
//api
import { GetAdvanceSearchCity } from '../../api/city';
import { GetUserLayout } from '../../api/user-preferred-layout';
import { GetUserSearchPreferenceByUserID } from '../../api/user-search-preference';

//language
import { formatMessage } from 'devextreme/localization';

//DataGridDefault
import { DataGridDefault, customSearchRenderer } from '../';
import { filterBySearchText } from '../data-grid/data-grid-util';

//iframe
import { getPostMessageObj } from '../../utils/iframe-func'
import { mousetrapMain } from '../../App';

//#endregion

class CityPopUp extends React.Component {
    constructor(props) {
        super(props);

        this.dataGridRef = React.createRef();
        this.quickSearchRef = React.createRef();

        this.addNewCity = this.addNewCity.bind(this);

        this.searchTagValueChangeHandler = this.searchTagValueChangeHandler.bind(this)
        this.searchValueChangeHandler = this.searchValueChangeHandler.bind(this);
        this.onFlashMessageTimer = this.onFlashMessageTimer.bind(this);
        this.showMsgHandler = this.showMsgHandler.bind(this);
        this.closeFlashMessageCallback = this.closeFlashMessageCallback.bind(this);
        this.openSourceTab = this.openSourceTab.bind(this);
        this.popupOnShowing = this.popupOnShowing.bind(this);
        this.popupOnShown = this.popupOnShown.bind(this);
        this.popupOnHidden = this.popupOnHidden.bind(this);
        this.handleErrorPopUp = this.handleErrorPopUp.bind(this);
        this.handlePreferedLayout = this.handlePreferedLayout.bind(this);

        this.fetchDataAPI = this.fetchDataAPI.bind(this);

        this.onKeyDownDataGrid = this.onKeyDownDataGrid.bind(this);
        this.onRowDblClick = this.onRowDblClick.bind(this);
        this.onApplyBtnClick = this.onApplyBtnClick.bind(this);


        this.state = {
            searchText: '',
            tagValue: ['CO_Description', 'ST_Description', 'CI_Description'],
            cityList: [],
            selectedCityData: {},
            addCityURL: '',

            //flash msg
            emptyMsg: '',
            displayFlashMessageDialog: false,
            status: 'success',
            size: 'flash-message-success-container',

            //Error pop up
            displayErrorPopUp: false,
            errorTitle: "",
            setErrorTitle: "",
        };

        this.searchbox = [
            {
                header: 'CO_Code',
                title: formatMessage('CountryCode')
            },
            {
                header: 'CO_Description',
                title: formatMessage('Country')
            },
            {
                header: 'ST_Code',
                title: formatMessage('StateCode')
            },
            {
                header: 'ST_Description',
                title: formatMessage('State')
            },
            {
                header: 'CI_Code',
                title: formatMessage('CityCode')
            },
            {
                header: 'CI_Description',
                title: formatMessage('City')
            },
        ]
    }

    get dataGrid() {
        // `current.instance` points to the UI component instance
        return this.dataGridRef.current.instance;
    }

    async searchValueChangeHandler(event) {
        this.setState({ searchText: event });
        const dataSource_AdvSearchCity = await GetAdvanceSearchCity(Token(), this.state.searchText, this.state.tagValue)
        this.setState({ cityList: dataSource_AdvSearchCity.Query_CityAdvanceSearch });

        filterBySearchText(this.state.searchText, this.dataGrid);
    }

    searchTagValueChangeHandler = (event) => {
        this.setState({ tagValue: event });
    }

    addNewCity(data) {
        this.props.parentSelectedData(data);
        this.props.hidePopup();
    }
    onApplyBtnClick(data) {
        const rowKey = this.dataGridRef.current.instance.option('focusedRowKey')
        this.dataGridRef.current.instance.byKey(rowKey).done((data) => {
            this.addNewCity(data);
        });
    }

    closeFlashMessageCallback = () => {
        this.setState(state => ({
            displayFlashMessageDialog: !state.displayFlashMessageDialog
        }))
    }

    handleStates = (cityData, userSearchPreference) => {
        const userSearchPreferenceArr = userSearchPreference?.USP_ColumnName.split(';');
        this.setState(prevState => ({
            cityList: cityData ? cityData.Query_CityAdvanceSearch : prevState.cityList,
            addCityURL: cityData ? cityData.AddNewCity_URL : prevState.addCityURL,
            tagValue: userSearchPreferenceArr ? userSearchPreferenceArr : prevState.tagValue
        }));
    }

    handleErrorPopUp = () => {
        this.setState(state => ({
            displayErrorPopUp: !state.displayErrorPopUp
        }))
    }

    handlePreferedLayout = (result) => {
        this.setState({ preferedLayout: result });
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onFlashMessageTimer, false);
        document.addEventListener('mousedown', this.onFlashMessageTimer, false);
    }

    componentWillUnmount() {
        mousetrapMain.unpause();

        document.removeEventListener('keydown', this.onFlashMessageTimer, false);
        document.removeEventListener('mousedown', this.onFlashMessageTimer, false);

    }

    async fetchDataAPI() {
        const [dataSource_AdvSearchCity, userPreferedLayout, userSearchPreference] = await Promise.all([
            GetAdvanceSearchCity(Token(), this.state.searchText, this.state.tagValue),
            GetUserLayout(Token(), UserID(), this.props.defaultSMI.moduleURL, this.props.defaultSMI.controlID),
            GetUserSearchPreferenceByUserID(Token(), UserID(), 'city')
        ])

        if (typeof dataSource_AdvSearchCity === "string" && dataSource_AdvSearchCity.includes("Error")) {
            const responseMsg = checkHTTPResponseCode(dataSource_AdvSearchCity);
            this.setState({
                errorTitle: responseMsg.title,
                errorSubTitle: responseMsg.subtitle,
                displayErrorPopUp: true
            })
        }
        else {
            this.handleStates(dataSource_AdvSearchCity, userSearchPreference);
            this.handlePreferedLayout(userPreferedLayout);
        }
    }

    onFlashMessageTimer() {
        if (this.state.displayFlashMessageDialog === true) {
            setTimeout(() => this.setState({ displayFlashMessageDialog: false }), 1000)
        }
    }

    showMsgHandler(params, value, size) {

        if (params !== '') {
            this.setState({ emptyMsg: params, displayFlashMessageDialog: true, status: value, size: size });
        }
        else {
            this.setState({ emptyMsg: '', displayFlashMessageDialog: false, status: value, size: size });
        }
    }

    openSourceTab() {
        window.parent.postMessage(getPostMessageObj(this.state.addCityURL, "Area"), "*");
    }

    //#region  datagrid function
    onKeyDownDataGrid(e) {

        if (e.event.key === "Enter") {
            const rowKey = e.component.option("focusedRowKey");
            e.component.byKey(rowKey).done((data) => {
                this.addNewCity(data);
            });
        }
    }

    onRowDblClick(e) {
        if (e.rowType === "data") {
            this.addNewCity(e.data);
        }
    }

    //#endregion

    //#region pop up function

    popupOnShowing() {
        mousetrapMain.pause();
    }

    popupOnShown(e) {
        mousetrapMain.pause();

        this.fetchDataAPI();
        this.quickSearchRef.setFocus(e);
    }

    popupOnHidden(e) {
        mousetrapMain.unpause();

        this.quickSearchRef.clear(e);
        this.dataGridRef.current.instance.pageIndex(0);
    }
    //#endregion

    render() {
        const { contextMenuAccess } = this.props;
        return (
            <Popup
                className="item-popup"
                showTitle={true}
                title={this.props.title}
                onHiding={this.props.hidePopup}
                onShowing={this.popupOnShowing}
                onShown={this.popupOnShown}
                onHidden={this.popupOnHidden}
                visible={this.props.visible}
                dragEnabled={true}
                closeOnOutsideClick={true}
                width={'81%'}
                height={'95%'}>

                <ErrorPopUp
                    parentCallback={this.handleErrorPopUp}
                    visible={this.state.displayErrorPopUp}
                    title={this.state.errorTitle}
                    subTitle={this.state.errorSubTitle}
                />

                <Flash
                    parentCallback={this.closeFlashMessageCallback}
                    message={this.state.emptyMsg}
                    visible={this.state.displayFlashMessageDialog}
                    severity={this.state.status}
                    container={this.state.size} />

                <ScrollView width='100%' height='90%'>
                    <Form
                        colCount={8}>

                        <Item colSpan={4}>
                            <ListingHeaderTagBox
                                parentCallback={this.searchTagValueChangeHandler}
                                textField={'dx-fieldset-popup-text'}
                                inputField={'dx-fieldset-popup'}
                                dataSource={this.searchbox}
                                valueExpr={'header'}
                                displayExpr={'title'}
                                caption={formatMessage('SearchColumn')}
                                defaultValue={this.state.tagValue}
                                value={this.state.tagValue} />
                        </Item>

                        <Item colSpan={4}></Item>

                        <Item colSpan={4}>
                            <TextBox2
                                placeHolderText={formatMessage('QuickSearchPlaceholderText')}
                                buttonStyle={'search-word'}
                                focusStateEnabled={true}
                                textField={'popup-text'}
                                buttonText={formatMessage('Search')}
                                value={this.state.searchText}
                                onTbvalueChange={this.searchValueChangeHandler}
                                ref={(ref) => this.quickSearchRef = ref}
                                visible={true}>
                            </TextBox2>
                        </Item>

                        <Item colSpan={4}>
                            <Button
                                className="add-item-btn"
                                hoverStateEnabled={true}
                                activeStateEnabled={false}
                                focusStateEnabled={false}
                                onClick={this.openSourceTab}
                                type={"default"}
                                stylingMode={"contained"}
                                icon={"normalize-icon glyphicon icon-add-icon"}
                                text={this.props.addNewButtonText || formatMessage('AddNew')}
                            >
                            </Button>
                        </Item>

                        <Item colSpan={8}>
                            <div style={{ marginTop: "12px" }}> </div>
                            <DataGridDefault
                                allowDisplayColumnChooser={contextMenuAccess.allowDisplayColumnChooser}
                                allowExportGrid={false}
                                allowedPageSizes={DefaultPager.allowedPageSizes_details}
                                allowRestoreLayout={contextMenuAccess.allowRestoreLayout}
                                allowSaveGridLayout={contextMenuAccess.allowSaveGridLayout}
                                className='dx-datagrid-items'
                                dataGridRef={this.dataGridRef}
                                dataSource={this.state.cityList}
                                defaultPageSize={DefaultPager.defaultPageSize_details}
                                defaultSMI={this.props.defaultSMI}
                                keyExpr="CI_ID"
                                noDataText={formatMessage("dxDataGrid-noDataText")}
                                preferedLayout={this.state.preferedLayout}
                                showMsgHandler={this.showMsgHandler}
                                customSelectionBox={false}
                                exportFileName="CityPopup"
                                focusedRowEnabled={true}
                                onRowDblClick={this.onRowDblClick}
                                onKeyDown={this.onKeyDownDataGrid}
                            >

                                <Column
                                    allowHiding={false}
                                    showInColumnChooser={false}
                                    dataField={'CI_ID'}
                                    width={90}
                                    visible={false} />

                                <Column
                                    caption={formatMessage('Country')}
                                    dataField={'CO_Description'}
                                    cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                    hasCustomSearch={true}
                                    minWidth={60} />

                                <Column
                                    caption={formatMessage('State')}
                                    dataField={'ST_Description'}
                                    cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                    hasCustomSearch={true}
                                    minWidth={60} />
                                <Column
                                    caption={formatMessage('City')}
                                    dataField={'CI_Description'}
                                    cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                    hasCustomSearch={true}
                                    minWidth={60} />
                                <Column
                                    caption={formatMessage('StateCode')}
                                    dataField={'ST_Code'}
                                    cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                    hasCustomSearch={true}
                                    minWidth={60} />
                                <Column
                                    caption={formatMessage('CityCode')}
                                    dataField={'CI_Code'}
                                    cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                    hasCustomSearch={true}
                                    minWidth={60} />

                            </DataGridDefault>
                        </Item>
                    </Form>
                </ScrollView>
                <div className="item-popup-footer">
                    <div className="left-btn-container">
                        <Button
                            hoverStateEnabled={true}
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            className="cancel-btn"
                            text={formatMessage("Cancel")}
                            type="normal"
                            stylingMode="contained"
                            onClick={this.props.hidePopup}>
                        </Button>
                    </div>
                    <div className="right-btn-container">
                        <Button
                            hoverStateEnabled={true}
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            className="apply-btn"
                            text={formatMessage("OK")}
                            onClick={this.onApplyBtnClick}
                            type="default"
                            stylingMode="contained">
                        </Button>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default CityPopUp;