import React from 'react'
import { Row, Col } from "antd";
import { Field } from "formik";
import { TextAreaInput } from '../../../fields';
import { formatMessage } from 'devextreme/localization';

const SectionRemarks = (props) => {
    return (
        <Row gutter={30}>
            <Col span={24}>
                <Field
                    // Labels
                    label={formatMessage('Remarks')}
                    name="remarks"
                    placeholder={'Enter a note here...'} // [Translation] Update Translation
                    // Others
                    component={TextAreaInput}
                />
            </Col>
        </Row>
    )
}

export default SectionRemarks