import React, { Component } from 'react'
import { Column, ColumnChooser, DataGrid, FilterRow, Grouping, GroupPanel, LoadPanel, Pager, Paging, RowDragging, Scrolling, SearchPanel, Selection, StateStoring } from 'devextreme-react/data-grid'
import { DefaultStateStoringIgnore } from '../../utils/default-data-grid-settings';
import DataGridContextMenu from './data-grid-context-menu';
import { PostUserLayout } from '../../api/user-preferred-layout';
import { CompanyID, UserID, ClientID, Token } from '../../utils/default-cookies';
import loadingBar from '../image/loadingBar.gif';
import dxDataGrid from "devextreme/ui/data_grid";
import ReactTooltip from 'react-tooltip';
import { debounce } from "lodash";
import './data-grid.scss';
import { mousetrapSecondary } from '../../App';

export default class DataGridDefault extends Component {
    constructor(props) {
        super(props);

        this.dataGridContextMenuRef = React.createRef();

        this.state = {
            selectedRowKeys: props.defaultSelectedRowKeys || [],
            showFilterRow: false,
            showGroupPanel: false,
            showPager: false,
        };

        this.rebuildTooltip = debounce(function () {
            // Prevent multiple rebuild by waiting for 1 second, redundant calls will be cancelled
            // Cons: Will cause dynamic content's tooltip to not appear within 0.5 second after update
            ReactTooltip.rebuild();
        }, 500);

        this.moduleItemID = null;

        this.focusedRowUp = this.focusedRowUp.bind(this);
        this.focusedRowDown = this.focusedRowDown.bind(this);

        this.addMenuItems = this.addMenuItems.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.onEditingStart = this.onEditingStart.bind(this);
        this.onReorder = this.onReorder.bind(this);
        this.onFocusedCellChanging = this.onFocusedCellChanging.bind(this);
        this.onOptionChanged = this.onOptionChanged.bind(this);
        this.onRowDblClick = this.onRowDblClick.bind(this);

        this.loadLayout = this.loadLayout.bind(this);
        this.saveLayout = this.saveLayout.bind(this);
        this.restoreLayout = this.restoreLayout.bind(this);

        this.toggleSelectionBox = this.toggleSelectionBox.bind(this);

        this.handleShowFilterRow = this.handleShowFilterRow.bind(this);
        this.handleShowGroupPanel = this.handleShowGroupPanel.bind(this);

        this.initDataGrid();
    }

    componentDidMount() {
        // register up down key binding
        this.mousetrap = this.props.mousetrap || mousetrapSecondary;

        this.mousetrap.bind('up', this.focusedRowUp);
        this.mousetrap.bind("down", this.focusedRowDown);
    }

    componentWillUnmount() {
        this.mousetrap.unbind("up");
        this.mousetrap.unbind("down");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.preferedLayout !== this.props.preferedLayout) {
            // load user preferred layout after props change
            const layout = this.loadLayout();
            if (layout) this.dataGrid.state(layout);
        }
    }

    initDataGrid() {
        // Set default allowEditing to false
        dxDataGrid.defaultOptions({
            options: {
                commonColumnSettings: {
                    allowEditing: false
                },
                navigateToFirst: false, // custom functional option to [trigger] navigation to first row (refer code at onContentReady)
                navigateToLast: false, // custom functional option to [trigger] navigation to last row (refer code at onContentReady)
                navigateToRow: -1, // custom functional option to [trigger] navigation to row by selected key (refer code at onContentReady)
                isEditing: false, // custom property option to keep [track] of editing state
            }
        })
    }

    get dataGrid() {
        // `current.instance` points to the UI component instance
        return this.props.dataGridRef.current.instance;
    }

    dataGridNullCheck() {
        return this.props.dataGridRef.current != null;
    }

    focusedRowUp() {
        const newFocusRowIndex = this.dataGrid.option('focusedRowIndex') - 1;
        const newRowElement = this.dataGrid.getRowElement(newFocusRowIndex);

        if (newFocusRowIndex > -1 && newRowElement) {
            this.dataGrid.option('focusedRowIndex', newFocusRowIndex);

            // scroll nearest
            newRowElement[0].scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
        }
    }

    focusedRowDown() {
        const newFocusRowIndex = this.dataGrid.option('focusedRowIndex') + 1;
        const newRowElement = this.dataGrid.getRowElement(newFocusRowIndex);

        if (newFocusRowIndex < this.dataGrid.getVisibleRows().length && newRowElement) {
            this.dataGrid.option('focusedRowIndex', newFocusRowIndex);

            // scroll nearest
            newRowElement[0].scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
        }
    }

    addMenuItems(event) {
        this.dataGridContextMenuRef.current.DataGridContextMenuItemsService(event, this.dataGrid, this.props.exportFileName);
    }

    onContentReady(event) {
        if (!this.dataGridNullCheck()) return;

        if (event.component.option("navigateToFirst")) {
            // Function: navigate to first row first page when repaint is triggered
            // Invoke: this.dataGrid.option('navigateToFirst', true)
            // Note, may need to manually call "this.datagrid.refresh(true)" after invoking if no repaint is triggered
            event.component.option("navigateToFirst", false);

            event.component.option("focusedRowKey", event.component.getKeyByRowIndex(0));
            event.component.option("focusedRowIndex", 0);
            event.component.pageIndex(0);
        }

        if (event.component.option("navigateToLast")) {
            // Function: navigate to last row last page when repaint is triggered
            // Invoke: this.dataGrid.option('navigateToLast', true)
            // Note, may need to manually call "this.datagrid.refresh(true)" after invoking if no repaint is triggered
            const lastRowIndex = event.component.pageSize() - 1;
            const lastPage = event.component.pageCount() - 1;

            event.component.option("navigateToLast", false);

            event.component.option("focusedRowKey", event.component.getKeyByRowIndex(lastRowIndex));
            event.component.option('focusedRowIndex', lastRowIndex);
            event.component.pageIndex(lastPage);
        }

        if (event.component.option("navigateToRow") !== -1) {
            // Function: navigate to row by selected key when repaint is triggered
            // Invoke: this.dataGrid.option('navigateToRow', key)
            // Note, may need to manually call "this.datagrid.refresh(true)" after invoking if no repaint is triggered
            const rowKey = event.component.option("navigateToRow");
            const rowIndex = event.component.getRowIndexByKey(rowKey);

            event.component.option("navigateToRow", -1);
            event.component.option("focusedRowKey", -1);
            event.component.option('focusedRowIndex', -1);

            event.component.option("focusedRowKey", rowKey);
            event.component.option('focusedRowIndex', rowIndex);
        }

        if (event.component.option("isEditing")) {
            // Update isEditing to false if no row is currently being edited
            const editingRows = event.component.getVisibleRows().filter(row => row.isEditing);

            if (editingRows.length === 0) {
                event.component.option("isEditing", false);
            }
        }

        if (!this.props.disablePaging) {
            // Show or hide pager
            this.setState({
                showPager: event.component.totalCount() > event.component.option("defaultPageSize")
            })
        }

        // Rebuild ReactTooltip to update position for dynamic content
        this.rebuildTooltip();

        this.props.onContentReady && this.props.onContentReady(event);
    }

    onEditingStart(event) {
        event.component.option("isEditing", true);

        this.props.onEditingStart && this.props.onEditingStart(event);
    }

    async onReorder(event) {
        if (this.props.customRowDragging) {
            const isIterable = target => target != null && Symbol.iterator in Object(target);

            if (isIterable(this.props.dataSource)) {
                if (!this.props.customRowDragging.updateDataSource) return;

                // dataSource is array
                const items = [...this.props.dataSource];
                const toIndex = event.toIndex;
                const fromIndex = items.indexOf(event.itemData);

                // remove row
                items.splice(fromIndex, 1);

                // add row back
                items.splice(toIndex, 0, event.itemData);

                // update sequenceKey
                if (this.props.customRowDragging.sequenceKey) {
                    const sequenceKey = this.props.customRowDragging.sequenceKey;
                    for (let i = 0; i < items.length; i++) {
                        items[i][sequenceKey] = i + 1;
                    }
                }

                // updateDataSource
                this.props.customRowDragging.updateDataSource([...items]);
            } else {
                // dataSource is DevExtreme DataLayer - DataSource - ArrayStore
                const dataSource = this.props.dataSource;
                const store = dataSource.store();
                const key = store.key();

                // remove row
                store.remove(event.itemData[key]);
                await dataSource.reload();

                // add row back
                store.insertAt(event.toIndex, event.itemData);
                await dataSource.reload();
            }
        }

        this.props.onReorder && this.props.onReorder(event);
    }

    onFocusedCellChanging(event) {
        if (event.event) {
            // Prevent highlight changing onto non-editable cell
            if (event.event.pointerType) {
                event.isHighlighted = false;
            } else if (event.columns[event.prevColumnIndex] && event.columns[event.prevColumnIndex].allowEditing) {
                if (!event.columns[event.newColumnIndex].allowEditing) {
                    event.newColumnIndex = event.prevColumnIndex;
                }
            } else {
                event.isHighlighted = false;
                event.newColumnIndex = event.prevColumnIndex;
            }
        }

    }

    onOptionChanged(event) {
        if (event.fullName.includes("sortOrder") || event.fullName.includes("filterValue")) {
            this.dataGrid.option("navigateToFirst", true);
        }

        if (event.fullName === 'selectedRowKeys') {
            this.setState({
                selectedRowKeys: event.value
            });
        }

        this.props.onOptionChanged && this.props.onOptionChanged(event);
    }

    onRowDblClick(event) {
        if (this.props.customSelectionBox) {
            this.toggleSelectionBox(event);
        }

        this.props.onRowDblClick && this.props.onRowDblClick(event);
    }

    loadLayout() {
        //load datagrid custom layout state
        if (this.props.preferedLayout && this.props.preferedLayout.length > 0) {
            this.moduleItemID = this.props.preferedLayout[0].UPL_ModuleItemID;

            if (this.dataGridNullCheck() && this.props.preferedLayout[0].UPL_Layout) {
                let userlayout = JSON.parse(this.props.preferedLayout[0].UPL_Layout);

                delete userlayout['focusedRowKey']; // prevent defaultFocusedRowIndex overridden

                if (typeof userlayout !== 'object') return;
                return userlayout;
            }
        }
    }

    saveLayout(state) {
        //save datagrid custom layout state 
        if (!this.props.allowSaveGridLayout) return;

        if (state) {
            state.selectedRowKeys = null;
            state.focusedRowKey = null;
            state.pageIndex = 0

            /* ignore column state need for loop to reset - DefaultStateStoringIgnore setting will do ignore when loadLayout for columns properties Only */
            //for (let i = 0; i < state.columns.length; i++) {
            //    state.columns[i].sortIndex = null;
            //}
        }

        if (this.moduleItemID) {
            PostUserLayout(Token(), CompanyID(), ClientID(), UserID(), this.moduleItemID, this.props.defaultSMI.controlID, this.props.defaultSMI.moduleURL, "storageKey", state);
        }

        if (this.props.preferedLayoutRef && this.props.preferedLayoutRef.current && this.props.preferedLayoutRef.current.length > 0) {
            // save preferedLayout to ref (used for PI Detail as changing tab will destroy data grid)
            this.props.preferedLayoutRef.current[0].UPL_Layout = JSON.stringify(state);
        }
    }

    restoreLayout() {
        //data-grid-context-menu to restore datagrid default layout 
        if (this.moduleItemID) {
            PostUserLayout(Token(), CompanyID(), ClientID(), UserID(), this.moduleItemID, this.props.defaultSMI.controlID, this.props.defaultSMI.moduleURL, "storageKey", " ");
        }

        if (this.dataGridNullCheck()) {
            this.dataGrid.state("");
        }

        if (this.props.preferedLayoutRef && this.props.preferedLayoutRef.current && this.props.preferedLayoutRef.current.length > 0) {
            // save preferedLayout to ref (used for PI Detail as changing tab will destroy data grid)
            this.props.preferedLayoutRef.current[0].UPL_Layout = "";
        }
    }

    toggleSelectionBox(event) {
        if (event.rowType === "data") {
            if (event.isSelected) {
                const tempSelectedRowKeys = this.state.selectedRowKeys.filter(curr => curr !== event.data[this.props.keyExpr]);
                this.setState({ selectedRowKeys: tempSelectedRowKeys });
            } else {
                const tempSelectedRowKeys = this.state.selectedRowKeys.concat(event.data[this.props.keyExpr]);
                this.setState({ selectedRowKeys: tempSelectedRowKeys });
            }
        }
    }

    handleShowFilterRow() {
        // set child - data-grid-context-menu to show filter row
        this.setState(prevState => ({
            showFilterRow: !prevState.showFilterRow
        }));
        this.handleClearFilter();
    }

    handleShowGroupPanel() {
        // set child - data-grid-context-menu to show group panel
        this.setState(prevState => ({
            showGroupPanel: !prevState.showGroupPanel
        }));
    }

    handleClearFilter() {
        this.dataGrid.clearFilter();
    }

    render() {
        return (
            <>
                <ReactTooltip
                    id="datagrid-tooltip-top"
                    place="top"
                    effect="solid"
                />
                <ReactTooltip
                    id="datagrid-tooltip-error"
                    place="top"
                    effect="solid"
                    type="error"
                    arrowColor="#f44336"
                    backgroundColor="#f44336"
                    className="datagrid-tooltip-error"
                />
                <DataGrid
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={false}
                    columnResizingMode={"widget"}
                    defaultFocusedRowIndex={0}
                    rowAlternationEnabled={true}
                    selectedRowKeys={this.state.selectedRowKeys}
                    showBorders={true}
                    showRowLines={true}
                    ref={this.props.dataGridRef}
                    repaintChangesOnly={true}
                    {...this.props}
                    onContextMenuPreparing={this.addMenuItems}
                    onContentReady={this.onContentReady}
                    onEditingStart={this.onEditingStart}
                    onFocusedCellChanging={this.onFocusedCellChanging}
                    onOptionChanged={this.onOptionChanged}
                    onRowDblClick={this.onRowDblClick}
                >
                    <ColumnChooser enabled={false} mode="select" />
                    <FilterRow visible={this.state.showFilterRow} />
                    <Grouping contextMenuEnabled={true} />
                    <GroupPanel visible={this.state.showGroupPanel} />
                    <LoadPanel indicatorSrc={loadingBar} />
                    <SearchPanel visible={false} text={""} />
                    <Paging
                        enabled={this.props.disablePaging ? false : true}
                        defaultPageSize={this.props.defaultPageSize}
                    />
                    <Pager
                        showPageSizeSelector={this.state.showPager}
                        allowedPageSizes={this.props.allowedPageSizes}
                        defaultPageIndex={0}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={this.state.showPager}
                    />
                    <Scrolling
                        mode="standard"
                        useNative={false}
                        scrollByContent={true}
                        scrollByThumb={true}
                        showScrollbar="always"
                    />
                    <StateStoring
                        enabled={true}
                        type="custom"
                        customLoad={this.loadLayout}
                        customSave={this.saveLayout}
                        ignoreColumnOptionNames={DefaultStateStoringIgnore}
                        savingTimeout={1000} //default is 2000  
                    />
                    {
                        this.props.customRowDragging &&
                        <RowDragging
                            allowReordering={true}
                            onReorder={this.onReorder}
                        />
                    }
                    {
                        this.props.customSelectionBox &&
                        <Selection
                            mode={"multiple"}
                            allowSelectAll={true}
                            showCheckBoxesMode={'always'}
                        />
                    }
                    {
                        this.props.customSelectionBox &&
                        <Column
                            type="selection"
                            fixed={true}
                            fixedPosition={'left'}
                            showInColumnChooser={false}
                            allowResizing={false}
                            allowHiding={false}
                            allowReordering={false}
                        />
                    }
                    {this.props.children}
                </DataGrid>
                <DataGridContextMenu
                    disableContextMenu={this.props.disableContextMenu}
                    displayColumnChooser={this.props.allowDisplayColumnChooser}
                    displayExportGrid={this.props.allowExportGrid}
                    displayRestoreLayout={this.props.allowRestoreLayout}
                    ref={this.dataGridContextMenuRef}
                    showRestoreMsg={this.props.showMsgHandler}
                    getChildFilterRow={this.handleShowFilterRow}
                    getChildGroupPanel={this.handleShowGroupPanel}
                    restoreLayout={this.restoreLayout}
                />
            </>
        )
    }
}
