import { Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import { formatMessage } from 'devextreme/localization';
import * as React from 'react';
import { useUserAccessContext } from '../../../../contexts/UserAccess';
import { DecimalPoints } from '../../../../utils/default-cookies';
import { DefaultPager } from '../../../../utils/default-data-grid-settings';
import { getPostMessageObj } from '../../../../utils/iframe-func';
import HyperlinkCell from '../../../data-grid/custom-cell/hyperlink-cell';
import DataGridDefault from '../../../data-grid/data-grid-default';

const moneyFormatColumn = {
    type: 'fixedPoint',
    precision: DecimalPoints(),
}

export default function PurchaseHistoryTable(props) {
    const {
        dataSource,
        userProfile,
    } = props;

    const dataGridRef = React.useRef(null);
    const { userAccess } = useUserAccessContext();

    const openSourceTab = (url) => {
        var urlArray = url.split(';');
        window.parent.postMessage(getPostMessageObj(urlArray[0], urlArray[1]), "*");
    }

    const onClickDocNoLink = (cellRow) => {
        openSourceTab(cellRow.data.LinkBtnUrlDocumentNo)
    }
    const onClickVendorNameLink = (cellRow) => {
        openSourceTab(cellRow.data.LinkBtnUrlVendor)
    }

    return (
        <div className="module-data-grid-popup">
            <DataGridDefault
                // Styling
                className='dx-datagrid-items'
                // DevExtreme DataGrid Configurations
                dataSource={dataSource || []}
                keyExpr="DocNo"
                allowDisplayColumnChooser={userAccess.allowDisplayColumnChooser}
                allowExportGrid={userAccess.allowExportGrid}
                allowRestoreLayout={userAccess.allowRestoreLayout}
                allowedPageSizes={DefaultPager.allowedPageSizes_details}
                defaultPageSize={DefaultPager.defaultPageSize_details}
                noDataText={formatMessage("dxDataGrid-noDataText")}
                focusedRowEnabled
                // Custom Configurations
                dataGridRef={dataGridRef}
                exportFileName="PurchaseHistory"
            >
                <Column // Summary Total
                    fixedPosition={'left'}
                    fixed={true}
                    width={0}
                    allowExporting={false}
                    allowFiltering={false}
                    allowHeaderFiltering={false}
                    allowHiding={false}
                    allowReordering={false}
                    allowResizing={false}
                    allowSorting={false}
                    showInColumnChooser={false}
                    cssClass="column-normalize"
                    dataField={'_TOTAL'} // dummy dataField for summary
                    caption={null}
                />
                <Column
                    caption={formatMessage('DocType')}
                    dataField={'DocType'}
                />
                <Column
                    caption={formatMessage('DocDate')}
                    dataField={'DocDate'}
                    dataType="date"
                    format={userProfile?.US_DateFormat || "dd/MM/yyyy"}
                />
                <Column
                    caption={formatMessage('PostingDate')}
                    dataField={'PostingDate'}
                    dataType="date"
                    format={userProfile?.US_DateFormat || "dd/MM/yyyy"}
                />
                <Column
                    caption={formatMessage('DocNo')}
                    dataField={'DocNo'}
                    cellRender={(cellData) => HyperlinkCell({ cellData, onClick: onClickDocNoLink })}
                />
                <Column
                    caption={formatMessage('VendorCode')}
                    dataField={'VendorCode'}
                />
                <Column
                    caption={formatMessage('VendorName')}
                    dataField={'VendorName'}
                    cellRender={(cellData) => HyperlinkCell({ cellData, onClick: onClickVendorNameLink })}
                />
                <Column
                    caption={formatMessage('UOM')}
                    dataField={'UOM'}
                />
                <Column
                    caption={formatMessage('QTY')}
                    dataField={'Qty'}
                />
                <Column
                    caption={formatMessage('UnitPrice')}
                    dataField={'UnitPrice'}
                    format={moneyFormatColumn}
                />
                <Column
                    caption={formatMessage('Discount')}
                    dataField={'Discount'}
                    format={moneyFormatColumn}
                />
                <Column
                    caption={formatMessage('SellPrice')}
                    dataField={'SellPrice'}
                    format={moneyFormatColumn}
                />
                <Column
                    caption={formatMessage('SubTotal')}
                    dataField={'SubTotal'}
                    format={moneyFormatColumn}
                />
                <Summary>
                    <TotalItem
                        summaryType="count"
                        showInColumn="_TOTAL"
                        displayFormat={`${formatMessage("Total")}:`}
                        cssClass="summary-overflow"
                        skipEmptyValues
                    />
                    <TotalItem
                        summaryType="sum"
                        column="Qty"
                        displayFormat="{0}"
                    />
                </Summary>
            </DataGridDefault>
        </div>
    )
}