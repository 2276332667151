export const GET = (token) => {
    return ({
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization' : "Bearer " + token
      })
    })
}

export const POST = (token) => {
    return ({
        method: 'POST',
        headers: new Headers({
          'accept': 'text/plain',
          'Content-Type': 'application/json-patch+json',//application/json
          'Authorization' : "Bearer " + token
        })
    })
}

export const PUT = (token) => {
  return ({
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  })
}

export const DELETE = (token) => {
  return ({
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  })
}
