import { ItemType } from "../../utils/constant-item-type";

export const checkIsAllowUpdateQty = (focusedRowGroupID, itemList, newQty) => {
    let obj = { isAllowUpdate: false, errorPoupMsg: "" };

    //check what is the item type
    let filterMasterItem = itemList.filter(x => x.PID_GroupID === focusedRowGroupID && x.PID_SubItem_YN === false);
    if (filterMasterItem.length === 0) return false;

    switch (filterMasterItem[0].PID_ItemTypeID) {
        case ItemType.STOCKITEM:
            //check is multi-bin stock item qty has been selected
            let filterSubItems = itemList.filter(x => x.PID_GroupID === focusedRowGroupID && x.PID_SubItem_YN === true && x.PID_ItemTypeID === ItemType.STOCKITEM);
            obj.isAllowUpdate = filterSubItems?.length > 1 ? false : true;
            if (!obj.isAllowUpdate)
                obj.errorPoupMsg = "NotAllowedtoUpdateQty";
            break;
        case ItemType.WEIGHT_ITEM:
            obj.isAllowUpdate = true;
            obj.errorPoupMsg = "";
            break;
        case ItemType.SERVICE_ITEM:
            obj.isAllowUpdate = true;
            obj.errorPoupMsg = "";
            break;
        default:
            obj.isAllowUpdate = false;
            obj.errorPoupMsg = "NotAllowedtoUpdateQty"
            break;
    }

    //check partial transfer balance qty enough or not
    if (obj.isAllowUpdate && filterMasterItem[0].PID_SourceDocID > 0 && filterMasterItem[0].CopyFrom?.DocTransferStatus === 'PARTIAL') {
        const newBalanceBasedQty = filterMasterItem[0].CopyFrom?.PIDBaseQty + filterMasterItem[0].CopyFrom?.BalanceBaseQty;
        const newBaseQty = newQty * filterMasterItem[0].PID_Rate;

        if (newBalanceBasedQty >= newBaseQty) {
            obj.isAllowUpdate = true;
            obj.errorPoupMsg = "";
        }
        else {
            obj.isAllowUpdate = false;
            obj.errorPoupMsg = "NotEnoughTransferQty";
        }
    } else if (obj.isAllowUpdate && filterMasterItem[0].PID_SourceDocID > 0 && filterMasterItem[0].CopyFrom?.DocTransferStatus === 'FULL') {
        //check is full transfer
        obj.isAllowUpdate = false;
        obj.errorPoupMsg = "SystemDoesNotAllowedtoUpdateQtyforFullTransferItem";
    }
    return obj;
}

export const checkIsAllowUpdatePrice = (focusedRowGroupID, itemList) => {
    let isAllowUpdate = true;
    //check what is the item type
    let filterMasterItem = itemList.filter(x => x.PID_GroupID === focusedRowGroupID && x.PID_SubItem_YN === false);
    if (filterMasterItem.length === 0) return true;

    switch (filterMasterItem[0].PID_ItemTypeID) {
        case ItemType.PACKAGE_ITEM:
            isAllowUpdate = false;
            break;
        default:
            isAllowUpdate = true;
            break;
    }
    return isAllowUpdate
}

export const checkIsAllowUpdateDiscountFormula = (focusedRowGroupID, itemList) => {
    let isAllowUpdate = false;
    //check what is the item type
    let filterMasterItem = itemList.filter(x => x.PID_GroupID === focusedRowGroupID && x.PID_SubItem_YN === false);
    if (filterMasterItem.length === 0) return true;

    switch (filterMasterItem[0].PID_ItemTypeID) {
        case ItemType.PACKAGE_ITEM:
            isAllowUpdate = false;
            break;
        default:
            isAllowUpdate = true;
            break;
    }

    if (filterMasterItem[0].PID_Item.IM_NonDiscount === true) {
        isAllowUpdate = false;
    }
    return isAllowUpdate
}

export const checkCorrectFormulaFormat = (discountFormula) => {
    var digit = parseFloat(discountFormula.replace(/[/+-.]/g, ''));
    if (digit / 1 === digit) {
        return true;
    }
    else {
        return false;
    }
}