import React from 'react'
import { Row, Col } from "antd";
import { Field } from "formik";
import { NumberInput } from '../../../fields';
import { validator } from '../../../../utils/form-validator';

const SectionInput = (props) => {
    return (
        <Row gutter={30}>
            <Col xs={24} md={12}>
                <Field
                    // Styling
                    className="input-w input-align-left"
                    // Labels
                    label={props.label}
                    name={props.name}
                    placeholder={props.placeholder}
                    // Formatting
                    step={0}
                    precision={0}
                    min={1}
                    max={999999999999}
                    // Events
                    onChangeCallback={props.onChangeCallback}
                    // Others
                    component={NumberInput}
                    validate={validator.notEmpty}
                    required={props.required}
                    disabled={props.disabled}
                />
            </Col>
        </Row>
    )
}

export default SectionInput