import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import loadingBar from '../image/alayaLoading.gif';
import './style.scss';

const OverlayBlockProvider = ({ children }) => {
    const [visible, setVisible] = useState(false);

    const showOverlayBlock = useCallback(() => {
        setVisible(true);
    }, [])

    const hideOverlayBlock = useCallback(() => {
        setVisible(false);
    }, [])

    const contextValue = useMemo(() => {
        return {
            showOverlayBlock,
            hideOverlayBlock
        };
    }, [showOverlayBlock, hideOverlayBlock])

    return (
        <OverlayBlockContext.Provider value={contextValue}>
            {children}
            {
                visible &&
                <div className='overlayprovider-container'>
                    <img src={loadingBar} alt="Loading..." />
                </div>
            }
        </OverlayBlockContext.Provider>
    )
}

const OverlayBlockContext = createContext({});
const useOverlayBlockContext = () => useContext(OverlayBlockContext);

export { OverlayBlockProvider, useOverlayBlockContext }