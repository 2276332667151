import React, { useCallback, useState, useRef } from 'react';
import Form from 'devextreme-react/form';
import { Item } from 'devextreme-react/select-box';
import { Button } from 'devextreme-react/button';

//language
import { formatMessage } from 'devextreme/localization';
import ReactTooltip from 'react-tooltip';

//components
import {
    Singleselectiondropdowntable,
    CalenderRange,
    Singleselectiondropdown,
    TextBox2,
    ListingHeaderTagBox
} from '../../../components/index';

//cookies
import { SearchText } from '../../../utils/default-cookies';
import './listing-header.scss';

// default value
import { DefaultPIDateTypeSource } from '../../../utils/default-date-type';
import { DefaultDocStatusSource } from '../../../utils/default-doc-status';
import { appData } from '../../../App';

const ListingHeaderDocument = (props) => {
    const { onSelectValueChange, onTagValueChange, onSearchValueChange, onCalendarRangeChange } = props;
    const portalRef = useRef(null);

    const [searchValue, setSearchValue] = useState(SearchText());
    const [tagValue, setTagValue] = useState(appData.PITagValue);
    const [dateRangeValid, setDateRangeValid] = useState(true);

    // company
    const onSelectionChanged = useCallback((selection, displayProp, valueProp) => {
        onSelectValueChange(selection[valueProp]);
    }, [onSelectValueChange]);

    // doc status
    const onTagChanged = useCallback((value) => {
        setTagValue(value)
        onTagValueChange(value)
    }, [onTagValueChange]);

    const onDateTypeChanged = (value) => {
        props.onDateTypeValueChange(value)
    }

    // search
    const onTbvalueChange = useCallback((value) => {
        setSearchValue(value);
        onSearchValueChange(value);
    }, [onSearchValueChange]);

    //calendar
    const onSelectDate = useCallback((fromDate, toDate, valid) => {
        setDateRangeValid(valid);

        if (valid) {
            onCalendarRangeChange(fromDate, toDate);
        }
    }, [onCalendarRangeChange]);

    return (
        <>
            <div className="portalRef dx-dropdowneditor-overlay" ref={portalRef} />

            <Form colCount={8}>
                <Item colSpan={4}>
                    <Singleselectiondropdowntable
                        dataSource={props.dataSource}
                        defaultValue={props.defaultValue}
                        defaultName={props.defaultName}
                        placeholder={props.placeholder}
                        caption={props.captionDropdown}
                        valueProp={props.valueProp}
                        nameProp={props.nameProp}
                        displayProp={props.displayProp}
                        onSelectionChanged={onSelectionChanged}>
                    </Singleselectiondropdowntable>
                </Item>

                <Item colSpan={2}></Item>

                <Item colSpan={2}>
                    <ListingHeaderTagBox
                        caption={props.captionTagbox}
                        dataSource={DefaultDocStatusSource}
                        defaultValue={tagValue}
                        value={tagValue}
                        inputField={'dx-fieldset'}
                        valueExpr={'displayValue'}
                        displayExpr={'displayValue'}
                        parentCallback={onTagChanged}
                        textField={"dx-fieldset-text"}
                    />
                </Item>

                <Item colSpan={2}>
                    <Singleselectiondropdown
                        asterisk={true}
                        caption={props.captionDropdown2}
                        dataSource={DefaultPIDateTypeSource}
                        defaultValue={appData.PIDateType}
                        valueExpr={'value'}
                        displayExpr={'display'}
                        placeholder={props.placeholder}
                        parentCallback={onDateTypeChanged}
                    />
                </Item>

                <Item colSpan={2}>
                    <CalenderRange
                        caption={formatMessage('DateRange')}
                        id={"calendar-dropdown-box"}
                        className={'filter-drop-down'}
                        parentCallback={onSelectDate}
                        dateFormat={props.defaultDateFormat}
                        defaultStartDate={props.defaultStart}
                        defaultEndDate={props.defaultEnd}
                        portalRef={portalRef}
                    />
                </Item>

                <Item colSpan={1}>
                    <div className="dx-fieldset">
                        <div className="dx-field">
                            <div className="dx-field-value-2" >
                                <Button
                                    icon={"normalize-icon glyphicon icon-filter-icon"}
                                    type="default"
                                    stylingMode={"contained"}
                                    text={props.buttonText4}
                                    className={"filter-btn"}
                                    onClick={props.onFilterClick}
                                    disabled={!dateRangeValid}
                                />
                            </div>
                        </div>
                    </div>
                </Item>

                <Item colSpan={3}></Item>

                <Item colSpan={8}>
                    <hr className="purchase-invoice-line-separator" />
                </Item>

                <Item colSpan={4}>
                    <TextBox2
                        placeHolderText={props.placeHolderText}
                        buttonStyle={'search-word'}
                        buttonText={props.buttonText}
                        value={searchValue}
                        onTbvalueChange={onTbvalueChange}
                        visible={true}
                        ref={props.searchBoxRef}
                    />
                </Item>
                <Item colSpan={4}>

                    <div className="dx-fieldset" >
                        <div className="dx-field" >
                            <div className="dx-field-value" >
                                <div data-tip={formatMessage("PrintDocumentSendWhatsappOrSendEmail")} data-for="docActionBtn" className="containerBtn">
                                    <Button
                                        id={'docActionBtn'}
                                        text={props.buttonText3}
                                        icon={"normalize-icon glyphicon icon-document-action-icon"}
                                        type={"default"}
                                        stylingMode={"outlined"}
                                        hoverStateEnabled={true}
                                        activeStateEnabled={false}
                                        focusStateEnabled={false}
                                        onClick={props.onDocActionBtnClick}>
                                    </Button>
                                    <ReactTooltip id="docActionBtn" place="bottom" effect="solid" arrowColor="#474751" />
                                </div>
                                <div className="containerBtn">
                                    <Button
                                        id={"analysisBtn"}
                                        text={props.buttonText2}
                                        icon={"normalize-icon glyphicon icon-analysis-icon"}
                                        type={"default"}
                                        stylingMode={"outlined"}
                                        hoverStateEnabled={true}
                                        activeStateEnabled={false}
                                        focusStateEnabled={false}
                                        onClick={props.onAnalysisBtnClick} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Item>
            </Form>
        </>
    )
};

export default ListingHeaderDocument;