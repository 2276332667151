import React, { createContext, useContext, useMemo, useState } from 'react'

const UserAccess = (props) => {
    const [userAccess, setUserAccess] = useState({
        allowExportGrid: false,
        allowDisplayColumnChooser: false,
        allowRestoreLayout: false,
        allowSaveGridLayout: false,
    })

    const contextValue = useMemo(() => {
        return {
            userAccess,
            setUserAccess,
        };
    }, [userAccess])

    return (
        <UserAccessContext.Provider value={contextValue} {...props} />
    );
}

const UserAccessContext = createContext({});
const useUserAccessContext = () => useContext(UserAccessContext);

export { UserAccess as UserAccessProvider, useUserAccessContext }