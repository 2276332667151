import React, { useState, useRef, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from 'react-dom';
import { FormikDefault } from "../../../components/forms/formik-default";
import { formatMessage } from 'devextreme/localization';
import { uniqueId, isEqual } from "lodash";
import { checkInvalidCell, checkInvalidRow } from "../../../components/data-grid/data-grid-util";
import { checkHTTPResponseCode } from '../../../utils/error-popup-http-error-msg';
import { CompanyID, UserID, ClientID, Token, ReportURL, SetCookies, AdminYN, SetDefaultName, SetDecimalPoints } from "../../../utils/default-cookies";
import { ItemType } from "../../../utils/constant-item-type";
import { getPostMessageObj, getReactParameters } from '../../../utils/iframe-func'
import Cookies from "universal-cookie";
import moment from 'moment';

//api
import { GetPIDtlHeaderMasterData, GetPIDtlData, GetPIDtlAccess, GetNewPIDTLItemsByCodeOrPluOrBarCode, GetNewPIDTLAccountsByID, GetNewPIDTLByImportData, GetCopyToPIBySourceDocID, GetPurchaseInvoiceHDRMaster_OnVendorChanged, GetPurchaseInvoicePIDtl_OnPurchaseGroupChange, PostPurchaseInvoice, GetWarehouseListByCompanyID } from "../../../api/purchase-invoice-details";
import PurchaseInvoiceDetails from "./purchase-invoice-details";
import { GetPurchaseCurrencyRateByCCID_EffectiveDate } from "../../../api/company-currency";
import { GetFiscalPeriodPByClientID_CompanyID_PostDate } from "../../../api/company-fiscal-year";
import { GetCompanyTaxTypeRatePurchase } from "../../../api/tax-type";
import { GetListItemCostByWareHouseBasedOnCostingMethod } from "../../../api/item-master";
import { CheckDuplicateSupplierInv } from "../../../api/purchase-submit-validation";
import { GetItemBinLocationCodeDesp } from "../../../api/bin-location";

//hooks
import { useHistory, useLocation } from "react-router-dom";
import { useEventListener } from "../../../hooks/useEventListener";

//context 
import { useErrorPopupContext } from "../../../components/modal-dialog/ErrorPopupProvider";
import { useFlashMsgContext } from "../../../components/modal-dialog/FlashMsgProvider";
import { useConfirmationPopupContext } from "../../../components/modal-dialog/ConfirmationPopupProvider";

//component
import { GetUserLayout } from "../../../api/user-preferred-layout";
import { DefaultSMIPIDetail } from "../../../utils/default-smi";
import { ItemState } from "../../../contexts/ItemState";
import { useOverlayBlockContext } from "../../../components/modal-dialog/OverlayBlockProvider";
import { calculateDocTotalData } from "../../../utils/purchase/purchase-calculate-total-func";
import useIsMounted from "../../../hooks/useIsMounted";
import usePrevious from "../../../hooks/usePrevious";
import { GetAdvanceSearchCity } from "../../../api/city";
import { useUserAccessContext } from "../../../contexts/UserAccess";
import { GetAllCompaniesIAGByUser } from "../../inventory-setting/package-item/package-item-services";

const cookies = new Cookies();

const PurchaseInvoiceContainer = () => {
    // Data from API (Won't Change)
    const [iAG, setIAG] = useState({ ID: 0, Desp: "" });
    const [docID, setDocID] = useState(0);
    const [piHdrMaster, setPiHdrMaster] = useState();
    const [piDtlItem, setPiDtlItem] = useState();
    const [docStatus, setDocStatus] = useState("DRAFT");
    const [nextPossibleNo, setNextPossibleNo] = useState("");
    const [piDtlAccess, setPiDtlAccess] = useState({});
    const [transferStatus, setTransferStatus] = useState();
    const [defaultReportTabName, setDefaultReportTabName] = useState(false);
    const [defaultReportURL, setDefaultReportURL] = useState(false);

    // Data from API (Data will Change)
    const [warehouseList, setWarehouseList] = useState();
    const [companyTaxTypeList, setCompanyTaxTypeList] = useState();
    const [companyFiscalPeriod, setCompanyFiscalPeriod] = useState({
        FP_ID: 0,
        FiscalYear: 0,
        FiscalPeriod: 0,
        isPostingPeriodClosed: false
    });
    const [cityList, setCityList] = useState();

    // Ref
    const formikRef = useRef(null);
    const itemStateRef = useRef(null);
    const preferedLayoutRef = useRef([]);
    const prevSubmitMethod = useRef(null);

    // instanceKey
    const [instanceKey, setInstanceKey] = useState();
    const prevInstanceKey = usePrevious(instanceKey);

    // OverlayBlock Popup
    const { showOverlayBlock, hideOverlayBlock } = useOverlayBlockContext();

    // Flash message
    const showFlashMsg = useFlashMsgContext();

    // Error Popup
    const showErrorPopup = useErrorPopupContext();

    // Confirmation Popup
    const showConfirmationPopup = useConfirmationPopupContext();

    // User Access
    const { setUserAccess } = useUserAccessContext();

    // Custom Hooks
    const history = useHistory();
    const location = useLocation();
    const isMounted = useIsMounted(); // Ref
    const prevDocStatus = usePrevious(docStatus);

    // Event Listener Hook
    useEventListener("message", receiveMessage);

    // #region State Setters (with Deep Compare)
    const safeSetWarehouseList = useCallback((newWarehouse) => {
        if (!isEqual(warehouseList, newWarehouse)) {
            setWarehouseList(newWarehouse);
            return true;
        }
        return false;
    }, [warehouseList])

    const safeSetCompanyTaxTypeList = useCallback((newCompanyTaxType) => {
        if (!isEqual(companyTaxTypeList, newCompanyTaxType)) {
            setCompanyTaxTypeList(newCompanyTaxType);
            return true;
        }
        return false;
    }, [companyTaxTypeList])

    const safeSetCompanyFiscalPeriod = useCallback((newCompanyFiscalPeriod) => {
        if (!isEqual(companyFiscalPeriod, newCompanyFiscalPeriod)) {
            setCompanyFiscalPeriod(newCompanyFiscalPeriod);
            return true;
        }
        return false;
    }, [companyFiscalPeriod])
    // #region State Handlers (with Deep Compare)

    // #region Util
    const isResponseSuccess = useCallback((values) => {
        if (typeof values === "string" && values.includes("Error")) {
            const responseMsg = checkHTTPResponseCode(values);
            showErrorPopup?.(responseMsg.title, responseMsg.subtitle);
            return false;
        }
        else if (values?.Result === false) {
            showErrorPopup?.("ErrorEncountered", values.Message);
            return false;
        }
        return true;
    }, [showErrorPopup])

    const setDefaultReportFormat = useCallback((reportTabName, reportURL) => {
        setDefaultReportTabName(reportTabName);
        setDefaultReportURL(reportURL);
    }, [])

    const openPreviewReportTab = useCallback(() => {
        if (defaultReportURL) {
            window.parent.postMessage(getPostMessageObj(defaultReportURL, defaultReportTabName), "*");
        }
    }, [defaultReportURL, defaultReportTabName])

    const openDocumentActionTab = useCallback(() => {
        if (piDtlAccess?.DocumentActionURL) {
            window.parent.postMessage(getPostMessageObj(piDtlAccess?.DocumentActionURL, "Document Action"), "*");
        }
    }, [piDtlAccess?.DocumentActionURL])

    const openPrintBarCode = useCallback(() => {
        if (piDtlAccess?.PrintBarcodeURL) {
            const documentNo = formikRef.current?.values?.DocumentNo;
            const tabTitle = `Print BarCode(${documentNo.replace("-", "_")})`;
            window.parent.postMessage(getPostMessageObj(piDtlAccess.PrintBarcodeURL, tabTitle, "*"));
        }
    }, [piDtlAccess?.PrintBarcodeURL])

    function receiveMessage(event) {
        if (event?.data?.accessToken && !Token()) {
            // set cookies
            SetCookies(cookies, event.data);

            // fetch API
            fetchDataAPI();
        } else if (typeof event.data === "string" && event.data.includes(";")) {
            // receive DocumentMappingActors data using postMessage from alaya accounting
            var responseMsg = event.data.split(";")
            var docNo = responseMsg[0]
            var moduleName = "Document Mapping";
            moduleName = moduleName + " (" + docNo + ")";

            window.parent.postMessage(getPostMessageObj("/Modules/Common/m_DocumentMapping.aspx#" + responseMsg[0] + responseMsg[1], moduleName), "*");
        }
    }

    const initData = useCallback((values, pageInfo) => {
        if (!isMounted.current) return;

        unstable_batchedUpdates(function () {
            const [PiDtlHeaderMasterData, PiDtlData, PiDtlAccess, UserPreferedLayout, AllCompaniesIAGByUser] = values;
            const { instanceKey, id, iagid, iagdesp, isCopyDoc } = pageInfo;
            const companyFiscalPeriod = {
                FP_ID: PiDtlHeaderMasterData?.FiscalPeriod?.FP_ID,
                FiscalYear: PiDtlHeaderMasterData?.FiscalPeriod?.FP_Year,
                FiscalPeriod: PiDtlHeaderMasterData?.FiscalPeriod?.FP_Period,
                isPostingPeriodClosed: PiDtlHeaderMasterData?.isPostingPeriodClosed
            }

            const vendorRounding = PiDtlHeaderMasterData?.CompanyRounding?.VendorRounding_YN;
            const docRound = PiDtlHeaderMasterData?.DocRound_YN;
            const shouldDocRoundAmount = id === 0 ? vendorRounding || docRound : docRound;

            const mainItemList = itemStateRef?.current?.initData(PiDtlData?.PurchaseInvoiceDTL);
            const docTotalData = calculateDocTotalData(
                "PID",
                PiDtlHeaderMasterData?.PurchaseInvoiceHDR?.PIH_InclusiveTax || false,
                PiDtlHeaderMasterData?.CompanyRounding,
                mainItemList,
                PiDtlHeaderMasterData?.DocDiscount,
                shouldDocRoundAmount,
            );

            setInstanceKey(instanceKey);

            setIAG({ ID: iagid, Desp: iagdesp });
            setDocID(isCopyDoc ? 0 : id);

            setPiHdrMaster(PiDtlHeaderMasterData);
            setDocStatus(PiDtlHeaderMasterData.Status);
            setNextPossibleNo(PiDtlHeaderMasterData.NextPossibleNo);
            setPiDtlItem(PiDtlData);
            setPiDtlAccess(PiDtlAccess);

            setWarehouseList(PiDtlHeaderMasterData?.Warehouse || null);
            setCompanyFiscalPeriod(companyFiscalPeriod);
            setCompanyTaxTypeList(PiDtlData?.CompanyTaxType);
            setTransferStatus(PiDtlHeaderMasterData.TransferStatus);

            setUserAccess({
                allowExportGrid: PiDtlAccess.LstUserAccess?.[0]?.ExportGrid,
                allowDisplayColumnChooser: PiDtlAccess.LstUserAccess?.[0]?.GridColumnChooser,
                allowRestoreLayout: PiDtlAccess.LstUserAccess?.[0]?.RestoreGridLayout,
                allowSaveGridLayout: PiDtlAccess.LstUserAccess?.[0]?.SaveGridLayout,
                allowShowCost: PiDtlAccess.LstUserAccess?.[0]?.ShowCost
            })

            setDefaultReportTabName(PiDtlAccess.PrintReportTabName);
            setDefaultReportURL(PiDtlAccess.PrintReportURL);

            preferedLayoutRef.current = UserPreferedLayout;

            // Company Data
            const currentCompanyData = AllCompaniesIAGByUser.find(x => x.CO_ID === CompanyID());
            SetDefaultName(cookies, currentCompanyData.CO_Name);
            SetDecimalPoints(cookies, currentCompanyData.IAG_ItemRounding);

            // Header
            formikRef.current.setFieldValue('Vendor', PiDtlHeaderMasterData?.Vendor);
            formikRef.current.setFieldValue('DocumentNo', PiDtlHeaderMasterData?.DocumentNo);
            formikRef.current.setFieldValue('DocumentNoFormatID', PiDtlHeaderMasterData?.DocumentNoFormatID); // Document No Format ID
            formikRef.current.setFieldValue('HeaderDesp', PiDtlHeaderMasterData?.HeaderDesp);
            formikRef.current.setFieldValue('SupplierInvNo', PiDtlHeaderMasterData?.SupplierInvNo);
            formikRef.current.setFieldValue('PurchaseAgentID', PiDtlHeaderMasterData?.PurchaseAgentID); // Purchase Agent
            formikRef.current.setFieldValue('DocumentDate', PiDtlHeaderMasterData?.DocumentDate);
            formikRef.current.setFieldValue('CompanyPaymentTermID', PiDtlHeaderMasterData?.CompanyPaymentTermID); // Payment Term
            formikRef.current.setFieldValue('PostingDate', PiDtlHeaderMasterData?.PostingDate);
            formikRef.current.setFieldValue('ContactPerson', PiDtlHeaderMasterData?.ContactPerson);
            formikRef.current.setFieldValue('GSTDate', PiDtlHeaderMasterData?.GSTDate);
            formikRef.current.setFieldValue('WarehouseID', PiDtlHeaderMasterData?.WarehouseID); // Warehouse
            formikRef.current.setFieldValue('DueDate', PiDtlHeaderMasterData?.DueDate);
            formikRef.current.setFieldValue('DocStatus', PiDtlHeaderMasterData?.Status);

            // Header - Currency
            formikRef.current.setFieldValue('CurrencyDesp', PiDtlHeaderMasterData?.CurrencyDesp);
            formikRef.current.setFieldValue('CurrencyDate', PiDtlHeaderMasterData?.CurrencyDate);
            formikRef.current.setFieldValue('CurrencyRate', PiDtlHeaderMasterData?.CurrencyRate);
            formikRef.current.setFieldValue('CompanyCurrencyID', PiDtlHeaderMasterData?.CompanyCurrencyID);

            // Item Tab
            formikRef.current.setFieldValue('isTaxInclusive', PiDtlHeaderMasterData?.PurchaseInvoiceHDR?.PIH_InclusiveTax || false);
            formikRef.current.setFieldValue('isTaxItemize', PiDtlHeaderMasterData?.PurchaseInvoiceHDR?.PIH_TaxItemize || false);

            // Logistic Tab
            formikRef.current.setFieldValue('ShipMethodID', PiDtlHeaderMasterData?.ShipMethodID); // Shipping Method
            formikRef.current.setFieldValue('DeliveryDate', PiDtlHeaderMasterData?.DeliveryDate);
            formikRef.current.setFieldValue('Address1', PiDtlHeaderMasterData?.Address1);
            formikRef.current.setFieldValue('Address2', PiDtlHeaderMasterData?.Address2);
            formikRef.current.setFieldValue('Address3', PiDtlHeaderMasterData?.Address3);
            formikRef.current.setFieldValue('Address4', PiDtlHeaderMasterData?.Address4);
            formikRef.current.setFieldValue('City', PiDtlHeaderMasterData?.City);
            formikRef.current.setFieldValue('Postcode', PiDtlHeaderMasterData?.Postcode);
            formikRef.current.setFieldValue('State', PiDtlHeaderMasterData?.State);
            formikRef.current.setFieldValue('Country', PiDtlHeaderMasterData?.Country);

            // Purchase Organization Tab
            formikRef.current.setFieldValue('PurchaseOrgID', PiDtlHeaderMasterData?.PurchaseOrgID); // Purchase Organization
            formikRef.current.setFieldValue('PurchaseGroupID', PiDtlHeaderMasterData?.PurchaseGroupID || 0); // Purchase Division

            // Additional Notes Tab
            formikRef.current.setFieldValue('Note', PiDtlHeaderMasterData?.Note);

            // Doc Total Form (from API)
            formikRef.current.setFieldValue('DocDiscount', PiDtlHeaderMasterData?.DocDiscount);
            formikRef.current.setFieldValue('DocRound_YN', shouldDocRoundAmount);

            // Doc Total Form (calculated)
            formikRef.current.setFieldValue("DocSubTotal", docTotalData.DocSubTotal);
            formikRef.current.setFieldValue("DocTaxableAmt", docTotalData.DocTaxableAmt);
            formikRef.current.setFieldValue("DocTax", docTotalData.DocTax);
            formikRef.current.setFieldValue("DocRoundAmt", docTotalData.DocRoundAmt);
            formikRef.current.setFieldValue("DocTotal", docTotalData.DocTotal);

            hideOverlayBlock();
        });
    }, [hideOverlayBlock, isMounted, setUserAccess])

    const fetchDataAPI = useCallback(() => {
        const decodedURI = decodeURIComponent(location.search);
        const searchQuery = new URLSearchParams(decodedURI);

        const instanceKey = location.state?.instanceKey;
        const id = parseInt(searchQuery.get("id"));
        const iagid = parseInt(searchQuery.get("iag"));
        const iagdesp = searchQuery.get("iag_desp");
        const isCopyDoc = searchQuery.get("isCopyDoc") === "true";

        const PIHDRMaster = Promise.resolve(
            GetPIDtlHeaderMasterData(Token(), UserID(), CompanyID(), ClientID(), id, isCopyDoc)
        );
        const PIDTL = Promise.resolve(
            GetPIDtlData(Token(), id, CompanyID(), ClientID(), isCopyDoc)
        );
        const PIDTLAccess = Promise.resolve(
            GetPIDtlAccess(Token(), id, UserID(), CompanyID(), ReportURL(), isCopyDoc)
        );
        const UserPreferedLayout = Promise.resolve(
            GetUserLayout(Token(), UserID(), DefaultSMIPIDetail.moduleURL, DefaultSMIPIDetail.controlID)
        );
        const AllCompaniesIAGByUser = Promise.resolve(
            GetAllCompaniesIAGByUser(Token(), ClientID(), UserID(), AdminYN())
        );

        Promise.all([PIHDRMaster, PIDTL, PIDTLAccess, UserPreferedLayout, AllCompaniesIAGByUser]).then((values) => {
            let errorIndex = values.findIndex((v) => typeof v === "string");
            if (errorIndex >= 0 && values[errorIndex].includes("Error")) {
                const responseMsg = checkHTTPResponseCode(values[errorIndex]);
                showErrorPopup?.(responseMsg.title, responseMsg.subtitle);
            } else {
                const pageInfo = { instanceKey, id, iagid, iagdesp, isCopyDoc };
                initData(values, pageInfo);
            }
        });
    }, [initData, location, showErrorPopup]);
    // #endregion Util

    // #region API Call
    const GetItemByCodeOrPLUOrBarCode = useCallback(async (itemsCodeString) => {
        const { WarehouseID, Vendor, PurchaseGroupID, PostingDate, isTaxInclusive, isTaxItemize } = formikRef.current?.values;
        const values = await GetNewPIDTLItemsByCodeOrPluOrBarCode(Token(), CompanyID(), ClientID(), itemsCodeString, iAG.ID, PurchaseGroupID, WarehouseID, Vendor?.VE_ID, PostingDate, isTaxInclusive, isTaxItemize, docID);

        if (!isResponseSuccess(values)) return null;

        return values.Obj.PurchaseInvoiceDTL;
    }, [isResponseSuccess, docID, iAG.ID])

    const GetAccountByAccountID = useCallback(async (accountsIDString) => {
        const { Vendor, PurchaseGroupID, PostingDate } = formikRef.current?.values;
        const values = await GetNewPIDTLAccountsByID(Token(), CompanyID(), ClientID(), accountsIDString, PurchaseGroupID, Vendor?.VE_ID, PostingDate, docID);

        if (typeof values === "string" && values.includes("Error")) {
            const responseMsg = checkHTTPResponseCode(values);
            showErrorPopup?.(responseMsg.title, responseMsg.subtitle);
            return null;
        }
        return values;
    }, [showErrorPopup, docID])

    const GetItemsByImportData = useCallback(async (importedData) => {
        const { WarehouseID, Vendor, PurchaseGroupID, PostingDate, isTaxInclusive, isTaxItemize } = formikRef.current?.values;
        const values = await GetNewPIDTLByImportData(Token(), CompanyID(), ClientID(), importedData, iAG.ID, PurchaseGroupID, WarehouseID, Vendor?.VE_ID, PostingDate, isTaxInclusive, isTaxItemize, docID);

        if (typeof values === "string" && values.includes("Error")) {
            const responseMsg = checkHTTPResponseCode(values);
            showErrorPopup?.(responseMsg.title, responseMsg.subtitle);
            return null;
        }
        return values;
    }, [showErrorPopup, docID, iAG.ID])

    const GetItemsByCopyFromDoc = useCallback(async (copyFromObj) => {
        const { PostingDate } = formikRef.current?.values;
        const values = await GetCopyToPIBySourceDocID(Token(), CompanyID(), ClientID(), copyFromObj.copyFromDocType, docID, PostingDate, copyFromObj);

        if (typeof values === "string" && values.includes("Error")) {
            const responseMsg = checkHTTPResponseCode(values);
            showErrorPopup?.(responseMsg.title, responseMsg.subtitle);
            return null;
        }
        else if (values?.Result === false) {
            showErrorPopup?.("ErrorEncountered", values.Message);
            return null;
        }
        return values;
    }, [docID, showErrorPopup])

    const GetCurrencyRate = useCallback(async (currencyDate) => {
        const { Vendor } = formikRef.current?.values;

        if (!Vendor?.VE_ID) return piHdrMaster?.CurrencyRate;

        const values = await GetPurchaseCurrencyRateByCCID_EffectiveDate(Token(), CompanyID(), ClientID(), Vendor?.VE_ID, currencyDate);

        if (!isResponseSuccess(values)) return null;

        return values.Obj.CurrencyRate;
    }, [isResponseSuccess, piHdrMaster?.CurrencyRate])

    const onVendorChange = useCallback(async (vendorID, documentDate, currencyDate, warehouseID) => {
        const values = await GetPurchaseInvoiceHDRMaster_OnVendorChanged(Token(), CompanyID(), ClientID(), vendorID, documentDate, currencyDate, warehouseID);

        if (!isResponseSuccess(values)) return null;

        return values.Obj;
    }, [isResponseSuccess])

    const onPurchaseGroupChange = useCallback(async (purchaseGroupID) => {
        const values = await GetPurchaseInvoicePIDtl_OnPurchaseGroupChange(Token(), CompanyID(), purchaseGroupID);

        if (!isResponseSuccess(values)) return null;

        return values.Obj.PurchaseWarehouseMapping;
    }, [isResponseSuccess])

    const GetCompanyFiscalPeriod = useCallback(async (postingDate) => {

        const values = await GetFiscalPeriodPByClientID_CompanyID_PostDate(Token(), CompanyID(), ClientID(), postingDate, UserID(), "~/Modules/Purchase/r_PurchaseInvoiceDetail.aspx");

        if (!isResponseSuccess(values)) return null;

        return values.Obj;
    }, [isResponseSuccess])

    const GetTaxTypeRatePurchase = useCallback(async (postingDate) => {
        const values = await GetCompanyTaxTypeRatePurchase(Token(), CompanyID(), ClientID(), postingDate);

        if (!isResponseSuccess(values)) return null;

        return values.Obj.CompanyTaxType;
    }, [isResponseSuccess])

    const GetItemCostByWarehouse = useCallback(async (warehouseID, itemObj) => {
        const { Vendor } = formikRef.current?.values;

        const values = await GetListItemCostByWareHouseBasedOnCostingMethod(Token(), CompanyID(), warehouseID, 1, "CP", Vendor?.VE_ID, itemObj);

        if (!isResponseSuccess(values)) return null;

        return values.Obj.ItemCost;
    }, [isResponseSuccess])

    const GetBinLocByWarehouseID = useCallback(async (warehouseID) => {
        const values = await GetItemBinLocationCodeDesp(Token(), CompanyID(), ClientID(), warehouseID);

        if (!isResponseSuccess(values)) return null;

        const binLocation = values.Obj.ItemBinLocationSelectedItem.map(({ BL_Code: Code, BL_Description: Description, BL_ID: ID }) => ({ Code, Description, ID }));

        return binLocation;
    }, [isResponseSuccess])

    const CheckDuplicatedSupplierInv = async (supplierInvNo) => {
        const { Vendor } = formikRef.current?.values;

        const values = await CheckDuplicateSupplierInv(Token(), supplierInvNo, Vendor?.VE_ID, docID);

        if (!isResponseSuccess(values)) return false;

        return values.Obj;
    }

    const SavePurchaseInvoice = async (method, formikData, itemTabData, apiData) => {
        const postPIResponseObj = await PostPurchaseInvoice(Token(), UserID(), ClientID(), CompanyID(), method, formikData, itemTabData, apiData, ItemType);
        const isSuccess = isResponseSuccess(postPIResponseObj);

        if (isSuccess) {
            showFlashMsg(formatMessage('SavedSuccessfully'), 'success', 'flash-message-success-container');
            return postPIResponseObj.Obj;
        }
        else {
            showFlashMsg(('Saved Unsuccessfully'), 'error', 'flash-message-error-container');
            return false;
        }
    }

    const GetWarehouseList = useCallback(async () => {
        const values = await GetWarehouseListByCompanyID(Token(), CompanyID(), ClientID());

        if (!isResponseSuccess(values)) return null;

        return values.Obj.ItemWarehouseSelectedItem;
    }, [isResponseSuccess])

    const GetCityList = useCallback(async () => {
        const values = await GetAdvanceSearchCity(Token());

        if (!isResponseSuccess(values)) return null;

        return values.Query_CityAdvanceSearch;
    }, [isResponseSuccess])
    // #endregion API Call

    // #region Submit
    function isPostingDateValid(formikData) {
        let isAllow = true;
        let documentDate = moment(formikData.DocumentDate).startOf('day');
        let postingDate = moment(formikData.PostingDate).startOf('day');
        let isAfter = moment(documentDate).isAfter(postingDate, "day");

        if (isAfter) {
            showErrorPopup?.("ErrorEncountered", "PostingDatecannotbeearlythenDocumentDate");
            isAllow = false;
        }
        else if (companyFiscalPeriod.FiscalPeriod === 0 || companyFiscalPeriod.FiscalYear === 0) {
            showErrorPopup?.("ErrorEncountered", "InvalidPostingDateFiscalPeriodNotFound");
            isAllow = false;
        }

        return isAllow;
    }

    function isPostingDateClosed() {
        let isAllow = true;

        if (companyFiscalPeriod.isPostingPeriodClosed) {
            showErrorPopup?.("ErrorEncountered", "PostingDatehaveclosedbyPostingPeriodsaveaborted");
            isAllow = false;
        }

        return isAllow;
    }

    function isItemTabDataEmpty() {
        let isAllow = true;

        if (!itemStateRef?.current?.hasItem()) {
            showErrorPopup?.("ErrorEncountered", "PleaseenterItembeforepost");
            isAllow = false;
        }

        return isAllow;
    }

    function isDocTransferred() {
        let isAllow = true;

        if (transferStatus === "FULL" || transferStatus === "PARTIAL") {
            showErrorPopup?.("ErrorEncountered", "CannotPostRecordHasBeenTransferred");
            isAllow = false;
        }

        return isAllow;
    }

    async function isAcceptDuplicatedSupplierInv(supplierInvNo) {
        const isDuplicate = await CheckDuplicatedSupplierInv(supplierInvNo);

        if (isDuplicate) {
            const isAllow = await showConfirmationPopup('SupplierInvoiceisduplicateddoyoustillwanttocontinue');
            return isAllow; // true or false
        } else {
            return true; // true, not duplicate
        }
    }

    async function isSufficientBalanceBaseQty() {
        let isAllow = true;

        const mainItemList = await itemStateRef?.current?.getMainItemData();

        mainItemList.forEach(mainItem => {
            if (mainItem.CopyFrom) {
                const baseQty = mainItem.PID_Qty * mainItem.PID_Rate;
                const balanceBaseQty = mainItem.CopyFrom.BalanceBaseQty + mainItem.CopyFrom.PIDBaseQty;

                if (baseQty > balanceBaseQty) {
                    itemStateRef?.current?.addInsufficientBalRow(mainItem);
                    isAllow = false;
                } else {
                    itemStateRef?.current?.removeInsufficientBalRow(mainItem);
                }
            }
        });

        if (!isAllow) {
            showErrorPopup?.("ErrorEncountered", "NotEnoughTransferQty");
        }

        return isAllow;
    }

    const onSubmit = async (values, actions, params) => {
        showOverlayBlock(); // hide overlay at initData or unsuccessful post

        await itemStateRef?.current?.dataGridRefresh();

        const itemTabData = await itemStateRef?.current?.getAllItemData(true);
        const method = params.method;
        const formikData = values;
        let isAllowSubmit = true;

        prevSubmitMethod.current = params.method;

        // Check for invalid rows or cells
        if (checkInvalidRow(itemTabData)) {
            showErrorPopup?.("ErrorEncountered", "ContainInvalidRow");
            hideOverlayBlock();
            actions.setSubmitting(false);
            return;
        } else if (await checkInvalidCell(itemTabData)) {
            showErrorPopup?.("ErrorEncountered", "ContainInvalidCell");
            hideOverlayBlock();
            actions.setSubmitting(false);
            return;
        }

        // Check Posting Date Valid
        isAllowSubmit = isPostingDateValid(formikData);
        if (!isAllowSubmit) {
            hideOverlayBlock();
            actions.setSubmitting(false);
            return;
        }

        // Check Posting Date Closed
        isAllowSubmit = isPostingDateClosed();
        if (!isAllowSubmit) {
            hideOverlayBlock();
            actions.setSubmitting(false);
            return;
        }

        // Check Item Tab Has Entries
        if (method !== "SaveDraft") {
            isAllowSubmit = isItemTabDataEmpty();
            if (!isAllowSubmit) {
                hideOverlayBlock();
                actions.setSubmitting(false);
                return;
            }
        }

        // Check Doc Transferred
        isAllowSubmit = isDocTransferred();
        if (!isAllowSubmit) {
            hideOverlayBlock();
            actions.setSubmitting(false);
            return;
        }

        // Check Duplicated Supplier Inv (wait until confirmation accept/reject)
        isAllowSubmit = await isAcceptDuplicatedSupplierInv(formikData.SupplierInvNo);
        if (!isAllowSubmit) {
            hideOverlayBlock();
            actions.setSubmitting(false);
            return;
        }

        // Check CopyFrom, baseQty <= BalanceBaseQty
        isAllowSubmit = await isSufficientBalanceBaseQty();
        if (!isAllowSubmit) {
            hideOverlayBlock();
            actions.setSubmitting(false);
            return;
        }

        // Proceed to call Post API
        const apiData = { docStatus, docID, postingPeriod: companyFiscalPeriod.FP_ID }
        const isSuccess = await SavePurchaseInvoice(method, formikData, itemTabData, apiData);
        if (!isSuccess) {
            hideOverlayBlock();
            actions.setSubmitting(false);
            return;
        }

        // Post Success
        if (method === "PostNew") {
            const queryString = `?${encodeURIComponent(`id=${0}&iag=${iAG.ID}&iag_desp=${iAG.Desp}`)}`; // id = 0

            const decodedURI = decodeURIComponent(location.search);
            const searchQuery = new URLSearchParams(decodedURI);
            const id = parseInt(searchQuery.get("id"));

            if (id === 0) {
                history.replace({
                    pathname: '/purchase-invoice-details',
                    search: queryString,
                    state: { instanceKey: uniqueId() }
                });
            } else {
                history.push({
                    pathname: '/purchase-invoice-details',
                    search: queryString,
                    state: { instanceKey: uniqueId() }
                });
            }
        } else {
            const { PIHID } = isSuccess;
            const queryString = `?${encodeURIComponent(`id=${PIHID}&iag=${iAG.ID}&iag_desp=${iAG.Desp}`)}`; // id = PIHID from API

            // Refresh Page (by changing URL => trigger useEffect of fetchDataAPI => has location dependency)
            if (PIHID === piHdrMaster.PIHID) {
                // Repost (use replace to trigger URL change)
                history.replace({
                    pathname: '/purchase-invoice-details',
                    search: queryString,
                    state: { instanceKey: uniqueId() }
                });
            } else {
                // New Post (redirect URL)
                history.push({
                    pathname: '/purchase-invoice-details',
                    search: queryString,
                    state: { instanceKey: uniqueId() }
                });
            }
        }

        actions.setSubmitting(false);
    };
    // #endregion Submit

    // #region useEffect
    useEffect(() => {
        if (Token()) {
            fetchDataAPI();
        } else {
            // prompt Alaya 1.0 to re-postMessage for session data after receiveMessage eventListener is registered
            // to handle issue where initial postMessage is sent before eventListener is registered (iOS and macOS)
            window.parent.postMessage(getReactParameters(), "*");
        }
    }, [fetchDataAPI]);

    useEffect(() => {
        // after submit document (All Post methods & SaveDraft)
        if (instanceKey && prevInstanceKey !== instanceKey) {
            // if new/draft document is posted
            if (prevDocStatus === "DRAFT" && prevSubmitMethod.current !== "SaveDraft") {
                // Open print barcode tab
                openPrintBarCode();
            }

            if (prevSubmitMethod.current === "PostPreview") {
                // Open report tab
                openPreviewReportTab();
            } else if (prevSubmitMethod.current === "PostDocumentAction") {
                // Open document action tab
                openDocumentActionTab();
            }

            prevSubmitMethod.current = null;
        }
    }, [instanceKey, prevInstanceKey, prevDocStatus, openPrintBarCode, openPreviewReportTab, openDocumentActionTab])
    // #endregion useEffect

    return (
        <FormikDefault
            initialValues={{
                // Header
                Vendor: undefined,
                DocumentNo: undefined,
                DocumentNoFormatID: undefined,
                HeaderDesp: undefined,
                SupplierInvNo: undefined,
                PurchaseAgentID: undefined,
                DocumentDate: undefined,
                CompanyPaymentTermID: undefined,
                PostingDate: undefined,
                ContactPerson: undefined,
                GSTDate: undefined,
                WarehouseID: undefined,
                DueDate: undefined,
                DocStatus: undefined,

                // Header - Currency
                CurrencyDesp: undefined,
                CurrencyDate: undefined,
                CurrencyRate: undefined,
                CompanyCurrencyID: undefined,

                // Item Tab
                isTaxInclusive: undefined,
                isTaxItemize: undefined,
                DocRound_YN: undefined, // shouldDocRoundAmount

                // Logistic Tab
                ShipMethodID: undefined,
                DeliveryDate: undefined,
                Address1: undefined,
                Address2: undefined,
                Address3: undefined,
                Address4: undefined,
                City: undefined,
                Postcode: undefined,
                State: undefined,
                Country: undefined,

                // Purchase Organization Tab
                PurchaseOrgID: undefined,
                PurchaseGroupID: undefined,

                // Additional Notes Tab
                Note: undefined,

                // Doc Total Form
                DocSubTotal: undefined,
                DocTaxableAmt: undefined,
                DocDiscount: undefined,
                DocTax: undefined,
                DocRoundAmt: undefined,
                DocTotal: undefined,
            }}
            onSubmit={onSubmit}
            formikRef={formikRef}
        >
            <ItemState
                ref={itemStateRef}
                formikRef={formikRef}
                isAutoGeneratedPI={piDtlAccess?.isAutoGeneratedPI}
                companyRounding={piHdrMaster?.CompanyRounding}
                companyTaxTypeList={companyTaxTypeList}
                GetItemCostByWarehouse={GetItemCostByWarehouse}
                GetBinLocByWarehouseID={GetBinLocByWarehouseID}
                GetAccountByAccountID={GetAccountByAccountID}
                GetItemsByImportData={GetItemsByImportData}
                GetItemByCodeOrPLUOrBarCode={GetItemByCodeOrPLUOrBarCode}
            >
                <PurchaseInvoiceDetails
                    // Key
                    key={instanceKey}

                    // Formik
                    formikRef={formikRef}

                    // ItemState
                    itemStateRef={itemStateRef}

                    // Common
                    userProfile={piHdrMaster?.UserProfile}
                    iAGID={iAG.ID}
                    iAGDescription={iAG.Desp}
                    piDtlItem={piDtlItem}
                    setDefaultReportFormat={setDefaultReportFormat}

                    // Header
                    vendorList={piHdrMaster?.VendorList}
                    onVendorChange={onVendorChange}
                    purchaseAgent={piHdrMaster?.PurchaseAgent}
                    paymentTerm={piHdrMaster?.PaymentTerm}
                    warehouseList={warehouseList}
                    safeSetWarehouseList={safeSetWarehouseList}
                    GetCurrencyRate={GetCurrencyRate}
                    GetCompanyFiscalPeriod={GetCompanyFiscalPeriod}
                    GetTaxTypeRatePurchase={GetTaxTypeRatePurchase}
                    GetItemCostByWarehouse={GetItemCostByWarehouse}
                    GetBinLocByWarehouseID={GetBinLocByWarehouseID}

                    // Item Tab
                    GetItemsByCopyFromDoc={GetItemsByCopyFromDoc}
                    companyTaxTypeList={companyTaxTypeList}
                    safeSetCompanyTaxTypeList={safeSetCompanyTaxTypeList}
                    preferedLayoutRef={preferedLayoutRef}

                    // Logistic Tab
                    shippingMethod={piHdrMaster?.ShippingMethod}
                    shippingMethodID={piHdrMaster?.ShipMethodID}
                    GetCityList={GetCityList}
                    cityList={cityList}
                    setCityList={setCityList}

                    // Purchase Organization Tab
                    purchaseGroup={piHdrMaster?.PurchaseGroup}
                    purchaseOrganisation={piHdrMaster?.PurchaseOrganisation}
                    onPurchaseGroupChange={onPurchaseGroupChange}
                    GetWarehouseList={GetWarehouseList}

                    // Links
                    Link_PaymentTerm={piHdrMaster?.Link_PaymentTerm}
                    Link_URLDocumentNo={piHdrMaster?.Link_URLDocumentNo}
                    Link_URLPurAgent={piHdrMaster?.Link_URLPurAgent}
                    Link_URLWarehouse={piHdrMaster?.Link_URLWarehouse}
                    Link_ShippingMethod={piHdrMaster?.Link_ShippingMethod}
                    Link_URLPurGrp={piHdrMaster?.Link_URLPurGrp}
                    Link_URLPurOrg={piHdrMaster?.Link_URLPurOrg}

                    // Toolbar
                    piDtlAccess={piDtlAccess}
                    documentNo={piHdrMaster?.DocumentNo}
                    docStatus={docStatus}
                    nextPossibleNo={nextPossibleNo}
                    docID={docID}
                    createdBy={piHdrMaster?.Createdby}
                    createdDate={piHdrMaster?.CreatedDateTime}
                    fiscalYear={companyFiscalPeriod.FiscalYear}
                    fiscalPeriod={companyFiscalPeriod.FiscalPeriod}
                    safeSetCompanyFiscalPeriod={safeSetCompanyFiscalPeriod}
                    openPreviewReportTab={openPreviewReportTab}

                    // Document no format pop up using dx
                    documentNoFormat={piHdrMaster?.DocumentNoFormat}
                    showDocNoFormat={docStatus === 'DRAFT' ? true : false}
                />
            </ItemState>
        </FormikDefault>
    );
};

export default PurchaseInvoiceContainer;