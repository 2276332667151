import TreeList, {
    Scrolling, Paging, Pager, RowDragging, StateStoring, FilterRow, ColumnChooser, LoadPanel, SearchPanel,
} from 'devextreme-react/tree-list';
import dxTreeList from 'devextreme/ui/tree_list'
import * as React from 'react';
import TreeListContextMenu from './tree-list-context-menu';
import loadingBar from '../image/loadingBar.gif';
import './tree-list.scss'

import { DefaultStateStoringIgnore } from '../../utils/default-tree-list-settings';
import { PostUserLayout } from '../../api/user-preferred-layout';
import { ClientID, CompanyID, Token, UserID } from '../../utils/default-cookies';
import { sleep } from '../data-grid/data-grid-util';
import { mousetrapSecondary } from '../../App';

export default function TreeListDefault(props) {
    const {
        children,
        defaultSMI,
        treeListRef,
    } = props;

    const [state, setState] = React.useState({
        selectedRowKeys: [],
        showFilterRow: false,
        showGroupPanel: false,
        showPager: false,
    })

    const treeListContextMenuRef = React.useRef()
    const moduleItemID = React.useRef(null)

    const treeList = React.useCallback(() => {
        // `current.instance` points to the UI component instance
        return props.treeListRef.current.instance
    }, [props.treeListRef])

    const treeListNullCheck = () => {
        return props.treeListRef.current != null;
    }

    const focusedRowUp = React.useCallback(() => {
        const newFocusRowIndex = treeList().option('focusedRowIndex') - 1;
        const newRowElement = treeList().getRowElement(newFocusRowIndex);

        if (newFocusRowIndex > -1 && newRowElement) {
            treeList().option('focusedRowIndex', newFocusRowIndex);

            if (newFocusRowIndex === 0) {
                // scroll to top if first row
                newRowElement[0].scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
            } else {
                // scroll nearest
                newRowElement[0].scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
            }
        }
    }, [treeList])

    const focusedRowDown = React.useCallback(() => {
        const newFocusRowIndex = treeList().option('focusedRowIndex') + 1;
        const newRowElement = treeList().getRowElement(newFocusRowIndex);

        if (newFocusRowIndex < treeList().getVisibleRows().length && newRowElement) {
            treeList().option('focusedRowIndex', newFocusRowIndex);

            if (newFocusRowIndex === treeList().getVisibleRows().length - 1) {
                // scroll to bottom if last row
                newRowElement[0].scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
            } else {
                // scroll nearest
                newRowElement[0].scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
            }
        }
    }, [treeList])

    const addMenuItems = (event) => {
        treeListContextMenuRef.current.TreeListContextMenuItemsService(event, treeList(), props.exportFileName)
    }

    const onContentReady = (event) => {
        if (!treeListNullCheck()) return;

        if (event.component.option("navigateToFirst")) {
            // Function: navigate to first row first page when repaint is triggered
            // Invoke: treeList().option('navigateToFirst', true)
            // Note, may need to manually call "treelist.refresh(true)" after invoking if no repaint is triggered
            event.component.option("navigateToFirst", false);

            event.component.option("focusedRowKey", event.component.getKeyByRowIndex(0));
            event.component.option("focusedRowIndex", 0);
            event.component.pageIndex(0);
        }

        if (event.component.option("navigateToLast")) {
            // Function: navigate to last row last page when repaint is triggered
            // Invoke: treeList().option('navigateToLast', true)
            // Note, may need to manually call "treelist.refresh(true)" after invoking if no repaint is triggered
            const lastRowIndex = event.component.pageSize() - 1;
            const lastPage = event.component.pageCount() - 1;

            event.component.option("navigateToLast", false);

            event.component.option("focusedRowKey", event.component.getKeyByRowIndex(lastRowIndex));
            event.component.option('focusedRowIndex', lastRowIndex);
            event.component.pageIndex(lastPage);
        }

        if (event.component.option("navigateToRow") !== -1) {
            // Function: navigate to row by selected key when repaint is triggered
            // Invoke: treeList().option('navigateToRow', key)
            // Note, may need to manually call "treelist.refresh(true)" after invoking if no repaint is triggered
            const rowKey = event.component.option("navigateToRow");
            const rowIndex = event.component.getRowIndexByKey(rowKey);

            event.component.option("navigateToRow", -1);
            event.component.option("focusedRowKey", -1);
            event.component.option('focusedRowIndex', -1);

            event.component.option("focusedRowKey", rowKey);
            event.component.option('focusedRowIndex', rowIndex);
        }

        if (event.component.option("isEditing")) {
            // Update isEditing to false if no row is currently being edited
            const editingRows = event.component.getVisibleRows().filter(row => row.isEditing);

            if (editingRows.length === 0) {
                event.component.option("isEditing", false);
            }
        }

        if (!props.disablePaging) {
            // Show or hide pager
            setState(prev => ({
                ...prev,
                showPager: event.component.totalCount() > event.component.option("defaultPageSize")
            }))
        }

        // Rebuild ReactTooltip to update position for dynamic content
        // rebuildTooltip();

        props.onContentReady && props.onContentReady(event)
    }

    const onEditingStart = (event) => {
        event.component.option("isEditing", true);

        props.onEditingStart && props.onEditingStart(event);
    }

    const onReorder = async (event) => {
        if (props.customRowDragging.updateDataSource) {
            const dataSource = [...props.dataSource];

            const visibleRows = event.component.getVisibleRows();
            const toIndex = dataSource.indexOf(visibleRows[event.toIndex].data);
            const fromIndex = dataSource.indexOf(event.itemData);

            // remove row
            dataSource.splice(fromIndex, 1);
            props.customRowDragging.updateDataSource([...dataSource]);

            // setTimeout twice to prevent dataSource mutation (reduce re-renders)
            await sleep(0);
            await sleep(0);

            // add row back
            dataSource.splice(toIndex, 0, event.itemData);
            if (props.customRowDragging.sequenceKey) {
                const sequenceKey = props.customRowDragging.sequenceKey;
                for (var i = 0; i < dataSource.length; i++) {
                    dataSource[i][sequenceKey] = i + 1;
                }
            }
            props.customRowDragging.updateDataSource([...dataSource]);
        }

        props.onReorder && props.onReorder(event);
    }

    const onFocusedCellChanging = (event) => {
        if (event.event) {
            // Prevent highlight changing onto non-editable cell
            if (event.event.pointerType) {
                event.isHighlighted = false;
            } else if (event.columns[event.prevColumnIndex] && event.columns[event.prevColumnIndex].allowEditing) {
                if (!event.columns[event.newColumnIndex].allowEditing) {
                    event.newColumnIndex = event.prevColumnIndex;
                }
            } else {
                event.isHighlighted = false;
                event.newColumnIndex = event.prevColumnIndex;
            }
        }
    }

    const onOptionChanged = (event) => {
        if (event.fullName.includes("sortOrder") || event.fullName.includes("filterValue")) {
            treeList().option("navigateToFirst", true);
        }

        if (event.fullName === 'selectedRowKeys') {
            setState(prevState => ({
                ...prevState,
                selectedRowKeys: event.value
            }));
        }

        props.onOptionChanged && props.onOptionChanged(event);
    }

    const loadLayout = () => {
        //load treelist custom layout state
        if (props.preferedLayout && props.preferedLayout.length > 0) {
            moduleItemID.current = props.preferedLayout[0].UPL_ModuleItemID;

            if (treeListNullCheck() && props.preferedLayout[0].UPL_Layout) {
                let userlayout = JSON.parse(props.preferedLayout[0].UPL_Layout);

                delete userlayout['focusedRowKey']; // prevent defaultFocusedRowIndex overridden

                if (typeof userlayout !== 'object') return;
                return userlayout;
            }
        }
    }

    const saveLayout = (state) => {
        //save treelist custom layout state 
        if (!props.allowSaveGridLayout) return;

        if (state) {
            state.selectedRowKeys = null;
            state.focusedRowKey = null;
            state.pageIndex = 0

            /* ignore column state need for loop to reset - DefaultStateStoringIgnore setting will do ignore when loadLayout for columns properties Only */
            //for (let i = 0; i < state.columns.length; i++) {
            //    state.columns[i].sortIndex = null;
            //}
        }

        if (moduleItemID.current) {
            PostUserLayout(Token(), CompanyID(), ClientID(), UserID(), moduleItemID.current, defaultSMI.controlID, defaultSMI.moduleURL, "storageKey", state);
        }
    }

    const restoreLayout = () => {
        //data-grid-context-menu to restore treelist default layout 
        if (moduleItemID.current) {
            PostUserLayout(Token(), CompanyID(), ClientID(), UserID(), moduleItemID.current, defaultSMI.controlID, defaultSMI.moduleURL, "storageKey", " ");
        }

        if (treeListNullCheck()) {
            treeList().state("");
        }
    }

    const handleShowFilterRow = () => {
        // set child - tree-list-context-menu to show filter row
        setState(prevState => ({
            ...prevState,
            showFilterRow: !prevState.showFilterRow
        }))
        handleClearFilter()
    }

    const handleShowGroupPanel = () => {
        // set child - data-grid-context-menu to show group panel
        setState(prevState => ({
            ...prevState,
            showGroupPanel: !prevState.showGroupPanel
        }));
    }

    const handleClearFilter = () => {
        treeList().clearFilter();
    }

    React.useEffect(() => {
        dxTreeList.defaultOptions({
            options: {
                commonColumnSettings: {
                    allowEditing: false
                },
                navigateToFirst: false, // custom functional option to [trigger] navigation to first row (refer code at onContentReady)
                navigateToLast: false, // custom functional option to [trigger] navigation to last row (refer code at onContentReady)
                navigateToRow: -1, // custom functional option to [trigger] navigation to row by selected key (refer code at onContentReady)
                isEditing: false, // custom property option to keep [track] of editing state
            }
        })
    }, [])

    React.useEffect(() => {
        const mousetrap = props.mousetrap || mousetrapSecondary;

        mousetrap.bind("up", focusedRowUp);
        mousetrap.bind("down", focusedRowDown);

        return () => {
            mousetrap.unbind("up");
            mousetrap.unbind("down");
        }
    }, [focusedRowDown, focusedRowUp, props.mousetrap])

    return (
        <>
            <TreeList
                allowColumnReordering
                allowColumnResizing
                columnAutoWidth
                columnHidingEnabled={false}
                columnResizingMode="widget"
                rowAlternationEnabled
                showBorders
                showRowLines
                repaintChangesOnly
                selectedRowKeys={state.selectedRowKeys}
                ref={treeListRef}
                {...props}
                onContextMenuPreparing={addMenuItems}
                onContentReady={onContentReady}
                onEditingStart={onEditingStart}
                onFocusedCellChanging={onFocusedCellChanging}
                onOptionChanged={onOptionChanged}
            >
                <ColumnChooser enabled={false} mode="select" />
                <FilterRow visible={state.showFilterRow} />
                <LoadPanel indicatorSrc={loadingBar} />
                <SearchPanel visible={false} text={""} />
                <Paging
                    enabled={props.disablePaging ? false : true}
                    defaultPageSize={props.defaultPageSize}
                />
                <Pager
                    showPageSizeSelector={state.showPager}
                    allowedPageSizes={props.allowedPageSizes}
                    defaultPageIndex={0}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={state.showPager}
                />
                <Scrolling
                    mode="standard"
                    useNative={false}
                    scrollByContent={true}
                    scrollByThumb={true}
                    showScrollbar="always"
                />
                {/* <StateStoring
                    enabled={true}
                    type="custom"
                    customLoad={loadLayout}
                    customSave={saveLayout}
                    ignoreColumnOptionNames={DefaultStateStoringIgnore}
                    savingTimeout={1000} //default is 2000
                /> */}
                {/* {
                    props.customRowDragging &&
                    <RowDragging
                        allowReordering={true}
                        onReorder={onReorder}
                    />
                } */}
                {children}
            </TreeList>
            <TreeListContextMenu
                disableContextMenu={props.disableContextMenu}
                displayColumnChooser={props.allowDisplayColumnChooser}
                displayExportGrid={props.allowExportGrid}
                displayRestoreLayout={props.allowRestoreLayout}
                ref={treeListContextMenuRef}
                showRestoreMsg={props.showMsgHandler}
                getChildFilterRow={handleShowFilterRow}
                getChildGroupPanel={handleShowGroupPanel}
                restoreLayout={restoreLayout}
            />
        </>
    )
}