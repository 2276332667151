import { withNavigationWatcher } from './contexts/navigation';
import { HomePage, TasksPage, ProfilePage, PackageItemPage, PackageItemDetails, ItemsPage, CostMapping, PurchaseInvoice, AuditLog, AccessDenied, PurchaseInvoiceDetails } from './pages';

const routes = [
  {
    path: '/items',
    component: ItemsPage
  },
  {
    path: '/access-denied',
    component: AccessDenied
  },
  {
    path: '/package-item',
    component: PackageItemPage
  },
  {
    path: '/package-item-details',
    component: PackageItemDetails
  },
  {
    path: '/cost-mapping',
    component: CostMapping
  },
  {
    path: '/purchase-invoice',
    component: PurchaseInvoice
  },
  {
    path: '/purchase-invoice-details',
    component: PurchaseInvoiceDetails
  },
  {
    path: '/audit-log',
    component: AuditLog
  },
  {
    path: '/tasks',
    component: TasksPage
  },
  {
    path: '/profile',
    component: ProfilePage
  },
  {
    path: '/home',
    component: HomePage
  },
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
