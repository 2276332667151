import React, { PureComponent } from 'react'
import { formatMessage } from 'devextreme/localization';

export const infoBtnRenderer = ({ cellData, onClick, enabled }) => {
    return (
        <InfoBtn
            {...cellData}
            onClick={onClick}
            enabled={enabled}
        />
    )
}

export class InfoBtn extends PureComponent {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        event.data = this.props.data;
        this.props.onClick(event);
    };

    render() {
        return (
            <div className="infoBtn">
                {
                    this.props.enabled ?
                        (
                            <div data-tip={formatMessage("Information")} data-for="datagrid-tooltip-top" onClick={this.onClick} className="normalize-icon icon-information-icon color-primary" />
                        )
                        :
                        (
                            <div data-tip={formatMessage("Information")} data-for="datagrid-tooltip-top" className="normalize-icon icon-information-icon color-disable" />
                        )
                }
            </div>
        )
    }
}
