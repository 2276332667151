export const hasRequiredColumnHeaders = (importData) => {

    const validatedData = Object.keys(importData).map(function (index) {

        const altObj = Object.fromEntries(
            Object.entries(importData[index]).map(([key, value]) =>
                // Modify key to upper case
                [key.toUpperCase().trim(), value]
            )
        )
        return altObj;
    });

    const hasKey1 = "ITEM CODE" in validatedData[0];
    const hasKey2 = "BIN LOCATION" in validatedData[0];
    const hasKey3 = "UOM" in validatedData[0];
    const hasKey4 = "QTY" in validatedData[0];
    const hasKey5 = "UNIT COST" in validatedData[0];
    const hasKey6 = "REMARKS" in validatedData[0];

    let missingColumnHeaders = "";
    if (!hasKey1) missingColumnHeaders = missingColumnHeaders + "ITEM CODE, ";
    if (!hasKey2) missingColumnHeaders = missingColumnHeaders + "BIN LOCATION, ";
    if (!hasKey3) missingColumnHeaders = missingColumnHeaders + "UOM, ";
    if (!hasKey4) missingColumnHeaders = missingColumnHeaders + "QTY, ";
    if (!hasKey5) missingColumnHeaders = missingColumnHeaders + "UNIT COST, ";
    if (!hasKey6) missingColumnHeaders = missingColumnHeaders + "REMARKS";

    //.$ will match any character at the end of a string.
    missingColumnHeaders = missingColumnHeaders.replace(/, $/, "");

    var resultObj = {
        hasRequired: hasKey1 && hasKey2 && hasKey3 && hasKey4 && hasKey5 && hasKey6,
        columnHeaders: missingColumnHeaders,
        data: validatedData
    };

    return resultObj;
}

export const hasEnoughColumns = (importData) => {
    const importDataArr = importData.split(/\r?\n/);
    let result = false;
    importDataArr.forEach(element => {
        if (element.split(";").length === 6 || element.split(";").length === 7)
            result = true;
        else
            result = false;
    });
    return result;
}

export const convertArrayWithKey = (importedData) => {
    try {
        Object.keys(importedData).map(function (index) {
            const valuearray = importedData[index].split(";");
            const altObj = Object.fromEntries(
                Object.entries(valuearray).map(([key, value]) =>
                    [(
                        key === "0" ? "ITEM CODE" :
                            key === "1" ? "BIN LOCATION" :
                                key === "2" ? "UOM" :
                                    key === "3" ? "QTY" :
                                        key === "4" ? "UNIT COST" : "REMARKS"
                    ), value]
                )
            )
            importedData[index] = altObj;
            return altObj;
        });
    }
    catch { }
}