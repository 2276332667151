import React, { useCallback, useEffect, useState } from 'react'
import { Row, Col } from "antd";
import RadioInput from './radio-input';
import { cloneDeep } from "lodash";

const SectionSngSequence = (props) => {
    const { formikRef, name } = props;

    const title = "Serial Number Format";  // [Translation] Update Translation
    const subTitle = "Select the initial sequence to generate the serial number (The Serial Number is generated from the sequence from right to left.)";  // [Translation] Update Translation

    const [radioInputObj, setRadioInputObj] = useState([
        { id: 0, radioSelected: false, radioDisabled: true, inputValue: "" },
        { id: 1, radioSelected: false, radioDisabled: true, inputValue: "" },
        { id: 2, radioSelected: false, radioDisabled: true, inputValue: "" },
    ]);

    const radioInputOnSelect = useCallback((data) => {
        setRadioInputObj((radioInputObjPrev) => {
            const radioInputObjCopy = cloneDeep(radioInputObjPrev);
            const radioInput = radioInputObjCopy.find((element) => element.id === data.id);

            if (!radioInput.radioDisabled) {
                radioInputObjCopy.forEach(element => {
                    element.radioSelected = false;
                });

                radioInput.radioSelected = true;
            }

            return radioInputObjCopy;
        });
    }, [])

    const radioInputOnChange = useCallback((data) => {
        setRadioInputObj((radioInputObjPrev) => {
            const radioInputObjCopy = cloneDeep(radioInputObjPrev);
            const radioInput = radioInputObjCopy.find((element) => element.id === data.id);
            const onlyDigits = /^\d+$/.test(data.value);
            const hasSelectedRadio = radioInputObjCopy.findIndex((element) => element.radioSelected === true && element.id !== radioInput.id);

            if (onlyDigits) {
                radioInput.radioDisabled = false;
            } else {
                radioInput.radioDisabled = true;
            }

            if (hasSelectedRadio === -1 && onlyDigits) {
                radioInput.radioSelected = true;
            } else {
                radioInput.radioSelected = false;
            }

            radioInput.inputValue = data.value;

            return radioInputObjCopy;
        });
    }, [])

    function renderRadioInput(data) {
        return (
            <Col xs={24} md={12} lg={6} key={data.id}>
                <RadioInput
                    id={data.id}
                    radioSelected={data.radioSelected}
                    radioDisabled={data.radioDisabled}
                    radioOnSelect={radioInputOnSelect}
                    inputValue={data.inputValue}
                    inputOnChange={radioInputOnChange}
                    placeholder={"Serial Number"}  // [Translation] Update Translation
                />
            </Col>
        );
    }

    useEffect(() => {
        formikRef.current.setFieldValue(name, radioInputObj);
    }, [formikRef, radioInputObj, name])

    return (
        <Row gutter={30}>
            <Col xs={24}>
                <div className='sng-header-container'>
                    <div>
                        <span style={{ color: "red" }}>* </span>
                        <span>{title}</span>
                    </div>
                    <div className='sng-header-subtitle'>
                        {subTitle}
                    </div>
                </div>
            </Col>
            {radioInputObj.map(renderRadioInput)}
        </Row >
    )
}

export default SectionSngSequence