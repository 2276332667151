import { GET } from './request-options'

export const GetAdvanceSearchCity = async (token, searchKeyword, lstColName) => {
    let queryString = `UI-Culture=en-US`;
    if (searchKeyword) queryString += `&searchKeyword=${searchKeyword}&lstColName=${lstColName}`;

    return fetch(`${process.env.REACT_APP_API_ADVANCE_CITY}${queryString}`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

function handleErrors(response) {
    if (!response.ok) throw new Error(response.status);
    return response;
}
