import React from "react";
import { Row, Col } from "antd";
import { FastField, Field } from "formik";
import { NumberInput } from "../../fields";
import CheckBoxInput from "../../fields/CheckBoxInput";
import "./styles/total.scss";

export const PurchaseInvoiceDetailsTotalForm = (props) => {
    const {
        itemStateRef,
    } = props;

    const onRoundAmountChecked = () => {
        itemStateRef?.current?.updateDocShouldRoundAmount();
    };

    const onDiscountChanged = () => {
        itemStateRef?.current?.updateDocDiscount();
    }

    const inputSpan = { sm: 24, md: 12 };
    const precision = 2;

    return (
        <Row className="invoice-details-total" justify="end">
            <Col span={8}>
                <Row gutter={30}>
                    <Col {...inputSpan}>
                        <FastField
                            label="Sub Total"
                            name="DocSubTotal"
                            className="input-w"
                            component={NumberInput}
                            disabled
                            // Formatting
                            step={0}
                            precision={precision}
                            min={-999999999999}
                            max={999999999999}
                            thousandSeparator
                            showAllDecimalPoints
                        />
                    </Col>
                    <Col {...inputSpan}>
                        <FastField
                            label="Taxable"
                            name="DocTaxableAmt"
                            className="input-w"
                            component={NumberInput}
                            disabled
                            // Formatting
                            step={0}
                            precision={precision}
                            min={-999999999999}
                            max={999999999999}
                            thousandSeparator
                            showAllDecimalPoints
                        />
                    </Col>
                    <Col {...inputSpan}>
                        <FastField
                            label="Discount"
                            name="DocDiscount"
                            className="input-w"
                            component={NumberInput}
                            onChangeCallback={onDiscountChanged}
                            // Formatting
                            placeholder={(0).toFixed(precision)}
                            step={0}
                            precision={precision}
                            min={-999999999999}
                            max={999999999999}
                            thousandSeparator
                            showAllDecimalPoints
                        />
                    </Col>
                    <Col {...inputSpan}>
                        <FastField
                            label="Tax"
                            name="DocTax"
                            className="input-w"
                            component={NumberInput}
                            disabled
                            // Formatting
                            step={0}
                            precision={precision}
                            min={-999999999999}
                            max={999999999999}
                            thousandSeparator
                            showAllDecimalPoints
                        />
                    </Col>
                    <Col {...inputSpan}>
                        <Field
                            label={
                                <FastField
                                    label={"Round Amount"}
                                    name="DocRound_YN"
                                    component={CheckBoxInput}
                                    onChangeCallback={onRoundAmountChecked}
                                />
                            }
                            name="DocRoundAmt"
                            className="input-w"
                            component={NumberInput}
                            disabled
                            // Formatting
                            step={0}
                            precision={precision}
                            min={-999999999999}
                            max={999999999999}
                            thousandSeparator
                            showAllDecimalPoints
                        />
                    </Col>
                    <Col {...inputSpan}>
                        <FastField
                            label="Total"
                            name="DocTotal"
                            className="input-w"
                            component={NumberInput}
                            disabled
                            // Formatting
                            step={0}
                            precision={precision}
                            min={-999999999999}
                            max={999999999999}
                            thousandSeparator
                            showAllDecimalPoints
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
