import React, { useEffect, useRef } from 'react';
import Form from 'devextreme-react/form';
import { Item } from 'devextreme-react/select-box';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { formatMessage } from 'devextreme/localization';
import { mousetrapMain } from '../../App';

const ErrorPopUp = (props) => {
    const {
        visible,
        title,
        subTitle,
        parentCallback,
        shouldFormatMessage = true,
    } = props;

    const buttonRef = useRef();
    const formattedTitle = shouldFormatMessage ? formatMessage(title) : title;
    const formattedSubTitle = shouldFormatMessage && subTitle?.indexOf(' ') === -1 ? formatMessage(subTitle) : subTitle;

    const onClickClose = () => {
        parentCallback();
    }

    const onShowing = () => {
        mousetrapMain.pause();
    }

    const onShown = () => {
        mousetrapMain.pause();
        buttonRef.current.instance.focus();
    }

    const onHidden = () => {
        mousetrapMain.unpause();
    }

    useEffect(() => {
        return () => {
            mousetrapMain.unpause();
        }
    }, [])

    return (
        <Popup
            id="error-popup"
            className="error-popup-center"
            onShowing={onShowing}
            onShown={onShown}
            onHidden={onHidden}
            visible={visible}
            showTitle={false}
            closeOnOutsideClick={false}
            dragEnabled={false}
            width={"auto"}
            height={"auto"}>
            <Form colCount={4}>
                <Item colSpan={4}>
                    <p className="middle-content-title">{formattedTitle}</p>
                    <div className="session-line-1">{formattedSubTitle}</div>
                </Item>
            </Form>
            <div className="middle-btn-container">
                <Button
                    className="middle-btn"
                    text={formatMessage("OK")}
                    type="normal"
                    stylingMode="contained"
                    onClick={onClickClose}
                    ref={buttonRef}>
                </Button>
            </div>
        </Popup>
    );
}

export default ErrorPopUp;