import { cloneDeep } from 'lodash';
import { ItemType } from "../utils/constant-item-type";
import { GET, POST } from './request-options'

// #region add items into grid
export const GetPIDtlHeaderMasterData = async (token, userID, companyID, clientID, id, isCopyDoc) => {
    //1. next possible number
    //2. document no format
    //3. purchase organization
    //4. purchase division
    //5. purchase agent
    //6. payment term
    //7. warehouse  
    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_HDR_MASTER}companyID=${companyID}&clientID=${clientID}&userID=${userID}&pihID=${id}&isCopyDoc=${isCopyDoc}&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

export const GetPIDtlData = async (token, id, companyID, clientID, isCopyDoc) => {
    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_DTL}PIHID=${id}&companyID=${companyID}&clientID=${clientID}&isCopyDoc=${isCopyDoc}&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

export const GetPIDtlAccess = async (token, id, userID, companyID, reportServerURL, isCopyDoc) => {

    let docID = isCopyDoc ? 0 : id;

    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_BTN_VISIBLE}PIHID=${docID}&userID=${userID}&companyID=${companyID}&reportServerURL=${reportServerURL}&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

export const GetNewPIDTLItemByCodeOrPluOrBarCode = async (token, companyID, clientID, itemCode, IAGID, PGID, warehouseID, vendorID, postingDate, isTaxInclusive, isTaxItemize, docID) => {
    const queryString = `itemCode=${itemCode}&IAGID=${IAGID}&companyID=${companyID}&clientID=${clientID}&PGID=${PGID}&warehouseID=${warehouseID}&vendorID=${vendorID}&postingDate=${postingDate}&isTaxInclusive=${isTaxInclusive}&isTaxItemize=${isTaxItemize}&docID=${docID}&UI-Culture=en-US`;

    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_DTL_GET_ITEM_BY_CODE}${queryString}`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj.PurchaseInvoiceDTL;
        })
        .catch((error) => { console.log(error); return error.toString(); });

}

export const GetNewPIDTLItemsByCodeOrPluOrBarCode = async (token, companyID, clientID, itemCode, IAGID, PGID, warehouseID, vendorID, postingDate, isTaxInclusive, isTaxItemize, docID) => {

    const PostOptions = POST(token);

    PostOptions.body = JSON.stringify({
        "itemCode": itemCode,
        "IAGID": IAGID,
        "companyID": companyID,
        "clientID": clientID,
        "PGID": PGID,
        "warehouseID": warehouseID,
        "vendorID": vendorID,
        "postingDate": postingDate,
        "isTaxInclusive": isTaxInclusive,
        "isTaxItemize": isTaxItemize,
        "PIHID": docID
    });

    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_DTL_GET_ITEMS_BY_CODE}UI-Culture=en-US`, PostOptions)
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => { console.log(error); return error.toString(); });

}

export const GetNewPIDTLAccountsByID = (token, companyID, clientID, accountIDs, PGID, vendorID, postingDate, docID) => {

    const PostOptions = POST(token);

    if (accountIDs) {
        PostOptions.body = JSON.stringify({
            "accountID": accountIDs,
            "companyID": companyID,
            "clientID": clientID,
            "PGID": PGID,
            "vendorID": vendorID,
            "postingDate": postingDate,
            "PIHID": docID
        });
    }

    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_DTL_GET_ACCOUNTS_BY_ID}UI-Culture=en-US`, PostOptions)
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj.PurchaseInvoiceDTL;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

export const GetNewPIDTLByImportData = (token, companyID, clientID, data, IAGID, PGID, warehouseID, vendorID, postingDate, isTaxInclusive, isTaxItemize, docID) => {

    const PostOptions = POST(token);


    PostOptions.body = JSON.stringify({
        "Data": data,
        "IAGID": IAGID,
        "companyID": companyID,
        "clientID": clientID,
        "PGID": PGID,
        "warehouseID": warehouseID,
        "vendorID": vendorID,
        "postingDate": postingDate,
        "isTaxInclusive": isTaxInclusive,
        "isTaxItemize": isTaxItemize,
        "PIHID": docID
    });

    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_DTL_GET_ITEM_BY_IMPORT}UI-Culture=en-US`, PostOptions)
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj.PurchaseInvoiceDTL;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

// #endregion

// #region Item Type Popup
// Matrix Item
export const GetItemMatrixStockInByDocID = async (queryParam) => {
    const {
        token,
        clientID,
        iAGID,
        groupID,
        docID,
        docType,
        itemID,
        sourceDocID,
        sourceDocType,
    } = queryParam

    let queryString = `clientID=${clientID}&iAGID=${iAGID}&groupID=${groupID}&destinationDocID=${docID}&destinationDocType=${docType}&itemID=${itemID}`;
    if (sourceDocID) queryString += `&sourceDocID=${sourceDocID}&sourceDocType=${sourceDocType}`;

    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_GET_MATRIX_STOCKIN_BY_DOCID}${queryString}`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => {
            console.log(error);
            return error.toString();
        });
}

export const GetItemMatrixByFormatID = async (token, clientID, iAGID, itemID, itemmatrixID) => {
    const queryString = `clientID=${clientID}&iAGID=${iAGID}&itemID=${itemID}&itemmatrixID=${itemmatrixID}`;

    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_GET_MATRIX_BY_FORMATID}${queryString}`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => {
            console.log(error);
            return error.toString();
        });
}

export const GetItemMatrixTemplate = async (token, clientID, iAGID) => {
    const queryString = `clientID=${clientID}&iAGID=${iAGID}`;

    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_GET_MATRIX_TEMPLATE}${queryString}`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => {
            console.log(error);
            return error.toString();
        });
}

// Package Item
export const GetPackageChildItem = async (token, itemID) => {
    const queryString = `itemID=${itemID}`;

    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_GET_PACKAGE_CHILD_ITEM}${queryString}`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj.PackageChildItem;
        })
        .catch((error) => {
            console.log(error);
            return error.toString();
        });
}

// Batch Item
export const GetItemBatchStockIn = async (queryParam) => {
    const {
        token,
        clientID,
        companyID,
        iAGID,
        docID,
        docType,
        groupID,
        itemID,
        binLocationID,
        sourceDocID,
        sourceDocType,
    } = queryParam

    let queryString = `clientID=${clientID}&companyID=${companyID}&iAGID=${iAGID}&destinationDocID=${docID}&destinationDocType=${docType}&groupID=${groupID}&itemID=${itemID}&binLocationID=${binLocationID}`;
    if (sourceDocID) queryString += `&sourceDocID=${sourceDocID}&sourceDocType=${sourceDocType}`;

    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_GET_BATCH_STOCKIN}${queryString}`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => {
            console.log(error);
            return error.toString();
        });
}

// Stock Item - Warehouse Bin Location
export const GetItemWarehouseBinLocation = async (token, itemID, warehouseID, PIHID, groupID) => {
    const queryString = `itemID=${itemID}&warehouseID=${warehouseID}&PIHID=${PIHID}&groupID=${groupID}&includeRemainBinLocation=true&showDetail=false`;

    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_GET_ITEM_WAREHOUSE_BINLOC}${queryString}`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => {
            console.log(error);
            return error.toString();
        });
}

// Serial Item
export const GetItemSerialStockIn = async (queryParam) => {
    const {
        token,
        clientID,
        iAGID,
        itemID,
        groupID,
        docID,
        docType,
        sourceDocID,
        sourceDocType,
    } = queryParam

    let queryString = `clientID=${clientID}&iAGID=${iAGID}&itemID=${itemID}&groupID=${groupID}&destinationDocID=${docID}&destinationDocType=${docType}`;
    if (sourceDocID) queryString += `&sourceDocID=${sourceDocID}&sourceDocType=${sourceDocType}`;

    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_GET_SERIAL_STOCKIN}${queryString}`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => {
            console.log(error);
            return error.toString();
        });
}

export const CheckExistSerialNo = async (token, companyID, docID, selectedSerialNo, itemID) => {
    const queryString = `companyID=${companyID}&docID=${docID}&selectedSerialNo=${selectedSerialNo}&itemID=${itemID}`;

    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_CHECK_EXIST_SERIAL}${queryString}`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => {
            console.log(error);
            return error.toString();
        });
}
// #endregion

// #region Information Popup
export const GetPurchaseInvoiceInstantInfo = async ({ token, companyID, itemID }) => {
    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_GET_INSTANT_INFO_PURCHASE}itemID=${itemID}&companyID=${companyID}`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => data.Obj)
        .catch((error) => { console.log(error); return error.toString(); });
}
// #endregion Information Popup

// #region copy to Purchase Invoice DTL
export const GetCopyToPIBySourceDocID = (token, companyID, clientID, sourceDocType, PIHID, postingDate, copyFromObj) => {
    const queryString = `companyID=${companyID}&clientID=${clientID}&sourceDocType=${sourceDocType}&PIHID=${PIHID}&postingDate=${postingDate}&UI-Culture=en-US`;
    const PostOptions = POST(token);

    //convert array to string
    const selectedDocIDStr = copyFromObj.selectedFullTransferID ? copyFromObj.selectedFullTransferID.toString() : "";
    const selectedDetailIDStr = copyFromObj.selectedPartialTransferID ? copyFromObj.selectedPartialTransferID.toString() : "";

    PostOptions.body = JSON.stringify({
        "selectedSourceDocID": selectedDocIDStr,
        "selectedSourceDetailID": selectedDetailIDStr,
        "selectedPartialTransferRowData": copyFromObj.selectedPartialTransferRowData
    });
console.log(PostOptions.body);
    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_DTL_GET_ITEM_BY_COPY}${queryString}`, PostOptions)
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            if (data.Result)
                return data.Obj;
            else
                return data;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}
// #endregion

// #region on value change
export const GetPurchaseInvoiceHDRMaster_OnVendorChanged = (token, companyID, clientID, vendorID, documentDate, currencyDate, warehouseID) => {

    if (warehouseID === null)
        warehouseID = 0;

    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_DTL_VENDOR_CHANGED}companyID=${companyID}&clientID=${clientID}&vendorID=${vendorID}&documentDate=${documentDate}&currencyDate=${currencyDate}&warehouseID=${warehouseID}&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

export const GetPurchaseInvoicePIDtl_OnPurchaseGroupChange = (token, companyID, purchaseGroupID) => {
    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_DTL_PURCHASE_GROUP_CHANGED}companyID=${companyID}&purchaseGroupID=${purchaseGroupID}&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}
// #endregion

// #region Getters
export const GetWarehouseListByCompanyID = (token, companyID, clientID) => {
    return fetch(`${process.env.REACT_APP_API_WAREHOUSE_BY_COMPANY_ID}companyID=${companyID}&clientID=${clientID}&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}
// #endregion Getters

// #region save purchase invoice
export const PostPurchaseInvoice = async (token, userID, clientID, companyID, method, formikData, itemTabData, apiData) => {
    const queryString = `UI-Culture=en-US`;
    const PostOptions = POST(token);

    const mainItemfilterKeyArray = [
        "PID_ID",
        "PID_PIHID",
        "PID_Seq",
        "PID_PostToStockDate",
        "PID_Description",
        "PID_Remarks",
        "PID_Qty",
        "PID_Rate",
        "PID_FOCQty",
        "PID_UnitPrice",
        "PID_DiscountPercentage",
        "PID_DiscountAmt",
        "PID_TaxAmt",
        "PID_SubTotal",
        "PID_LocalSubTotal",
        "PID_LocalTax",
        "PID_LocalTaxAdjustment",
        "PID_DeliveryDate",
        "PID_TaxableAmt",
        "PID_TaxAdjustment",
        "PID_Total",
        "PID_DiscountFormula",
        "PID_PurchaseOrderDocumentNo",
        "PID_PurchaseOrderDocumentDate",
        "PID_GroupID",
        "PID_SubItem_YN",
        "PID_TransferQty",
        "PID_CurrencyRate",
        "PID_ItemUOMID",
        "PID_ItemID",
        "PID_ProjectID",
        "PID_CostCentreID",
        "PID_ControlAccount",
        "PID_TaxTypeID",
        "PID_WarehouseID",
        "PID_BinLocationID",
        "PID_ItemTypeID",
        "PID_SourceDocID",
        "PID_SourceDocTypeID",
        "PID_CompanyCurrencyID",
        "PID_NewUnitPrice",
        "PID_ItemFooterDiscount",
        "PID_TaxableExcFooterDisc",
        "itemDetail"
    ];
    const mainItemData = itemTabData?.map(o => Object.fromEntries(mainItemfilterKeyArray.map(key => [key, o[key]])));
    const isPost = method === "SaveDraft" ? false : true;
    const isNew = apiData.docStatus === "DRAFT" ? true : false;


    function removeStockSubItemDetails(itemDetailCopy) {
        delete itemDetailCopy.itemDetail;

        return itemDetailCopy;
    }

    function getMatrixSubItemDetails(itemDetailCopy) {
        const { itemDetail, ...item } = itemDetailCopy;
        const matrixItemDetail = itemDetail?.StockInDocMatrixItemList;
        if (matrixItemDetail) {
            item.PID_ItemMatrix = matrixItemDetail.reduce((array, element) => {
                if (element.Qty !== 0 && element.Qty) {
                    delete element.AvailableQty;
                    array.push(element);
                }
                return array;
            }, [])
        }
        else {
            item.PID_ItemMatrix = [];
        }
        return item;
    }

    function getSerialSubItemDetails(itemDetailCopy) {
        const serialItemDetail = itemDetailCopy?.itemDetail?.ItemSNList;
        const serialBatchNo = itemDetailCopy?.itemDetail?.BatchNo;

        if (serialItemDetail) {
            itemDetailCopy.PID_ItemSN = [...serialItemDetail];
            delete itemDetailCopy.itemDetail;
        }
        else {
            itemDetailCopy.PID_ItemSN = [];
            delete itemDetailCopy.itemDetail;
        }

        if (serialBatchNo) {
            itemDetailCopy.PID_ItemSN.forEach(item => {
                item.BatchNo = serialBatchNo;
            });
        }

        return itemDetailCopy;
    }

    function getBatchSubItemDetails(itemDetailCopy) {
        const batchItemDetail = itemDetailCopy?.itemDetail?.StockInDocBatchItemList;
        if (batchItemDetail) {
            itemDetailCopy.PID_ItemBatch = [...batchItemDetail];
            delete itemDetailCopy.itemDetail;
        }
        else {
            itemDetailCopy.PID_ItemBatch = [];
            delete itemDetailCopy.itemDetail;
        }
        return itemDetailCopy;
    }

    const itemData = mainItemData.map((item) => {
        const itemDetailCopy = cloneDeep(item);
        switch (item.PID_ItemTypeID) {
            case ItemType.STOCKITEM:
                return removeStockSubItemDetails(itemDetailCopy);
            case ItemType.SERVICE_ITEM:
            case ItemType.ACCOUNT_ITEM:
            case ItemType.WEIGHT_ITEM:
            case ItemType.VARIANCE_ITEM:
                return itemDetailCopy;
            case ItemType.SERIALIZED_ITEM:
            case ItemType.STOCK_SN_AND_BATCH_ITEM:
                return getSerialSubItemDetails(itemDetailCopy);
            case ItemType.MATRIX_ITEM:
                return getMatrixSubItemDetails(itemDetailCopy);
            case ItemType.PACKAGE_ITEM:
                return removeStockSubItemDetails(itemDetailCopy);
            case ItemType.BATCH_ITEM:
                return getBatchSubItemDetails(itemDetailCopy);
            /* tested in alaya 1.0 , no pop up ui for this item type, no sub item save in DB 
            case ItemType.WEIGHT_AND_BATCH_ITEM:
                break;
            */
            default:
                return itemDetailCopy;
        }

    });

    PostOptions.body = JSON.stringify({
        "ReportServerURL": "http://localhost:11445",
        "Header": {
            ...formikData,

            "DocDiscount": formikData.DocDiscount || 0,

            "CompanyID": companyID,
            "ClientID": clientID,
            "UserID": userID,
            "CreatedByID": userID,

            "CityID": formikData.City?.CI_ID,
            "VendorID": formikData.Vendor?.VE_ID,

            "PIHID": apiData.docID,
            "Status": isPost && isNew ? "POSTED" : apiData.docStatus,
            "PostingPeriod": apiData.postingPeriod,
        },
        "Details": itemData || []
    });

    return fetch(`${process.env.REACT_APP_API_PURCHASE_INVOICE_SAVE_PURCHASE_INVOICE}${queryString}`, PostOptions)
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            if (data.Result)
                return data;
            else
                return data;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}
// #endregion

const handleErrors = (response) => {
    if (!response.ok) throw new Error(response.status);
    return response;
}
