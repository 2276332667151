import { Row, Col, Tabs } from 'antd';
import { formatMessage } from 'devextreme/localization';
import { useState, useEffect } from 'react';
import InformationDetail from './features/information-detail/information-detail';
import PopupDefault from '../item-type-popup/popup-default';
import PurchaseHistoryTable from './features/purchase-history-table';
import { CompanyID, Token } from '../../../utils/default-cookies';
import ReactTooltip from 'react-tooltip';
import { useErrorPopupContext } from '../../modal-dialog/ErrorPopupProvider';
import { getErrorCode, setErrorPopUpMsg } from '../../../utils/error-popup-http-error-msg';
import { GetPurchaseInvoiceInstantInfo } from '../../../api/purchase-invoice-details';
import StockBalanceTable from './features/stock-balance-table';
import './style.scss'

const { TabPane } = Tabs;

const InformationPopup = (props) => {
    const {
        userProfile,
        visible,
        onHiding,
        onHidden,
    } = props;

    const {
        mainItem
    } = props.popupData;

    const [data, setData] = useState();
    const showErrorPopup = useErrorPopupContext();

    // #region fetch data
    useEffect(() => {
        async function fetchData() {
            const result = await GetPurchaseInvoiceInstantInfo({ token: Token(), companyID: CompanyID(), itemID: mainItem.PID_ItemID })

            if (typeof result === "string" && result.includes("Error")) {
                const errorCode = getErrorCode(result);
                const errorMsg = setErrorPopUpMsg(errorCode);

                showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            } else {
                setData(result);
            }
        }

        fetchData();
    }, [showErrorPopup, mainItem])
    // #endregion

    return (
        <PopupDefault
            className="information-popup"
            title={formatMessage("Information")}
            visible={visible}
            onHiding={onHiding}
            onHidden={onHidden}
            maxHeight="800px"
        >
            <Row gutter={[0, 16]}>
                <Col className="gutter-row" span={24}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={24} sm={12} md={8}>
                            <InformationDetail
                                label={formatMessage('ItemCode')}
                                value={data?.ItemDetail?.ItemCode || '-'}
                                dataFor="informationDetail-tooltip"
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={8}>
                            <InformationDetail
                                label={formatMessage('Description')}
                                value={data?.ItemDetail?.ItemDesc || '-'}
                                dataFor="informationDetail-tooltip"
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={8}>
                            <InformationDetail
                                label={formatMessage('ItemType')}
                                value={data?.ItemDetail?.ItemType || '-'}
                                dataFor="informationDetail-tooltip"
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={8}>
                            <InformationDetail
                                label={formatMessage('ItemGroup')}
                                value={data?.ItemDetail?.ItemGroup || '-'}
                                dataFor="informationDetail-tooltip"
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={8}>
                            <InformationDetail
                                label={formatMessage('ItemCategory')}
                                value={data?.ItemDetail?.ItemCtgy || '-'}
                                dataFor="informationDetail-tooltip"
                            />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={8}>
                            <InformationDetail
                                label={formatMessage('BaseUOM')}
                                value={data?.ItemDetail?.UOM || '-'}
                                dataFor="informationDetail-tooltip"
                            />
                        </Col>
                    </Row>
                    <ReactTooltip
                        id="informationDetail-tooltip"
                        arrowColor="#474751"
                        place="bottom"
                        effect="solid"
                        className='information-detail-tooltip'
                    />
                </Col>
                <Col className="gutter-row" span={24}>
                    <Tabs
                        defaultActiveKey="1"
                        type="card"
                    >
                        <TabPane
                            tab={formatMessage('StockBalance')}
                            key="1"
                        >
                            <StockBalanceTable
                                dataSource={data?.ListStockBalance}
                            />
                        </TabPane>
                        <TabPane
                            tab={formatMessage('PurchaseHistory')}
                            key="2"
                        >
                            <PurchaseHistoryTable
                                dataSource={data?.ListPurchaseHistory}
                                userProfile={userProfile}
                            />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </PopupDefault>
    )
}

export default InformationPopup
