import React from 'react';
import TextBox from 'devextreme-react/text-box';
import './text-box.scss';
import { formatMessage } from 'devextreme/localization';

const TextBox1 = ({ onTbvalueChange, value, displayValue, disabled, caption, stylingMode, placeholder, displayStar, isPopup, showRequired }) => {

    function onValueChanged(event) {
        onTbvalueChange(event.value);
    }

    return (
        <div>
            <div className={!!isPopup ? "dx-fieldset-popup-text" : "dx-fieldset-text"}>
                <div className="dx-field">
                    <div className="dx-field-label">
                        {displayStar ? (<span style={{ color: 'red' }}>* </span>) : ('')}
                        <span>{caption}</span>
                        {showRequired && displayStar ? <span className="required-field">{formatMessage('Required')}</span> : null}
                    </div>
                </div>
            </div>
            <div className={!!isPopup ? "dx-fieldset-popup" : "dx-fieldset"}>
                <div className="dx-field">
                    <div className="dx-field-value" >
                        <TextBox
                            className="text-box-1"
                            value={value}
                            readOnly={disabled}
                            placeholder={placeholder}
                            onValueChanged={onValueChanged}
                            valueChangeEvent="keyup"
                            stylingMode={stylingMode}>
                        </TextBox>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TextBox1;