import { useEffect } from "react";
import { isEqual } from "lodash";
import usePrevious from "./usePrevious";

const useFormikValidateOnError = (field, form, validateOnError) => {
    const prevValue = usePrevious(field.value);

    useEffect(() => {
        if (!validateOnError) return;
        if (isEqual(prevValue, field.value)) return;

        const error = form.errors[field.name];
        if (error) form.validateField(field.name);
    }, [prevValue, field, form, validateOnError])

    return form.errors[field.name];
}

export default useFormikValidateOnError