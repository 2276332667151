import React from "react";
import { Input } from "antd";
import { FormItem } from "../forms";

const { TextArea } = Input;

export const TextAreaInput = (props) => {
    const {
        minRows = 8,
        maxRows = 8,
        placeholder = "",
        field,
        maxLength = 500,
    } = props;

    return (
        <FormItem field={field} {...props}>
            <TextArea
                {...field}
                placeholder={placeholder}
                autoSize={{ minRows, maxRows }}
                defaultValue={props.form.values[props.field.name]}
                maxLength={maxLength}
            />
        </FormItem>
    );
};