import React from "react";
import { Row, Col } from "antd";
import _ from "lodash";

import "../styles/DropdownInput.scss";

const SelectOptGroup = (props) => {
    const { headerColumn, dataColumn } = props;

    return (
        <Row>
            {_.map(headerColumn, (h, i) => {
                return dataColumn.length < 4 ? (
                    i !== dataColumn.length - 1 ? (
                        <Col
                            key={i}
                            span={i === 0 ? 7 : i === 1 ? 7 : 5}
                            offset={i === 0 ? 0 : 1}
                        >
                            {h}
                        </Col>
                    ) : (
                        <Col
                            key={i}
                            span={
                                dataColumn.length === 3
                                    ? 8
                                    : dataColumn.length === 4
                                        ? 2
                                        : false
                            }
                            offset={i === 0 ? 0 : 1}
                        >
                            {h}
                        </Col>
                    )
                ) : (
                    <Col key={i} span={3} offset={i === 0 ? 0 : 1}>
                        {h}
                    </Col>
                );
            })}
        </Row>
    );
};

export default SelectOptGroup;