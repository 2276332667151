import React, { useCallback, useRef, useState } from "react";
import { unstable_batchedUpdates } from 'react-dom';
import { Row, Col } from "antd";
import { SearchInput, DropdownInput, TextInput, DateSinglePickerInput, NumberInput, SearchInputDocument, } from "../../fields";
import VendorPopUp from "../../pop-up/vendor-popup";
import DocumentFormatPopUp from "../../../components/pop-up/document-format-popup";
import { FastField } from "formik";
import { validator } from "../../../../src/utils/form-validator";
import { GetDueDate } from '../../../utils/due-date-func'
import { formatMessage } from "devextreme/localization";
import { GetVendorTabURL } from '../../../api/vendor';
import { CompanyID, Token } from '../../../utils/default-cookies';
import { DefaultSMIPIDetailVendorAdvSearch } from '../../../utils/default-smi';
import { useConfirmationPopupContext } from "../../modal-dialog/ConfirmationPopupProvider";
import { sleep } from "../../data-grid/data-grid-util";
import { isEqual } from "lodash";
import FastFieldWithProps from "../../fields/FastFieldWithProps";
import { useUserAccessContext } from "../../../contexts/UserAccess";

export const PurchaseInvoiceDetailsForm = (props) => {
    const {
        formikRef,
        itemStateRef,
        warehouseList,
        onVendorChange,
        paymentTerm,
        GetCompanyFiscalPeriod,
        safeSetCompanyFiscalPeriod,
        GetTaxTypeRatePurchase,
        safeSetCompanyTaxTypeList,
        GetCurrencyRate,
        documentNoFormat,
        vendorList,
        piDtlAccess,
        showDocNoFormat,
        purchaseAgent,
        userProfile,
        Link_URLDocumentNo,
        Link_URLPurAgent,
        Link_PaymentTerm,
        Link_URLWarehouse,
    } = props;

    const [vendorModalVisible, setVendorModalVisible] = useState(false);
    const [documentFormatVisible, setDocumentFormatVisible] = useState(false);

    // Confirmation Popup
    const showConfirmationPopup = useConfirmationPopupContext();

    // UserAccess
    const { userAccess } = useUserAccessContext();

    const portalRef = useRef(null);

    //#region vendor input field
    const setFieldValueOnVendorChange = useCallback(async (vendorObj) => {
        const prevVendor = formikRef.current.values.Vendor;

        if (!vendorObj) {
            formikRef.current.setFieldValue("Vendor", null);
            return;
        } else if (isEqual(prevVendor, vendorObj)) {
            return;
        }

        // validate Dates and return if one of it is invalid
        const isPostingDateValid = await formikRef.current.validateField("PostingDate").then((result) => result !== "Required");
        const isCurrencyDateValid = await formikRef.current.validateField("CurrencyDate").then((result) => result !== "Required");
        const isDocumentDateValid = await formikRef.current.validateField("DocumentDate").then((result) => result !== "Required");

        if (isPostingDateValid === false || isCurrencyDateValid === false || isDocumentDateValid === false) {
            formikRef.current.setFieldValue("Vendor", null); // set Vendor to empty to force re-render to prevVendor if Dates are invalid
            await sleep(0);
            formikRef.current.setFieldValue("Vendor", prevVendor);
            return;
        }

        // fetch newValues from API
        const newValues = await onVendorChange(vendorObj.VE_ID, formikRef.current.values.DocumentDate, formikRef.current.values.CurrencyDate, formikRef.current.values.WarehouseID);

        // check warehouse exist to handle case where default warehouse is not in the active warehouse list of purchase group
        const warehouseExist = warehouseList.find((warehouse) => warehouse.ID === newValues.WarehouseID);
        const newWarehouseID = warehouseExist ? newValues.WarehouseID : null;

        unstable_batchedUpdates(async function () {
            // update Vendor
            formikRef.current.setFieldValue("Vendor", vendorObj);

            // update warehouse
            formikRef.current.setFieldValue("WarehouseID", newWarehouseID);

            // update newValues
            formikRef.current.setFieldValue("IsAllowGSTforVendor", newValues.IsAllowGSTforVendor);
            formikRef.current.setFieldValue("CompanyPaymentTermID", newValues.CompanyPaymentTermID);
            formikRef.current.setFieldValue("ContactPerson", newValues.ContactPerson);
            formikRef.current.setFieldValue("CompanyCurrencyID", newValues.CompanyCurrencyID);
            formikRef.current.setFieldValue("CurrencyDesp", newValues.CurrencyDesp);
            formikRef.current.setFieldValue("CurrencyRate", newValues.CurrencyRate);
            formikRef.current.setFieldValue("DueDate", newValues.DueDate);
            formikRef.current.setFieldValue("isTaxInclusive", newValues.isTaxInclusive);
            formikRef.current.setFieldValue("isTaxItemize", newValues.isTaxItemize);

            // update mainItemList
            itemStateRef?.current?.handleCurrencyRateChange(newValues.CurrencyRate);

            if (itemStateRef?.current?.hasItem() && newWarehouseID && formikRef.current.values.WarehouseID !== newWarehouseID) {
                // show warehouse change confirmation pop up if (1) has item (2) has selected warehouse (3) selected warehouse ID is not the same
                const accept = await showConfirmationPopup('ConfirmtochangeallitemtoselectedWarehouse');
                if (accept) itemStateRef?.current?.handleWarehouseChange(newWarehouseID);
            }
        })
    }, [formikRef, itemStateRef, onVendorChange, showConfirmationPopup, warehouseList])

    //call back from Vendor Pop up
    const setSelectedVendorData = (childData) => {
        const vendorFieldValue = {
            VE_ID: childData.VE_ID,
            VE_AccountCode: childData.VE_AccountCode,
            VE_Name: childData.VE_Name,
        }

        setFieldValueOnVendorChange(vendorFieldValue);
    };

    //call back from Vendor Search Input
    const setSelectedVendorWithFilterValue = useCallback((data) => {
        let objData = data;

        if (data && data.filtervalue) {
            objData = {
                VE_ID: data.id,
                ...JSON.parse(data.filtervalue)
            };
        }

        setFieldValueOnVendorChange(objData);
    }, [setFieldValueOnVendorChange])

    const getTabLink = useCallback(async (inputKey) => {
        const tabUrl = await GetVendorTabURL(Token(), CompanyID(), inputKey === undefined ? 0 : inputKey);
        return tabUrl;
    }, [])
    //#endregion

    const setDocumentNoFormat = (id) => {
        // for DocumentFormatPopUp to update Formik value
        formikRef.current.setFieldValue("DocumentNoFormatID", id[0]);
    };

    //#region on Date Change
    const onCurrencyDateChange = useCallback(async (currencyDate) => {
        if (!currencyDate) return;

        const currencyRate = await GetCurrencyRate(currencyDate);

        // update formik
        formikRef.current.setFieldValue("CurrencyRate", currencyRate);

        // update mainItemList
        itemStateRef?.current?.handleCurrencyRateChange(currencyRate);
    }, [GetCurrencyRate, formikRef, itemStateRef])

    const onPaymentTermChange = useCallback(({ obj: paymentTerm }) => {
        if (!paymentTerm)
            formikRef.current.setFieldValue("DueDate", null);
        else {
            const dtDueDate = GetDueDate(paymentTerm, formikRef.current.values.DueDate, formikRef.current.values.DocumentDate);
            formikRef.current.setFieldValue("DueDate", dtDueDate);
        }
    }, [formikRef])

    const onPostingDateChange = useCallback(async (postingDate) => {
        formikRef.current.setFieldValue("CurrencyDate", postingDate);
        formikRef.current.setFieldValue("GSTDate", postingDate);
        onCurrencyDateChange(postingDate);

        if (postingDate) {
            const companyFiscalPeriod = await GetCompanyFiscalPeriod(postingDate);
            safeSetCompanyFiscalPeriod({
                FP_ID: companyFiscalPeriod?.FiscalPeriod?.FP_ID,
                FiscalYear: companyFiscalPeriod?.FiscalPeriod?.FP_Year,
                FiscalPeriod: companyFiscalPeriod?.FiscalPeriod?.FP_Period,
                isPostingPeriodClosed: companyFiscalPeriod?.isPostingPeriodClosed,
            });

            const purchaseTaxType = await GetTaxTypeRatePurchase(postingDate);
            const companyTaxTypeListChanged = safeSetCompanyTaxTypeList(purchaseTaxType);
            if (companyTaxTypeListChanged) {
                // if companyTaxTypeList changed
                itemStateRef?.current?.handleCompanyTaxTypeListChange(purchaseTaxType);
            }
        }
    }, [GetCompanyFiscalPeriod, GetTaxTypeRatePurchase, formikRef, itemStateRef, onCurrencyDateChange, safeSetCompanyFiscalPeriod, safeSetCompanyTaxTypeList])

    const onDocumentDateChange = useCallback((documentDate) => {
        const paymentTermID = formikRef.current.values.CompanyPaymentTermID;
        const paymentTermSelected = paymentTerm.find((paymentTerm) => paymentTerm.CPT_ID === paymentTermID);

        formikRef.current.setFieldValue("PostingDate", documentDate);
        onPostingDateChange(documentDate);
        onPaymentTermChange({ obj: paymentTermSelected });
    }, [formikRef, onPaymentTermChange, onPostingDateChange, paymentTerm])

    const onWarehouseChange = useCallback(async ({ id }) => {
        if (itemStateRef?.current?.hasItem() && id) {
            const accept = await showConfirmationPopup('ConfirmtochangeallitemtoselectedWarehouse');
            if (accept) itemStateRef?.current?.handleWarehouseChange(id);
        }
    }, [itemStateRef, showConfirmationPopup])

    const onGSTDateChange = useCallback(async (gstDate) => {
        console.log(gstDate);
    }, [])
    //#endregion

    const longInputSpan = { xs: 24, sm: 16 };
    const shortInputSpan = { xs: 12, sm: 8 };

    return (
        <Row gutter={35}>
            <div className="portalRef dx-dropdowneditor-overlay" ref={portalRef} />
            {vendorModalVisible &&
                <VendorPopUp
                    parentSelectedData={setSelectedVendorData}
                    hidePopup={() => setVendorModalVisible(false)}
                    visible={vendorModalVisible}
                    title="Add Vendor"
                    addNewButtonText={formatMessage("AddNew")}
                    defaultSMI={DefaultSMIPIDetailVendorAdvSearch}
                    contextMenuAccess={userAccess}
                />
            }
            {documentFormatVisible &&
                <DocumentFormatPopUp
                    setSelectedID={setDocumentNoFormat}
                    hidePopup={() => setDocumentFormatVisible(false)}
                    visible={documentFormatVisible}
                    title={formatMessage('DOCUMENTNO.FORMAT')}
                    dataSource={documentNoFormat}
                    selectedID={formikRef.current.values.DocumentNoFormatID}
                />
            }
            <Col {...longInputSpan}>
                <FastFieldWithProps
                    label="Vendor"
                    name="Vendor"
                    placeholder="Vendor"
                    setModalVisible={setVendorModalVisible}
                    getTabLink={getTabLink}
                    setSelectedData={setSelectedVendorWithFilterValue}
                    component={SearchInput}
                    arrayTitle={["Acount No.", "Name"]}
                    arrayColumn={["VE_AccountCode", "VE_Name"]}
                    showTextColumn="VE_Name"
                    filtervalue={["VE_AccountCode", "VE_Name"]}
                    keyColumn="VE_ID"
                    dataSource={vendorList}
                    validate={validator.notEmpty}
                    required
                    disabled={piDtlAccess?.isAutoGeneratedPI}
                />
            </Col>
            <Col xs={24} sm={8}>
                <FastField
                    label="Document No."
                    name="DocumentNo"
                    setModalVisible={setDocumentFormatVisible}
                    component={SearchInputDocument}
                    placeholder="Default"
                    showSuffix={true}
                    disabledButton={!showDocNoFormat}
                    link={Link_URLDocumentNo}
                    tabName={formatMessage('DOCUMENTNO.FORMAT')}
                    validate={validator.notEmpty}
                    required
                />
            </Col>
            <Col {...longInputSpan}>
                <FastField
                    label="Description"
                    name="HeaderDesp"
                    component={TextInput}
                    maxLength={100}
                />
            </Col>
            <Col {...shortInputSpan}>
                <FastField
                    label="Supplier Inv. No."
                    name="SupplierInvNo"
                    component={TextInput}
                    maxLength={20}
                    validate={validator.notEmpty}
                    required
                />
            </Col>
            <Col {...shortInputSpan}>
                <FastField
                    label="Currency"
                    name="CurrencyDesp"
                    component={TextInput}
                    validate={validator.notEmpty}
                    required
                    disabled
                />
            </Col>
            <Col {...shortInputSpan}>
                <FastField
                    label="Purchase Agent"
                    name="PurchaseAgentID"
                    dataSource={purchaseAgent}
                    headerColumn={["Code", "Name"]}
                    dataColumn={["ST_Code", "ST_Description"]}
                    displayColumn="ST_Description"
                    filtervalue={["ST_Code", "ST_Description"]}
                    getColumnValue="ST_ID"
                    placeholder="Select Purchase Agent"
                    tabLink={Link_URLPurAgent}
                    tabTitle={"Staff"}
                    component={DropdownInput}
                />
            </Col>
            <Col {...shortInputSpan}>
                <FastFieldWithProps
                    label="Document Date"
                    name="DocumentDate"
                    format={userProfile?.US_DateFormat}
                    component={DateSinglePickerInput}
                    onChangeCallback={onDocumentDateChange}
                    validate={validator.notEmpty}
                    required
                    portalRef={portalRef}
                />
            </Col>
            <Col {...shortInputSpan}>
                <FastFieldWithProps
                    label="Currency Date"
                    name="CurrencyDate"
                    format={userProfile?.US_DateFormat}
                    component={DateSinglePickerInput}
                    onChangeCallback={onCurrencyDateChange}
                    validate={validator.notEmpty}
                    required
                    portalRef={portalRef}
                    disabled={piDtlAccess?.isAutoGeneratedPI}
                />
            </Col>
            <Col {...shortInputSpan}>
                <FastFieldWithProps
                    label="Payment Term"
                    name="CompanyPaymentTermID"
                    dataSource={paymentTerm}
                    headerColumn={["Code", "Name"]}
                    dataColumn={["PT_Code", "PT_Description"]}
                    displayColumn="PT_Description"
                    filtervalue={["PT_Code", "PT_Description"]}
                    getColumnValue="CPT_ID"
                    placeholder="Select Payment Term"
                    tabLink={Link_PaymentTerm}
                    tabTitle={"Payment Term"}
                    component={DropdownInput}
                    onChangeCallback={onPaymentTermChange}
                />
            </Col>
            <Col {...shortInputSpan}>
                <FastFieldWithProps
                    label="Posting Date"
                    name="PostingDate"
                    format={userProfile?.US_DateFormat}
                    component={DateSinglePickerInput}
                    onChangeCallback={onPostingDateChange}
                    validate={validator.notEmpty}
                    required
                    portalRef={portalRef}
                />
            </Col>
            <Col {...shortInputSpan}>
                <FastField
                    label="Currency Rate"
                    name="CurrencyRate"
                    className="input-lg"
                    step="0"
                    size="large"
                    component={NumberInput}
                    validate={validator.notEmpty}
                    required
                    disabled
                />
            </Col>
            <Col {...shortInputSpan}>
                <FastField
                    label="Contact Person"
                    name="ContactPerson"
                    placeholder="Contact Person"
                    component={TextInput}
                    maxLength={100}
                />
            </Col>
            <Col {...shortInputSpan}>
                <FastFieldWithProps
                    label="GST Date"
                    name="GSTDate"
                    format={userProfile?.US_DateFormat}
                    component={DateSinglePickerInput}
                    onChangeCallback={onGSTDateChange}
                    portalRef={portalRef}
                />
            </Col>
            <Col {...longInputSpan}>
                <FastFieldWithProps
                    label="Warehouse"
                    name="WarehouseID"
                    dataSource={warehouseList}
                    headerColumn={["Code", "Name"]}
                    dataColumn={["Code", "Description"]}
                    displayColumn="Description"
                    filtervalue={["Code", "Description"]}
                    getColumnValue="ID"
                    placeholder="Select Warehouse"
                    tabLink={Link_URLWarehouse}
                    tabTitle={"Warehouse"}
                    component={DropdownInput}
                    onChangeCallback={onWarehouseChange}
                    validate={validator.notEmpty}
                    required
                    denyEmpty
                    disabled={piDtlAccess?.isAutoGeneratedPI}
                />
            </Col>
            <Col {...shortInputSpan}>
                <FastFieldWithProps
                    label="Due Date"
                    name="DueDate"  //due date value could be empty
                    format={userProfile?.US_DateFormat}
                    component={DateSinglePickerInput}
                    portalRef={portalRef}
                />
            </Col>
        </Row>
    );
};
