// to store the SMI_URL in table SystemModuleItem

//#region package item
export const DefaultSMIPackageItem = {
    moduleURL: "r_PackageItem.aspx",
    controlID: "r_gvPackageItem"
}

export const DefaultSMIPackageItemDetails = {
    moduleURL: "r_PackageItemDetail.aspx",
    controlID: "r_gvPackageItemDetails"
}

export const DefaultSMIPackageItemDetailItemAdvSearch = {
    moduleURL: "r_PackageItemDetail.aspx",
    controlID: "r_gvItemSearchControl"
}
//#endregion

//#region audit log
export const DefaultSMIAuditLog = {
    moduleURL: "r_AuditLog.aspx",
    controlID: "r_gvAuditLog"
}
//#endregion

//#region purchase invoice
export const DefaultSMIPI = {
    moduleURL: "r_PurchaseInvoice.aspx",
    controlID: "r_gvPurchaseInvoices"
}

export const DefaultSMIPIDetail = {
    moduleURL: "r_PurchaseInvoiceDetail.aspx",
    controlID: "r_gvPIItem"
}

export const DefaultSMIPIDetailItemAdvSearch = {
    moduleURL: "r_PurchaseInvoiceDetail.aspx",
    controlID: "r_gvItemSearchControl"
}

export const DefaultSMIPIDetailVendorAdvSearch = {
    moduleURL: "r_PurchaseInvoiceDetail.aspx",
    controlID: "r_gvGeneralSearchControl_vendor"
}

export const DefaultSMIPIDetailCityAdvSearch = {
    moduleURL: "r_PurchaseInvoiceDetail.aspx",
    controlID: "r_gvGeneralSearchControl_city"
}

export const DefaultSMIPIDetailAccountAdvSearch = {
    moduleURL: "r_PurchaseInvoiceDetail.aspx",
    controlID: "r_gvGeneralSearchControl_account"
}

export const DefaultSMIPIDetailPopupCopyFrom = {
    moduleURL: "r_PurchaseInvoiceDetail.aspx",
    fullCopyFrom: {
        moduleURL: "r_PurchaseInvoiceDetail.aspx",
        controlID: "r_gvTransferFromFull"
    },
    partialCopyFrom: {
        moduleURL: "r_PurchaseInvoiceDetail.aspx",
        controlID: "r_gvTransferFromPartial"
    }
}

export const DefaultSMIPIDetailReportFormat = {
    moduleURL: "r_PurchaseInvoiceDetail.aspx",
    controlID: "r_gvReportsFormatDocumentControl"
}
//#endregion