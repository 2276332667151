import { GET, POST } from './request-options'

export const GetUserLayout = (token, userID, moduleItem, controlID) =>
{
  return fetch(`${process.env.REACT_APP_API_GET_USER_PREFERED_LAYOUT}userID=${userID}&moduleItem=${moduleItem}&controlID=${controlID}&UI-Culture=en-US`, GET(token))
  .then(handleErrors)
  .then(response => response.json())
  .then(data =>{
    return data.Obj.Query_UserPreferedLayout;
  })
  .catch((error) => {console.log(error); return error.toString();});  
}

export const PostUserLayout = (token, companyID, clientID, userID, moduleItemID, controlID, moduleItem, storageKey, data) =>
{
  const PostOptions = POST(token);
  if(data){
    PostOptions.body = JSON.stringify
    ({
      "UPL_ID": 0,
      "UPL_UserID": userID,
      "UPL_ModuleItemID": moduleItemID,
      "UPL_ControlID": controlID,
      "UPL_Layout": JSON.stringify(data),
      "UPL_DefaultLayout": "",
      "UPL_DateCreated": "",
      "UPL_CreatedBy": userID,
      "UPL_DateModified": "",
      "UPL_ModifiedBy": userID,
      "UPL_CompanyID": companyID,
      "UPL_ClientID" : clientID,
      "UPL_LayoutName": "",
    });
  }
  
  return fetch(`${process.env.REACT_APP_API_POST_USER_PREFERED_LAYOUT}moduleItem=${moduleItem}&UI-Culture=en-US`, PostOptions)
  .then(handleErrors)
  .then(response => response.json())
  .catch((error) => {console.log(error); return error.toString();});  
}

//handle errors
function handleErrors(response) {
  if (!response.ok) throw new Error(response.status);
  return response;
}