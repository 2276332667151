import React, { useState } from 'react'
import { Row, Col } from "antd";
import { Field } from "formik";
import { DateSinglePickerInput, DropdownInput, TextInput } from '../../../../fields';
import { formatMessage } from 'devextreme/localization';
import { Button } from 'devextreme-react';

const SectionBatchDetailsCF = (props) => {
    const {
        itemBinLocationList,
        itemUOMList,
        onSearch,
        onUOMChange,
        disabled,
        portalRef,
        dateFormat,
    } = props;

    const [searchType, setSearchType] = useState('BatchNo');

    const searchTypeSource = [
        { Description: 'Batch No.', ID: 'BatchNo' },  // [Translation] Update Translation
        { Description: formatMessage('ExpiryDate'), ID: 'ExpiryDate' },
    ];

    function onChange(value) {
        setSearchType(value.id);
    }

    return (
        <Row gutter={16}>
            <Col xs={24} md={10} lg={5}>
                <Field
                    suffixIcon={<></>}
                    component={DropdownInput}
                    // Labels
                    label="Search By" // [Translation] Update Translation
                    name="searchType"
                    placeholder="Select Search By" // [Translation] Update Translation
                    // Dropdown
                    headerColumn={[formatMessage("Description")]}
                    dataColumn={["Description"]}
                    displayColumn="Description"
                    filtervalue={["Description"]}
                    // Data
                    dataSource={searchTypeSource}
                    getColumnValue="ID"
                    // Events
                    onChangeCallback={onChange}
                    // Others
                    disabled={disabled}
                />
            </Col>

            <Col xs={24} md={10} lg={5}>
                {
                    searchType === "BatchNo" ?
                        <Field
                            // Styling
                            className="input-w input-align-left"
                            // Labels
                            label={'Batch No.'} // [Translation] Update Translation
                            name="searchBatch"
                            placeholder={'Batch No.'} // [Translation] Update Translation
                            // Events
                            onPressEnterCallback={onSearch}
                            // Others
                            component={TextInput}
                            disabled={disabled}
                        />
                        :
                        <Field
                            label={formatMessage('ExpiryDate')}
                            name="searchDate"
                            format={dateFormat}
                            component={DateSinglePickerInput}
                            portalRef={portalRef}
                        />
                }
            </Col>

            <Col xs={24} md={4} lg={4}>
                <Button
                    hoverStateEnabled={true}
                    activeStateEnabled={false}
                    focusStateEnabled={false}
                    className="section-input-btn section-input-btn-left"
                    text={formatMessage("Search")}
                    type="default"
                    stylingMode="contained"
                    onClick={onSearch}
                    disabled={disabled}
                />
            </Col>

            <Col xs={24} md={10} lg={5}>
                <Field
                    suffixIcon={<></>}
                    component={DropdownInput}
                    // Labels
                    label={formatMessage("BinLocation")}
                    name="binLocation"
                    placeholder="Select Bin Location"
                    // Dropdown
                    headerColumn={[formatMessage("Code"), formatMessage("Description")]}
                    dataColumn={["Code", "Description"]}
                    displayColumn="Code"
                    filtervalue={["Code", "Description"]}
                    // Data
                    dataSource={itemBinLocationList}
                    getColumnValue="ID"
                    // Others
                    disabled={disabled}
                    required
                />
            </Col>

            <Col xs={24} md={10} lg={5}>
                <Field
                    suffixIcon={<></>}
                    component={DropdownInput}
                    // Labels
                    label={formatMessage("UOM")}
                    name="uom"
                    placeholder="Select UOM"
                    // Dropdown
                    headerColumn={[formatMessage("Code"), formatMessage("Description")]}
                    dataColumn={["Code", "Description"]}
                    displayColumn="Code"
                    filtervalue={["Code", "Description"]}
                    // Data
                    dataSource={itemUOMList}
                    getColumnValue="ID"
                    // Events
                    onChangeCallback={onUOMChange}
                    // Others
                    disabled={disabled}
                    required
                />
            </Col>
        </Row>
    )
}

export default SectionBatchDetailsCF