import React, { useRef, useState, useEffect, useCallback, memo, useMemo } from "react";
import { unstable_batchedUpdates } from 'react-dom';
import { Row, Col, Dropdown, Menu, Button } from "antd";
import { formatMessage } from "devextreme/localization";
import { Column, Editing, RequiredRule, Summary, TotalItem } from "devextreme-react/data-grid";
import TextBox4 from "../../text-box/text-box-4";
import ItemPopUp from "../../../components/pop-up/item-popup";
import AccountPopUp from "../../../components/pop-up/account-popup";
import ImportPopup from "../../../components/pop-up/import-popup";
import DeletePopUp from '../../../components/pop-up/delete-popup';
import { getPostMessageObj } from '../../../utils/iframe-func'
import { useMediaQuery } from "react-responsive";
import { calculateTaxableBasedOnTotal } from "../../../utils/purchase/purchase-calculate-total-func";
import CheckBoxInput from "../../fields/CheckBoxInput";
import { useFlashMsgContext } from "../../modal-dialog/FlashMsgProvider";
import { FastField } from "formik";
import { useItemStateContext } from "../../../contexts/ItemState";
import { isEmpty } from "lodash";
import { checkCorrectFormulaFormat, checkIsAllowUpdateDiscountFormula, checkIsAllowUpdatePrice, checkIsAllowUpdateQty } from "../../../utils/purchase/purchase-item-type-func";
import { useErrorPopupContext } from "../../modal-dialog/ErrorPopupProvider";
import { useConfirmationPopupContext } from "../../modal-dialog/ConfirmationPopupProvider";

// DataGridDefault
import { DataGridDefault, DeleteBtn, DragBtn, EditBtn, InfoBtn, RowNumCell, customSearchRenderer } from "../../../components";
import EditCellNumber from '../../data-grid/custom-cell/edit-cell-number';
import EditCellDropdown from "../../data-grid/custom-cell/edit-cell-dropdown";
import EditCellTextArea from '../../data-grid/custom-cell/edit-cell-textarea';
import EditCellText from '../../data-grid/custom-cell/edit-cell-text';

// Default
import { DefaultPager } from "../../../utils/default-data-grid-settings";
import { hasRequiredColumnHeaders, convertArrayWithKey } from "../../../utils/document-import-validation";
import { ItemType } from "../../../utils/constant-item-type";

// Item Type Popup
import usePopup from "../../../hooks/usePopup";
import MatrixItemPopup from "../../pop-up/item-type-popup/matrix-item-popup";
import PackageItemPopup from "../../pop-up/item-type-popup/package-item-popup";
import BatchItemPopup from "../../pop-up/item-type-popup/batch-item-popup";
import BinLocationPopup from "../../pop-up/item-type-popup/bin-location-popup";
import SerializedItemPopup from "../../pop-up/item-type-popup/serialized-item-popup";
import { getEditCellProps } from "../../data-grid/custom-cell/util/edit-cell-util";
import InformationPopup from "../../pop-up/information-popup/information-popup";

import { DecimalPoints } from "../../../utils/default-cookies";
import { mousetrapMain } from "../../../App";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import "./styles/item.scss";
import { DefaultSMIPIDetail, DefaultSMIPIDetailAccountAdvSearch, DefaultSMIPIDetailItemAdvSearch } from "../../../utils/default-smi";
import { useUserAccessContext } from "../../../contexts/UserAccess";
import { getRoundingValue } from "../../../utils/math-rounding-func";

const { SubMenu } = Menu;

export const PurchaseInvoiceDetailsItemForm = memo((props) => {
    const {
        formikRef,
        iAGID,
        piDtlItem,
        GetBinLocByWarehouseID,
        GetItemCostByWarehouse,
        userProfile,
        companyTaxTypeList,
        warehouseList,
        isAutoGeneratedPI,
        preferedLayoutRef,
    } = props;

    const {
        // [DataGrid]
        setDataGrid,
        // [State & Ref]
        mainItemDataSource,
        mainItemArrayStore,
        subItemObj,
        isLoading,
        usedSerialNumSetList,
        deletedSerialNumSetList,
        // [Functions]
        hasItem,
        getMainItemData,
        getAllItemData,
        updateDocDiscount,
        updateAllItemTax,
        updateMainItemByDiscFormula,
        editMainItemByGroupID,
        removeItemByGroupID,
        // [Callbacks]
        handleAddItemByCodeString,
        handleAddAccountByIDString,
        handleAddItemByImportData,
        handleCellChange,
        // [IsAlertRow]
        addAlertRow,
        removeAlertRow
    } = useItemStateContext();

    // [Variables]
    const isLoadingText = isLoading ? formatMessage("Loading") : formatMessage("dxDataGrid-noDataText");
    const decimalPoints = DecimalPoints();

    // [State] Popups Visibility
    const [itemPopupVisible, setItemPopupVisible] = useState(false);
    const [accountPopupVisible, setAccountPopupVisible] = useState(false);
    const [importPopupVisible, setImportPopupVisible] = useState(false);
    const [deletePopupVisible, setDeletePopupVisible] = useState(false);

    // [State] Insert Dropdown Visibility
    const [insertDropdownVisible, setInsertDropdownVisible] = useState(false);

    // [Ref] Component Reference
    const searchBoxRef = useRef();
    const dataGridRef = useRef();
    const portalRef = useRef(null);

    // [Ref] Insert Before/After
    const isInsertBefore = useRef(false);
    const isInsertAfter = useRef(false);

    // [Ref] Dropdown DataGrid Config (CONSTANT)
    const dropdownCellConfig = useRef({
        columns: [
            { dataField: "Code", caption: formatMessage("Code") },
            { dataField: "Description", caption: formatMessage("Description") },
        ],
        keyExpr: "ID",
        displayExpr: "Code",
    });
    const taxTypeCellConfig = useRef({
        columns: [
            { dataField: "Code", caption: formatMessage("Code") },
            { dataField: "Description", caption: formatMessage("Description") },
        ],
        keyExpr: "CTT_ID",
        displayExpr: "Code",
    });

    // [useMemo] FixedFormat
    const fixedFormatRef = useMemo(() => ({
        type: "fixedPoint",
        precision: 2,
    }), [])

    // [useMemo] FixedFormat
    const formatRef = useMemo(() => ({
        type: "fixedPoint",
        precision: decimalPoints > 0 ? parseInt(decimalPoints) : 2,
    }), [decimalPoints])

    // [Context] FlashMsgContext
    const showFlashMsg = useFlashMsgContext();

    // [Context] ErrorPopupContext
    const showErrorPopup = useErrorPopupContext();

    // [Context] Confirmation Popup
    const showConfirmationPopup = useConfirmationPopupContext();

    // [Context] UserAccess
    const { userAccess } = useUserAccessContext();

    // [Custom Hooks] useMediaQuery
    const isNotMobile = useMediaQuery({ minWidth: 880 });

    // [Custom Hooks] usePopup
    const { popupProps: matrixItemProps, isInactive: isMatrixItemInactive, showPopup: showMaxtrixItemPopup } = usePopup({ defaultVisible: false });
    const { popupProps: packageItemProps, isInactive: isPackageItemInactive, showPopup: showPackageItemPopup } = usePopup({ defaultVisible: false });
    const { popupProps: batchItemProps, isInactive: isBatchItemInactive, showPopup: showBatchItemPopup } = usePopup({ defaultVisible: false });
    const { popupProps: binLocationProps, isInactive: isBinLocationInactive, showPopup: showBinLocationPopup } = usePopup({ defaultVisible: false });
    const { popupProps: serializedItemProps, isInactive: isSerializedItemInactive, showPopup: showSerializedItemPopup } = usePopup({ defaultVisible: false });
    const { popupProps: informationProps, isInactive: isInformationInactive, showPopup: showInformationPopup } = usePopup({ defaultVisible: false });
    const popupKey = useRef(0);

    // #region Util
    function getDataGrid() {
        return dataGridRef.current?.instance;
    }

    function updateInsertBeforeAfter(beforeAfter = { before: false, after: false }) {
        const { before, after } = beforeAfter;
        isInsertBefore.current = before;
        isInsertAfter.current = after;
    }

    function isAllowUpdateQty(focusedRowGroupID, itemList, newQty) {
        let isAllowUpdateObj = checkIsAllowUpdateQty(focusedRowGroupID, itemList, newQty);
        let isAllow = true;

        if (!isAllowUpdateObj.isAllowUpdate) {
            showErrorPopup?.("ErrorEncountered", isAllowUpdateObj.errorPoupMsg);
            isAllow = false;
        }

        return isAllow;
    }

    function isAllowUpdatePrice(focusedRowGroupID, itemList) {
        let isAllowUpdate = checkIsAllowUpdatePrice(focusedRowGroupID, itemList);

        if (!isAllowUpdate) {
            showErrorPopup?.("ErrorEncountered", "NotAllowedtoUpdatePrice");
        }

        return isAllowUpdate;
    }

    function isAllowUpdateDiscFormula(focusedRowGroupID, itemList, discountFormula) {
        let isAllow = true;
        let isCorrectFormatFormula = checkCorrectFormulaFormat(discountFormula);
        let isAllowUpdate = checkIsAllowUpdateDiscountFormula(focusedRowGroupID, itemList);

        if (!isCorrectFormatFormula) {
            showErrorPopup?.("ErrorEncountered", "InvalidFormat!Pleasecorrectformatwith");
            isAllow = false;
        }
        else if (!isAllowUpdate) {
            showErrorPopup?.("ErrorEncountered", "NotAllowedtoApplyDiscount");
            isAllow = false;
        }

        return isAllow;
    }

    function isAllowDelete() {
        let isAllowDel = true;

        if (!isAllowDel) {
            showErrorPopup?.("ErrorEncountered", "NotAllowedtoDelete");
        }

        return isAllowDel;
    }
    // #endregion Util

    // #region ItemPopUp, AccountPopUp, ImportPopup
    async function showItemPopup(beforeAfter) {
        const errors = await formikRef.current.validateForm();
        if (!isEmpty(errors)) return;

        updateInsertBeforeAfter(beforeAfter);
        setItemPopupVisible(true);
    }

    async function showAccountPopup(beforeAfter) {
        const errors = await formikRef.current.validateForm();
        if (!isEmpty(errors)) return;

        updateInsertBeforeAfter(beforeAfter);
        setAccountPopupVisible(true);
    }

    async function showImportPopup() {
        const errors = await formikRef.current.validateForm();
        if (!isEmpty(errors)) return;

        setImportPopupVisible(true);
    }

    function hideItemPopup() {
        setItemPopupVisible(false);
    }

    function hideAccountPopup() {
        setAccountPopupVisible(false);
    }

    function hideImportPopup() {
        setImportPopupVisible(false);
    };

    async function itemPopupCallback(newItem) {
        if (!isEmpty(newItem)) {
            const itemsCodeString = newItem.reduce((prev, item) => prev + item.IM_Code + ",", "");
            const item = await handleAddItemByCodeString(itemsCodeString, isInsertBefore.current, isInsertAfter.current);
            if (item) showItemTypePopup(item, true);
        }

        updateInsertBeforeAfter();
        hideItemPopup();
    }

    async function accountPopupCallback(newAccount) {
        if (!isEmpty(newAccount)) {
            const accountsIDString = newAccount.reduce((prev, account) => prev + account.CCOAD_ID + ",", "");
            await handleAddAccountByIDString(accountsIDString, isInsertBefore.current, isInsertAfter.current);
        }

        updateInsertBeforeAfter();
        hideAccountPopup();
    }

    async function importPopupCallback(importedData) {
        if (!importedData.some(function (o) { return typeof o === "object" })) {
            convertArrayWithKey(importedData);
        }

        const isValidData = hasRequiredColumnHeaders(importedData);
        await handleAddItemByImportData(isValidData);

        updateInsertBeforeAfter();
        hideImportPopup();
    }
    // #endregion ItemPopUp, AccountPopUp, ImportPopup

    // #region DeletePopup
    const deletePopupAccept = () => {
        const focusedRowGroupID = getDataGrid()?.option("focusedRowKey");
        const isAllow = isAllowDelete();

        if (isAllow) {
            removeItemByGroupID(focusedRowGroupID);
        }

        setDeletePopupVisible(false);
        searchBoxRef.current.setFocus();
    }

    const deletePopupReject = () => {
        setDeletePopupVisible(false);
        searchBoxRef.current.setFocus();
    }
    // #endregion DeletePopup

    // #region Item Type Popup
    const showItemTypePopup = useCallback((cellData, deleteOnCancel = false) => {
        const itemType = cellData.PID_ItemTypeID;
        const groupID = cellData.PID_GroupID;

        const mainItem = cellData;
        const subItemList = subItemObj.current[groupID] || [];
        const popupData = { mainItem, subItemList, deleteOnCancel };

        popupKey.current += 1;

        switch (itemType) {
            case ItemType.MATRIX_ITEM: {
                showMaxtrixItemPopup(popupData);
                break;
            }
            case ItemType.PACKAGE_ITEM: {
                showPackageItemPopup({
                    ...popupData,
                    _usedSerialNumSetList: usedSerialNumSetList.current,
                    _deletedSerialNumSetList: deletedSerialNumSetList.current
                });
                break;
            }
            case ItemType.BATCH_ITEM: {
                showBatchItemPopup(popupData);
                break;
            }
            case ItemType.STOCKITEM: {
                showBinLocationPopup(popupData);
                break;
            }
            case ItemType.SERIALIZED_ITEM: {
                const _usedSerialNumSet = usedSerialNumSetList.current[cellData.PID_ItemID] || new Set();
                const _deletedSerialNumSet = deletedSerialNumSetList.current[cellData.PID_ItemID] || new Set();
                showSerializedItemPopup({ ...popupData, _usedSerialNumSet, _deletedSerialNumSet });
                break;
            }
            case ItemType.STOCK_SN_AND_BATCH_ITEM: {
                const _usedSerialNumSet = usedSerialNumSetList.current[cellData.PID_ItemID] || new Set();
                const _deletedSerialNumSet = deletedSerialNumSetList.current[cellData.PID_ItemID] || new Set();
                const hasBatchNo = true;
                showSerializedItemPopup({ ...popupData, _usedSerialNumSet, _deletedSerialNumSet, hasBatchNo });
                break;
            }
            default: {
                break;
            }
        }
    }, [showMaxtrixItemPopup, showPackageItemPopup, showBatchItemPopup, showBinLocationPopup, showSerializedItemPopup, subItemObj, usedSerialNumSetList, deletedSerialNumSetList])

    const handleItemPopupSubmit = (editedMainItem) => {
        removeAlertRow(editedMainItem); // set IsAlertRow to false and remove alert-row classname
        editMainItemByGroupID(editedMainItem); // update mainItem
    }

    const itemTypePopupSubmit = ({ editedMainItem }) => {
        unstable_batchedUpdates(function () {
            handleItemPopupSubmit(editedMainItem);
        })
    }

    const packageItemPopupSubmit = ({ editedMainItem, editedSubItem }) => {
        unstable_batchedUpdates(function () {
            usedSerialNumSetList.current = editedMainItem.itemDetail.usedSerialNumSetList; // update usedSerialNumSet
            deletedSerialNumSetList.current = editedMainItem.itemDetail.deletedSerialNumSetList; // update deletedSerialNumSet

            handleItemPopupSubmit(editedMainItem);
            subItemObj.current[editedMainItem.PID_GroupID] = editedSubItem;// replace subItem
        })
    }

    const binLocationPopupSubmit = ({ editedMainItem, editedSubItem }) => {
        unstable_batchedUpdates(function () {
            handleItemPopupSubmit(editedMainItem);
            subItemObj.current[editedMainItem.PID_GroupID] = editedSubItem; // replace subItem
        })
    }

    const serializedItemPopupSubmit = ({ itemID, editedMainItem }) => {
        unstable_batchedUpdates(function () {
            usedSerialNumSetList.current[itemID] = editedMainItem.itemDetail.usedSerialNumSet; // update usedSerialNumSet
            deletedSerialNumSetList.current[itemID] = editedMainItem.itemDetail.deletedSerialNumSet; // update deletedSerialNumSet

            handleItemPopupSubmit(editedMainItem);
        })
    }

    const itemTypePopupCancel = async (groupID) => {
        const mainItemList = await getMainItemData();
        const itemIndex = mainItemList.findIndex(item => item.PID_GroupID === groupID);
        if (itemIndex === -1) return;

        // delete main and sub item on cancel if IsAlertRow is true
        if (mainItemList[itemIndex]["IsAlertRow"]) {
            removeItemByGroupID(groupID);
        }
    }
    // #endregion Item Type Popup

    // #region Buttons Event
    const onClickEdit = useCallback(async (event) => {
        async function getEventData() {
            const mainItemList = await getMainItemData();
            const focusedKey = getDataGrid()?.option("focusedRowKey");
            const focusedItem = mainItemList.find((mainItem) => mainItem.PID_GroupID === focusedKey);

            return focusedItem;
        }

        const cellData = event?.data || await getEventData();
        const isEditEnabled = cellData.ButtonConfig?.AllowEdit;
        const isEditting = getDataGrid()?.option("isEditing");

        if (!isEditting && isEditEnabled) {
            showItemTypePopup(cellData);
        }
    }, [showItemTypePopup, getMainItemData])

    const onClickDelete = useCallback(async () => {
        const mainItemList = await getMainItemData();
        const focusedKey = getDataGrid()?.option("focusedRowKey");
        const focusedItem = mainItemList.find((mainItem) => mainItem.PID_GroupID === focusedKey);

        const isAllowDelete = focusedItem?.ButtonConfig?.AllowDelete;
        const isEditting = getDataGrid()?.option("isEditing");

        if (!isEditting && isAllowDelete) {
            setDeletePopupVisible(true);
        }
    }, [getMainItemData])

    const onClickInfo = useCallback((event) => {
        const popupData = { mainItem: event.data };
        popupKey.current += 1;

        showInformationPopup(popupData);
    }, [showInformationPopup])

    const onCheckBoxInclusive = () => {
        //[TODO] check is Vendor has active GST
        updateAllItemTax();
    };

    const onCheckBoxItemize = () => {
        //[TODO] check is Vendor has active GST
        updateAllItemTax();
    };
    // #endregion

    // #region Cell Change Event
    // #region EditCellNumber
    const onQtyChange = useCallback(async (newData, value, currentRowData) => {
        // update newData
        Object.assign(newData, currentRowData);
        newData.PID_Qty = value;

        // update subItem (single bin stock item)
        const isSingleBinStockItem = newData.PID_ItemTypeID === ItemType.STOCKITEM && subItemObj.current[newData.PID_GroupID]?.length === 1;
        if (isSingleBinStockItem) {
            subItemObj.current[newData.PID_GroupID][0].PID_Qty = value;
        }

        // calculate price and update mainItemArrayStore & docTotal
        await handleCellChange(newData);
    }, [handleCellChange, subItemObj])

    const onUnitPriceChange = useCallback(async (newData, value, currentRowData) => {
        // update newData
        Object.assign(newData, currentRowData);
        newData.PID_UnitPrice = value
        newData.PID_DiscountFormula = null;
        newData.PID_DiscountAmt = 0;

        // update subItem (stock item)
        const isStockItem = newData.PID_ItemTypeID === ItemType.STOCKITEM;
        if (isStockItem) {
            const subItemList = subItemObj.current[newData.PID_GroupID] || [];
            subItemList.forEach(subItem => {
                subItem.PID_UnitPrice = value
                subItem.PID_DiscountFormula = null;
                subItem.PID_DiscountAmt = 0;
            });
        }

        // calculate price and update mainItemArrayStore & docTotal
        await handleCellChange(newData);
    }, [handleCellChange, subItemObj])

    const onDiscountAmtChange = useCallback(async (newData, value, currentRowData) => {
        // update newData
        Object.assign(newData, currentRowData);
        newData.PID_DiscountAmt = value
        newData.PID_DiscountFormula = null;

        // update subItem (stock item)
        const isStockItem = newData.PID_ItemTypeID === ItemType.STOCKITEM;
        if (isStockItem) {
            const subItemList = subItemObj.current[newData.PID_GroupID] || [];
            subItemList.forEach(subItem => {
                subItem.PID_DiscountAmt = value
                subItem.PID_DiscountFormula = null;
            });
        }

        // calculate price and update mainItemArrayStore & docTotal
        await handleCellChange(newData);
    }, [handleCellChange, subItemObj])

    const onNewUnitPriceChange = useCallback(async (newData, value, currentRowData) => {
        // update newData
        Object.assign(newData, currentRowData);
        newData.PID_NewUnitPrice = value
        newData.PID_DiscountFormula = null;
        newData.PID_DiscountAmt = newData.PID_UnitPrice - newData.PID_ItemFooterDiscount - value;

        // update subItem (stock item)
        const isStockItem = newData.PID_ItemTypeID === ItemType.STOCKITEM;
        if (isStockItem) {
            const subItemList = subItemObj.current[newData.PID_GroupID] || [];
            subItemList.forEach(subItem => {
                subItem.PID_NewUnitPrice = value
                subItem.PID_DiscountFormula = null;
                subItem.PID_DiscountAmt = newData.PID_UnitPrice - newData.PID_ItemFooterDiscount - value;
            });
        }

        // calculate price and update mainItemArrayStore & docTotal
        await handleCellChange(newData);
    }, [handleCellChange, subItemObj])

    const onTaxAdjustmentChange = useCallback(async (newData, value, currentRowData) => {
        // update newData
        Object.assign(newData, currentRowData);
        newData.PID_TaxAdjustment = value

        // update subItem (stock item)
        const isStockItem = newData.PID_ItemTypeID === ItemType.STOCKITEM;
        if (isStockItem) {
            const subItemList = subItemObj.current[newData.PID_GroupID] || [];
            let remainingValue = value; // evenly divide by subItem.length, except last subItem takes remainingValue (to fix rounding issue)

            subItemList.forEach((subItem, index, array) => {
                let partialValue = 0;

                if (index !== array.length - 1) {
                    partialValue = getRoundingValue(value / array.length);
                    remainingValue -= partialValue;
                } else {
                    partialValue = remainingValue;
                }

                subItem.PID_TaxAdjustment = partialValue;
            });
        }

        // calculate price and update mainItemArrayStore & docTotal
        await handleCellChange(newData);
    }, [handleCellChange, subItemObj])

    const onTotalChange = useCallback(async (newData, value, currentRowData) => {
        const docDiscount = formikRef.current?.values.DocDiscount;
        const accept = !docDiscount ? true : await showConfirmationPopup('FooterDiscountWillResetToZeroAreYouSureYouWantToChangeTotal');

        // on confirmation accept or no docDiscount
        if (accept) {
            const isTaxInclusive = formikRef.current?.values.isTaxInclusive;

            // update doc discount to 0
            if (docDiscount) {
                formikRef.current.setFieldValue("DocDiscount", 0);
                updateDocDiscount();
            }

            // update cellData
            Object.assign(newData, currentRowData);
            newData.PID_Total = value;
            newData.PID_DiscountFormula = null;
            newData.PID_ItemFooterDiscount = 0;
            calculateTaxableBasedOnTotal("PID", isTaxInclusive, newData); // calculate price related column based on total

            // update subItem (stock item)
            const isStockItem = newData.PID_ItemTypeID === ItemType.STOCKITEM;
            if (isStockItem) {
                const subItemList = subItemObj.current[newData.PID_GroupID] || [];
                let remainingValue = value; // evenly divide by subItem.length, except last subItem takes remainingValue (to fix rounding issue)

                subItemList.forEach((subItem, index, array) => {
                    let partialValue = 0;

                    if (index !== array.length - 1) {
                        partialValue = getRoundingValue(value / array.length);
                        remainingValue -= partialValue;
                    } else {
                        partialValue = remainingValue;
                    }

                    subItem.PID_Total = partialValue;
                    subItem.PID_DiscountFormula = null;
                    subItem.PID_ItemFooterDiscount = 0;
                    calculateTaxableBasedOnTotal("PID", isTaxInclusive, subItem); // calculate price related column based on total
                });
            }

            // calculate price and update mainItemArrayStore & docTotal
            handleCellChange(newData);
        }
    }, [formikRef, showConfirmationPopup, handleCellChange, updateDocDiscount, subItemObj])
    // #endregion EditCellNumber

    // #region EditCellText
    const onDiscountFormulaChange = useCallback(async (newData, value, currentRowData) => {
        // update newData
        Object.assign(newData, currentRowData);
        newData.PID_DiscountFormula = value
        updateMainItemByDiscFormula(newData, value); // update formula

        // update subItem (stock item)
        const isStockItem = newData.PID_ItemTypeID === ItemType.STOCKITEM;
        if (isStockItem) {
            const subItemList = subItemObj.current[newData.PID_GroupID] || [];
            subItemList.forEach(subItem => {
                subItem.PID_DiscountFormula = value
                updateMainItemByDiscFormula(subItem, value); // update formula
            });
        }

        // calculate price and update mainItemArrayStore & docTotal
        await handleCellChange(newData);
    }, [handleCellChange, subItemObj, updateMainItemByDiscFormula])
    // #endregion EditCellText

    // #region EditCellDropdown
    const onTaxTypeIDCellChange = useCallback(async (newData, value, currentRowData) => {
        const selectedTaxType = companyTaxTypeList?.find((taxType) => taxType.CTT_ID === value);

        // update newData
        Object.assign(newData, currentRowData);
        newData.PID_TaxTypeID = value || null;
        newData.PID_TaxRate = selectedTaxType?.CTT_Percentage || 0;

        // update subItem (stock item)
        const isStockItem = newData.PID_ItemTypeID === ItemType.STOCKITEM;
        if (isStockItem) {
            const subItemList = subItemObj.current[newData.PID_GroupID] || [];
            subItemList.forEach(subItem => {
                subItem.PID_TaxTypeID = value || null;
                subItem.PID_TaxRate = selectedTaxType?.CTT_Percentage || 0;
            });
        }

        // calculate price and update mainItemArrayStore & docTotal
        await handleCellChange(newData);
    }, [companyTaxTypeList, handleCellChange, subItemObj])

    const onItemUOMIDChange = useCallback(async (newData, value, currentRowData) => {
        getDataGrid()?.beginCustomLoading();

        const selectedUOM = currentRowData?.ItemUOM?.find((uom) => uom.ID === value);
        const itemObj = { itemID: currentRowData.PID_ItemID, itemUOMID: value };
        const itemCost = await GetItemCostByWarehouse(currentRowData.PID_WarehouseID, itemObj);

        // update newData
        Object.assign(newData, currentRowData);
        newData.PID_ItemUOMID = value || null;
        newData.PID_UnitPrice = itemCost[0]?.Cost || 0; // update PID_UnitPrice
        newData.PID_Rate = selectedUOM?.Rate || 1; // update PID_Rate

        // update subItem (stock item)
        const isStockItem = newData.PID_ItemTypeID === ItemType.STOCKITEM;
        if (isStockItem) {
            const subItemList = subItemObj.current[newData.PID_GroupID] || [];
            subItemList.forEach(subItem => {
                subItem.PID_ItemUOMID = value || null;
                subItem.PID_UnitPrice = itemCost[0]?.Cost || 0; // update PID_UnitPrice
                subItem.PID_Rate = selectedUOM?.Rate || 1; // update PID_Rate
            });
        }

        // calculate price and update mainItemArrayStore & docTotal
        await handleCellChange(newData);

        getDataGrid()?.endCustomLoading();
    }, [GetItemCostByWarehouse, handleCellChange, subItemObj])

    const onItemBinLocationIDChange = useCallback(async (newData, value, currentRowData) => {
        // update newData
        Object.assign(newData, currentRowData);
        newData.PID_BinLocationID = value || null;

        // update subItem (all item type)
        const subItemList = subItemObj.current[newData.PID_GroupID] || [];
        subItemList.forEach(subItem => {
            subItem.PID_BinLocationID = value || null;
        });

        // calculate price and update mainItemArrayStore & docTotal
        await handleCellChange(newData);
    }, [handleCellChange, subItemObj])

    const onWarehouseIDChange = useCallback(async (newData, value, currentRowData) => {
        getDataGrid()?.beginCustomLoading();

        const binLocation = await GetBinLocByWarehouseID(value);

        // update newData
        Object.assign(newData, currentRowData);
        newData.BinLoc = binLocation;
        newData.PID_BinLocationID = binLocation[0].ID;
        newData.PID_WarehouseID = value || null;

        const subItemList = subItemObj.current[newData.PID_GroupID] || [];
        const isStockItem = newData.PID_ItemTypeID === ItemType.STOCKITEM;

        // update subItem (stock item)
        if (isStockItem) {
            // if stock item with multibin, change alert row to true and delete all sub items
            const isMultiBinStockItem = subItemList?.length > 1;

            if (isMultiBinStockItem) {
                subItemList.splice(0, subItemList.length);
                delete subItemObj.current[newData.PID_GroupID];
                addAlertRow(newData);
            }

            // if stock item, clear itemDetail
            newData.itemDetail = null;
        }

        // update subItem (all item type)
        subItemList?.forEach(subItem => {
            subItem.BinLoc = binLocation;
            subItem.PID_BinLocationID = binLocation[0].ID;
            subItem.PID_WarehouseID = value || null;
        });

        if (!isEmpty(subItemList)) subItemObj.current[newData.PID_GroupID] = subItemList;

        // calculate price and update mainItemArrayStore & docTotal
        await handleCellChange(newData);

        getDataGrid()?.endCustomLoading();
    }, [GetBinLocByWarehouseID, subItemObj, handleCellChange, addAlertRow])
    // #endregion EditCellDropdown
    // #endregion Cell Change Event

    // #region Search Box
    const editFocusedItemQty = async (newQty) => {
        if (!hasItem()) return;

        const mainItemList = await getMainItemData();
        const allItemList = await getAllItemData();
        const focusedKey = getDataGrid()?.option("focusedRowKey");
        const focusedItem = mainItemList.find((mainItem) => mainItem.PID_GroupID === focusedKey);

        const isAllow = isAllowUpdateQty(focusedItem.PID_GroupID, allItemList, newQty); // check using allItemList as STOCK item need subItem info
        if (!isAllow) return;

        // update data
        focusedItem.PID_Qty = newQty;

        // update subItem (single bin stock item)
        const isSingleBinStockItem = focusedItem.PID_ItemTypeID === ItemType.STOCKITEM && subItemObj.current[focusedItem.PID_GroupID]?.length === 1;
        if (isSingleBinStockItem) {
            subItemObj.current[focusedItem.PID_GroupID][0].PID_Qty = newQty;
        }

        editMainItemByGroupID(focusedItem); // update mainItemArrayStore & trigger price calculation
    }

    const editFocusedItemUnitPrice = async (newPrice) => {
        if (!hasItem()) return;

        const mainItemList = await getMainItemData();
        const focusedKey = getDataGrid()?.option("focusedRowKey");
        const focusedItem = mainItemList.find((mainItem) => mainItem.PID_GroupID === focusedKey);

        const isAllow = isAllowUpdatePrice(focusedItem.PID_GroupID, mainItemList);
        if (!isAllow) return;

        // update data
        focusedItem.PID_UnitPrice = newPrice;
        focusedItem.PID_DiscountFormula = null;
        focusedItem.PID_DiscountAmt = 0;

        // update subItem (stock item)
        const isStockItem = focusedItem.PID_ItemTypeID === ItemType.STOCKITEM;
        if (isStockItem) {
            const subItemList = subItemObj.current[focusedItem.PID_GroupID] || [];
            subItemList.forEach(subItem => {
                subItem.PID_UnitPrice = newPrice
                subItem.PID_DiscountFormula = null;
                subItem.PID_DiscountAmt = 0;
            });
        }

        editMainItemByGroupID(focusedItem); // update mainItemArrayStore & trigger price calculation
    }

    const editFocusedItemDiscFormula = async (newDiscountFormula) => {
        if (!hasItem()) return;

        const mainItemList = await getMainItemData();
        const focusedKey = getDataGrid()?.option("focusedRowKey");
        const focusedItem = mainItemList.find((mainItem) => mainItem.PID_GroupID === focusedKey);

        const isAllow = isAllowUpdateDiscFormula(focusedItem.PID_GroupID, mainItemList, newDiscountFormula);
        if (!isAllow) return;

        // update data
        focusedItem.PID_DiscountFormula = newDiscountFormula;
        updateMainItemByDiscFormula(focusedItem, newDiscountFormula); // update formula

        // update subItem (stock item)
        const isStockItem = focusedItem.PID_ItemTypeID === ItemType.STOCKITEM;
        if (isStockItem) {
            const subItemList = subItemObj.current[focusedItem.PID_GroupID] || [];
            subItemList.forEach(subItem => {
                subItem.PID_DiscountFormula = newDiscountFormula
                updateMainItemByDiscFormula(subItem, newDiscountFormula); // update formula
            });
        }

        editMainItemByGroupID(focusedItem); // update mainItemArrayStore & trigger price calculation
    }

    const onSearchEnterKey = async (event) => {
        const errors = await formikRef.current.validateForm();
        if (!isEmpty(errors)) return;

        const searchValue = event.component.option('text').trim();

        if (searchValue) {
            const input = searchValue.substring(0, 1);

            if (input === "*") {
                const qty = parseFloat(searchValue.substring(1));
                editFocusedItemQty(qty);
            }
            else if (input === ".") {
                const price = parseFloat(searchValue.substring(1));
                editFocusedItemUnitPrice(price);
            }
            else if (input === "/" || input === "+" || input === "-") {
                const discountFormula = searchValue.trim();
                editFocusedItemDiscFormula(discountFormula);
            }
            else {
                const item = await handleAddItemByCodeString(searchValue, isInsertBefore.current, isInsertAfter.current);
                if (item) showItemTypePopup(item, true);
            }

            searchBoxRef.current.clear();
        }
    }
    // #endregion

    // #region Insert Dropdown
    const toggleInsertDropdown = useCallback((event) => {
        if (event.target.type === "button") {
            setInsertDropdownVisible((insertDropdownVisible) => !insertDropdownVisible);
        }
    }, [])

    const hideInsertDropdown = useCallback(() => {
        setInsertDropdownVisible(false)
    }, [])

    const insertWrapperRef = useOutsideClick(true, hideInsertDropdown); // ref to wrapper div
    // #endregion Insert Dropdown

    // #region DataGrid configurations
    const onRowPrepared = useCallback((event) => {
        if (event.rowType === "data") {
            if (event.data.IsAlertRow && !event.rowElement.classList.contains('alert-row')) {
                event.rowElement.classList.add('alert-row');
            }
            if (event.data.IsInsufficientBal && !event.rowElement.classList.contains('insufficient-row')) {
                event.rowElement.classList.add('insufficient-row');
            }
        }
    }, [])

    const onDataGridKeyDown = useCallback((event) => {
        if (event.event.key === "Delete") {
            onClickDelete();
        } else if (event.event.key === "Enter") {
            onClickEdit();
        }
    }, [onClickDelete, onClickEdit])

    const docNoLinkClick = useCallback((data) => {
        var tabData = data?.PID_SourceDocLink.split(";")
        var tabURL = tabData[0];
        var tabName = tabData[1];

        window.parent.postMessage(getPostMessageObj(tabURL, tabName), "*");
    }, [])
    // #endregion DataGrid configurations

    // #region useEffect
    useEffect(() => {
        // register del event
        mousetrapMain.bind('del', onClickDelete);
        mousetrapMain.bind('enter', onClickEdit);

        return () => {
            mousetrapMain.unbind("del");
            mousetrapMain.unbind("enter");
        };
    }, [onClickDelete, onClickEdit]);

    useEffect(() => {
        setDataGrid(dataGridRef);
    }, [setDataGrid])
    // #endregion useEffect

    //#region Render Components
    const insertArrowIcon = () => {
        return (
            <div style={{ pointerEvents: "none" }}>
                <i className={`${insertDropdownVisible ? "up-icon" : "down-icon"}`}>
                    <i className="icon-down-icon" style={{ color: "#1dafed", width: 35 }} />
                </i>
            </div>
        )
    }

    const insertDropdownContent = () => {
        return (
            <div style={{ pointerEvents: "none" }}>
                <i className="icon-insert-icon" style={{ paddingRight: 5 }} />
                <span className="insert-button-text">Insert</span>
            </div>
        );
    }

    const insertOverlayMenu = () => {
        return (
            <Menu style={{ borderRadius: 10, width: "125px" }}>
                <SubMenu
                    key={"Item"}
                    className="first-submenu"
                    title="Item"
                    icon={<i className="icon-item-icon" />}
                >
                    <Menu.Item onClick={() => { showItemPopup({ before: true }) }} key={"ItemBefore"}>
                        <i className="icon-before-icon" style={{ paddingRight: 10 }} /> Before
                    </Menu.Item>
                    <Menu.Item onClick={() => { showItemPopup({ after: true }) }} style={{ borderRadius: 0 }} key={"ItemAfter"}>
                        <i className="icon-after-icon" style={{ paddingRight: 10 }} /> After
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    key={"Account"}
                    className="sec-submenu"
                    title="Account"
                    icon={<i className="icon-account-icon" />}
                >
                    <Menu.Item onClick={() => { showAccountPopup({ before: true }) }} key={"AccountBefore"}>
                        <i className="icon-before-icon" style={{ paddingRight: 10 }} /> Before
                    </Menu.Item>
                    <Menu.Item onClick={() => { showAccountPopup({ after: true }) }} style={{ borderRadius: 0 }} key={"AccountAfter"}>
                        <i className="icon-after-icon" style={{ paddingRight: 10 }} /> After
                    </Menu.Item>
                </SubMenu>
            </Menu>
        );
    }

    const buttonGroup = (cellData) => {
        const isEditEnabled = cellData.data.ButtonConfig?.AllowEdit;
        const isDeleteEnabled = cellData.data.ButtonConfig?.AllowDelete;
        const isInfoEnabled = cellData.data.ButtonConfig?.AllowShowInfo;

        return (
            <div className="icon-row-flex">
                <div>
                    <EditBtn
                        data={cellData.data}
                        onClick={onClickEdit}
                        enabled={isEditEnabled}
                    />
                </div>
                <div>
                    <DeleteBtn
                        data={cellData.data}
                        onClick={onClickDelete}
                        enabled={isDeleteEnabled}
                    />
                </div>
                <div>
                    <InfoBtn
                        data={cellData.data}
                        onClick={onClickInfo}
                        enabled={isInfoEnabled}
                    />
                </div>
            </div>
        );
    };

    const editCellDropdown = (cellData, dataGridConfig, dataSourceConfig) => {
        function getEditable(dataField, itemType, groupID) {
            if (isAutoGeneratedPI || itemType === ItemType.ACCOUNT_ITEM) {
                if (dataField === "PID_BinLocationID") return false
                else if (dataField === "PID_WarehouseID") return false;
                else if (dataField === "PID_ControlAccount") return false;
                else if (dataField === "PID_ItemUOMID") return false;
            }

            if (dataField === "PID_BinLocationID") {
                switch (itemType) {
                    case ItemType.STOCKITEM:
                        // Stock Item with multi bin
                        return subItemObj.current[groupID]?.length > 1 ? false : true;
                    default:
                        return true;
                }
            } else if (dataField === "PID_ItemUOMID") {
                switch (itemType) {
                    case ItemType.SERIALIZED_ITEM:
                    case ItemType.BATCH_ITEM:
                    case ItemType.STOCK_SN_AND_BATCH_ITEM:
                    case ItemType.PACKAGE_ITEM:
                        return false;
                    default:
                        return true;
                }
            } else {
                return true;
            }
        }

        function getDisplayValueNull(dataField, itemType) {
            return dataField === "PID_BinLocationID" && itemType === ItemType.SERVICE_ITEM;
        }

        function getDataSource() {
            if (dataSourceConfig && typeof dataSourceConfig === "string") {
                return info.data?.[dataSourceConfig];
            } else if (dataSourceConfig && typeof dataSourceConfig === "object") {
                return dataSourceConfig;
            } else {
                return null;
            }
        }

        function getAddEmptySelection(dataField) {
            switch (dataField) {
                case "PID_TaxTypeID":
                case "PID_ProjectID":
                case "PID_CostCentreID":
                    return true;
                default:
                    return false;
            }
        }

        const { info, ...editCellProps } = getEditCellProps(cellData);
        const isEditable = getEditable(info.column.dataField, info.data.PID_ItemTypeID, info.data.PID_GroupID);
        const isDisplayValueNull = getDisplayValueNull(info.column.dataField, info.data.PID_ItemTypeID);
        const dataSource = getDataSource();
        const addEmptySelection = getAddEmptySelection(info.column.dataField);

        return (
            <EditCellDropdown
                {...editCellProps}
                {...dataGridConfig}
                dataSource={dataSource}
                portalRef={portalRef}
                isEditable={isEditable}
                isDisplayValueNull={isDisplayValueNull}
                addEmptySelection={addEmptySelection}
            />
        )
    }

    const editCellTextArea = (cellData) => {
        const { info, ...editCellProps } = getEditCellProps(cellData);

        return (
            <EditCellTextArea
                {...editCellProps}
                portalRef={portalRef}
                isEditable
            />
        )
    }

    const editCellNumber = (cellData) => {
        function getEditable(info) {
            const { dataField } = info.column;
            const { PID_ItemTypeID: itemType, PID_GroupID: groupID } = info.data;

            if (isAutoGeneratedPI) {
                if (dataField === "PID_Qty") return false
                else if (dataField === "PID_UnitPrice") return false;
                else if (dataField === "PID_NewUnitPrice") return false;
                else if (dataField === "PID_DiscountAmt") return false;
            }

            if (dataField === "PID_Qty") {
                switch (itemType) {
                    case ItemType.STOCKITEM:
                        // Stock Item with multi bin
                        return subItemObj.current[groupID]?.length > 1 ? false : true;
                    default:
                        return false;
                }
            } else if (dataField === "PID_UnitPrice" || dataField === "PID_DiscountAmt") {
                switch (itemType) {
                    case ItemType.PACKAGE_ITEM:
                        return false
                    default:
                        return true;
                }
            } else if (dataField === "PID_TaxAdjustment") {
                const { PID_TaxTypeID: taxTypeID } = info.data;
                if (taxTypeID && taxTypeID !== 0) return true;
                return false;
            } else {
                return true;
            }
        }

        function getCellFormat(dataField) {
            if (dataField === "PID_Qty") {
                return {
                    min: 1,
                };
            } else if (dataField === "PID_UnitPrice" || dataField === "PID_NewUnitPrice") {
                return {
                    precision: formatRef.precision,
                    thousandSeparator: true,
                    showAllDecimalPoints: true,
                };
            } else if (dataField === "PID_DiscountAmt") {
                return {
                    precision: fixedFormatRef.precision,
                    thousandSeparator: false,
                    showAllDecimalPoints: true,
                    maxDisplayPrecision: formatRef.precision,
                };
            } else {
                return {
                    precision: fixedFormatRef.precision,
                    thousandSeparator: false,
                    showAllDecimalPoints: true,
                }
            }
        }

        const { info, ...editCellProps } = getEditCellProps(cellData);
        const isEditable = getEditable(info);
        const cellFormat = getCellFormat(info.column.dataField);

        return (
            <EditCellNumber
                {...editCellProps}
                {...cellFormat}
                isEditable={isEditable}
                isRequired
                changeEmptyToMin
            />
        )
    }

    const editCellText = (cellData) => {
        function getEditable(dataField, itemType) {
            if (isAutoGeneratedPI) {
                if (dataField === "PID_DiscountFormula") return false
            }

            if (dataField === "PID_DiscountFormula") {
                switch (itemType) {
                    case ItemType.PACKAGE_ITEM:
                        return false;
                    default:
                        return true;
                }
            } else {
                return true;
            }
        }

        const { info, ...editCellProps } = getEditCellProps(cellData);
        const isEditable = getEditable(info.column.dataField, info.data.PID_ItemTypeID);

        return (
            <EditCellText
                {...editCellProps}
                isEditable={isEditable}
            />
        )
    }
    //#endregion Render Components

    return (
        <Row className="purchase-invoice-item" align="middle" gutter={[0, 22]}>
            <div className="portalRef dx-dropdowneditor-overlay" ref={portalRef} />

            {itemPopupVisible &&
                <ItemPopUp
                    parentCallback={itemPopupCallback}
                    hidePopup={hideItemPopup}
                    visible={itemPopupVisible}
                    title={formatMessage("AddItem")}
                    addNewButtonText="Create New"
                    allowDisplayColumnChooser={userAccess.allowDisplayColumnChooser}
                    allowExportGrid={userAccess.allowExportGrid}
                    allowRestoreLayout={userAccess.allowRestoreLayout}
                    allowSaveGridLayout={userAccess.allowSaveGridLayout}
                    defaultSMI={DefaultSMIPIDetailItemAdvSearch}
                />
            }
            {accountPopupVisible &&
                <AccountPopUp
                    parentCallback={accountPopupCallback}
                    hidePopup={hideAccountPopup}
                    visible={accountPopupVisible}
                    title={formatMessage("AddAccountNo")}
                    postingDate={formikRef.current?.values.PostingDate}
                    defaultSMI={DefaultSMIPIDetailAccountAdvSearch}
                    contextMenuAccess={userAccess}
                />
            }
            {
                isMatrixItemInactive ? null :
                    <MatrixItemPopup
                        {...matrixItemProps}
                        key={`ItemTypePopup-Matrix-${popupKey.current}`}
                        submitCallback={itemTypePopupSubmit}
                        cancelCallback={itemTypePopupCancel}
                        iAGID={iAGID}
                        docType={"PI"}
                    />
            }
            {
                isPackageItemInactive ? null :
                    <PackageItemPopup
                        {...packageItemProps}
                        key={`ItemTypePopup-Package-${popupKey.current}`}
                        submitCallback={packageItemPopupSubmit}
                        cancelCallback={itemTypePopupCancel}
                        iAGID={iAGID}
                        docType={"PI"}
                        userProfile={userProfile}
                    />
            }
            {
                isBatchItemInactive ? null :
                    <BatchItemPopup
                        {...batchItemProps}
                        key={`ItemTypePopup-Batch-${popupKey.current}`}
                        submitCallback={itemTypePopupSubmit}
                        cancelCallback={itemTypePopupCancel}
                        iAGID={iAGID}
                        docType={"PI"}
                        userProfile={userProfile}
                    />
            }
            {
                isBinLocationInactive ? null :
                    <BinLocationPopup
                        {...binLocationProps}
                        key={`ItemTypePopup-Stock-${popupKey.current}`}
                        submitCallback={binLocationPopupSubmit}
                        cancelCallback={itemTypePopupCancel}
                        iAGID={iAGID}
                        docType={"PI"}
                    />
            }
            {
                isSerializedItemInactive ? null :
                    <SerializedItemPopup
                        {...serializedItemProps}
                        key={`ItemTypePopup-Serialized-${popupKey.current}`}
                        submitCallback={serializedItemPopupSubmit}
                        cancelCallback={itemTypePopupCancel}
                        iAGID={iAGID}
                        docType={"PI"}
                        userProfile={userProfile}
                    />
            }
            {
                isInformationInactive ? null :
                    <InformationPopup
                        {...informationProps}
                        key={`Popup-Information-${popupKey.current}`}
                        iAGID={iAGID}
                        docType={"PI"}
                        userProfile={userProfile}
                    />
            }

            <ImportPopup
                parentCallback={importPopupCallback}
                hidePopup={hideImportPopup}
                visible={importPopupVisible}
            />

            <DeletePopUp
                parentCallback={deletePopupAccept}
                hideDeletePopup={deletePopupReject}
                visible={deletePopupVisible}
            />

            {
                !isAutoGeneratedPI && <>
                    <Col span={12} style={{ maxWidth: isNotMobile ? "31.5%" : "50%" }}>
                        <TextBox4
                            placeHolderText={formatMessage("InputCodeText")}
                            tooltipText={formatMessage("SearchPlaceholderTooltipText")}
                            openItemPopUp={showItemPopup}
                            onTbEnterKey={onSearchEnterKey}
                            visible={true}
                            ref={searchBoxRef}
                        />
                    </Col>
                    <Col
                        span={5}
                        style={{ display: "flex", height: 35, maxWidth: isNotMobile ? "18.5%" : "16.66666667%" }}
                    >
                        <div onClick={toggleInsertDropdown} ref={insertWrapperRef}>
                            <Dropdown.Button
                                className="insert-button"
                                overlay={insertOverlayMenu()}
                                icon={insertArrowIcon()}
                                getPopupContainer={() => insertWrapperRef.current}
                                visible={insertDropdownVisible}
                                trigger={[]} // events are overriden by outer wrapper as antd dropdown.button doesn't allow left button to toggle dropdown
                            >
                                {insertDropdownContent()}
                            </Dropdown.Button>
                        </div>
                    </Col>
                    <Col
                        className="import-button"
                        span={4}
                        offset={isNotMobile ? 8 : 4}
                        style={{ textAlign: "right", height: 35 }}
                    >
                        <Button
                            style={{ borderRadius: 5, height: "100%", width: "94px", padding: 0 }}
                            onClick={showImportPopup}
                        >
                            <i className="icon-import-icon-01" style={{ paddingRight: 5 }} />
                            Import
                        </Button>
                    </Col>
                </>
            }

            <Col
                className="tax-check-box"
                span={24}
                style={{ textAlign: "right", height: 0, fontFamily: "PoppinsRegular" }}
            >
                <FastField
                    label={formatMessage("TaxInclusive")}
                    name="isTaxInclusive"
                    component={CheckBoxInput}
                    onChangeCallback={onCheckBoxInclusive}
                    disabled={isAutoGeneratedPI}
                />
                <FastField
                    label={formatMessage("TaxItemize")}
                    name="isTaxItemize"
                    component={CheckBoxInput}
                    onChangeCallback={onCheckBoxItemize}
                    disabled={isAutoGeneratedPI}
                />
            </Col>

            <Col span={24}>
                <div className="module-data-grid">
                    <DataGridDefault
                        allowDisplayColumnChooser={userAccess.allowDisplayColumnChooser}
                        allowExportGrid={userAccess.allowExportGrid}
                        allowedPageSizes={DefaultPager.allowedPageSizes_details}
                        allowRestoreLayout={userAccess.allowRestoreLayout}
                        allowSaveGridLayout={userAccess.allowSaveGridLayout}
                        className="dx-datagrid-items"
                        dataGridRef={dataGridRef}
                        dataSource={mainItemDataSource}
                        defaultPageSize={DefaultPager.defaultPageSize_details}
                        defaultSMI={DefaultSMIPIDetail}
                        focusedRowEnabled={true}
                        keyExpr="PID_GroupID"
                        noDataText={isLoadingText}
                        onKeyDown={onDataGridKeyDown}
                        onRowPrepared={onRowPrepared}
                        preferedLayout={preferedLayoutRef.current}
                        preferedLayoutRef={preferedLayoutRef}
                        exportFileName="PurchaseInvoiceDetail"
                        showMsgHandler={showFlashMsg}
                        customRowDragging={true}
                        mousetrap={mousetrapMain}
                    >
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                        />
                        <Column
                            visibleIndex={0}
                            fixedPosition={'left'}
                            fixed={true}
                            width={100}
                            minWidth={100}
                            allowExporting={false}
                            allowFiltering={false}
                            allowHeaderFiltering={false}
                            allowHiding={false}
                            allowReordering={false}
                            allowResizing={false}
                            allowSorting={false}
                            showInColumnChooser={false}
                            cssClass="column-normalize"
                            caption={null}
                            cellRender={buttonGroup}
                            dataField={'_TOTAL'} // dummy dataField for summary
                        />
                        <Column
                            visibleIndex={1}
                            type="drag"
                            fixedPosition={'left'}
                            fixed={true}
                            width={30}
                            minWidth={30}
                            allowFiltering={false}
                            allowHeaderFiltering={false}
                            allowReordering={false}
                            allowResizing={false}
                            allowSorting={false}
                            allowHiding={false}
                            showInColumnChooser={false}
                            cellRender={DragBtn}
                        />
                        <Column
                            visibleIndex={2}
                            fixedPosition={'left'}
                            fixed={true}
                            alignment={'left'}
                            width={60}
                            minWidth={60}
                            allowFiltering={false}
                            allowHeaderFiltering={false}
                            allowReordering={false}
                            allowResizing={false}
                            allowSorting={false}
                            caption={'No.'}
                            dataField={"PID_Seq"}
                            cellRender={RowNumCell}
                        />
                        <Column
                            showInColumnChooser={false}
                            dataField={"PID_ID"}
                            width={0}
                            minWidth={0}
                            visible={false}
                        />
                        <Column
                            dataField={'PID_Item.IM_Code'}
                            caption={formatMessage('ItemCode')}
                            width={120}
                            minWidth={60}
                            allowHiding={true}
                        />
                        <Column
                            dataField={"PID_Description"}
                            caption={formatMessage("Description")}
                            allowEditing={true}
                            allowHiding={true}
                            cssClass="column-normalize"
                            cellRender={editCellText}
                            editCellComponent={editCellText}
                            dataType="string"
                            minWidth={60}
                        />
                        <Column
                            dataField={"PID_WarehouseID"}
                            caption={formatMessage("Warehouse")}
                            allowEditing={true}
                            allowHiding={true}
                            visible={false}
                            cssClass="column-normalize"
                            cellRender={(cellData) => editCellDropdown(cellData, dropdownCellConfig.current, warehouseList)}
                            editCellComponent={(cellData) => editCellDropdown(cellData, dropdownCellConfig.current, warehouseList)}
                            setCellValue={onWarehouseIDChange}
                            dataType="string"
                            minWidth={60}
                            editCellDisplayValue="editCell.warehouse"
                            calculateDisplayValue="editCell.warehouse"
                        />
                        <Column
                            dataField={"PID_BinLocationID"}
                            caption={formatMessage("BinLocation")}
                            allowEditing={true}
                            allowHiding={true}
                            cssClass="column-normalize"
                            cellRender={(cellData) => editCellDropdown(cellData, dropdownCellConfig.current, "BinLoc")}
                            editCellComponent={(cellData) => editCellDropdown(cellData, dropdownCellConfig.current, "BinLoc")}
                            setCellValue={onItemBinLocationIDChange}
                            dataType="string"
                            minWidth={60}
                            editCellDisplayValue="editCell.binLocation"
                            calculateDisplayValue="editCell.binLocation"
                        />
                        <Column
                            dataField={"PID_ItemUOMID"}
                            caption={formatMessage("UOM")}
                            allowEditing={true}
                            allowHiding={true}
                            cssClass="column-normalize"
                            cellRender={(cellData) => editCellDropdown(cellData, dropdownCellConfig.current, "ItemUOM")}
                            editCellComponent={(cellData) => editCellDropdown(cellData, dropdownCellConfig.current, "ItemUOM")}
                            setCellValue={onItemUOMIDChange}
                            dataType="string"
                            minWidth={60}
                            editCellDisplayValue="editCell.itemUOM"
                            calculateDisplayValue="editCell.itemUOM"
                        />
                        <Column
                            dataField={"PID_Qty"}
                            caption={formatMessage("QTY")}
                            allowEditing={true}
                            allowHiding={false}
                            allowReordering={false}
                            cssClass="column-normalize"
                            cellRender={editCellNumber}
                            editCellComponent={editCellNumber}
                            setCellValue={onQtyChange}
                            dataType="number"
                            minWidth={60}
                        />
                        <Column
                            dataField={"PID_UnitPrice"}
                            caption={formatMessage("UnitPrice")}
                            allowEditing={true}
                            allowHiding={false}
                            allowReordering={false}
                            cssClass="column-normalize"
                            cellRender={editCellNumber}
                            editCellComponent={editCellNumber}
                            setCellValue={onUnitPriceChange}
                            dataType="number"
                            minWidth={60}
                        />
                        <Column
                            dataField={"PID_DiscountFormula"}
                            caption={formatMessage("DiscForm")}
                            allowEditing={true}
                            allowHiding={false}
                            allowReordering={false}
                            cssClass="column-normalize"
                            cellRender={editCellText}
                            editCellComponent={editCellText}
                            setCellValue={onDiscountFormulaChange}
                            dataType="string"
                            minWidth={60}
                        >
                        </Column>
                        <Column
                            dataField={"PID_DiscountAmt"}
                            caption={formatMessage("DiscAmt")}
                            allowEditing={true}
                            allowHiding={false}
                            allowReordering={false}
                            cssClass="column-normalize"
                            cellRender={editCellNumber}
                            editCellComponent={editCellNumber}
                            setCellValue={onDiscountAmtChange}
                            dataType="number"
                            minWidth={60}
                        />
                        <Column
                            dataField={"PID_ItemFooterDiscount"}
                            caption={formatMessage("FooterDiscount")}
                            format={formatRef}
                            allowHiding={false}
                            allowReordering={false}
                        >
                            <RequiredRule />
                        </Column>
                        <Column
                            dataField={"PID_NewUnitPrice"}
                            caption={formatMessage("NewUnitPrice")}
                            allowEditing={true}
                            allowHiding={false}
                            allowReordering={false}
                            cssClass="column-normalize"
                            cellRender={editCellNumber}
                            editCellComponent={editCellNumber}
                            setCellValue={onNewUnitPriceChange}
                            dataType="number"
                            minWidth={60}
                        />
                        <Column
                            dataField={"PID_SubTotal"}
                            caption={formatMessage("SubTotal")}
                            format={fixedFormatRef}
                            allowEditing={false}
                            allowHiding={false}
                            allowReordering={false}
                            minWidth={60}
                        />
                        <Column
                            dataField={"PID_TaxTypeID"}
                            caption={formatMessage("TaxCode")}
                            allowEditing={true}
                            allowHiding={true}
                            cssClass="column-normalize"
                            cellRender={(cellData) => editCellDropdown(cellData, taxTypeCellConfig.current, companyTaxTypeList)}
                            editCellComponent={(cellData) => editCellDropdown(cellData, taxTypeCellConfig.current, companyTaxTypeList)}
                            setCellValue={onTaxTypeIDCellChange}
                            dataType="string"
                            minWidth={60}
                            editCellDisplayValue="editCell.taxType"
                            calculateDisplayValue="editCell.taxType"
                        />
                        <Column
                            dataField={"PID_TaxAmt"}
                            caption={formatMessage("TaxAmount")}
                            format={fixedFormatRef}
                            allowEditing={false}
                            allowHiding={false}
                            allowReordering={false}
                            minWidth={60}
                        />
                        <Column
                            dataField={"PID_TaxAdjustment"}
                            caption={formatMessage("TaxAdjustment")}
                            allowEditing={true}
                            allowHiding={true}
                            cssClass="column-normalize"
                            cellRender={editCellNumber}
                            editCellComponent={editCellNumber}
                            setCellValue={onTaxAdjustmentChange}
                            dataType="number"
                            minWidth={60}
                        />
                        <Column
                            dataField={"PID_TaxableAmt"}
                            caption={formatMessage("Taxable")}
                            format={fixedFormatRef}
                            allowEditing={false}
                            allowHiding={false}
                            allowReordering={false}
                            minWidth={60}
                        />
                        <Column
                            dataField={"PID_Total"}
                            caption={formatMessage("Total")}
                            allowEditing={true}
                            allowHiding={false}
                            allowReordering={false}
                            cssClass="column-normalize"
                            cellRender={editCellNumber}
                            editCellComponent={editCellNumber}
                            setCellValue={onTotalChange}
                            dataType="number"
                            minWidth={60}
                        />
                        <Column
                            dataField={"PID_ControlAccount"}
                            caption={formatMessage("AccountNo")}
                            allowEditing={true} // not allow to edit if item code = 0
                            allowHiding={true}
                            visible={false}
                            cssClass="column-normalize"
                            cellRender={(cellData) => editCellDropdown(cellData, dropdownCellConfig.current, piDtlItem?.CompanyChartOfAccount)}
                            editCellComponent={(cellData) => editCellDropdown(cellData, dropdownCellConfig.current, piDtlItem?.CompanyChartOfAccount)}
                            dataType="string"
                            minWidth={60}
                            editCellDisplayValue="editCell.controlAccount"
                            calculateDisplayValue="editCell.controlAccount"
                        />
                        <Column
                            dataField={"PID_CostCentreID"}
                            caption={formatMessage("CostCentre")}
                            allowEditing={true}
                            allowHiding={true}
                            visible={false}
                            cssClass="column-normalize"
                            cellRender={(cellData) => editCellDropdown(cellData, dropdownCellConfig.current, piDtlItem?.CostCentre)}
                            editCellComponent={(cellData) => editCellDropdown(cellData, dropdownCellConfig.current, piDtlItem?.CostCentre)}
                            dataType="string"
                            minWidth={60}
                            editCellDisplayValue="editCell.costCentre"
                            calculateDisplayValue="editCell.costCentre"
                        />
                        <Column
                            dataField={"PID_ProjectID"}
                            caption={formatMessage("Project")}
                            allowEditing={true}
                            allowHiding={true}
                            visible={false}
                            cssClass="column-normalize"
                            cellRender={(cellData) => editCellDropdown(cellData, dropdownCellConfig.current, piDtlItem?.Project)}
                            editCellComponent={(cellData) => editCellDropdown(cellData, dropdownCellConfig.current, piDtlItem?.Project)}
                            dataType="string"
                            minWidth={60}
                            editCellDisplayValue="editCell.project"
                            calculateDisplayValue="editCell.project"
                        />
                        <Column
                            dataField={"PID_Remarks"}
                            caption={formatMessage("Remarks")}
                            allowEditing={true}
                            allowHiding={true}
                            cssClass="column-normalize"
                            cellRender={editCellTextArea}
                            editCellComponent={editCellTextArea}
                            minWidth={60}
                            maxWidth={200}
                        />
                        <Column
                            dataField={"PID_PurchaseOrderDocumentNo"}
                            caption={formatMessage("TransferFrom")}
                            allowEditing={false}
                            allowHiding={true}
                            visible={false}
                            cellRender={(cellData) => customSearchRenderer({ cellData, link: true, onClick: docNoLinkClick })}
                            minWidth={60}
                        ></Column>
                        <Column
                            dataField={"PID_TransferQty"}
                            caption={formatMessage("TransferedQty")}
                            allowEditing={false}
                            allowHiding={true}
                            visible={false}
                            minWidth={60}
                        />
                        <Summary>
                            <TotalItem
                                summaryType="count"
                                showInColumn="_TOTAL"
                                displayFormat={`${formatMessage("Total")}:`}
                                cssClass="summary-overflow"
                                skipEmptyValues
                            />
                            <TotalItem
                                name="totalQty"
                                summaryType="sum"
                                displayFormat="{0}"
                                column="PID_Qty"
                            />
                            <TotalItem
                                name="totalSubTotal"
                                summaryType="sum"
                                displayFormat="{0}"
                                valueFormat="##0.00"
                                column="PID_SubTotal"
                            />
                        </Summary>

                    </DataGridDefault>
                </div>
            </Col>
        </Row>
    );
});
