import { GET } from './request-options'

export const GetPurchaseCurrencyRateByCCID_EffectiveDate = (token, companyID, clientID, vendorID, effectiveDate) => {
    return fetch(`${process.env.REACT_APP_API_PURCHASE_CURRENCY_RATE_BY_DATE}companyID=${companyID}&clientID=${clientID}&vendorID=${vendorID}&effectiveDate=${effectiveDate}&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

function handleErrors(response) {
    if (!response.ok) throw new Error(response.status);
    return response;
}