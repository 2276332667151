import React, { memo, useCallback, useEffect, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom';
import { DataGrid, DropDownBox, TextBox } from 'devextreme-react';
import { Column, Paging, Selection } from 'devextreme-react/data-grid';
import { DropDownOptions, Position } from 'devextreme-react/drop-down-box';
import { cloneDeep, isEqual, set } from "lodash";
import { insertEmptyAt } from '../data-grid-util';

/*
    Note on custom property [editCellDisplayValue]
    Usage: This property is used to store displayValue (while dataField will store ID) 
    - EditCellDropdown will inject a new property (editCellDisplayValue) in data to store display value
    - Then, calculateDisplayValue will get display value from this new property (editCellDisplayValue)
    - If not assigned, export and sorting will based on dataField(ID) rather than display value

    Example:
    <Column
        ...
        dataField={"PID_WarehouseID"}
        editCellDisplayValue="editCell.warehouse"
        calculateDisplayValue="editCell.warehouse"
    />
    
    data.PID_WarehouseID=1321
    data.editCell.warehouse=KAJANG
*/
const EditCellDropdown = (props) => {
    const {
        // editCellProps
        value: selectedID,
        data,
        dataField,
        setValueCallback,
        dataGridParent,
        cellElement,
        isEditor,
        editCellDisplayValue,
        // config
        dataSource: initialDataSource,
        columns,
        keyExpr,
        displayExpr,
        // others
        portalRef,
        isEditable: initialIsEditable,
        isDisplayValueNull,
        onChange,
        addEmptySelection,
    } = props;

    const dataSource = addEmptySelection && isEditor ? insertEmptyAt(cloneDeep(initialDataSource), 'start') : initialDataSource;
    const isEditable = initialIsEditable && !!dataSource;

    const [displayValue, setDisplayValue] = useState(getInitialDisplay());
    const [selectedRowKeys, setSelectedRowKeys] = useState(getInitialSelected());
    const [dropdownOpened, setDropdownOpened] = useState(isEditor);

    function getInitialDisplay() {
        if (isDisplayValueNull || !dataSource) return null;

        const itemIndex = dataSource.findIndex(item => item[keyExpr] === selectedID);
        if (itemIndex === -1) return null;

        const displayValue = dataSource[itemIndex][displayExpr];

        if (editCellDisplayValue) {
            set(data, editCellDisplayValue, displayValue);
        }

        return displayValue;
    }

    function getInitialSelected() {
        if ((!selectedID && selectedID !== 0) || !dataSource) return [];

        const itemIndex = dataSource.findIndex(item => item[keyExpr] === selectedID);
        if (itemIndex === -1) return [];

        const selectedRowKeys = [selectedID];
        return selectedRowKeys;
    }

    const onOpened = useCallback(() => {
        // prevent focus to dropdown
        setTimeout(function () {
            dataGridParent.focus(cellElement);
        }, 0);
    }, [dataGridParent, cellElement])

    const onClosed = useCallback(() => {
        // reset focus & close editor
        dataGridParent.focus(cellElement);
        dataGridParent.closeEditCell();
    }, [dataGridParent, cellElement])

    const onOptionChanged = useCallback((event) => {
        if (event.name === 'opened') {
            setDropdownOpened(event.value);

            if (!event.value) {
                onClosed();
            }
        }
    }, [onClosed])

    const onSelectionChanged = useCallback((event) => {
        unstable_batchedUpdates(function () {
            // update selectedRowKeys
            setSelectedRowKeys(event.selectedRowKeys);

            // update data
            const displayValue = isDisplayValueNull || !event.selectedRowsData[0] ? null : event.selectedRowsData[0][displayExpr];
            setValueCallback(event.selectedRowKeys[0]);
            setDisplayValue(displayValue);
            if (editCellDisplayValue) {
                set(data, editCellDisplayValue, displayValue);
            }

            // close dropdown
            setDropdownOpened(false);
            onClosed();

            // onChange callback
            if (onChange) {
                const value = event.selectedRowKeys[0];
                const selectedObj = event.selectedRowsData[0];
                const updatedData = cloneDeep(data);
                updatedData[dataField] = event.selectedRowKeys[0];

                const obj = {
                    value,
                    selectedObj,
                    cellData: updatedData,
                }

                onChange(obj);
            }
        })
    }, [isDisplayValueNull, displayExpr, setValueCallback, onClosed, onChange, data, dataField, editCellDisplayValue])

    function columnsRender(config) {
        return (
            <Column
                key={config.dataField}
                {...config}
                allowSorting={false}
            />
        );
    }

    function fieldRender() {
        return (
            <>
                <div className='editCell-dropdown-value'>
                    <span>
                        {displayValue || ""}
                    </span>
                </div>
                <div className="editCell-dropdown-btn">
                    <div className="normalize-icon icon-dropdowneditor-icon" />
                </div>
                <TextBox stylingMode={'outlined'} style={{ display: "none" }}></TextBox>
            </>
        )
    }

    function dataGridRender() {
        return (
            <DataGrid
                dataSource={dataSource}
                hoverStateEnabled={true}
                focusStateEnabled={true}
                showColumnHeaders={true}
                showRowLines={false}
                onSelectionChanged={onSelectionChanged}
                className="editCell-dropdown-datagrid dropdown-datagrid"
                keyExpr={keyExpr}
                selectedRowKeys={selectedRowKeys}
                columnAutoWidth
            >
                <Selection mode="single" />
                <Paging enabled={false} />
                {columns.map(columnsRender)}
            </DataGrid>
        );
    }

    function displayCell() {
        return (
            <div className={`editCell editCell-dropdown ${!isEditable && 'editCell-disabled'}`}>
                <div className='editCell-dropdown-value'>
                    <span>
                        {displayValue || ""}
                    </span>
                </div>
                <div className="editCell-dropdown-btn">
                    <div className="normalize-icon icon-dropdowneditor-icon" />
                </div>
            </div>
        )
    }

    function editorCell() {
        return (
            <div className='editCell editCell-editor editCell-dropdown'>
                <DropDownBox
                    // Styling
                    stylingMode={'outlined'}
                    // States
                    opened={dropdownOpened}
                    // Data
                    dataSource={dataSource}
                    displayExpr={displayExpr}
                    valueExpr={keyExpr}
                    value={displayValue || ""}
                    // Events
                    onOptionChanged={onOptionChanged}
                    onOpened={onOpened}
                    // Others
                    contentRender={dataGridRender}
                    fieldRender={fieldRender}
                    showDropDownButton={false}
                >
                    <DropDownOptions
                        closeOnTargetScroll={false}
                        minWidth={400}
                        width={'auto'}
                        container={portalRef.current}
                    >
                        <Position
                            my="top left"
                            at="bottom left"
                            of={cellElement}
                            boundary={portalRef.current}
                            collision="flip fit"
                            offset={"8 -8"}
                        />
                    </DropDownOptions>
                </DropDownBox>
            </div>
        )
    }

    useEffect(() => {
        if (!isEditable && isEditor) {
            dataGridParent.focus(cellElement);
            dataGridParent.closeEditCell();
        }
    }, [isEditable, isEditor, dataGridParent, cellElement])

    return isEditable && isEditor ? editorCell() : displayCell();
}

function propsComparator(prevProps, nextProps) {
    let equal = true;

    for (const key in nextProps) {
        if (key === "dataSource") {
            // deep compare dataSource
            equal = isEqual(prevProps[key], nextProps[key]);
        } else {
            // shallow compare other props
            equal = prevProps[key] === nextProps[key];
        }

        if (!equal) break;
    }

    return equal;
}

export default memo(EditCellDropdown, propsComparator)