import React, { useCallback, useEffect, useRef, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom';
import { formatMessage } from 'devextreme/localization';
import { DataGridDefault } from '../..';
import { Column, Editing, Summary, TotalItem } from 'devextreme-react/data-grid';
import { DefaultPager } from '../../../utils/default-data-grid-settings';
import { DeleteBtn } from '../..';
import useFlashMsg from '../../modal-dialog/useFlashMsg';
import PopupDefault from './popup-default';
import SectionRemarksUnitCost from './features/section-remarks-unitcost';
import { FormikDefault } from '../../forms/formik-default';
import { ClientID, CompanyID, Token } from '../../../utils/default-cookies';
import { getErrorCode, setErrorPopUpMsg } from '../../../utils/error-popup-http-error-msg';
import { isEmpty } from "lodash";
import { CheckExistSerialNo, GetItemSerialStockIn } from '../../../api/purchase-invoice-details';
import EditCellTextArea from '../../data-grid/custom-cell/edit-cell-textarea';
import EditCellDropdown from '../../data-grid/custom-cell/edit-cell-dropdown';
import { checkInvalidCell } from '../../data-grid/data-grid-util';
import EditCellCalendar from '../../data-grid/custom-cell/edit-cell-calendar';
import DeletePopUp from '../delete-popup';
import SectionSerializedDetails from './features/serialized-item/section-serialized-details';
import usePopup from '../../../hooks/usePopup';
import SerialNumGeneratorPopup from './serial-num-generator-popup';
import { useErrorPopupContext } from '../../modal-dialog/ErrorPopupProvider';
import { getEditCellProps } from '../../data-grid/custom-cell/util/edit-cell-util';
import { DocumentTypeId } from "../../../utils/constant-document-type-id";
import { getInterfaceType, isCopyFromUI } from './util/item-type-popup-util';
import { useUserAccessContext } from '../../../contexts/UserAccess';

const SerializedItemPopup = (props) => {
    const {
        submitCallback,
        cancelCallback,
        iAGID,
        docType,
        userProfile,
        visible,
        onHiding,
        onHidden
    } = props;

    const {
        mainItem,
        deleteOnCancel,
        _usedSerialNumSet,
        _deletedSerialNumSet,
        hasBatchNo,
        requiredQty,
    } = props.popupData;

    const {
        PID_GroupID: groupID,
        PID_PIHID: docID,
        PID_ItemID: itemID,
        PID_ItemTypeID: itemType,
        PID_BinLocationID: binLocationID,
        PID_Remarks: remarks,
        PID_UnitPrice: unitCost,
        PID_SourceDocID: sourceDocID,
        PID_SourceDocTypeID: sourceDocTypeID,
    } = mainItem;

    const [deletePopUpVisible, setDeletePopUpVisible] = useState(false);

    const [itemColorList, setItemColorList] = useState(); // ItemColorList
    const [itemList, setItemList] = useState([]); // ItemSNList > DataGrid source
    const [defaultSelectedRowKeys, setDefaultSelectedRowKeys] = useState([]); // ItemSNList with IsChecked true

    const itemColorConfig = useRef({}); // Color Dropdown DataGrid Config
    const itemDelete = useRef(null); // To keep track of item to be deleted
    const usedSerialNumSet = useRef(_usedSerialNumSet); // To keep track of used SerialNum
    const deletedSerialNumSet = useRef(_deletedSerialNumSet); // To keep track of deleted SerialNum

    const formikRef = useRef(null);
    const dataGridRef = useRef(null);
    const portalRef = useRef(null);
    const apiLoaded = useRef(false);

    const isSubItem = useRef(mainItem.PID_SubItem_YN);
    const interfaceType = useRef(getInterfaceType(sourceDocTypeID, itemType, isSubItem.current));

    const showErrorPopup = useErrorPopupContext();
    const { userAccess } = useUserAccessContext();
    const { flashMsg, showFlashMsg } = useFlashMsg();
    const { popupProps: serialNumGeneratorProps, isInactive: isSerialNumGeneratorInactive, showPopup: showSerialNumGeneratorPopup } = usePopup({ defaultVisible: false });

    // #region Util
    function getDataGrid() {
        return dataGridRef?.current?.instance;
    }

    function sortSerialNum(array) {
        return array.sort((itemA, itemB) => ('' + (itemA?.SerialNo || itemA)).localeCompare('' + (itemB?.SerialNo || itemB)));
    }

    function showDeletePopUp() {
        setDeletePopUpVisible(true);
    }

    function hideDeletePopUp() {
        setDeletePopUpVisible(false);
    }

    function callbackDeletePopUp() {
        deleteSerialItem();
        hideDeletePopUp();
    }

    function deleteSerialItem() {
        const deletedItem = itemDelete.current;

        usedSerialNumSet.current.delete(deletedItem.SerialNo.toLowerCase());
        deletedSerialNumSet.current.add(deletedItem.SerialNo.toLowerCase());

        setItemList(itemListPrev => itemListPrev.filter(item => item.SerialNo !== deletedItem.SerialNo));
    }

    async function insertSerialItem(insertionObj) {
        function createSerialItemObj(obj) {
            const {
                SerialNo = "",
                ExpiryDate = null,
                ManufacturingDate = null,
                Color = null,
                Reference = null,
                Remarks = null,
            } = obj;

            return {
                ID: 0,
                SerialNo: SerialNo,
                ItemID: itemID,
                DocID: docID,
                BinLocationID: binLocationID,
                SequenceID: 0,
                GroupID: groupID,
                IsUsed: false,
                Color: Color,
                ColorCode: null,
                ColorDesc: null,
                ExpiryDate: ExpiryDate,
                Reference: Reference,
                Remarks: Remarks,
                ManufacturingDate: ManufacturingDate,
            }
        }

        const currentSerialNumList = itemList.map(item => item.SerialNo); // SerialNum in current popup datagrid (itemID + groupID)
        const acceptanceObj = { accepted: new Set([]), rejected: new Set([]) };
        const proxyAccepted = new Set([]); // proxy accept
        const proxyRejected = new Set([]) // proxy reject

        insertionObj.forEach(element => {
            const serialNum = element.SerialNo.toString().trim(); // removes whitespace from both sides of a string
            element.SerialNo = serialNum;
            if (serialNum.length === 0) return;

            const serialNumExist = currentSerialNumList.find((item) => item.toLowerCase() === serialNum.toLowerCase());

            if (serialNumExist) {
                // reject if SerialNum is in datagrid (proxy reject)
                proxyRejected.add(serialNumExist);
            } else if (deletedSerialNumSet.current.has(serialNum.toLowerCase())) {
                // skip checks if it's recently deleted SerialNum (proxy accepted)
                proxyAccepted.add(serialNum);
            } else if (!usedSerialNumSet.current.has(serialNum.toLowerCase())) {
                // check against unposted & used SerialNum (accepted)
                acceptanceObj.accepted.add(serialNum);
            } else {
                // check against unposted & used SerialNum (rejected)
                acceptanceObj.rejected.add(serialNum);
            }
        });

        if (!isEmpty(acceptanceObj.accepted)) {
            // check against SerialNum in the system
            const data = await fetchCheckSerialNum(acceptanceObj.accepted);
            if (!data) return; // Fetch API error encountered, abort insertion

            if (data.hasOwnProperty("NewItemSN")) {
                // update accepted from API data
                acceptanceObj.accepted = new Set([...data.NewItemSN]);
            }

            if (data.hasOwnProperty("ExistingItemSN")) {
                // update rejected from API data
                acceptanceObj.rejected = new Set([...acceptanceObj.rejected, ...data.ExistingItemSN]);
            }
        }

        // add back proxy accepted
        acceptanceObj.accepted = new Set([...acceptanceObj.accepted, ...proxyAccepted]);

        if (!isEmpty(acceptanceObj.accepted)) {
            // add serialNum for each accepted
            const insertedList = [];

            acceptanceObj.accepted.forEach(serialNum => {
                const obj = insertionObj.find((element) => element.SerialNo === serialNum);
                const newItem = createSerialItemObj(obj);

                usedSerialNumSet.current.add(serialNum.toLowerCase());
                deletedSerialNumSet.current.delete(serialNum.toLowerCase());

                insertedList.push(newItem);
            });

            setItemList((itemListPrev) => sortSerialNum([...itemListPrev, ...insertedList]));
        }

        if (!isEmpty(acceptanceObj.rejected)) {
            // shows error for rejected serialNum
            const errorMsg = {
                title: formatMessage("ErrorEncountered"),
                subTitle: `${formatMessage("FollowingSerialNumberAlreadyExist")}: ${Array.from(acceptanceObj.rejected).join(",")}`,
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle, false);
        }

        // create INSERTED list from accepted and proxyRejected (for navigation)
        const insertedList = sortSerialNum([...acceptanceObj.accepted, ...proxyRejected]);

        if (!isEmpty(insertedList)) {
            // navigate to last SerialNum of INSERTED list
            const navigateKey = insertedList.pop();

            getDataGrid()?.option('navigateToRow', navigateKey);
            getDataGrid()?.refresh(true);
        }
    }

    function selectSerialNum(serialNum) {
        if (!serialNum) return;

        const serialNumTrim = serialNum.trim();
        const currentSerialNumList = itemList.map(item => item.SerialNo);
        const serialNumExist = currentSerialNumList.find((item) => item.toLowerCase() === serialNumTrim.toLowerCase());

        if (serialNumExist) {
            const selectedRowKeys = getDataGrid()?.getSelectedRowKeys();

            // deselect or select
            if (selectedRowKeys.includes(serialNumExist)) {
                getDataGrid()?.deselectRows([serialNumExist]);
            } else {
                getDataGrid()?.selectRows([serialNumExist], true);
            }

            // navigate to row
            getDataGrid()?.option('navigateToRow', serialNumExist);
            getDataGrid()?.refresh(true);
        }
    }
    // #endregion Util

    // #region Events
    function onSerialNumGeneratorSubmit(itemList) {
        insertSerialItem(itemList);
    }

    function onSerialNumSubmit() {
        const serialNum = formikRef?.current?.values?.serialNum;
        insertSerialItem([{ SerialNo: serialNum }]);
        formikRef.current.setFieldValue("serialNum", null); // clear serialNum input
    }

    function onSelectSubmit() {
        const serialNum = formikRef?.current?.values?.serialNum;
        selectSerialNum(serialNum);
        formikRef.current.setFieldValue("serialNum", null); // clear serialNum input
    }

    function onClickDelete(item) {
        itemDelete.current = item;
        showDeletePopUp();
    }

    function onClickGenerate() {
        showSerialNumGeneratorPopup();
    }

    async function onSubmit(values, actions) {
        function checkRequiredQty(total) {
            if (!isSubItem.current) return true;
            return total === requiredQty;
        }

        await getDataGrid()?.refresh(true); // refresh data grid to update data rows & summary

        const total = getDataGrid()?.getTotalSummaryValue("_TOTAL");

        if (await checkInvalidCell(itemList)) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: "ContainInvalidCell"
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            actions.setSubmitting(false);
            return false;
        } else if (!total) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: "PleaseInsertAtLeastOneSerialNumber"
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            actions.setSubmitting(false);
            return false;
        } else if (!checkRequiredQty(total)) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: `${formatMessage("InvalidRequiredPackageQuantity")} (${requiredQty})`
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle, false);
            actions.setSubmitting(false);
            return false;
        } else {
            const itemDetail = {
                ItemColorList: itemColorList,
                ItemSNList: itemList,
                usedSerialNumSet: usedSerialNumSet.current,
                deletedSerialNumSet: deletedSerialNumSet.current,
            };

            if (hasBatchNo) {
                itemDetail.BatchNo = values.batchNo;
            }

            if (isCopyFromUI(interfaceType.current)) {
                const selectedRowKeys = getDataGrid()?.getSelectedRowKeys();

                itemList.forEach(item => {
                    if (selectedRowKeys.includes(item.SerialNo)) {
                        item.IsChecked = true;
                    } else {
                        item.IsChecked = false;
                    }
                });
            }

            const remarks = values.remarks;
            const unitCost = values.unitCost;
            const editedMainItem = { ...mainItem, PID_Remarks: remarks, PID_UnitPrice: unitCost, itemDetail };

            if (!isSubItem.current) {
                // if isSubItem, no need update PID_Qty (Serialized Item inside Package Item)        
                editedMainItem.PID_Qty = total;
            }

            submitCallback({ itemID, editedMainItem });
            actions.setSubmitting(false);
            return true;
        }
    }

    function onCancel() {
        if (deleteOnCancel) {
            cancelCallback?.(groupID);
        }
    }
    // #endregion Events

    // #region Data Util
    function initData(data) {
        function sortSerialNum(array) {
            return array.sort((itemA, itemB) => ('' + (itemA?.SerialNo || itemA)).localeCompare('' + (itemB?.SerialNo || itemB)));
        }

        function setItemColorData(itemColorList) {
            itemColorConfig.current = {
                dataSource: itemColorList,
                columns: [
                    { dataField: "IC_Code", caption: formatMessage("Code") },
                    { dataField: "IC_Description", caption: formatMessage("Description") }
                ],
                keyExpr: "IC_ID",
                displayExpr: "IC_Code",
                addEmptySelection: true,
            }
        }

        function updateDefaultSelectedRowKeys(sortedItemList) {
            const isCheckedList = [];

            sortedItemList.forEach(item => {
                if (item.IsChecked) {
                    isCheckedList.push(item.SerialNo);
                }
            });

            setDefaultSelectedRowKeys(isCheckedList);
        }

        unstable_batchedUpdates(function () {
            const { BatchNo, ItemColorList, ItemSNList } = data;
            const sortedItemList = sortSerialNum(ItemSNList);

            formikRef.current.setFieldValue("batchNo", BatchNo);

            setItemColorList(ItemColorList);
            setItemList(sortedItemList);

            setItemColorData(ItemColorList);

            if (isCopyFromUI(interfaceType.current)) {
                updateDefaultSelectedRowKeys(sortedItemList);
            }

            apiLoaded.current = true;
        })
    }
    // #endregion Data Util

    // #region Data Fetch API
    const fetchInitialData = useCallback(() => {
        if (!Token()) return;

        setTimeout(() => {
            getDataGrid()?.beginCustomLoading();
        }, 0);

        const queryParam = {
            token: Token(),
            clientID: ClientID(),
            iAGID,
            itemID,
            groupID,
            docID,
            docType,
            sourceDocID,
            sourceDocType: DocumentTypeId.getDocTypeByDocTypeId(sourceDocTypeID),
        }

        GetItemSerialStockIn(queryParam).then((data) => {
            if (typeof data === "string" && data.includes("Error")) {
                const errorCode = getErrorCode(data);
                const errorMsg = setErrorPopUpMsg(errorCode);

                showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            } else {
                initData(data);
            }
        }).finally(() => {
            getDataGrid()?.endCustomLoading();
        })
    }, [iAGID, docID, docType, groupID, itemID, sourceDocID, sourceDocTypeID, showErrorPopup]);

    const fetchCheckSerialNum = useCallback(async (serialNumSet) => {
        if (!Token()) return;

        getDataGrid()?.beginCustomLoading();
        const data = await CheckExistSerialNo(Token(), CompanyID(), 0, Array.from(serialNumSet), itemID); // docID as 0 to include current document SN
        getDataGrid()?.endCustomLoading();

        if (typeof data === "string" && data.includes("Error")) {
            const errorCode = getErrorCode(data);
            const errorMsg = setErrorPopUpMsg(errorCode);

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            return;
        } else {
            return data;
        }
    }, [itemID, showErrorPopup]);
    // #endregion Data Fetch API

    // #region UseEffect
    useEffect(() => {
        if (!mainItem.itemDetail) {
            fetchInitialData();
        } else {
            initData(mainItem?.itemDetail);
        }
    }, [mainItem, fetchInitialData]);
    // #endregion UseEffect

    // #region Render
    function deleteBtnCell(cellData) {
        const isEnabled = !cellData.data.IsUsed;

        return (
            <DeleteBtn
                {...cellData}
                onClick={onClickDelete}
                enabled={isEnabled}
            />
        );
    }

    function editCellDropdown(cellData, dataGridConfig) {
        const { info, ...editCellProps } = getEditCellProps(cellData);
        const isEditable = !isCopyFromUI(interfaceType.current);

        return (
            <EditCellDropdown
                {...editCellProps}
                {...dataGridConfig}
                portalRef={portalRef}
                isEditable={isEditable}
            />
        )
    }

    function editCellCalendar(cellData) {
        const { info, ...editCellProps } = getEditCellProps(cellData);
        const dateFormat = userProfile?.US_DateFormat;
        const isEditable = !isCopyFromUI(interfaceType.current);

        return (
            <EditCellCalendar
                {...editCellProps}
                portalRef={portalRef}
                dateFormat={dateFormat}
                isEditable={isEditable}
            />
        )
    }

    function editCellTextarea(cellData) {
        const { info, ...editCellProps } = getEditCellProps(cellData);
        const isEditable = !isCopyFromUI(interfaceType.current);

        return (
            <EditCellTextArea
                {...editCellProps}
                portalRef={portalRef}
                isEditable={isEditable}
            />
        )
    }
    // #endregion Render
    return (
        <FormikDefault
            initialValues={{
                batchNo: "",
                serialNum: "",
                remarks: remarks,
                unitCost: unitCost
            }}
            onSubmit={onSubmit}
            formikRef={formikRef}
        >
            <PopupDefault
                className="purchase-invoice-popup"
                title={"Serial Number"}  // [Translation] Update Translation
                visible={visible}
                onHiding={onHiding}
                onHidden={onHidden}
                cancelForm={onCancel}
                apiLoaded={apiLoaded.current}
                formikRef={formikRef}
            >
                <div className="portalRef dx-dropdowneditor-overlay" ref={portalRef} />

                {flashMsg}

                {
                    isSerialNumGeneratorInactive ? null :
                        <SerialNumGeneratorPopup
                            {...serialNumGeneratorProps}
                            submitCallback={onSerialNumGeneratorSubmit}
                            userProfile={userProfile}
                            itemColorConfig={itemColorConfig.current}
                        />
                }

                {
                    !isCopyFromUI(interfaceType.current) &&
                    <DeletePopUp
                        parentCallback={callbackDeletePopUp}
                        hideDeletePopup={hideDeletePopUp}
                        visible={deletePopUpVisible}
                    />
                }

                <SectionSerializedDetails
                    // Events
                    onSerialNumSubmit={onSerialNumSubmit}
                    onSelectSubmit={onSelectSubmit}
                    onClickGenerate={onClickGenerate}
                    // Others
                    disabled={!apiLoaded.current}
                    hasBatchNo={hasBatchNo}
                    interfaceType={interfaceType}
                />

                <div className="module-data-grid-popup" style={{ marginTop: "16px" }}>
                    <DataGridDefault
                        // Styling
                        className='dx-datagrid-items datagrid-normalize'
                        // DevExtreme DataGrid Configurations
                        dataSource={itemList}
                        keyExpr="SerialNo"
                        allowDisplayColumnChooser={userAccess.allowDisplayColumnChooser}
                        allowExportGrid={userAccess.allowExportGrid}
                        allowRestoreLayout={userAccess.allowRestoreLayout}
                        allowedPageSizes={DefaultPager.allowedPageSizes_details}
                        defaultPageSize={DefaultPager.defaultPageSize_details}
                        noDataText={formatMessage("dxDataGrid-noDataText")}
                        focusedRowEnabled={!isCopyFromUI(interfaceType.current)}
                        // Custom Configurations
                        dataGridRef={dataGridRef}
                        showMsgHandler={showFlashMsg}
                        customSelectionBox={isCopyFromUI(interfaceType.current)}
                        defaultSelectedRowKeys={defaultSelectedRowKeys}
                        exportFileName="SerialNumberPopup"
                    >
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                        />
                        {
                            !isCopyFromUI(interfaceType.current) &&
                            <Column
                                visibleIndex={0}
                                fixedPosition={'left'}
                                fixed={true}
                                width={50}
                                minWidth={50}
                                allowExporting={false}
                                allowFiltering={false}
                                allowHeaderFiltering={false}
                                allowHiding={false}
                                allowReordering={false}
                                allowResizing={false}
                                allowSorting={false}
                                showInColumnChooser={false}
                                cssClass="column-normalize"
                                caption={null}
                                cellRender={deleteBtnCell}
                                dataField={'_TOTAL'} // dummy dataField for summary
                            />
                        }
                        <Column
                            caption={'Serial No.'} // [Translation] Update Translation
                            dataField={'SerialNo'}
                            cssClass="column-keepSpaces"
                        />
                        <Column
                            caption={formatMessage('ExpiryDate')}
                            dataField={`ExpiryDate`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={editCellCalendar}
                            editCellComponent={editCellCalendar}
                            width={200}
                            dataType="string"
                        />
                        <Column
                            caption={'Manufacturer Date'} // [Translation] Update Translation
                            dataField={`ManufacturingDate`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={editCellCalendar}
                            editCellComponent={editCellCalendar}
                            width={200}
                            dataType="string"
                        />
                        <Column
                            caption={formatMessage('Color')}
                            dataField={`Color`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={(cellData) => editCellDropdown(cellData, itemColorConfig.current)}
                            editCellComponent={(cellData) => editCellDropdown(cellData, itemColorConfig.current)}
                            dataType="string"
                            minWidth={60}
                            editCellDisplayValue="editCell.color"
                            calculateDisplayValue="editCell.color"
                        />
                        <Column
                            caption={formatMessage('Reference')}
                            dataField={`Reference`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={editCellTextarea}
                            editCellComponent={editCellTextarea}
                            minWidth={60}
                            maxWidth={200}
                        />
                        <Column
                            caption={formatMessage('Remarks')}
                            dataField={`Remarks`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={editCellTextarea}
                            editCellComponent={editCellTextarea}
                            width={200}
                        />
                        <Summary>
                            <TotalItem
                                summaryType="count"
                                column="_TOTAL"
                                displayFormat={`${formatMessage("Total")}: {0}`}
                                cssClass="summary-overflow"
                                skipEmptyValues
                            />
                        </Summary>
                    </DataGridDefault>
                </div>

                <SectionRemarksUnitCost />

            </PopupDefault>
        </FormikDefault>
    )
}

export default SerializedItemPopup