import React, { createContext, forwardRef, useCallback, useContext, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { isEmpty, partition, groupBy } from "lodash";
import { calculateDocTotalData, calculateMainItemAndDocTotal, calculateSubItem } from '../utils/purchase/purchase-calculate-total-func';
import { unstable_batchedUpdates } from 'react-dom';
import { ItemType } from '../utils/constant-item-type';
import { formatMessage } from 'devextreme/localization';
import { useErrorPopupContext } from '../components/modal-dialog/ErrorPopupProvider';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { isNumeric } from '../utils/common-function';

const ItemState = forwardRef((props, ref) => {
    const {
        formikRef,
        isAutoGeneratedPI,
        companyRounding,
        companyTaxTypeList,
        GetItemCostByWarehouse,
        GetBinLocByWarehouseID,
        GetAccountByAccountID,
        GetItemsByImportData,
        GetItemByCodeOrPLUOrBarCode,
    } = props;

    // [State & Ref] Main Item & Sub Item
    const [mainItemArrayStore, setMainItemArrayStore] = useState();
    const [mainItemDataSource, setMainItemDataSource] = useState();
    const subItemObj = useRef({}); // Ref as it doesn't need to re-render UI

    // [State] Others
    const [isLoading, setIsLoading] = useState(true);

    // [Ref] Used/Delete SerialNum Sets
    const usedSerialNumSetList = useRef([]);
    const deletedSerialNumSetList = useRef([]);

    // [Ref] DataGrid
    const itemDataGridRef = useRef();

    // [Context] ErrorPopupContext
    const showErrorPopup = useErrorPopupContext();

    // #region DataGrid Util
    const setDataGrid = useCallback((dataGridRef) => {
        itemDataGridRef.current = dataGridRef;
    }, [])

    function getDataGrid() {
        return itemDataGridRef.current?.current?.instance;
    }

    const dataGridNavigator = useCallback((method = "navigateToLast", key = 0) => {
        switch (method) {
            case "navigateToRow":
                getDataGrid()?.option(method, key);
                break;
            case "navigateToLast":
            case "navigateToFirst":
            default:
                getDataGrid()?.option(method, true);
                break;
        }
    }, [])

    const dataGridRefresh = useCallback(async (repaintChangesOnly = false) => {
        await getDataGrid()?.refresh(repaintChangesOnly);
    }, [])
    // #endregion DataGrid Util

    // #region Util
    const partitionItemList = useCallback((itemList) => {
        function partitionFunc(item) {
            return item.PID_SubItem_YN === false;
        }

        return partition(itemList, partitionFunc);
    }, [])

    const initData = useCallback((itemList) => {
        setIsLoading(false);

        const [mainItemList, subItemList] = partitionItemList(itemList);
        subItemObj.current = groupBy(subItemList, 'PID_GroupID');

        const mainItemArrayStore = new ArrayStore({
            key: 'PID_GroupID',
            data: mainItemList,
        })

        mainItemArrayStore.insertAt = function (index, values) {
            mainItemArrayStore._array.splice(index, 0, values);
        }

        const mainItemDataSource = new DataSource({
            store: mainItemArrayStore,
        });

        setMainItemArrayStore(mainItemArrayStore);
        setMainItemDataSource(mainItemDataSource);

        return mainItemList;
    }, [partitionItemList])

    const hasItem = useCallback(() => {
        return !isEmpty(mainItemDataSource.items()); // Gets an array of data items on the current page
    }, [mainItemDataSource])

    const getMainItemData = useCallback(async () => {
        const mainItemList = await mainItemArrayStore.load().done(data => data);
        return mainItemList;
    }, [mainItemArrayStore])

    const getAllItemData = useCallback(async (updateSequenceKey = false) => {
        const mainItemList = await getMainItemData();

        for (const groupID in subItemObj.current) {
            // combine main and sub item list
            const index = mainItemList.findIndex(item => item.PID_GroupID === groupID) + 1;
            mainItemList.splice(index, 0, ...subItemObj.current[groupID]);
        }

        if (updateSequenceKey) {
            // update all sequenceKey incrementally
            mainItemList.forEach((item, index) => {
                item.PID_Seq = index + 1;
            });
        }

        return mainItemList;
    }, [getMainItemData])

    const updateMainItemListAndDocTotal = useCallback(async (mainItemList) => {
        const {
            DocDiscount: docDiscount,
            DocRound_YN: docShouldRoundAmount,
            isTaxInclusive,
            isTaxItemize,
        } = formikRef.current?.values;

        if (!mainItemList) {
            mainItemList = await getMainItemData();
        }

        // calculate document (mainItem and footer doc)
        const [updatedMainItemList, docTotalData] = calculateMainItemAndDocTotal(
            "PID",
            isTaxInclusive,
            isTaxItemize,
            companyRounding,
            mainItemList,
            docDiscount,
            docShouldRoundAmount
        );

        // calculate subItem
        for (const [groupId, subItemList] of Object.entries(subItemObj.current)) {
            const updatedMainItem = updatedMainItemList.find(mainItem => mainItem.PID_GroupID === groupId);
            if (updatedMainItem) {
                const isStockItem = updatedMainItem.PID_ItemTypeID === ItemType.STOCKITEM;
                if (isStockItem) {
                    const subItemDocDiscount = updatedMainItem.PID_ItemFooterDiscount * updatedMainItem.PID_Qty;
                    calculateSubItem("PID", isTaxInclusive, isTaxItemize, subItemList, subItemDocDiscount);
                }
            }
        }

        // update doc total
        unstable_batchedUpdates(function () {
            formikRef.current.setFieldValue("DocSubTotal", docTotalData.DocSubTotal);
            formikRef.current.setFieldValue("DocTaxableAmt", docTotalData.DocTaxableAmt);
            formikRef.current.setFieldValue("DocTax", docTotalData.DocTax);
            formikRef.current.setFieldValue("DocRoundAmt", docTotalData.DocRoundAmt);
            formikRef.current.setFieldValue("DocTotal", docTotalData.DocTotal);
        })

        // update mainItemArrayStore
        updatedMainItemList.forEach(mainItem => {
            mainItemArrayStore.update(mainItem.PID_GroupID, mainItem);
        });

        // reload mainItemDataSource
        await mainItemDataSource.reload();
    }, [formikRef, mainItemArrayStore, mainItemDataSource, companyRounding, getMainItemData])

    const updateDocDiscount = useCallback(() => {
        updateMainItemListAndDocTotal();
    }, [updateMainItemListAndDocTotal])

    const updateAllItemTax = useCallback(() => {
        updateMainItemListAndDocTotal();
    }, [updateMainItemListAndDocTotal])

    const updateDocShouldRoundAmount = useCallback(async () => {
        const {
            DocDiscount: docDiscount,
            DocRound_YN: docShouldRoundAmount,
            isTaxInclusive,
        } = formikRef.current?.values;

        const mainItemList = await getMainItemData();
        const docTotalData = calculateDocTotalData("PID", isTaxInclusive, companyRounding, mainItemList, docDiscount, docShouldRoundAmount);

        unstable_batchedUpdates(function () {
            // update doc total
            formikRef.current.setFieldValue("DocSubTotal", docTotalData.DocSubTotal);
            formikRef.current.setFieldValue("DocTaxableAmt", docTotalData.DocTaxableAmt);
            formikRef.current.setFieldValue("DocTax", docTotalData.DocTax);
            formikRef.current.setFieldValue("DocRoundAmt", docTotalData.DocRoundAmt);
            formikRef.current.setFieldValue("DocTotal", docTotalData.DocTotal);
        })
    }, [formikRef, getMainItemData, companyRounding])

    const updateMainItemByDiscFormula = useCallback((item, discountFormula) => {
        const arrFormula = discountFormula.split(/(?=\/|-|\+)/gi);
        let amt = item.PID_UnitPrice;
        let value = 0;

        for (let i = 0; i < arrFormula.length; i++) {
            if (arrFormula[i].indexOf('/') === 0) {
                value = parseFloat(arrFormula[i].replace('/', ''));
                amt = amt - (amt * value / 100);
            } else if (arrFormula[i].indexOf('+') === 0) {
                value = parseFloat(arrFormula[i].replace('+', ''));
                amt = amt + value;
            } else if (arrFormula[i].indexOf('-') === 0) {
                value = parseFloat(arrFormula[i].replace('-', ''));
                amt = amt - value;
            } else if (arrFormula.length === 1 && isNumeric(arrFormula[i])) {
                value = parseFloat(arrFormula[i]);
                amt = amt - value;
                if (value !== 0) discountFormula = "-" + discountFormula;
            }
        }

        item.PID_DiscountAmt = item.PID_UnitPrice - amt;
        item.PID_NewUnitPrice = amt - item.PID_ItemFooterDiscount;
        item.PID_DiscountFormula = discountFormula;
    }, [])

    const editMainItemByGroupID = useCallback(async (editedMainItem) => {
        const mainItemList = await getMainItemData();
        const updatedMainItemList = mainItemList.map(mainItem => {
            if (mainItem.PID_GroupID === editedMainItem.PID_GroupID) {
                return editedMainItem;
            } else {
                return mainItem;
            }
        })

        updateMainItemListAndDocTotal(updatedMainItemList);
    }, [getMainItemData, updateMainItemListAndDocTotal])

    const removeItemByGroupID = useCallback(async (groupID) => {
        if (!groupID) return;

        // delete item
        mainItemArrayStore.remove(groupID); // delete from mainItemArrayStore
        delete subItemObj.current[groupID]; // delete from subItemObj
        await mainItemDataSource.reload();

        // update sequence & docTotal
        const mainItemList = await getMainItemData();
        mainItemList.forEach((item, index) => {
            item.PID_Seq = index + 1;
        });

        updateMainItemListAndDocTotal(mainItemList);
    }, [mainItemArrayStore, mainItemDataSource, getMainItemData, updateMainItemListAndDocTotal])
    // #endregion Util

    // #region IsAlertRow
    const addAlertRow = useCallback((mainItem) => {
        const rowIndex = getDataGrid()?.getRowIndexByKey(mainItem.PID_GroupID);
        const rowElements = getDataGrid()?.getRowElement(rowIndex) || [];

        rowElements.forEach(element => {
            if (!element.classList.contains('alert-row')) {
                element.classList.add('alert-row');
            }
        });

        mainItem.IsAlertRow = true;

        // update mainItemArrayStore
        mainItemArrayStore.update(mainItem.PID_GroupID, mainItem);
    }, [mainItemArrayStore])

    const removeAlertRow = useCallback((mainItem) => {
        const rowIndex = getDataGrid()?.getRowIndexByKey(mainItem.PID_GroupID);
        const rowElements = getDataGrid()?.getRowElement(rowIndex) || [];

        rowElements.forEach(element => {
            element.classList.remove('alert-row');
        });

        mainItem.IsAlertRow = false;

        // update mainItemArrayStore
        mainItemArrayStore.update(mainItem.PID_GroupID, mainItem);
    }, [mainItemArrayStore])
    // #endregion IsAlertRow

    // #region IsInsufficientBal
    const addInsufficientBalRow = useCallback((mainItem) => {
        const rowIndex = getDataGrid()?.getRowIndexByKey(mainItem.PID_GroupID);
        const rowElements = getDataGrid()?.getRowElement(rowIndex) || [];

        rowElements.forEach(element => {
            if (!element.classList.contains('insufficient-row')) {
                element.classList.add('insufficient-row');
            }
        });

        mainItem.IsInsufficientBal = true;

        // update mainItemArrayStore
        mainItemArrayStore.update(mainItem.PID_GroupID, mainItem);
    }, [mainItemArrayStore])

    const removeInsufficientBalRow = useCallback((mainItem) => {
        const rowIndex = getDataGrid()?.getRowIndexByKey(mainItem.PID_GroupID);
        const rowElements = getDataGrid()?.getRowElement(rowIndex) || [];

        rowElements.forEach(element => {
            element.classList.remove('insufficient-row');
        });

        mainItem.IsInsufficientBal = false;

        // update mainItemArrayStore
        mainItemArrayStore.update(mainItem.PID_GroupID, mainItem);
    }, [mainItemArrayStore])
    // #endregion IsInsufficientBal

    // #region Callbacks
    const handleAddMainItem = useCallback(async (itemList, before, after) => {
        // for use by handleAddItemByCodeString, handleAddAccountByIDString, handleAddItemByImportData, handleAddItemByCopyFromData only
        if ((before || after) && hasItem()) {
            const mainItemList = await getMainItemData();
            const focusedRowGroupID = getDataGrid()?.option("focusedRowKey");
            const focusedRowIndex = mainItemList.findIndex(item => item.PID_GroupID === focusedRowGroupID);
            let index = null;

            if (after) {
                index = focusedRowIndex + 1;
            } else {
                index = focusedRowIndex;
            }

            itemList.reverse().forEach((item) => {
                mainItemArrayStore.insertAt(index, item); // insert item
            })
            await mainItemDataSource.reload(); // dataSource reload (to update dataSource after insert)
            await updateMainItemListAndDocTotal(); // update states (after reload with latest dataSource)
        } else {
            itemList.forEach((item) => {
                mainItemArrayStore.insert(item); // insert item
            })
            await mainItemDataSource.reload(); // dataSource reload (to update dataSource after insert)
            await updateMainItemListAndDocTotal(); // update states (after reload with latest dataSource)
        }
    }, [hasItem, mainItemArrayStore, mainItemDataSource, updateMainItemListAndDocTotal, getMainItemData])

    const handleAddItemByCodeString = useCallback(async (itemsCodeString, before, after) => {
        const addItemList = await GetItemByCodeOrPLUOrBarCode(itemsCodeString);
        if (isEmpty(addItemList)) return;

        const mainItemList = await getMainItemData();
        const duplicatedMainItemCodeList = [];

        const [newMainItemList, newSubItemList] = partitionItemList(addItemList);
        const newSubItemObj = groupBy(newSubItemList, 'PID_GroupID');
        const addSingleItem = newMainItemList.length === 1;

        // Remove duplicated item (for ItemType MATRIX_ITEM, PACKAGE_ITEM)
        const filteredNewMainItemList = newMainItemList.filter((newMainItem) => {
            if (newMainItem.PID_ItemTypeID === ItemType.MATRIX_ITEM || newMainItem.PID_ItemTypeID === ItemType.PACKAGE_ITEM) {
                let duplicatedItemGroupID = "";

                const duplicatedItem = mainItemList.find((mainItem) => {
                    const found = mainItem.PID_ItemID === newMainItem.PID_ItemID;
                    if (found) duplicatedItemGroupID = newMainItem.PID_GroupID;
                    return found;
                });

                if (duplicatedItem) {
                    duplicatedMainItemCodeList.push(duplicatedItem.PID_Item?.IM_Code);
                    delete newSubItemObj[duplicatedItemGroupID];
                    return false;
                }
            }

            return true;
        });

        // Add Item
        if (filteredNewMainItemList.length > 0) {
            // insert subItem
            for (const [key, value] of Object.entries(newSubItemObj)) {
                subItemObj.current[key] = value;
            }

            // navigate to row
            const navigateKey = filteredNewMainItemList[filteredNewMainItemList.length - 1]?.PID_GroupID;
            dataGridNavigator('navigateToRow', navigateKey);

            // insert mainItem
            await handleAddMainItem(filteredNewMainItemList, before, after);
        }

        // Show Error Popup
        if (duplicatedMainItemCodeList.length > 0) {
            const title = formatMessage("ErrorEncountered");
            const subTitle = `Item Cannot Be Duplicate (${duplicatedMainItemCodeList.join(", ")})`;
            showErrorPopup?.(title, subTitle, true);
        }

        // Return Item to Show ItemType Popup
        if (addSingleItem && filteredNewMainItemList[0] && filteredNewMainItemList[0].IsAlertRow) {
            return filteredNewMainItemList[0];
        } else {
            return null;
        }
    }, [GetItemByCodeOrPLUOrBarCode, handleAddMainItem, getMainItemData, partitionItemList, showErrorPopup, dataGridNavigator])

    const handleAddAccountByIDString = useCallback(async (accountsIDString, before, after) => {
        const accountList = await GetAccountByAccountID(accountsIDString);
        if (isEmpty(accountList)) return;

        // navigate to row
        const navigateKey = accountList[accountList.length - 1]?.PID_GroupID;
        dataGridNavigator('navigateToRow', navigateKey);

        // insert mainItem
        await handleAddMainItem(accountList, before, after);
    }, [GetAccountByAccountID, handleAddMainItem, dataGridNavigator])

    const handleAddItemByImportData = useCallback(async (importedData) => {
        if (!importedData.hasRequired) {
            const title = formatMessage("ErrorEncountered");
            const subTitle = `Column header “${importedData.columnHeaders}” is required for import Excel file.`;
            showErrorPopup?.(title, subTitle, true);
            return;
        }

        const importedItemList = await GetItemsByImportData(importedData.data);
        if (isEmpty(importedItemList)) return;

        const mainItemList = await getMainItemData();
        const duplicatedMainItemCodeList = [];

        const [newMainItemList, newSubItemList] = partitionItemList(importedItemList);
        const newSubItemObj = groupBy(newSubItemList, 'PID_GroupID');

        // Remove duplicated item (for ItemType MATRIX_ITEM, PACKAGE_ITEM)
        const filteredNewMainItemList = newMainItemList.filter((newMainItem) => {
            if (newMainItem.PID_ItemTypeID === ItemType.MATRIX_ITEM || newMainItem.PID_ItemTypeID === ItemType.PACKAGE_ITEM) {
                let duplicatedItemGroupID = "";

                const duplicatedItem = mainItemList.find((mainItem) => {
                    const found = mainItem.PID_ItemID === newMainItem.PID_ItemID;
                    if (found) duplicatedItemGroupID = newMainItem.PID_GroupID;
                    return found;
                });

                if (duplicatedItem) {
                    duplicatedMainItemCodeList.push(duplicatedItem.PID_Item?.IM_Code);
                    delete newSubItemObj[duplicatedItemGroupID];
                    return false;
                }
            }

            return true;
        });

        // Add Item
        if (filteredNewMainItemList.length > 0) {
            // insert subItem
            for (const [key, value] of Object.entries(newSubItemObj)) {
                subItemObj.current[key] = value;
            }

            // navigate to last
            dataGridNavigator('navigateToLast');

            // insert mainItem
            await handleAddMainItem(filteredNewMainItemList);
        }

        // Show Error Popup
        if (duplicatedMainItemCodeList.length > 0) {
            const title = formatMessage("ErrorEncountered");
            const subTitle = `Item Cannot Be Duplicate (${duplicatedMainItemCodeList.join(", ")})`;
            showErrorPopup?.(title, subTitle, true);
        }
    }, [GetItemsByImportData, dataGridNavigator, getMainItemData, partitionItemList, showErrorPopup, handleAddMainItem])

    const handleAddItemByCopyFromData = useCallback(async (copyFromItemList) => {
        if (!isEmpty(copyFromItemList)) {
            const mainItemList = await getMainItemData();
            const mainItemGroupIDList = mainItemList.map(mainItem => mainItem.PID_GroupID);

            const filteredNewItemList = copyFromItemList.filter((item) => !mainItemGroupIDList.includes(item.PID_GroupID));
            const [newMainItemList, newSubItemList] = partitionItemList(filteredNewItemList);

            if (newMainItemList.length > 0) {
                const newSubItemObj = groupBy(newSubItemList, 'PID_GroupID');

                // insert subItem
                for (const [key, value] of Object.entries(newSubItemObj)) {
                    subItemObj.current[key] = value;
                }

                // navigate to last
                dataGridNavigator('navigateToLast');

                // insert mainItem
                await handleAddMainItem(newMainItemList);
            }
        }
    }, [dataGridNavigator, partitionItemList, getMainItemData, handleAddMainItem])

    const handleWarehouseChange = useCallback(async (warehouseID, projectID = null, costCentreID = null) => {
        if (isAutoGeneratedPI) return;

        const mainItemList = await getMainItemData();

        const itemID = mainItemList.reduce((prev, item) => prev + item.PID_ItemID + ",", "");
        const itemUOMID = mainItemList.reduce((prev, item) => prev + item.PID_ItemUOMID + ",", "");
        const itemObj = { itemID, itemUOMID };

        const [
            itemCost,
            binLocation
        ] = await Promise.all([
            GetItemCostByWarehouse(warehouseID, itemObj),
            GetBinLocByWarehouseID(warehouseID)
        ]);

        mainItemList.forEach(mainItem => {
            const subItemList = subItemObj.current[mainItem.PID_GroupID];

            // exclude item type = ACCOUNT_ITEM
            if (mainItem.PID_ItemTypeID === ItemType.ACCOUNT_ITEM) return;

            if (mainItem.PID_TaxTypeID) {
                // clear PID_TaxTypeID (selected company tax type) if not found in companyTaxTypeList array (CTT_ID)
                const companyTaxTypeExist = companyTaxTypeList.find(function (taxType) { return taxType.CTT_ID === mainItem.PID_TaxTypeID });

                if (!companyTaxTypeExist) {
                    mainItem.PID_TaxTypeID = null;
                    mainItem.PID_TaxRate = 0;
                    subItemList?.forEach(subItem => {
                        subItem.PID_TaxTypeID = null;
                        subItem.PID_TaxRate = 0;
                    });
                }
            }

            if (mainItem.PID_ItemTypeID !== ItemType.PACKAGE_ITEM) {
                // set all items unit cost, new unit price, subtotal, discount formula, discount amt (EXCEPT package item)
                const itemCostObj = itemCost.find(function (element) {
                    return element.IM_ID === mainItem.PID_ItemID
                        && element.UOM_ID === mainItem.PID_ItemUOMID
                });

                mainItem.PID_UnitPrice = itemCostObj?.Cost || 0; // UnitPrice = (objUnitPrice.IW_AvarageCost);
                mainItem.PID_NewUnitPrice = itemCostObj?.Cost || 0; // NewUnitPrice = (objUnitPrice.IW_AvarageCost);
                mainItem.PID_SubTotal = itemCostObj?.Cost || 0 * mainItem.PID_Qty; // SubTotal = (objUnitPrice.IW_AvarageCost) * (qty);
                mainItem.PID_DiscountFormula = null; // DiscountFormula = "";
                mainItem.PID_DiscountAmt = 0; // DiscountAmt = (decimal?)null;
                subItemList?.forEach(subItem => {
                    subItem.PID_UnitPrice = itemCostObj?.Cost || 0; // UnitPrice = (objUnitPrice.IW_AvarageCost);
                    subItem.PID_NewUnitPrice = itemCostObj?.Cost || 0; // NewUnitPrice = (objUnitPrice.IW_AvarageCost);
                    subItem.PID_SubTotal = itemCostObj?.Cost || 0 * subItem.PID_Qty; // SubTotal = (objUnitPrice.IW_AvarageCost) * (qty);
                    subItem.PID_DiscountFormula = null; // DiscountFormula = "";
                    subItem.PID_DiscountAmt = 0; // DiscountAmt = (decimal?)null;
                });
            }

            // update all items project & cost centre
            mainItem.PID_ProjectID = projectID;
            mainItem.PID_CostCentreID = costCentreID;
            subItemList?.forEach(subItem => {
                subItem.PID_ProjectID = projectID;
                subItem.PID_CostCentreID = costCentreID;
            });

            // if not copy from
            if (!mainItem.IsCopyRow) {
                // set all items bin location & warehouse
                mainItem.BinLoc = binLocation;
                mainItem.PID_BinLocationID = binLocation[0].ID;
                mainItem.PID_WarehouseID = warehouseID;
                subItemList?.forEach(subItem => {
                    subItem.BinLoc = binLocation;
                    subItem.PID_BinLocationID = binLocation[0].ID;
                    subItem.PID_WarehouseID = warehouseID;
                });

                // if stock item with multibin, change alert row and delete all sub items
                if (mainItem.PID_ItemTypeID === ItemType.STOCKITEM) {
                    const isMultiBinStockItem = subItemList?.length > 1;

                    if (isMultiBinStockItem) {
                        subItemList.splice(0, subItemList.length);
                        delete subItemObj.current[mainItem.PID_GroupID];
                        addAlertRow(mainItem);
                    }

                    mainItem.itemDetail = null;
                }
            }
        });

        // update state
        updateMainItemListAndDocTotal(mainItemList);
    }, [GetBinLocByWarehouseID, GetItemCostByWarehouse, addAlertRow, companyTaxTypeList, isAutoGeneratedPI, getMainItemData, updateMainItemListAndDocTotal])

    const handleCompanyTaxTypeListChange = useCallback(async (companyTaxTypeList) => {
        const mainItemList = await getMainItemData();
        let hasChanges = false;

        mainItemList.forEach(mainItem => {
            const subItemList = subItemObj.current[mainItem.PID_GroupID];

            // exclude item type = ACCOUNT_ITEM
            if (mainItem.PID_ItemTypeID === ItemType.ACCOUNT_ITEM) return;

            if (mainItem.PID_TaxTypeID) {
                // clear PID_TaxTypeID (selected company tax type) if not found in companyTaxTypeList array (CTT_ID)
                const companyTaxTypeExist = companyTaxTypeList.find(function (taxType) { return taxType.CTT_ID === mainItem.PID_TaxTypeID });

                if (!companyTaxTypeExist) {
                    mainItem.PID_TaxTypeID = null;
                    mainItem.PID_TaxRate = 0;
                    subItemList?.forEach(subItem => {
                        subItem.PID_TaxTypeID = null;
                        subItem.PID_TaxRate = 0;
                    });

                    hasChanges = true;
                }
            }
        });

        // update state
        if (hasChanges) {
            updateMainItemListAndDocTotal(mainItemList);
        }
    }, [getMainItemData, updateMainItemListAndDocTotal])

    const handleCurrencyRateChange = useCallback(async (currencyRate) => {
        const mainItemList = await getMainItemData();
        let hasChanges = false;

        // update mainItemArrayStore PID_CurrencyRate
        mainItemList.forEach(mainItem => {
            const subItemList = subItemObj.current[mainItem.PID_GroupID];

            if (mainItem.PID_CurrencyRate !== currencyRate) {
                mainItem.PID_CurrencyRate = currencyRate;
                subItemList?.forEach(subItem => {
                    subItem.PID_CurrencyRate = currencyRate;
                });

                hasChanges = true;
            }
        });

        // update state
        if (hasChanges) {
            updateMainItemListAndDocTotal(mainItemList);
        }
    }, [getMainItemData, updateMainItemListAndDocTotal])

    const handleCellChange = useCallback(async (editedMainItem) => {
        const {
            DocDiscount: docDiscount,
            DocRound_YN: docShouldRoundAmount,
            isTaxInclusive,
            isTaxItemize,
        } = formikRef.current?.values;

        const mainItemList = await getMainItemData();
        const editedMainItemList = mainItemList.map(mainItem => {
            if (mainItem.PID_GroupID === editedMainItem.PID_GroupID) {
                return editedMainItem;
            } else {
                return mainItem;
            }
        })

        // calculate document (mainItem and doc total)
        const [updatedMainItemList, docTotalData] = calculateMainItemAndDocTotal(
            "PID",
            isTaxInclusive,
            isTaxItemize,
            companyRounding,
            editedMainItemList,
            docDiscount,
            docShouldRoundAmount
        );

        // calculate subItem (STOCK ITEM only)
        const isStockItem = editedMainItem.PID_ItemTypeID === ItemType.STOCKITEM;
        if (isStockItem) {
            const subItemList = subItemObj.current[editedMainItem.PID_GroupID] || [];
            const updatedMainItem = updatedMainItemList.find(mainItem => mainItem.PID_GroupID === editedMainItem.PID_GroupID);
            if (updatedMainItem) {
                const subItemDocDiscount = updatedMainItem.PID_ItemFooterDiscount * updatedMainItem.PID_Qty;
                calculateSubItem("PID", isTaxInclusive, isTaxItemize, subItemList, subItemDocDiscount);
            }
        }

        // update doc total
        unstable_batchedUpdates(function () {
            formikRef.current.setFieldValue("DocSubTotal", docTotalData.DocSubTotal);
            formikRef.current.setFieldValue("DocTaxableAmt", docTotalData.DocTaxableAmt);
            formikRef.current.setFieldValue("DocTax", docTotalData.DocTax);
            formikRef.current.setFieldValue("DocRoundAmt", docTotalData.DocRoundAmt);
            formikRef.current.setFieldValue("DocTotal", docTotalData.DocTotal);
        })

        // update mainItemArrayStore
        updatedMainItemList.forEach(mainItem => {
            mainItemArrayStore.update(mainItem.PID_GroupID, mainItem);
        });

        // no need reload dataSource
    }, [companyRounding, formikRef, getMainItemData, mainItemArrayStore])
    // #endregion Callbacks

    // [Context]
    const contextValue = useMemo(() => {
        return {
            // [DataGrid]
            setDataGrid,
            dataGridNavigator,
            dataGridRefresh,
            // [State & Ref]
            mainItemDataSource,
            mainItemArrayStore,
            subItemObj,
            isLoading,
            usedSerialNumSetList,
            deletedSerialNumSetList,
            // [Functions]
            initData,
            partitionItemList,
            hasItem,
            getMainItemData,
            getAllItemData,
            updateMainItemListAndDocTotal,
            updateDocDiscount,
            updateDocShouldRoundAmount,
            updateAllItemTax,
            updateMainItemByDiscFormula,
            editMainItemByGroupID,
            removeItemByGroupID,
            // [Callbacks]
            handleAddItemByCodeString,
            handleAddAccountByIDString,
            handleAddItemByImportData,
            handleAddItemByCopyFromData,
            handleWarehouseChange,
            handleCompanyTaxTypeListChange,
            handleCurrencyRateChange,
            handleCellChange,
            // [IsAlertRow]
            addAlertRow,
            removeAlertRow,
            // [IsInsufficientBal]
            addInsufficientBalRow,
            removeInsufficientBalRow,
        };
    }, [
        setDataGrid,
        dataGridNavigator,
        dataGridRefresh,
        mainItemDataSource,
        mainItemArrayStore,
        subItemObj,
        isLoading,
        usedSerialNumSetList,
        deletedSerialNumSetList,
        initData,
        partitionItemList,
        hasItem,
        getMainItemData,
        getAllItemData,
        updateMainItemListAndDocTotal,
        updateDocDiscount,
        updateDocShouldRoundAmount,
        updateAllItemTax,
        updateMainItemByDiscFormula,
        editMainItemByGroupID,
        removeItemByGroupID,
        handleAddItemByCodeString,
        handleAddAccountByIDString,
        handleAddItemByImportData,
        handleAddItemByCopyFromData,
        handleWarehouseChange,
        handleCompanyTaxTypeListChange,
        handleCurrencyRateChange,
        handleCellChange,
        addAlertRow,
        removeAlertRow,
        addInsufficientBalRow,
        removeInsufficientBalRow,
    ])

    // [useImperativeHandle]
    useImperativeHandle(ref, () => (contextValue));

    return (
        <ItemStateContext.Provider value={contextValue} {...props} />
    );
});

const ItemStateContext = createContext({});
const useItemStateContext = () => useContext(ItemStateContext);

export { ItemState, useItemStateContext }