import { Column, Lookup } from 'devextreme-react/tree-list'
import { formatMessage } from 'devextreme/localization';
import * as React from 'react';
import { useUserAccessContext } from '../../../../contexts/UserAccess';
import TreeListDefault from '../../../tree-list/tree-list-default';

const cellNumber = (cellData) => {
    const isParent = cellData.row.data.TLparent === "0"
    return <span style={{ fontWeight: isParent ? 'bold' : "inherit" }}>{cellData.text}</span>
}

const cellLead = (cellData) => {
    const isParent = cellData.row.data.TLparent === "0"
    if (!isParent) {
        return null
    }
    return cellData.text
}

export default function StockBalanceTable(props) {
    const {
        dataSource,
    } = props;

    const treeListRef = React.useRef(null);
    const { userAccess } = useUserAccessContext();

    return (
        <div className="module-data-grid-popup">
            <TreeListDefault
                // Styling
                className='dx-datagrid-items'
                // DevExtreme TreeList Configurations
                dataSource={dataSource || []}
                autoExpandAll={false}
                keyExpr="TLchild"
                parentIdExpr="TLparent"
                allowDisplayColumnChooser={userAccess.allowDisplayColumnChooser}
                allowExportGrid={userAccess.allowExportGrid}
                allowRestoreLayout={userAccess.allowRestoreLayout}
                // allowedPageSizes={DefaultPager.allowedPageSizes_details}
                // defaultPageSize={DefaultPager.defaultPageSize_details}
                noDataText={formatMessage("dxDataGrid-noDataText")}
                focusedRowEnabled
                // Custom Configurations
                treeListRef={treeListRef}
            >
                <Column
                    caption={formatMessage('Warehouse')}
                    dataField="Warehouse"
                    allowHiding={false}
                    cellRender={cellLead}
                />
                <Column
                    caption={formatMessage('BinLocation')}
                    dataField="BinLocation"
                    allowHiding={false}
                >
                    <Lookup
                        dataSource={dataSource || []}
                        valueExpr="BinLocation"
                        displayExpr="BinLocation"
                    />
                </Column>
                <Column
                    caption={formatMessage('ReorderPoint')}
                    dataField="ReorderPoint"
                    allowHiding={false}
                />
                <Column
                    caption={formatMessage('QtyonHand')}
                    dataField="QtyOnHand"
                    allowHiding={false}
                    cellRender={cellNumber}
                >
                    <Lookup
                        dataSource={dataSource || []}
                        valueExpr="QtyOnHand"
                        displayExpr="QtyOnHand"

                    />
                </Column>
                <Column
                    caption={formatMessage('QtyReserved')}
                    dataField="QtyReserved"
                    allowHiding={false}
                    cellRender={cellNumber}
                >
                    <Lookup
                        dataSource={dataSource || []}
                        valueExpr="QtyReserved"
                        displayExpr="QtyReserved"
                    />
                </Column>
                <Column
                    caption={formatMessage('QtyAvailable')}
                    dataField="QtyAvailable"
                    allowHiding={false}
                    cellRender={cellNumber}
                >
                    <Lookup
                        dataSource={dataSource || []}
                        valueExpr="QtyAvailable"
                        displayExpr="QtyAvailable"
                    />
                </Column>
                <Column
                    caption={formatMessage('QtyOrdered')}
                    dataField="QtyOrdered"
                    allowHiding={false}
                    cellRender={cellNumber}
                >
                    <Lookup
                        dataSource={dataSource || []}
                        valueExpr="QtyOrdered"
                        displayExpr="QtyOrdered"
                    />
                </Column>
            </TreeListDefault>
        </div>
    )
}