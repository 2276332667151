import React from "react";
import TagBox from 'devextreme-react/tag-box';

export const ListingHeaderTagBox = (props) => {

    const _onValueChanged = (value) => {
        props.parentCallback(value)
    }

    //const _onSelectionChanged = (e) => {
    //    //get value in array and turn into string 
    //    const value = e.component.option("value");
    //}

    return (
        <>
            <div className={props.textField}>
                <div className="dx-field">
                    <div className="dx-field-label">
                        <span style={{ color: "red" }}>* </span>
                        <span>{props.caption}</span>
                        {props.defaultValue.length === 0 && <span className="required-field">Required</span>}
                    </div>
                </div>
            </div>

            <div className={props.inputField}>
                <div className="dx-field">
                    <div className="dx-field-value">
                        <TagBox
                            valueExpr={props.valueExpr}
                            dataSource={props.dataSource}
                            displayExpr={props.displayExpr}
                            defaultValue={props.defaultValue}
                            value={props.value}
                            placeholder="Select a value"
                            className="tagBox filter-drop-down"
                            stylingMode={'outlined'}
                            showSelectionControls={true}
                            showMultiTagOnly={true}
                            showDropDownButton={true}
                            searchEnabled={true}
                            onValueChange={_onValueChanged}
                            maxDisplayedTags={3}>
                        </TagBox>
                    </div>
                </div>
            </div>
        </>
    )
}
