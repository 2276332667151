import React, { forwardRef, useCallback, useState, useImperativeHandle, useRef, useEffect } from 'react';
import Form from 'devextreme-react/form';
import { Item } from 'devextreme-react/select-box';
import TextBox1 from '../../../components/text-box/text-box-1';
import TextBox4 from '../../../components/text-box/text-box-4';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import './listing-header.scss';
import NumberBox from 'devextreme-react/number-box';
import {computePackageItemPriceRatio, computePackageItemTotalPrice, computePackageSubItemPrice, calculateFooterPrice} from '../../../pages/inventory-setting/package-item/package-item-services';
import Singleselectiondropdown from '../../../components/dropdown-list/single-selection-dropdown';
//import Alert from '@material-ui/lab/Alert';
import { Alert, AlertTitle } from '@mui/material';
import Collapse from '@material-ui/core/Collapse';
import { ClearIcon } from '../../../components/message/flash';
import ScrollView from 'devextreme-react/scroll-view';

//language
import { formatMessage } from 'devextreme/localization';
import { DecimalPoints } from '../../../utils/default-cookies';
import { mousetrapMain } from '../../../App';

const ListingHeaderPackageItemDetails =  
    forwardRef((props, ref) => {

    const searchBox = React.createRef(); 

    const numBoxPrice1Ref = useRef(null);
    const numBoxPrice2Ref = useRef(null);
    const numBoxPrice3Ref = useRef(null);
    const numBoxPrice4Ref = useRef(null);
    const numBoxPrice5Ref = useRef(null);
    const numBoxPrice6Ref = useRef(null);
    const numBoxPurchasePriceRef = useRef(null);
    const cancelBtnRef = useRef(null);
    const applyBtnRef  = useRef(null);

    const [searchValue, setSearchValue] = useState('');

    const onTbvalueChange = useCallback((value) => {
        setSearchValue(value);
        props.onSearchValueChange(value)
    }, [props.onSearchValueChange]);

    const [state, setState] = useState({
        currentItemPrice: {},
        autoCalPricePopUpVisible: false
    });

    const [newItemPrice, setNewItemPrice] = useState({
        totalPrice1: null,
        totalPrice2: null,        
        totalPrice3: null,
        totalPrice4: null,
        totalPrice5: null,
        totalPrice6: null,
        totalPurchasePrice: null
    });
    
    const[priceColor, setPriceColor] = useState({
        price1: '',
        price2: '',        
        price3: '',
        price4: '',
        price5: '',
        price6: '',
        totalPrice: ''
    })

    const[priceErrorMsg, setPriceErrorMsg] = useState({
        price1: '',
        price2: '',        
        price3: '',
        price4: '',
        price5: '',
        price6: '',
        totalPrice: ''
    })

    const[pricePointer, setPricePointer] = useState({
        price1Event: 'auto',
        price2Event: 'auto',        
        price3Event: 'auto',
        price4Event: 'auto',
        price5Event: 'auto',
        price6Event: 'auto',
        totalPriceEvent: 'auto'
    });
    
    const[priceDisabled, setPriceDisabled] = useState({
        price1Disabled: false,
        price2Disabled: false,        
        price3Disabled: false,
        price4Disabled: false,
        price5Disabled: false,
        price6Disabled: false,
        totalPriceDisabled: false
    });

    var packageItemList = props.packageItemList;
    const [currentPackageItemList, setCurrentPackageItemList] = useState({packageItemList});
    const [showValidationMsg, setShowValidationMsg] = useState(false);
    const [validationPriceMsg, setValidationPriceMsg] = useState('');
    const [isItemPrice1Valid, setIsItemPrice1Valid] = useState(true);
    const [isItemPrice2Valid, setIsItemPrice2Valid] = useState(true);
    const [isItemPrice3Valid, setIsItemPrice3Valid] = useState(true);
    const [isItemPrice4Valid, setIsItemPrice4Valid] = useState(true);
    const [isItemPrice5Valid, setIsItemPrice5Valid] = useState(true);
    const [isItemPrice6Valid, setIsItemPrice6Valid] = useState(true);
    const [isItemPurchasePriceValid, setIsItemPurchasePriceValid] = useState(true);

    const[newComputePrice, setNewComputePrice] = useState({
        newPackageItemPriceRatio : {},
        newPackageItemList : {},
        newTotalPackage : {}
    })

    useImperativeHandle(ref, () => ({
        setCurrentPackageItemList(packageItemList) {
            setCurrentPackageItemList({
                packageItemList
            });
        },

        clearText() {
            searchBox.current?.clear();
        },

        setFocus() {
            searchBox.current?.setFocus();
        }
    }));

    const validationMsg = {
        isnotallowedtobeALLzeroorempty: ' is not allowed to be ALL zero or empty.',
        systemgeneratedpriceisnotsameasyouritemprice : formatMessage('SystemcantfullyallocateTotalPricetoitembaseonpriceratio'),
        totalitempriceonratio : formatMessage('Totalitempriceonratio'),
    }

    function itemPriceValueChanged(e){

        //const previousValue = e.previousValue;
        const newValue = e.value;
        // Event handling commands go here
        setNewItemPrice({ 
            ...newItemPrice,
            [e.element.id]: newValue
        });

        if(e.element.id === 'totalPrice1' && numBoxPrice2Ref.current != null && pricePointer.price2Event !== 'none'){
          numBoxPrice2Ref.current.instance.focus();
        }

        if(e.element.id === 'totalPrice2' && numBoxPrice3Ref.current != null && pricePointer.price3Event !== 'none'){
            numBoxPrice3Ref.current.instance.focus();
        }

        if(e.element.id === 'totalPrice3' && numBoxPrice4Ref.current != null && pricePointer.price4Event !== 'none'){
            numBoxPrice4Ref.current.instance.focus();
        }

        if(e.element.id === 'totalPrice4' && numBoxPrice5Ref.current != null && pricePointer.price5Event !== 'none'){
            numBoxPrice5Ref.current.instance.focus();
        }

        if(e.element.id === 'totalPrice5' && numBoxPrice6Ref.current != null && pricePointer.price6Event !== 'none'){
            numBoxPrice6Ref.current.instance.focus();
        }

        if(e.element.id === 'totalPrice6' && numBoxPurchasePriceRef.current != null && pricePointer.totalPriceEvent !== 'none'){
            numBoxPurchasePriceRef.current.instance.focus();
        }

        if(e.element.id === 'totalPurchasePrice' && numBoxPurchasePriceRef.current != null){
            applyBtnRef.current.instance.focus();
        }
    }

    const setZeroErrorMsg = (price1, price2, price3, price4, price5, price6, totalPrice) => {
        //price1
        if(price1 == 0) {
            setPriceColor(prev => ({...prev, price1:'red'}))
            setPricePointer(prev => ({...prev, price1Event:'none'}))
            setPriceDisabled(prev => ({...prev, price1Disabled:true}))
        }

        if(price1 != 0) {
            setPriceColor(prev => ({...prev, price1:'#E8EAED'}))
            setPricePointer(prev => ({...prev, price1Event:'auto'}))
            setPriceDisabled(prev => ({...prev, price1Disabled:false}))
        }
            
        //price2
        if(price2 == 0) {
            setPriceColor(prev => ({...prev, price2:'red'}))
            setPricePointer(prev => ({...prev, price2Event:"none"}))
            setPriceDisabled(prev => ({...prev, price2Disabled:true}))
        }

        if(price2 != 0) {
            setPriceColor(prev => ({...prev, price2:'#E8EAED'}))
            setPricePointer(prev => ({...prev, price2Event:'auto'}))
            setPriceDisabled(prev => ({...prev, price2Disabled:false}))
        }
        
        //price3
        if(price3 == 0) {
            setPriceColor(prev => ({...prev, price3:'red'}))
            setPricePointer(prev => ({...prev, price3Event:'none'}))
            setPriceDisabled(prev => ({...prev, price3Disabled:true}))
        }

        if(price3 != 0) {
            setPriceColor(prev => ({...prev, price3:'#E8EAED'}))
            setPricePointer(prev => ({...prev, price3Event:'auto'}))
            setPriceDisabled(prev => ({...prev, price3Disabled:false}))
        }

        //price4
        if(price4 == 0) {
            setPriceColor(prev => ({...prev, price4:'red'}))
            setPricePointer(prev => ({...prev, price4Event:'none'}))
            setPriceDisabled(prev => ({...prev, price4Disabled:true}))
        }
        
        if(price4 != 0) {
            setPriceColor(prev => ({...prev, price4:'#E8EAED'}))
            setPricePointer(prev => ({...prev, price4Event:'auto'}))
            setPriceDisabled(prev => ({...prev, price4Disabled:false}))
        }

        //price5
        if(price5 == 0) {
            setPriceColor(prev => ({...prev, price5:'red'}))
            setPricePointer(prev => ({...prev, price5Event:'none'}))
            setPriceDisabled(prev => ({...prev, price5Disabled:true}))
        }

        if(price5 != 0) {
            setPriceColor(prev => ({...prev, price5:'#E8EAED'}))
            setPricePointer(prev => ({...prev, price5Event:'auto'}))
            setPriceDisabled(prev => ({...prev, price5Disabled:false}))
        }
        
        //price6
        if(price6 == 0) {
            setPriceColor(prev => ({...prev, price6:'red'}))   
            setPricePointer(prev => ({...prev, price6Event:'none'})) 
            setPriceDisabled(prev => ({...prev, price6Disabled:true}))
        }

        if(price6 != 0) {
            setPriceColor(prev => ({...prev, price6:'#E8EAED'}))
            setPricePointer(prev => ({...prev, price6Event:'auto'}))
            setPriceDisabled(prev => ({...prev, price6Disabled:false}))
        }

        //total price
        if(totalPrice == 0) {
            setPriceColor(prev => ({...prev, totalPrice:'red'}))    
            setPricePointer(prev => ({...prev, totalPriceEvent:'none'})) 
            setPriceDisabled(prev => ({...prev, totalPriceDisabled:true}))
        }
            
        if(totalPrice != 0) {
            setPriceColor(prev => ({...prev, totalPrice:'#E8EAED'}))   
            setPricePointer(prev => ({...prev, totalPriceEvent:'auto'})) 
            setPriceDisabled(prev => ({...prev, totalPriceDisabled:false}))
        }
    }

    const onCalBtnClick = () => {

        var packageItemList = calculateFooterPrice(currentPackageItemList.packageItemList);
        var displayAutoCal = true;

        setZeroErrorMsg(packageItemList.totalPrice1, packageItemList.totalPrice2,
            packageItemList.totalPrice3, packageItemList.totalPrice4, packageItemList.totalPrice5,
            packageItemList.totalPrice6, packageItemList.totalPurchasePrice)

        if (packageItemList.totalPrice1 === 0 &&
            packageItemList.totalPrice2 === 0 &&
            packageItemList.totalPrice3 === 0 &&
            packageItemList.totalPrice4 === 0 &&
            packageItemList.totalPrice5 === 0 &&
            packageItemList.totalPrice6 === 0 &&
            packageItemList.totalPurchasePrice === 0) {
        
            displayAutoCal = false;            
        }

        if(displayAutoCal === false){
            props.showErrorMsg(formatMessage('PriceisnotAllowedtobeAllZeroorEmpty'), 'error', 'flash-message-error-container');        
        }
        else{
            props.showErrorMsg('', 'error', 'flash-message-error-container');
            showAutoCalPrice();
        }
    }

    function openItemPopUp(){
        props.openItemPopUp();
    }

    const onApplyBtnClick = () => {

        //check is system generated price has rounding issue
        if(validationPriceMsg === validationMsg.systemgeneratedpriceisnotsameasyouritemprice && showValidationMsg) {
            setShowValidationMsg(false);
            hideAutoCalPrice();
            //update data grid footer and child package item price
            props.packageItemPriceChange(newItemPrice, newComputePrice.newTotalPackage, newComputePrice.newPackageItemList, newComputePrice.newPackageItemPriceRatio);
        }
        else {
            validateSystemGeneratedPrice();
        }
    }

    onkeydown = (e) => {
        if(e.code === "Delete" && state.autoCalPricePopUpVisible == true) {
            props.ondisableDeletePopUp();
        }
    }
    
    const onCancelBtnClick = () => {
        setNewItemPrice(previousValue => ({
            ...previousValue
        }));
        hideAutoCalPrice();
    }

    function hideAutoCalPrice(){
        setState({
          currentItemPrice: newItemPrice,
          autoCalPricePopUpVisible: false
        });
    }

    function onPopUpShowing(){
        mousetrapMain.pause();

        if(validationPriceMsg === validationMsg.systemgeneratedpriceisnotsameasyouritemprice && !showValidationMsg) {
            setIsItemPrice1Valid(true);
            setIsItemPrice2Valid(true);
            setIsItemPrice3Valid(true);
            setIsItemPrice4Valid(true);
            setIsItemPrice5Valid(true);
            setIsItemPrice6Valid(true);
            setIsItemPurchasePriceValid(true);
        }
    }

    function onShown(e) {
        mousetrapMain.pause();

        e.component.focus();
        if(numBoxPrice1Ref.current != null && pricePointer.price1Event !== 'none'){
            numBoxPrice1Ref.current.instance.focus();
        }
        else if(numBoxPrice2Ref.current != null && pricePointer.price2Event !== 'none'){
            numBoxPrice2Ref.current.instance.focus();
        }
        else if(numBoxPrice3Ref.current != null && pricePointer.price3Event !== 'none'){
            numBoxPrice3Ref.current.instance.focus();
        }
        else if(numBoxPrice4Ref.current != null && pricePointer.price4Event !== 'none'){
            numBoxPrice4Ref.current.instance.focus();
        }
        else if(numBoxPrice5Ref.current != null && pricePointer.price5Event !== 'none'){
            numBoxPrice5Ref.current.instance.focus();
        }
        else if(numBoxPrice6Ref.current != null && pricePointer.price6Event !== 'none'){
            numBoxPrice6Ref.current.instance.focus();
        }
        else if(numBoxPurchasePriceRef.current != null && pricePointer.totalPriceEvent !== 'none'){
            numBoxPurchasePriceRef.current.instance.focus();
        }
    }

    function onHidden() {
        mousetrapMain.unpause();
    }

    function showAutoCalPrice(){

        setState(prevState => ({
          currentItemPrice:prevState.currentItemPrice,
          autoCalPricePopUpVisible:true
        }));

        // intialize number box every time pop up open
        setNewItemPrice({
            totalPrice1: null,
            totalPrice2: null,        
            totalPrice3: null,
            totalPrice4: null,
            totalPrice5: null,
            totalPrice6: null,
            totalPurchasePrice: null
        });


        var packageItemPrice = computePackageItemTotalPrice(currentPackageItemList.packageItemList);       

        //validate package sub item price  - Price 1, 2,3,4,5,6 is not allowed to be ALL zero or empty.
        if (packageItemPrice.totalPrice1 === 0 ||
            packageItemPrice.totalPrice2 === 0 ||
            packageItemPrice.totalPrice3 === 0 ||
            packageItemPrice.totalPrice4 === 0 ||
            packageItemPrice.totalPrice5 === 0 ||
            packageItemPrice.totalPrice6 === 0 ||
            packageItemPrice.totalPurchasePrice === 0) {
        
            setShowValidationMsg(true);
            setValidationPriceMsg(formatMessage('PriceisnotAllowedtobeAllZeroorEmpty'));
        }
        else {
            setShowValidationMsg(false);
        }
        resetIsItemPriceValidMsg();
    }

    const validateSystemGeneratedPrice = () =>{

        var newComputePackageItemPriceRatio = computePackageItemPriceRatio(newItemPrice, currentPackageItemList.packageItemList);
        var newComputePackageItemList       = computePackageSubItemPrice(currentPackageItemList.packageItemList, newComputePackageItemPriceRatio);
        var newComputeTotalPackage          = computePackageItemTotalPrice(newComputePackageItemList);

        //round up based on company decimal point settings
        Object.keys(newComputeTotalPackage).map(function(key, index) {
            newComputeTotalPackage[key] = parseFloat(newComputeTotalPackage[key].toFixed(DecimalPoints()));
        });

        setNewComputePrice({
            newPackageItemPriceRatio : newComputePackageItemPriceRatio,
            newPackageItemList : newComputePackageItemList,
            newTotalPackage : newComputeTotalPackage
        })

        //validate if got rounding differences and all item qty > 1
        var result = newComputePackageItemList.filter(x => x.PI_Qty === 1);
        var isSubPrice1Negative = false;
        var isSubPrice2Negative = false;
        var isSubPrice3Negative = false;
        var isSubPrice4Negative = false;
        var isSubPrice5Negative = false;
        var isSubPrice6Negative = false;
        var isSubPurchasePriceNegative = false;
        var isNegative = false;
        if (result.length > 0)
        {
            isSubPrice1Negative = checkIsNegative(newItemPrice.totalPrice1, newComputeTotalPackage.totalPrice1, newComputePackageItemList, "PI_SalesPrice1", result);
            isSubPrice2Negative = checkIsNegative(newItemPrice.totalPrice2, newComputeTotalPackage.totalPrice2, newComputePackageItemList, "PI_SalesPrice2", result);
            isSubPrice3Negative = checkIsNegative(newItemPrice.totalPrice3, newComputeTotalPackage.totalPrice3, newComputePackageItemList, "PI_SalesPrice3", result);
            isSubPrice4Negative = checkIsNegative(newItemPrice.totalPrice4, newComputeTotalPackage.totalPrice4, newComputePackageItemList, "PI_SalesPrice4", result);
            isSubPrice5Negative = checkIsNegative(newItemPrice.totalPrice5, newComputeTotalPackage.totalPrice5, newComputePackageItemList, "PI_SalesPrice5", result);
            isSubPrice6Negative = checkIsNegative(newItemPrice.totalPrice6, newComputeTotalPackage.totalPrice6, newComputePackageItemList, "PI_SalesPrice6", result);
            isSubPurchasePriceNegative = checkIsNegative(newItemPrice.totalPurchasePrice, newComputeTotalPackage.totalPurchasePrice, newComputePackageItemList, "PI_PurchasePrice", result); 
            if(isSubPrice1Negative || isSubPrice2Negative || isSubPrice3Negative || isSubPrice4Negative || isSubPrice5Negative || isSubPrice6Negative || isSubPurchasePriceNegative)
                isNegative = true;
        }
        else if(result.length === 0)
        {
            isSubPrice1Negative = true;
            isSubPrice2Negative = true;
            isSubPrice3Negative = true;
            isSubPrice4Negative = true;
            isSubPrice5Negative = true;
            isSubPrice6Negative = true;
            isSubPurchasePriceNegative = true;
        }

        if(((newComputeTotalPackage.totalPrice1 !== newItemPrice.totalPrice1 && newItemPrice.totalPrice1 !== null)  ||
            (newComputeTotalPackage.totalPrice2 !== newItemPrice.totalPrice2  && newItemPrice.totalPrice2 !== null) ||
            (newComputeTotalPackage.totalPrice3 !== newItemPrice.totalPrice3  && newItemPrice.totalPrice3 !== null) ||
            (newComputeTotalPackage.totalPrice4 !== newItemPrice.totalPrice4  && newItemPrice.totalPrice4 !== null) ||
            (newComputeTotalPackage.totalPrice5 !== newItemPrice.totalPrice5  && newItemPrice.totalPrice5 !== null) ||
            (newComputeTotalPackage.totalPrice6 !== newItemPrice.totalPrice6  && newItemPrice.totalPrice6 !== null) ||
            (newComputeTotalPackage.totalPurchasePrice !== newItemPrice.totalPurchasePrice && newItemPrice.totalPurchasePrice !== null)) && 
            (result.length === 0 || isNegative))
        {
            setShowValidationMsg(true);
            setValidationPriceMsg(validationMsg.systemgeneratedpriceisnotsameasyouritemprice);
            setIsItemPriceValid (newItemPrice, newComputeTotalPackage, isSubPrice1Negative, isSubPrice2Negative, isSubPrice3Negative, isSubPrice4Negative, isSubPrice5Negative, isSubPrice6Negative, isSubPurchasePriceNegative);
            setIsItemPriceValidMsg(newItemPrice, newComputeTotalPackage, isSubPrice1Negative, isSubPrice2Negative, isSubPrice3Negative, isSubPrice4Negative, isSubPrice5Negative, isSubPrice6Negative, isSubPurchasePriceNegative);
            setItemPriceReadOnly();
        }
        else
        {
            setShowValidationMsg(false);
            resetIsItemPriceValidMsg();
            hideAutoCalPrice();
            //update data grid footer and child package item price
            props.packageItemPriceChange(newItemPrice, newComputeTotalPackage, newComputePackageItemList, newComputePackageItemPriceRatio);
        }      
    }

    const checkIsNegative = (newItemPriceTotalPrice, newComputeTotalPackageTotalPrice, newComputePackageItemList, key, result) => {
        var isNegative = false;
        var roudingDiff = 0
        if((newItemPriceTotalPrice !== '' && newItemPriceTotalPrice !== null) && newComputeTotalPackageTotalPrice !== (newItemPriceTotalPrice === null ? 0 : newItemPriceTotalPrice)){
          roudingDiff = newComputeTotalPackageTotalPrice - newItemPriceTotalPrice;
          for (var i in newComputePackageItemList) {
            if(newComputePackageItemList[i].PI_ID === result[0].PI_ID && (newComputePackageItemList[i][key] - roudingDiff < 0)){
                isNegative = true;
              break; //Stop this loop, we found it!
            }
          }
        } 
        return isNegative;                                                                                                                                                                                                                                          
    }

    const setIsItemPriceValid = (newItemPrice, newComputeTotalPackage, isSubPrice1Negative, isSubPrice2Negative, isSubPrice3Negative, isSubPrice4Negative, isSubPrice5Negative, isSubPrice6Negative, isSubPurchasePriceNegative) => {

        (newItemPrice.totalPrice1 !== null && newComputeTotalPackage.totalPrice1 !== newItemPrice.totalPrice1 && isSubPrice1Negative)?
            setPriceColor(prev => ({...prev, price1:'red'})) :  setPriceColor(prev => ({...prev, price1:'#E8EAED'}));
        (newItemPrice.totalPrice2 !== null && newComputeTotalPackage.totalPrice2 !== newItemPrice.totalPrice2 && isSubPrice2Negative)?
            setPriceColor(prev => ({...prev, price2:'red'})) :  setPriceColor(prev => ({...prev, price2:'#E8EAED'}));
        (newItemPrice.totalPrice3 !== null && newComputeTotalPackage.totalPrice3 !== newItemPrice.totalPrice3 && isSubPrice3Negative)?
            setPriceColor(prev => ({...prev, price3:'red'})) : setPriceColor(prev => ({...prev, price3:'#E8EAED'}));
        (newItemPrice.totalPrice4 !== null && newComputeTotalPackage.totalPrice4 !== newItemPrice.totalPrice4 && isSubPrice4Negative)?
            setPriceColor(prev => ({...prev, price4:'red'})) :  setPriceColor(prev => ({...prev, price4:'#E8EAED'}));
        (newItemPrice.totalPrice5 !== null && newComputeTotalPackage.totalPrice5 !== newItemPrice.totalPrice5 && isSubPrice5Negative)?
            setPriceColor(prev => ({...prev, price5:'red'})) :  setPriceColor(prev => ({...prev, price5:'#E8EAED'}));
        (newItemPrice.totalPrice6 !== null && newComputeTotalPackage.totalPrice6 !== newItemPrice.totalPrice6 && isSubPrice6Negative)?
            setPriceColor(prev => ({...prev, price6:'red'})) :  setPriceColor(prev => ({...prev, price6:'#E8EAED'}));
        (newItemPrice.totalPurchasePrice !== null && newComputeTotalPackage.totalPurchasePrice !== newItemPrice.totalPurchasePrice && isSubPurchasePriceNegative)?
            setPriceColor(prev => ({...prev, totalPrice:'red'})) :  setPriceColor(prev => ({...prev, totalPrice:'#E8EAED'}));

    }

    const setIsItemPriceValidMsg = (newItemPrice, newComputeTotalPackage, isSubPrice1Negative, isSubPrice2Negative, isSubPrice3Negative, isSubPrice4Negative, isSubPrice5Negative, isSubPrice6Negative, isSubPurchasePriceNegative) => {
        (newItemPrice.totalPrice1 !== null && newComputeTotalPackage.totalPrice1 !== newItemPrice.totalPrice1 && isSubPrice1Negative)?
            setPriceErrorMsg(prev => ({...prev, price1: validationMsg.totalitempriceonratio + ' ' +  newComputeTotalPackage.totalPrice1})) :  setPriceErrorMsg(prev => ({...prev, price1:''}));
        (newItemPrice.totalPrice2 !== null && newComputeTotalPackage.totalPrice2 !== newItemPrice.totalPrice2 && isSubPrice2Negative)?
            setPriceErrorMsg(prev => ({...prev, price2: validationMsg.totalitempriceonratio + ' ' +  newComputeTotalPackage.totalPrice2})) :  setPriceErrorMsg(prev => ({...prev, price2:''}));
        (newItemPrice.totalPrice3 !== null && newComputeTotalPackage.totalPrice3 !== newItemPrice.totalPrice3 && isSubPrice3Negative)?
            setPriceErrorMsg(prev => ({...prev, price3: validationMsg.totalitempriceonratio + ' ' +  newComputeTotalPackage.totalPrice3})) : setPriceErrorMsg(prev => ({...prev, price3:''}));
        (newItemPrice.totalPrice4 !== null && newComputeTotalPackage.totalPrice4 !== newItemPrice.totalPrice4 && isSubPrice4Negative)?
            setPriceErrorMsg(prev => ({...prev, price4: validationMsg.totalitempriceonratio + ' ' +  newComputeTotalPackage.totalPrice4})) :  setPriceErrorMsg(prev => ({...prev, price4:''}));
        (newItemPrice.totalPrice5 !== null && newComputeTotalPackage.totalPrice5 !== newItemPrice.totalPrice5 && isSubPrice5Negative)?
            setPriceErrorMsg(prev => ({...prev, price5: validationMsg.totalitempriceonratio + ' ' +  newComputeTotalPackage.totalPrice5})) :  setPriceErrorMsg(prev => ({...prev, price5:''}));
        (newItemPrice.totalPrice6 !== null && newComputeTotalPackage.totalPrice6 !== newItemPrice.totalPrice6 && isSubPrice6Negative)?
            setPriceErrorMsg(prev => ({...prev, price6: validationMsg.totalitempriceonratio + ' ' +  newComputeTotalPackage.totalPrice6})) :  setPriceErrorMsg(prev => ({...prev, price6:''}));
        (newItemPrice.totalPurchasePrice !== null && newComputeTotalPackage.totalPurchasePrice !== newItemPrice.totalPurchasePrice && isSubPurchasePriceNegative)?
            setPriceErrorMsg(prev => ({...prev, totalPrice: validationMsg.totalitempriceonratio + ' ' +  newComputeTotalPackage.totalPurchasePrice})) :  setPriceErrorMsg(prev => ({...prev, totalPrice:''}));
    }

    const resetIsItemPriceValidMsg = () => {
        setPriceErrorMsg(prev => ({...prev, price1:''}));
        setPriceErrorMsg(prev => ({...prev, price2:''}));
        setPriceErrorMsg(prev => ({...prev, price3:''}));
        setPriceErrorMsg(prev => ({...prev, price4:''}));
        setPriceErrorMsg(prev => ({...prev, price5:''}));
        setPriceErrorMsg(prev => ({...prev, price6:''}));
        setPriceErrorMsg(prev => ({...prev, totalPrice:''}));
    }

    const setItemPriceReadOnly = () => {
        setPriceDisabled(prev => ({...prev, price1Disabled:true}))
        setPriceDisabled(prev => ({...prev, price2Disabled:true}))
        setPriceDisabled(prev => ({...prev, price3Disabled:true}))
        setPriceDisabled(prev => ({...prev, price4Disabled:true}))
        setPriceDisabled(prev => ({...prev, price5Disabled:true}))
        setPriceDisabled(prev => ({...prev, price6Disabled:true}))
        setPriceDisabled(prev => ({...prev, totalPriceDisabled:true}))
    }

    useEffect(() => {
        return () => {
            mousetrapMain.unpause();
        }
    }, [])

    return(
        <React.Fragment>
            <Popup
                className="dx-popup-fixed autoCal-popup" 
                visible={state.autoCalPricePopUpVisible}
                onHiding={hideAutoCalPrice}
                onShowing={onPopUpShowing}
                onShown = {onShown}
                onHidden = {onHidden}
                dragEnabled={true}
                showTitle={true}
                title={formatMessage('AutoCalcItemPrice')}
                closeOnOutsideClick={false}
                height={"90%"}
                style={{"WebkitUserDrag": "none", padding: "9px 0px"}} >
                <ScrollView width='100%' height='90%'>
                    <Form  colCount={2}>  

                        <Item colSpan={2}>                 
                            <div className="error-msg-container">
                                <Collapse in={showValidationMsg}>
                                    <Alert
                                        className="cal-alert-box"
                                        variant="filled" 
                                        severity="error"
                                        icon={<ClearIcon />}>
                                            <AlertTitle>{validationPriceMsg}</AlertTitle>
                                        {validationPriceMsg === validationMsg.systemgeneratedpriceisnotsameasyouritemprice ? formatMessage('ClickApplytoproceedtotalpricebaseonratio') : ""}
                                    </Alert>
                                </Collapse>
                            </div>
                        </Item>    

                        <Item colSpan={2}>
                            <div className="auto-cal-border" style={{borderColor: priceColor.price1, pointerEvents: pricePointer.price1Event}}>
                                <div className="total-price">{formatMessage('TotalPrice1')}</div>
                                <NumberBox
                                    className="number-box"
                                    disabled={priceDisabled.price1Disabled}
                                    id='totalPrice1'
                                    showClearButton={false} 
                                    focusStateEnabled = {true}
                                    value={newItemPrice.totalPrice1}
                                    min={0}
                                    max={999999999999}
                                    //onKeyDown={handleKeyPress}
                                    onValueChanged={itemPriceValueChanged}
                                    isValid={isItemPrice1Valid}
                                    ref={numBoxPrice1Ref}
                                    width="30%"
                                    step={0}>
                                </NumberBox>
                            </div>
                            <span className="autoCal-popup-error">{priceErrorMsg.price1}</span>

                        </Item>

                        <Item colSpan={2}>
                            <div className="auto-cal-border" style={{borderColor: priceColor.price2, pointerEvents: pricePointer.price2Event}}>
                                <div className="total-price">{formatMessage('TotalPrice2')}</div>
                                <NumberBox 
                                    className="number-box"
                                    disabled={priceDisabled.price2Disabled}
                                    id='totalPrice2'
                                    showClearButton={false}
                                    value={newItemPrice.totalPrice2}
                                    min={0}
                                    max={999999999999}
                                    onValueChanged={itemPriceValueChanged}
                                    isValid={isItemPrice2Valid}
                                    ref={numBoxPrice2Ref}
                                    width="30%"
                                    step={0}>
                                </NumberBox>
                            </div>
                            <span className="autoCal-popup-error">{priceErrorMsg.price2}</span>
                        </Item>

                        <Item colSpan={2}>
                            <div className="auto-cal-border" style={{borderColor: priceColor.price3, pointerEvents: pricePointer.price3Event}}>
                                <div className="total-price">{formatMessage('TotalPrice3')}</div>
                                <NumberBox 
                                    className="number-box"
                                    disabled={priceDisabled.price3Disabled}
                                    id='totalPrice3'
                                    showClearButton={false}
                                    value={newItemPrice.totalPrice3}
                                    min={0}
                                    max={999999999999}
                                    onValueChanged={itemPriceValueChanged}
                                    isValid={isItemPrice3Valid}
                                    ref={numBoxPrice3Ref}
                                    width="30%"
                                    step={0}>
                                </NumberBox>
                            </div>
                            <span className="autoCal-popup-error">{priceErrorMsg.price3}</span>
                        </Item>

                        <Item colSpan={2}>
                            <div className="auto-cal-border" style={{borderColor: priceColor.price4, pointerEvents: pricePointer.price4Event}}>
                                <div className="total-price">{formatMessage('TotalPrice4')}</div>
                                <NumberBox 
                                    className="number-box"
                                    disabled={priceDisabled.price4Disabled}
                                    id='totalPrice4'
                                    showClearButton={false}
                                    value={newItemPrice.totalPrice4}
                                    min={0}
                                    max={999999999999}
                                    onValueChanged={itemPriceValueChanged}
                                    isValid={isItemPrice4Valid}
                                    ref={numBoxPrice4Ref}
                                    width="30%"
                                    step={0}>
                                </NumberBox>
                            </div>
                            <span className="autoCal-popup-error">{priceErrorMsg.price4}</span>
                        </Item>

                        <Item colSpan={2}>
                            <div className="auto-cal-border" style={{borderColor: priceColor.price5, pointerEvents: pricePointer.price5Event}}>
                                <div className="total-price">{formatMessage('TotalPrice5')}</div>
                                <NumberBox 
                                    className="number-box"
                                    disabled={priceDisabled.price5Disabled}
                                    id='totalPrice5'
                                    showClearButton={false}
                                    value={newItemPrice.totalPrice5}
                                    min={0}
                                    max={999999999999}
                                    onValueChanged={itemPriceValueChanged}
                                    isValid={isItemPrice5Valid}
                                    ref={numBoxPrice5Ref}
                                    width="30%"
                                    step={0}>
                                </NumberBox>
                            </div>
                            <span className="autoCal-popup-error">{priceErrorMsg.price5}</span>
                        </Item>

                        <Item colSpan={2}>
                            <div className="auto-cal-border" style={{borderColor: priceColor.price6, pointerEvents: pricePointer.price6Event}}>
                                <div className="total-price">{formatMessage('TotalPrice6')}</div>
                                <NumberBox 
                                    className="number-box"
                                    disabled={priceDisabled.price6Disabled}
                                    id='totalPrice6'
                                    showClearButton={false}
                                    value={newItemPrice.totalPrice6}
                                    min={0}
                                    max={999999999999}
                                    onValueChanged={itemPriceValueChanged}
                                    isValid={isItemPrice6Valid}
                                    ref={numBoxPrice6Ref}
                                    width="30%"
                                    step={0}>
                                </NumberBox>
                            </div>
                            <span className="autoCal-popup-error">{priceErrorMsg.price6}</span>
                        </Item>

                        <Item colSpan={2}>
                            <div className="auto-cal-border" style={{borderColor: priceColor.totalPrice, pointerEvents: pricePointer.totalPriceEvent}}>
                                <div className="purchase-price">{formatMessage('TotalPurchasePrice')}</div>
                                <NumberBox 
                                    className="number-box"
                                    disabled={priceDisabled.totalPriceDisabled}
                                    id='totalPurchasePrice'
                                    showClearButton={false}
                                    value={newItemPrice.totalPurchasePrice}
                                    min={0}
                                    max={999999999999}
                                    onValueChanged={itemPriceValueChanged}
                                    isValid={isItemPurchasePriceValid}
                                    ref={numBoxPurchasePriceRef}
                                    width="30%"
                                    step={0}>
                                </NumberBox>
                            </div>
                            <span className="autoCal-popup-error" style={{marginBottom:"10px"}}>{priceErrorMsg.totalPrice}</span>
                            <div className="space"/>
                        </Item>
                    </Form>
                    </ScrollView>

                    <div className="autoCal-popup-footer">  
                        <div className="left-btn-container-no-cover">
                            <Button
                                className="cancel-btn"
                            text={formatMessage("Cancel")}
                            type="normal"
                            stylingMode="contained"
                            onClick={onCancelBtnClick}
                                ref={cancelBtnRef}>    
                            </Button>
                        </div>
                        <div className="right-btn-container-no-cover">
                            <Button
                                className="apply-btn"
                            text={formatMessage("Apply")}
                            type="default"
                            stylingMode="contained"
                            onClick={onApplyBtnClick}
                                ref={applyBtnRef}>
                            </Button>
                        </div>
                    </div>
                
            </Popup>

            <Form  colCount={8}>
                <Item colSpan={2}>
                    <TextBox1 
                        displayValue= {'value'}
                        value       ={props.valueTextbox1}
                        disabled    ={true}
                        caption     ={props.captionTextbox1}
                        stylingMode = {'filled'}>
                    </TextBox1>
                </Item> 
                <Item colSpan={2}>
                    <TextBox1 
                        value      ={props.valueTextbox2}
                        disabled   ={true}
                        caption    ={props.captionTextbox2}
                        stylingMode={'filled'}>    
                    </TextBox1>
                </Item>
                <Item colSpan={4}></Item>

                <Item colSpan={4}>
                    <Singleselectiondropdown
                        placeholder = {formatMessage('Select')}
                        dataSource= {[formatMessage('Item')]}
                        defaultValue={formatMessage('Item')}
                        caption={props.captionDropDown1} />                    
                </Item>

                <Item colSpan={4}></Item>

                <Item colSpan={8}>
                    <hr className="line-separator"/>
                </Item>

                <Item colSpan={4}>
                    <TextBox4
                        placeHolderText = {props.placeHolderText}
                        tooltipText = {props.tooltipText}
                        value={searchValue}
                        openItemPopUp = {openItemPopUp}
                        onTbvalueChange = {onTbvalueChange}
                        visible = {props.allowAdd !== false}
                        ref={searchBox}>
                    </TextBox4>
                </Item>
                
                <Item colSpan={2}></Item>
                <Item colSpan={2}>
                        <div className="dx-fieldset">
                            <div className="dx-field">
                                <div className="dx-field-value" >
                                    <Button
                                        className="auto-cal-btn"
                                        text={props.buttonText2}
                                        icon="normalize-icon glyphicon icon-calculation-icon"
                                        type="default"
                                        stylingMode="outlined"
                                        hoverStateEnabled={true}
                                        activeStateEnabled={false}
                                        focusStateEnabled={false}
                                        onClick={onCalBtnClick}
                                    ></Button>
                                </div>
                            </div>
                        </div>  
                </Item>
            </Form>                       
        </React.Fragment>
    );
});
export default ListingHeaderPackageItemDetails;
