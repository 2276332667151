import React, { useCallback } from "react";
import { Row, Col } from "antd";
import { DropdownInput } from "../../fields";
import { FastField } from "formik";
import { unstable_batchedUpdates } from 'react-dom';
import { useConfirmationPopupContext } from "../../modal-dialog/ConfirmationPopupProvider";
import FastFieldWithProps from "../../fields/FastFieldWithProps";

export const PurchaseInvoiceDetailsOrganizationForm = (props) => {
    const {
        formikRef,
        itemStateRef,
        safeSetWarehouseList,
        onPurchaseGroupChange,
        GetWarehouseList,
    } = props;

    // Confirmation Popup
    const showConfirmationPopup = useConfirmationPopupContext();

    const inputSpan = { xs: 24, sm: 12 };
    const emptyInputSpan = { xs: 0, sm: 12 };

    const onPurchaseGroupChangeCallback = useCallback(async ({ obj: purchaseGroup, id }) => {
        if (!purchaseGroup) {
            formikRef.current.setFieldValue("PurchaseGroupID", id); // null

            // fetch warehouse list
            const warehouseList = await GetWarehouseList();

            // set warehouse data source
            safeSetWarehouseList(warehouseList);
        } else {
            if (typeof purchaseGroup === "string") purchaseGroup = JSON.parse(purchaseGroup);

            // fetch warehouse data source which has purchase warehouse mapping
            const warehouseObj = await onPurchaseGroupChange(id);

            // check warehouse exist to handle case where default warehouse is not in the active warehouse list of purchase group
            const warehouseList = warehouseObj?.Warehouse;
            const warehouseExist = warehouseList.find((warehouse) => warehouse.ID === purchaseGroup.PG_DefaultWarehouse);
            const newWarehouseID = warehouseExist ? purchaseGroup.PG_DefaultWarehouse : null;

            unstable_batchedUpdates(async function () {
                // set warehouse data source which has purchase warehouse mapping
                safeSetWarehouseList(warehouseList);

                // set purchase group
                formikRef.current.setFieldValue("PurchaseGroupID", id);

                // set purchase group default warehouse
                formikRef.current.setFieldValue("WarehouseID", newWarehouseID);

                // set warehouseObj
                formikRef.current.setFieldValue("Address1", warehouseObj.Address1);
                formikRef.current.setFieldValue("Address2", warehouseObj.Address2);
                formikRef.current.setFieldValue("Address3", warehouseObj.Address3);
                formikRef.current.setFieldValue("Address4", warehouseObj.Address4);
                formikRef.current.setFieldValue("City", warehouseObj?.City);
                formikRef.current.setFieldValue("Postcode", warehouseObj.Postcode);
                formikRef.current.setFieldValue("State", warehouseObj?.State);
                formikRef.current.setFieldValue("Country", warehouseObj?.Country);

                if (itemStateRef?.current?.hasItem() && newWarehouseID && formikRef.current.values.WarehouseID !== newWarehouseID) {
                    // show warehouse change confirmation pop up if (1) has item (2) has selected warehouse (3) selected warehouse ID is not the same
                    const accept = await showConfirmationPopup('ConfirmToChangeWarehouseByPurchaseDivision');
                    if (accept) itemStateRef?.current?.handleWarehouseChange(newWarehouseID, purchaseGroup?.PG_DefaultProject, purchaseGroup?.PG_DefaultCostCentre);
                }
            })
        }
    }, [formikRef, itemStateRef, safeSetWarehouseList, showConfirmationPopup, onPurchaseGroupChange, GetWarehouseList])

    return (
        <Row gutter={35}>
            <Col {...inputSpan}>
                <FastField
                    label="Purchase Organization"
                    name="PurchaseOrgID"
                    dataSource={props.purchaseOrganisation}
                    headerColumn={["Code", "Name"]}
                    dataColumn={["PO_Code", "PO_Name"]}
                    displayColumn="PO_Name"
                    filtervalue={["PO_Code", "PO_Name"]}
                    getColumnValue="PO_ID"
                    placeholder="Select Purchase Organization"
                    tabLink={props.Link_URLPurOrg}
                    tabTitle={"Purchase Organization"}
                    component={DropdownInput}
                />
            </Col>
            <Col {...emptyInputSpan}></Col>
            <Col {...inputSpan}>
                <FastFieldWithProps
                    label="Purchase Division"
                    name="PurchaseGroupID"
                    dataSource={props.purchaseGroup}
                    headerColumn={["Code", "Name"]}
                    dataColumn={["PG_Code", "PG_Name"]}
                    displayColumn="PG_Name"
                    filtervalue={["PG_Code", "PG_Name"]}
                    getColumnValue="PG_ID"
                    placeholder="Select Purchase Division"
                    tabLink={props.Link_URLPurGrp}
                    tabTitle={"Purchase Division"}
                    component={DropdownInput}
                    onChangeCallback={onPurchaseGroupChangeCallback}
                />
            </Col>
        </Row>
    );
};
