import React, { useCallback, useEffect, useRef, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom';
import { formatMessage } from 'devextreme/localization';
import { DataGridDefault } from '../..';
import { Column, Editing, Summary, TotalItem } from 'devextreme-react/data-grid';
import { DefaultPager } from '../../../utils/default-data-grid-settings';
import PopupDefault from './popup-default';
import SectionDropdown from './features/section-dropdown';
import SectionRemarksUnitCost from './features/section-remarks-unitcost';
import { ClientID, Token } from '../../../utils/default-cookies';
import { GetItemMatrixByFormatID, GetItemMatrixStockInByDocID, GetItemMatrixTemplate } from '../../../api/purchase-invoice-details';
import { getErrorCode, setErrorPopUpMsg } from '../../../utils/error-popup-http-error-msg';
import useAsyncEffect from 'use-async-effect';
import EditCellNumber from '../../data-grid/custom-cell/edit-cell-number';
import { FormikDefault } from '../../forms/formik-default';
import { useErrorPopupContext } from '../../modal-dialog/ErrorPopupProvider';
import { getEditCellProps } from '../../data-grid/custom-cell/util/edit-cell-util';
import { checkInvalidCell } from '../../data-grid/data-grid-util';
import { DocumentTypeId } from "../../../utils/constant-document-type-id";
import { getInterfaceType, isCopyFromUI } from './util/item-type-popup-util';

const MatrixItemPopup = (props) => {
    const {
        submitCallback,
        cancelCallback,
        iAGID,
        docType,
        visible,
        onHiding,
        onHidden
    } = props;

    const {
        mainItem,
        deleteOnCancel,
    } = props.popupData;

    const {
        PID_GroupID: groupID,
        PID_PIHID: docID,
        PID_ItemID: itemID,
        PID_ItemTypeID: itemType,
        PID_BinLocationID: binLocationID,
        PID_Remarks: remarks,
        PID_UnitPrice: unitCost,
        PID_SourceDocID: sourceDocID,
        PID_SourceDocTypeID: sourceDocTypeID,
    } = mainItem;

    const [rowData, setRowData] = useState([]); // data grid row data

    const [selectedItemMatrixID, setSelectedItemMatrixID] = useState(null); // dropdown selected id
    const [itemMatrixURL, setItemMatrixURL] = useState(""); // dropdown URL
    const [itemMatrixList, setItemMatrixList] = useState(null); // dropdown selection list (ItemMatrixTemplate)
    const [itemMatrix, setItemMatrix] = useState(null); // itemColorList, itemSizeList, itemList

    const formikRef = useRef(null);
    const dataGridRef = useRef(null);
    const apiLoaded = useRef(false);

    const interfaceType = useRef(getInterfaceType(sourceDocTypeID, itemType));

    const showErrorPopup = useErrorPopupContext();

    // #region Util
    function getDataGrid() {
        return dataGridRef?.current?.instance;
    }
    // #endregion Util

    // #region Events
    function onItemMatrixChanged({ id }) {
        setSelectedItemMatrixID(id);
    }

    async function onSubmit(values, actions) {
        await getDataGrid()?.refresh(true); // refresh data grid to update data rows & summary

        const total = getDataGrid()?.getTotalSummaryValue("_TOTALQTY");

        if (await checkInvalidCell(rowData)) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: "ContainInvalidCell"
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            actions.setSubmitting(false);
            return false;
        } else if (!total) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: "PleaseInsertAtLeastOneQuantity"
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            actions.setSubmitting(false);
            return false;
        } else {
            const itemDetail = {
                SelectedItemMatrix: selectedItemMatrixID,
                AddNewItemMatrixURL: itemMatrixURL,
                ItemMatrixList: itemMatrixList,
                ItemColorList: itemMatrix.itemColorList,
                ItemSizeList: itemMatrix.itemSizeList,
                StockInDocMatrixItemList: [],
            };

            rowData.forEach((item, index) => {
                for (const [key, value] of Object.entries(item)) {
                    if (key.match(/^qty([0-9]+)$/)) {
                        itemDetail.StockInDocMatrixItemList.push({
                            ID: value.ID,
                            Qty: value.quantity,
                            GroupID: groupID,
                            ItemID: value.itemID,
                            MatrixItemID: itemID,
                            ItemMatrixID: selectedItemMatrixID,
                            ItemSizeID: value.sizeID,
                            ItemColorID: item.colorID,
                            DocID: docID,
                            BinLocationID: binLocationID,
                            AvailableQty: value.availableQty,
                            SequenceID: index + 1,
                        });
                    }
                }
            });

            const remarks = values.remarks;
            const unitCost = values.unitCost;
            const editedMainItem = { ...mainItem, PID_Remarks: remarks, PID_UnitPrice: unitCost, PID_Qty: total, itemDetail };

            submitCallback({ editedMainItem });
            actions.setSubmitting(false);
            return true;
        }
    }

    function onCancel() {
        if (deleteOnCancel) {
            cancelCallback?.(groupID);
        }
    }
    // #endregion Events

    // #region Data Util
    function initData(data) {
        unstable_batchedUpdates(function () {
            const { SelectedItemMatrix, AddNewItemMatrixURL, ItemMatrixList, ItemColorList, ItemSizeList, StockInDocMatrixItemList } = data;
            const itemMatrix = {
                itemColorList: ItemColorList,
                itemSizeList: ItemSizeList,
                itemList: StockInDocMatrixItemList,
            };

            formikRef.current.setFieldValue("matrixTemplate", SelectedItemMatrix);

            setSelectedItemMatrixID(SelectedItemMatrix);
            setItemMatrixURL(AddNewItemMatrixURL);
            setItemMatrixList(ItemMatrixList);
            setItemMatrix(itemMatrix);
        });
    }

    function initRowData(itemMatrix) {
        if (!itemMatrix) return;

        const { itemColorList = [], itemSizeList = [], itemList = [] } = itemMatrix;

        const dataHolder = Array.from(Array(itemColorList.length), function () {
            const data = {};
            itemSizeList.forEach((size, index) => {
                data[`qty${index}`] = {
                    sizeID: size.IS_ID,
                    size: size.IS_Code,
                    quantity: 0,
                    itemID: 0,
                    ID: 0,
                    availableQty: 0,
                }
            });
            return data;
        });

        itemList.forEach(item => {
            const rowIndex = itemColorList.findIndex(color => color.IC_ID === item.ItemColorID);
            const columnIndex = itemSizeList.findIndex(size => size.IS_ID === item.ItemSizeID);

            dataHolder[rowIndex][`qty${columnIndex}`].quantity = item.Qty;
            dataHolder[rowIndex][`qty${columnIndex}`].itemID = item.ItemID;
            dataHolder[rowIndex][`qty${columnIndex}`].ID = item.ID;
            dataHolder[rowIndex][`qty${columnIndex}`].availableQty = item.AvailableQty;
        });

        dataHolder.forEach((data, index) => {
            data['colorID'] = itemColorList[index].IC_ID;
            data['color'] = itemColorList[index].IC_Code;
        });

        setRowData(dataHolder);

        apiLoaded.current = true;
    }
    // #endregion Data Util

    // #region Data Fetch API
    const fetchInitialData = useCallback(() => {
        if (!Token()) return;

        setTimeout(() => {
            getDataGrid()?.beginCustomLoading();
        }, 0);

        const queryParam = {
            token: Token(),
            clientID: ClientID(),
            iAGID,
            groupID,
            docID,
            docType,
            itemID,
            sourceDocID,
            sourceDocType: DocumentTypeId.getDocTypeByDocTypeId(sourceDocTypeID),
        }

        GetItemMatrixStockInByDocID(queryParam).then((data) => {
            if (typeof data === "string" && data.includes("Error")) {
                const errorCode = getErrorCode(data);
                const errorMsg = setErrorPopUpMsg(errorCode);

                showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            } else {
                initData(data);
            }
        }).finally(() => {
            getDataGrid()?.endCustomLoading();
        })
    }, [iAGID, groupID, docID, docType, itemID, sourceDocID, sourceDocTypeID, showErrorPopup]);

    const fetchItemMatrix = useCallback((isActive) => {
        if (!Token()) return;

        getDataGrid()?.beginCustomLoading();

        GetItemMatrixByFormatID(Token(), ClientID(), iAGID, itemID, selectedItemMatrixID).then((data) => {
            if (!isActive()) return;

            if (typeof data === "string" && data.includes("Error")) {
                const errorCode = getErrorCode(data);
                const errorMsg = setErrorPopUpMsg(errorCode);

                showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            } else {
                const { ItemColorList, ItemSizeList } = data;
                const itemMatrix = {
                    itemColorList: ItemColorList,
                    itemSizeList: ItemSizeList,
                    itemList: [],
                };

                setItemMatrix(itemMatrix);
            }
        }).finally(() => {
            if (!isActive()) return;
            getDataGrid()?.endCustomLoading();
        })
    }, [selectedItemMatrixID, iAGID, itemID, showErrorPopup])

    const fetchMatrixTemplate = useCallback(async () => {
        if (!Token()) return;

        getDataGrid()?.beginCustomLoading();

        let newItemMatrixList = [];

        await GetItemMatrixTemplate(Token(), ClientID(), iAGID).then((data) => {
            if (typeof data === "string" && data.includes("Error")) {
                const errorCode = getErrorCode(data);
                const errorMsg = setErrorPopUpMsg(errorCode);

                showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            } else {
                const { ItemMatrixList } = data;

                setItemMatrixList(ItemMatrixList);
                newItemMatrixList = ItemMatrixList;
            }
        }).finally(() => {
            getDataGrid()?.endCustomLoading();
        })

        return newItemMatrixList
    }, [iAGID, showErrorPopup]);
    // #endregion Data Fetch API

    // #region useEffect
    useAsyncEffect((isActive) => {
        // fetch ItemColorList, ItemSizeList when selectedItemMatrixID changed (except first render)
        if (!apiLoaded.current) return;
        fetchItemMatrix(isActive);
    }, [fetchItemMatrix]);

    useEffect(() => {
        if (!mainItem.itemDetail) {
            fetchInitialData();
        } else {
            initData(mainItem?.itemDetail);
        }
    }, [mainItem, fetchInitialData]);

    useEffect(() => {
        // populate data grid after itemMatrix changed
        initRowData(itemMatrix);
    }, [itemMatrix])
    // #endregion useEffect

    // #region Render
    function sizeColumnRender(size, index) {
        return (
            <Column
                width={`${100 / itemMatrix?.itemSizeList?.length}%`}
                caption={`${size.IS_Code}`}
                dataField={`qty${index}.quantity`}
                key={size.IS_ID}
                allowEditing={true}
                allowHiding={false}
                allowReordering={false}
                showInColumnChooser={false}
                cssClass="column-normalize"
                cellRender={(cellData) => qtyCell(cellData, index)}
                editCellComponent={(cellData) => qtyCell(cellData, index)}
                dataType="number"
            />
        );
    }

    function sizeSummaryRender(data, index) {
        return (
            <TotalItem
                summaryType="sum"
                column={`qty${index}.quantity`}
                displayFormat="{0}"
                key={data.IS_ID}
            />
        );
    }

    function calculateTotalQty(rowData) {
        let totalQty = 0;

        for (const [key, value] of Object.entries(rowData)) {
            if (key.match(/^qty([0-9]+)$/)) {
                totalQty += value.quantity;
            }
        }

        return totalQty;
    }

    function qtyCell(cellData, index) {
        const { info, ...editCellProps } = getEditCellProps(cellData);
        const availableQty = info.data[`qty${index}`]?.availableQty;

        return (
            <EditCellNumber
                {...editCellProps}
                isEditable
                min={0}
                max={isCopyFromUI(interfaceType.current) ? availableQty : null}
                precision={0}
                showMax={isCopyFromUI(interfaceType.current)}
                changeEmptyToMin
            />
        )
    }
    // #endregion Render

    return (
        <FormikDefault
            initialValues={{
                matrixTemplate: undefined,
                remarks: remarks,
                unitCost: unitCost,
            }}
            onSubmit={onSubmit}
            formikRef={formikRef}
        >
            <PopupDefault
                className="purchase-invoice-popup"
                title={"Matrix Item"} // [Translation] Update Translation
                visible={visible}
                onHiding={onHiding}
                onHidden={onHidden}
                cancelForm={onCancel}
                apiLoaded={apiLoaded.current}
                formikRef={formikRef}
            >
                {
                    !isCopyFromUI(interfaceType.current) &&
                    <SectionDropdown
                        // Labels
                        label="Matrix Template Code"
                        name="matrixTemplate"
                        placeholder="Select Matrix Template Code"
                        tabTitle={"Matrix Template"}
                        // Dropdown
                        headerColumn={[formatMessage("Code"), formatMessage("Description")]}
                        dataColumn={["IM_Code", "IM_Description"]}
                        displayColumn="IM_Description"
                        filtervalue={["IM_Code", "IM_Description"]}
                        // Data
                        dataSource={itemMatrixList}
                        tabLink={itemMatrixURL}
                        getColumnValue="IM_ID"
                        // Events
                        onChangeCallback={onItemMatrixChanged}
                        refreshDataFn={fetchMatrixTemplate}
                        // Others
                        required
                        disabled={!apiLoaded.current}
                        denyEmpty
                    />
                }

                <div className="module-data-grid-popup" style={{ marginTop: "16px" }}>
                    <DataGridDefault
                        // Styling
                        className='dx-datagrid-items datagrid-normalize'
                        // DevExtreme DataGrid Configurations
                        dataSource={rowData}
                        keyExpr="colorID"
                        allowedPageSizes={DefaultPager.allowedPageSizes_details}
                        defaultPageSize={DefaultPager.defaultPageSize_details}
                        noDataText={formatMessage("dxDataGrid-noDataText")}
                        allowColumnResizing={false}
                        focusedRowEnabled={true}
                        // Custom Configurations
                        dataGridRef={dataGridRef}
                        disableContextMenu={true}
                        exportFileName="MatrixItemPopup"
                    >
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                        />
                        <Column // Summary Total
                            visibleIndex={0}
                            fixedPosition={'left'}
                            fixed={true}
                            width={0}
                            allowExporting={false}
                            allowFiltering={false}
                            allowHeaderFiltering={false}
                            allowHiding={false}
                            allowReordering={false}
                            allowResizing={false}
                            allowSorting={false}
                            showInColumnChooser={false}
                            cssClass="column-normalize"
                            dataField={'_TOTAL'} // dummy dataField for summary
                            caption={null}
                        />
                        <Column
                            caption={'Color/Size'} // [Translation] Update Translation
                            dataField={'color'}
                            allowHiding={false}
                            allowReordering={false}
                            showInColumnChooser={false}
                        />
                        {itemMatrix?.itemSizeList?.map(sizeColumnRender)}
                        <Column
                            minWidth={"144px"}
                            caption={formatMessage('Total')}
                            dataField={'_TOTALQTY'} // dummy dataField for summary
                            calculateCellValue={calculateTotalQty}
                            allowHiding={false}
                            allowReordering={false}
                            showInColumnChooser={false}
                        />
                        <Summary>
                            <TotalItem
                                summaryType="count"
                                showInColumn="_TOTAL"
                                displayFormat={`${formatMessage("Total")}:`}
                                cssClass="summary-overflow"
                                skipEmptyValues
                            />
                            {itemMatrix?.itemSizeList?.map(sizeSummaryRender)}
                            <TotalItem
                                summaryType="sum"
                                column="_TOTALQTY"
                                displayFormat="{0}"
                            />
                        </Summary>
                    </DataGridDefault>
                </div>

                <SectionRemarksUnitCost />

            </PopupDefault>
        </FormikDefault>
    )
}

export default MatrixItemPopup