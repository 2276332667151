import { GET } from '../../../api/request-options'
export const GetAllCompaniesIAGByUser = async (token, clientID, userID, isAdmin) => {
   
    return fetch(`${process.env.REACT_APP_API_BASE_URL}api/Company/GetAllCompaniesIAGByUser?clientID=${clientID}&UserID=${userID}&IsAdmin=${isAdmin}&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => { console.log(error); return error; });
}

export const GetAllDocumentType = async (token) => {

    return fetch(`${process.env.REACT_APP_API_BASE_URL}api/MasterData/GetAllDocumentType?UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj.DocumentType;
        })
        .catch((error) => { console.log(error); return error; });
}

export const GetAllSetting = async (token) => {
    return fetch(`${process.env.REACT_APP_API_BASE_URL}api/MasterData/GetAllSetting?UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj.Setting;
        })
        .catch((error) => { console.log(error); return error; });
}

export const GetAllUser = async (token, userID) => {
    return fetch(`${process.env.REACT_APP_API_BASE_URL}api/Users/GetAllUsers?userID=${userID}&userName=Administrator&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj.Query_User;
        })
        .catch((error) => { console.log(error); return error; });
}

export const GetAuditLogSettingType = async (token, companyID, userID, code, fromDate, toDate) => {
    return fetch(`${process.env.REACT_APP_API_BASE_URL}api/AuditLog/GetAuditLogSettingType?companyID=${companyID}&fromDate=${fromDate}&toDate=${toDate}&userID=${userID}&setting=Package Item&code=${code}&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            if (data.Obj)
                return data.Obj;
            else
                return data;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

export const GetAuditLogSettingTypeCount = async (token, companyID, userID, code, fromDate, toDate) => {

    return fetch(`${process.env.REACT_APP_API_BASE_URL}api/AuditLog/GetAuditLogSettingTypeCount?companyID=${companyID}&fromDate=${fromDate}&toDate=${toDate}&userID=${userID}&setting=Package Item&code=${code}&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

export const GetAuditLogDocumentType = async (token, companyID, userID, code, fromDate, toDate, documentNo) => {
    return fetch(`${process.env.REACT_APP_API_BASE_URL}api/AuditLog/GetAuditLogDocumentType?companyID=${companyID}&fromDate=${fromDate}&toDate=${toDate}&userID=${userID}&documentType=PI&documentNo=${documentNo}&code=${code}&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            if (data.Obj)
                return data.Obj;
            else
                return data;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

export const GetAuditLogDocumentTypeCount = async (token, companyID, userID, code, fromDate, toDate, documentNo) => {

    return fetch(`${process.env.REACT_APP_API_BASE_URL}api/AuditLog/GetAuditLogDocumentTypeCount?companyID=${companyID}&fromDate=${fromDate}&toDate=${toDate}&userID=${userID}&documentType=PI&documentNo=${documentNo}&code=${code}&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

//handle errors
function handleErrors(response) {
    if (!response.ok) throw new Error(response.status);
    return response;
}



//#endregion
