import React from 'react';
import Form from 'devextreme-react/form';
import { Button } from 'devextreme-react';
import '../pop-up/popup.scss';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { Radio } from "antd";

//#region customized 

//language
import { formatMessage } from 'devextreme/localization';

//DataGridDefault
import DataGrid, { Column, Selection } from 'devextreme-react/data-grid';
import { mousetrapMain } from '../../App';
//#endregion

class DocumentFormatPopUp extends React.Component {
    constructor(props) {
        super(props);

        this.dataGridRef = React.createRef();

        this.updateSelectedID = this.updateSelectedID.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.cellRender = this.cellRender.bind(this);

        this.state = {
            selectedID: [this.props.selectedID]
        };
    }

    get dataGrid() {
        // `current.instance` points to the UI component instance
        return this.dataGridRef.current.instance;
    }

    componentWillUnmount() {
        mousetrapMain.unpause();
    }

    updateSelectedID() {
        this.props.hidePopup();
        this.props.setSelectedID(this.state.selectedID);
    }


    onSelectionChanged(e) {
        this.setState({
            selectedID: e.selectedRowKeys
        });
    }

    onShowing() {
        mousetrapMain.pause();
    }

    onShown() {
        mousetrapMain.pause();
    }

    onHidden() {
        mousetrapMain.unpause();
    }

    cellRender(data) {
        return <Radio
            value={data.data?.DNF_ID}
            checked={this.state.selectedID[0] === data.data?.DNF_ID ? true : false}
        //disabled={this.state.radioValue === a.VE_ID ? true : false}
        />
    }

    render() {
        return (
            <Popup
                className="item-popup"
                showTitle={true}
                title={this.props.title}
                onShowing={this.onShowing}
                onShown={this.onShown}
                onHiding={this.props.hidePopup}
                onHidden={this.onHidden}
                visible={this.props.visible}
                dragEnabled={true}
                closeOnOutsideClick={true}
                width={'50%'}
                height={'85%'}
            >
                <ScrollView width='100%' height='90%'>
                    <Form
                        id="package-item-form"
                        width="100%"
                        colCount={8}
                    >

                    </Form>
                    <div className="module-data-grid-popup">
                        <DataGrid
                            className='dx-datagrid-items'
                            dataGridRef={this.dataGridRef}
                            dataSource={this.props.dataSource}
                            keyExpr="DNF_ID"
                            noDataText={formatMessage("dxDataGrid-noDataText")}
                            selectedRowKeys={this.state.selectedID}
                            onSelectionChanged={this.onSelectionChanged}
                            rowAlternationEnabled={true}
                        >
                            <Selection mode="single" />

                            <Column
                                caption={""}
                                dataField={'DNF_ID'}
                                allowHiding={false}
                                allowSorting={false}
                                showInColumnChooser={false}
                                width={90}
                                cellRender={this.cellRender}
                            />

                            <Column
                                caption={formatMessage('Name')}
                                dataField={'DNF_Description'}
                                minWidth={60}
                            />
                            <Column
                                caption={formatMessage('DOCUMENTNO.FORMAT')}
                                dataField={'DNF_DocNoFormat'}
                                minWidth={60}
                            />

                        </DataGrid>
                    </div>
                </ScrollView>
                <div className="item-popup-footer">
                    <div className="left-btn-container">
                        <Button
                            hoverStateEnabled={true}
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            className="cancel-btn"
                            text={formatMessage("Cancel")}
                            type="normal"
                            stylingMode="contained"
                            onClick={this.props.hidePopup}>
                        </Button>
                    </div>
                    <div className="right-btn-container">
                        <Button
                            hoverStateEnabled={true}
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            className="apply-btn"
                            text={formatMessage("OK")}
                            onClick={this.updateSelectedID}
                            type="default"
                            stylingMode="contained">
                        </Button>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default DocumentFormatPopUp;