/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useState } from "react";
import { Collapse, Row, Col, Typography, Tag, Tabs } from "antd";
import { Toolbar1 } from "../../../components";
import {
    PurchaseInvoiceDetailsForm,
    PurchaseInvoiceDetailsTotalForm,
    PurchaseInvoiceDetailsItemForm,
    PurchaseInvoiceDetailsLogisticsForm,
    PurchaseInvoiceDetailsOrganizationForm,
    PurchaseInvoiceDetailsNotesForm,
} from "../../../components/forms";
import _, { uniqueId, isEmpty } from "lodash";
import { formatMessage } from "devextreme/localization";
import Flash from "../../../components/message/flash";
import CopyFromPopUp from "../../../components/pop-up/copy-from-popup";
import ReportFormatPopUp from "../../../components/pop-up/report-format-popup";
import { useHistory, useLocation } from "react-router-dom";
import "antd/dist/antd.css";
import "./styles/purchase-invoice-details.scss";

//iframe
import { getPostMessageObj, getPurchaseInvoiceRelations } from '../../../utils/iframe-func'

//default 
import { DefaultSMIPIDetailPopupCopyFrom, DefaultSMIPIDetailReportFormat, DefaultSMIAuditLog } from '../../../utils/default-smi';

//cookies
import { CompanyID } from '../../../utils/default-cookies';
import { useUserAccessContext } from "../../../contexts/UserAccess";

const { Panel } = Collapse;
const { Title } = Typography;
const { TabPane } = Tabs;

const PurchaseInvoiceDetails = (props) => {
    const {
        formikRef,
        itemStateRef,
        docStatus,
        docID,
        nextPossibleNo,
        piDtlAccess,
        iAGID,
        documentNo,
        userProfile,
        companyTaxTypeList,
        warehouseList,
        safeSetWarehouseList,
        openPreviewReportTab,
        cityList,
        setCityList,
        paymentTerm,
        vendorList,
        piDtlItem,
        purchaseAgent,
        setDefaultReportFormat,
        preferedLayoutRef,
        shippingMethod,
        shippingMethodID,
        purchaseOrganisation,
        purchaseGroup,

        // link
        Link_ShippingMethod,
        Link_URLPurOrg,
        Link_URLPurGrp,
        Link_URLDocumentNo,
        Link_URLPurAgent,
        Link_PaymentTerm,
        Link_URLWarehouse,

        //document info
        documentNoFormat,
        showDocNoFormat,
        iAGDescription,
        createdBy,
        createdDate,
        fiscalYear,
        fiscalPeriod,
        safeSetCompanyFiscalPeriod,
        safeSetCompanyTaxTypeList,

        //call api
        onVendorChange,
        onPurchaseGroupChange,
        GetCurrencyRate,
        GetCompanyFiscalPeriod,
        GetTaxTypeRatePurchase,
        GetItemCostByWarehouse,
        GetBinLocByWarehouseID,
        GetWarehouseList,
        GetCityList,
        GetItemsByCopyFromDoc,
    } = props;

    const [tabKey, setTabKey] = useState("1");
    const [activeKey, setActiveKey] = useState(["1", "2"]);
    const [flashMessageVisible, setFlashMessageVisible] = useState(false);
    const [reportFormatPopUpVisible, setReportFormatPopUpVisible] = useState(false);

    const [copyFromPopUpVisible, setCopyFromPopUpVisible] = useState(false);
    const [copyFromDocType, setCopyFromDocType] = useState("");
    const [copyFromPopUpTitle, setCopyFromPopUpTitle] = useState("");

    const history = useHistory();
    const location = useLocation();
    const { userAccess } = useUserAccessContext();

    //#region UI
    const tabsCallback = (key) => {
        setTabKey(key);
    };

    const copyFromCallBack = async (childData) => {
        const copyFromData = await GetItemsByCopyFromDoc(childData);

        // update item state
        itemStateRef?.current?.handleAddItemByCopyFromData(copyFromData?.PurchaseInvoiceDTL);

        // update formik
        formikRef.current.setFieldValue('PurchaseOrgID', copyFromData?.PurchaseOrgID);
        formikRef.current.setFieldValue('PurchaseAgentID', copyFromData?.PurchaseAgentID);
        formikRef.current.setFieldValue('PurchaseGroupID', copyFromData?.PurchaseGroupID || 0);
    };

    const handleDropDown = (key) => {
        let checkKey = false;

        _.map(activeKey, (k) => {
            if (k === key) checkKey = true;
        });

        if (checkKey) {
            setActiveKey(
                _.remove(activeKey, (k) => {
                    return k !== key;
                })
            );
        } else {
            setActiveKey([...activeKey, key]);
        }
    };

    const checkKeyChangeIcon = (key) => {
        let checkKey = false;

        _.map(activeKey, (k) => {
            if (k === key) checkKey = true;
        });

        return checkKey;
    };

    //#endregion

    //#region toolbar function
    const goBack = () => {
        history.push('/purchase-invoice');
    };

    function openSourceTab(url, tabName) {
        window.parent.postMessage(getPostMessageObj(url, tabName), "*");
    }

    const openAuditLog = (auditLogURL) => {
        if (!auditLogURL) {
            openSourceTab(`/Modules/Common/${DefaultSMIAuditLog.moduleURL}?id=${docID}&code=${documentNo}&companyID=${CompanyID()}&isDocType=true`, `Purchase Invoice(${documentNo}) Audit Log`);
        }
        else {
            const params = new Proxy(new URLSearchParams(auditLogURL), {
                get: (searchParams, prop) => searchParams.get(prop),
            });
            let documentNo = params.docno;

            openSourceTab(`${auditLogURL}&companyID=${CompanyID()}&isDocType=true`, `Purchase Invoice(${documentNo}) Audit Log`);
        }
    };

    const openNew = () => {
        const queryString = `?${encodeURIComponent(`id=${0}&iag=${iAGID}&iag_desp=${iAGDescription}`)}`;

        const decodedURI = decodeURIComponent(location.search);
        const searchQuery = new URLSearchParams(decodedURI);
        const id = parseInt(searchQuery.get("id"));

        if (id === 0) {
            history.replace({
                pathname: '/purchase-invoice-details',
                search: queryString,
                state: { instanceKey: uniqueId() }
            });
        } else {
            history.push({
                pathname: '/purchase-invoice-details',
                search: queryString,
                state: { instanceKey: uniqueId() }
            });
        }
    }

    const openDocumentMapping = (docID, documentNo) => {
        window.parent.postMessage(getPurchaseInvoiceRelations(docID, documentNo), "*");
    }

    const openCopyFromPopUp = async (docType) => {
        const errors = await formikRef.current.validateField("Vendor");
        if (!isEmpty(errors)) return;

        setCopyFromPopUpVisible(true);
        setCopyFromDocType(docType);

        if (docType === "PO") {
            setCopyFromPopUpTitle("Transfer from Purchase Order");
        }
        else if (docType === "GR") {
            setCopyFromPopUpTitle("Transfer from Good Receive");
        }

    }
    //#endregion

    return (
        <div className="invoice-details" style={{ marginBottom: 40 }}>
            <div className="module-navbar">
                <Toolbar1
                    copyBtnText="CopyFrom"
                    saveBtnText="SaveDraft"
                    displayBackBtn={piDtlAccess.BtnShowBack}
                    displayPostBtn={piDtlAccess.BtnShowPost}
                    displaySaveBtn={piDtlAccess.BtnShowSave}
                    displayAddNewBtn={piDtlAccess.BtnShowClear}
                    displayPreviewReport={piDtlAccess.BtnShowPrintReport}
                    displayPostPreview={piDtlAccess.BtnShowPostPreview}
                    displayPostNew={piDtlAccess.BtnShowPostNew}
                    displayCopyFrom={piDtlAccess.BtnShowCopyFrom}
                    displayCopyFromPO={piDtlAccess.BtnShowCopyFromPO}
                    displayCopyFromPGR={piDtlAccess.BtnShowCopyFromPGR}
                    displayCopyFromPRI={piDtlAccess.BtnShowCopyFromPRI}
                    displayCopyFromPI={piDtlAccess.BtnShowCopyFromPI}
                    displayMoreOptions={piDtlAccess.BtnShowMoreOptions}
                    displayAuditLog={piDtlAccess.BtnShowAuditLog}
                    displayDocumentMapping={piDtlAccess.BtnShowDocumentMapping}
                    displayDocumentAction={piDtlAccess.BtnShowDocumentAction}
                    displayACPosting={piDtlAccess.BtnShowACPosting}
                    displayPrintBarCode={piDtlAccess.BtnShowPrintBarcode}

                    //document info 
                    displayCreatedBy={createdBy}
                    displayCreatedDate={createdDate}
                    displayIAGDescription={iAGDescription}
                    displayFiscalYear={fiscalYear}
                    displayFiscalPeriod={fiscalPeriod}
                    showFiscal={true}

                    documentActionURL={piDtlAccess.DocumentActionURL}
                    documentMappingURL={piDtlAccess.DocumentMappingURL}
                    printBarcodeURL={piDtlAccess.PrintBarcodeURL}
                    auditLogURL={piDtlAccess.AuditLogURL}
                    documentNo={documentNo}
                    docID={docID}
                    saveCallback={() => formikRef.current.submitFormWithParam({ method: "SaveDraft" })}
                    postFn={() => formikRef.current.submitFormWithParam({ method: "Post" })}
                    postPreviewFn={() => formikRef.current.submitFormWithParam({ method: "PostPreview" })}
                    postAndNewFn={() => formikRef.current.submitFormWithParam({ method: "PostNew" })}
                    postDocumentActionFn={() => formikRef.current.submitFormWithParam({ method: "PostDocumentAction" })}
                    purchaseOrderFn={() => setCopyFromPopUpVisible(true)}
                    openCopyFromPopUp={openCopyFromPopUp}
                    previewReportFn={openPreviewReportTab}
                    reportFormatFn={() => setReportFormatPopUpVisible(true)}
                    openAuditLog={openAuditLog}
                    goBack={goBack}
                    openNew={openNew}
                    openDocumentMapping={openDocumentMapping}
                ></Toolbar1>
            </div>
            {copyFromPopUpVisible &&
                <CopyFromPopUp
                    {...userProfile}
                    copyFromCallBack={copyFromCallBack}
                    hidePopup={() => setCopyFromPopUpVisible(false)}
                    visible={copyFromPopUpVisible}
                    title={copyFromPopUpTitle}
                    copyFromDocType={copyFromDocType}
                    copyToDocType={"PI"}
                    openCopyFromPopUp={openCopyFromPopUp}
                    vendorID={formikRef.current?.values.Vendor?.VE_ID}
                    docID={docID}
                    warehouseID={formikRef.current?.values.WarehouseID}
                    contextMenuAccess={userAccess}
                    defaultSMI={DefaultSMIPIDetailPopupCopyFrom}
                />}
            {reportFormatPopUpVisible &&
                <ReportFormatPopUp
                    hidePopup={() => setReportFormatPopUpVisible(false)}
                    visible={reportFormatPopUpVisible}
                    title="Report Format"
                    docID={docID}
                    setDefaultReportFormat={setDefaultReportFormat}
                    defaultSMI={DefaultSMIPIDetailReportFormat}
                    contextMenuAccess={userAccess}
                />}
            <Flash
                parentCallback={() => setFlashMessageVisible(!flashMessageVisible)}
                message="Saved successfully."
                visible={flashMessageVisible}
                severity="success"
                container="flash-message-success-container"
            />
            <Collapse
                bordered={false}
                expandIconPosition="right"
                style={{ backgroundColor: "white" }}
                activeKey={activeKey}
            >
                <Panel
                    key="1"
                    extra={
                        <div
                            className="panel-drop-down"
                            onClick={() => handleDropDown("1")}
                            style={{
                                transform:
                                    checkKeyChangeIcon("1") === true
                                        ? "unset"
                                        : "rotate(0.5turn)",
                            }}
                        >
                            <i className="icon-arrow-up-icon" />
                        </div>
                    }
                    showArrow={false}
                    collapsible="disabled"
                    header={
                        <Row gutter={20} align="middle">
                            <Col>
                                <Title level={2} className="purchase-invoice-detail-title">
                                    {formatMessage("PurchaseInvoice")}
                                </Title>
                            </Col>
                            <Col>
                                <Tag className={docStatus}>{docStatus}</Tag>
                            </Col>
                            <Col className="possible-no">
                                {docStatus === "DRAFT"
                                    ? "(Next Possible No. " + nextPossibleNo + ")"
                                    : ""}
                            </Col>
                        </Row>
                    }
                >
                    <PurchaseInvoiceDetailsForm
                        formikRef={formikRef}
                        itemStateRef={itemStateRef}
                        warehouseList={warehouseList}
                        onVendorChange={onVendorChange}
                        paymentTerm={paymentTerm}
                        GetCompanyFiscalPeriod={GetCompanyFiscalPeriod}
                        safeSetCompanyFiscalPeriod={safeSetCompanyFiscalPeriod}
                        GetTaxTypeRatePurchase={GetTaxTypeRatePurchase}
                        safeSetCompanyTaxTypeList={safeSetCompanyTaxTypeList}
                        GetCurrencyRate={GetCurrencyRate}
                        documentNoFormat={documentNoFormat}
                        vendorList={vendorList}
                        piDtlAccess={piDtlAccess}
                        showDocNoFormat={showDocNoFormat}
                        purchaseAgent={purchaseAgent}
                        userProfile={userProfile}
                        Link_URLDocumentNo={Link_URLDocumentNo}
                        Link_URLPurAgent={Link_URLPurAgent}
                        Link_PaymentTerm={Link_PaymentTerm}
                        Link_URLWarehouse={Link_URLWarehouse}
                    />
                </Panel>
                <Panel
                    key="2"
                    className="ant-collapse2"
                    style={{
                        marginBottom: "26px",
                        paddingBottom: "14px",
                        marginTop: "20px",
                    }}
                    extra={
                        <div
                            className="panel-drop-down"
                            onClick={() => handleDropDown("2")}
                            style={{
                                transform:
                                    checkKeyChangeIcon("2") === true
                                        ? "unset"
                                        : "rotate(0.5turn)",
                                marginBottom: "10px",
                            }}
                        >
                            <i className="icon-arrow-up-icon" />
                        </div>
                    }
                    showArrow={false}
                    collapsible="disabled"
                    header={
                        <Tabs defaultActiveKey="1" onChange={tabsCallback} type="card">
                            <TabPane tab="Item" key="1" />
                            <TabPane tab="Logistics" key="2" />
                            <TabPane tab={<>Purchase<br />Organization</>} key="3" />
                            <TabPane tab={<>Additional<br />Notes</>} key="4" />
                        </Tabs>
                    }
                >
                    {tabKey === "1" && (
                        <PurchaseInvoiceDetailsItemForm
                            formikRef={formikRef}
                            iAGID={iAGID}
                            piDtlItem={piDtlItem}
                            GetBinLocByWarehouseID={GetBinLocByWarehouseID}
                            GetItemCostByWarehouse={GetItemCostByWarehouse}
                            userProfile={userProfile}
                            companyTaxTypeList={companyTaxTypeList}
                            warehouseList={warehouseList}
                            isAutoGeneratedPI={piDtlAccess?.isAutoGeneratedPI}
                            preferedLayoutRef={preferedLayoutRef}
                        />
                    )}
                    {tabKey === "2" && (
                        <PurchaseInvoiceDetailsLogisticsForm
                            formikRef={formikRef}
                            shippingMethod={shippingMethod}
                            Link_ShippingMethod={Link_ShippingMethod}
                            shippingMethodID={shippingMethodID}
                            userProfile={userProfile}
                            GetCityList={GetCityList}
                            cityList={cityList}
                            setCityList={setCityList}
                        />
                    )}
                    {tabKey === "3" && (
                        <PurchaseInvoiceDetailsOrganizationForm
                            formikRef={formikRef}
                            itemStateRef={itemStateRef}
                            purchaseOrganisation={purchaseOrganisation}
                            Link_URLPurOrg={Link_URLPurOrg}
                            purchaseGroup={purchaseGroup}
                            Link_URLPurGrp={Link_URLPurGrp}
                            isAutoGeneratedPI={piDtlAccess.isAutoGeneratedPI}
                            onPurchaseGroupChange={onPurchaseGroupChange}
                            GetWarehouseList={GetWarehouseList}
                            safeSetWarehouseList={safeSetWarehouseList}
                        />
                    )}
                    {tabKey === "4" && (
                        <PurchaseInvoiceDetailsNotesForm />
                    )}
                </Panel>
            </Collapse>
            <PurchaseInvoiceDetailsTotalForm
                itemStateRef={itemStateRef}
            />
        </div>
    );
};

export default memo(PurchaseInvoiceDetails);
