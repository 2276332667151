/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Select, Row, Col } from "antd";
import SelectOptGroup from "./SelectOptGroup";
import SelectOption from "./SelectOption";
import _ from "lodash";

import "../styles/DropdownInput.scss";

const { Option, OptGroup } = Select;

export const SelectRightIcon = (props) => {
    const {
        field,
        color = "#1dafed",
        headerColumn,
        dataColumn,
        onSelectRowClick,
        selected,
        selectParentRef,
        selectRef,
        inputRef,
        data,
        dropdownBool,
        setDropdownBool,
        updateSelectData,
        disabled
    } = props;
    const [onClickCount, setOnClickCount] = useState(0);
    const [onDropdownCount, setOnDropdownCount] = useState(0);

    const icon =
        dropdownBool === false ? (
            <i className="down-icon">
                <i className="icon-down-icon" style={{ color }} />
            </i>
        ) : (
            <i className="up-icon">
                <i className="icon-down-icon" style={{ color }} />
            </i>
        );

    useEffect(() => {
        if (document.activeElement === inputRef.current.input) setOnDropdownCount(onDropdownCount + 1);
    }, [document.activeElement]);

    const handleClick = () => {
        if (disabled) return;

        setOnClickCount(2);
        setOnDropdownCount(1);
        if (dropdownBool === false) updateSelectData();
        else setDropdownBool(!dropdownBool);
    }

    const onDropdownVisibleChange = (open) => {
        let count = onDropdownCount + 1;
        setTimeout(() => {
            if (!open && document.activeElement !== inputRef.current.input && onClickCount !== count) {
                setDropdownBool(open);
            }
        }, 0);
        setOnDropdownCount(onDropdownCount + 1);
    }

    const onKeyDownSelect = (event) => {
        if (event.key === "Enter") setDropdownBool(false);
    }

    return (
        <div className="select-after" ref={selectParentRef}>
            <Select
                {...field}
                ref={selectRef}
                onClick={handleClick}
                onChange={onSelectRowClick}
                onDropdownVisibleChange={onDropdownVisibleChange}
                open={dropdownBool}
                labelInValue
                value={{ value: selected }}
                getPopupContainer={(trigger) => selectParentRef?.current?.parentElement || trigger.parentElement}
                onInputKeyDown={onKeyDownSelect}
                suffixIcon={icon}
                disabled={disabled}
            >
                {headerColumn ? (
                    <OptGroup label={<SelectOptGroup headerColumn={headerColumn} dataColumn={dataColumn} />} >
                        {_.map(data, (da, dai) => {
                            return SelectOption({ dataColumn, dai, da });
                        })}
                    </OptGroup>
                ) : (
                    _.map(data, (v, i) => {
                        return (
                            <Option key={i} value={JSON.stringify(v)}>
                                <Row>
                                    {_.map(dataColumn, (dc, dcId) => {
                                        return <Col key={dcId}>{v[dc]}</Col>;
                                    })}
                                </Row>
                            </Option>
                        );
                    })
                )}
            </Select>
        </div>
    );
};
