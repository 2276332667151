import React, { useState, useRef, useEffect, useCallback } from "react";
import { unstable_batchedUpdates } from 'react-dom';
import { Row, Col, Typography } from "antd";
import { SearchInput, DropdownInput, TextInput, DateSinglePickerInput } from "../../fields";
import CityPopUp from "../../../components/pop-up/city-popup";
import { FastField } from "formik";
import { DefaultSMIPIDetailCityAdvSearch } from '../../../utils/default-smi';
import { formatMessage } from 'devextreme/localization';
import FastFieldWithProps from "../../fields/FastFieldWithProps";
import { useUserAccessContext } from "../../../contexts/UserAccess";
const { Title } = Typography;

export const PurchaseInvoiceDetailsLogisticsForm = (props) => {
    const {
        formikRef,
        shippingMethod,
        Link_ShippingMethod,
        shippingMethodID,
        userProfile,
        GetCityList,
        cityList,
        setCityList,
    } = props;

    const [cityModalVisible, setCityModalVisible] = useState(false);
    const portalRef = useRef(null);
    const { userAccess } = useUserAccessContext();

    const inputSpan = { xs: 24, sm: 12 };
    const emptyInputSpan = { xs: 0, sm: 12 };

    const setSelectedCityDataByPopup = (childData) => {
        const cityFieldValue = {
            CI_ID: childData.CI_ID,
            CI_Code: childData.CI_Code,
            CI_Description: childData.CI_Description
        };

        unstable_batchedUpdates(function () {
            formikRef.current.setFieldValue("City", cityFieldValue);
            formikRef.current.setFieldValue("State", childData.ST_Description);
            formikRef.current.setFieldValue("Country", childData.CO_Description);
        })
    };

    const setSelectedCityDataByInput = useCallback((data) => {
        let objData = data;

        if (data && data.filtervalue) {
            const city = cityList.find((city) => city.CI_ID === data.id);
            objData = city;
        }

        unstable_batchedUpdates(function () {
            if (objData) {
                const cityFieldValue = {
                    CI_ID: objData.CI_ID,
                    CI_Code: objData.CI_Code,
                    CI_Description: objData.CI_Description
                };

                formikRef.current.setFieldValue("City", cityFieldValue);
                formikRef.current.setFieldValue("State", objData.ST_Description);
                formikRef.current.setFieldValue("Country", objData.CO_Description);
            } else {
                formikRef.current.setFieldValue("City", null);
                formikRef.current.setFieldValue("State", "");
                formikRef.current.setFieldValue("Country", "");
            }
        })
    }, [cityList, formikRef]);

    useEffect(() => {
        async function fetchCityList() {
            const cityList = await GetCityList();
            setCityList(cityList);
        }

        if (!cityList) {
            fetchCityList();
        }
    }, [GetCityList, cityList, setCityList])

    return (
        <Row gutter={35}>
            <div className="portalRef dx-dropdowneditor-overlay" ref={portalRef} />

            {cityModalVisible &&
                <CityPopUp
                    parentSelectedData={setSelectedCityDataByPopup}
                    hidePopup={() => setCityModalVisible(false)}
                    visible={cityModalVisible}
                    title="Add Country, State and City"
                    addNewButtonText={formatMessage("AddNew")}
                    defaultSMI={DefaultSMIPIDetailCityAdvSearch}
                    contextMenuAccess={userAccess}
                />
            }

            <Col {...inputSpan}>
                <FastField
                    label="Shipping Method"
                    name="ShipMethodID"
                    dataSource={shippingMethod}
                    headerColumn={["Code", "Name"]}
                    dataColumn={["SM_Code", "SM_Description"]}
                    displayColumn="SM_Description"
                    filtervalue={["SM_Code", "SM_Description"]}
                    getColumnValue="SM_ID"
                    placeholder="Select Shipping Method"
                    tabLink={Link_ShippingMethod}
                    tabTitle={"Shipping Method"}
                    selectedId={shippingMethodID}
                    component={DropdownInput}
                />
            </Col>

            <Col {...emptyInputSpan}></Col>

            <Col {...inputSpan}>
                <FastField
                    label="Delivery Date"
                    name="DeliveryDate"
                    format={userProfile?.US_DateFormat}
                    portalRef={portalRef}
                    component={DateSinglePickerInput}
                />
            </Col>

            <Col span={24}>
                <Title level={2} style={{ fontFamily: "PoppinsMedium" }}>
                    Delivery Address
                </Title>
            </Col>

            <Col span={24}>
                <Row gutter={[35, 10]}>
                    <Col {...inputSpan}>
                        <FastField
                            label="Address"
                            name="Address1"
                            placeholder="Address 1"
                            component={TextInput}
                            maxLength={100}
                        />
                    </Col>
                    <Col {...inputSpan}>
                        <FastField
                            label={<div style={{ paddingTop: "8px" }} />}
                            name="Address2"
                            placeholder="Address 2"
                            component={TextInput}
                        />
                    </Col>
                    <Col {...inputSpan}>
                        <FastField
                            name="Address3"
                            placeholder="Address 3"
                            component={TextInput}
                        />
                    </Col>
                    <Col {...inputSpan}>
                        <FastField
                            name="Address4"
                            placeholder="Address 4"
                            component={TextInput}
                        />
                    </Col>
                </Row>
            </Col>

            <Col {...inputSpan}>
                <FastFieldWithProps
                    label="City"
                    name="City"
                    placeholder="City"
                    setModalVisible={setCityModalVisible}
                    component={SearchInput}
                    arrayTitle={["Code", "Description"]}
                    arrayColumn={["CI_Code", "CI_Description"]}
                    showTextColumn="CI_Description"
                    filtervalue={["CI_Code", "CI_Description"]}
                    keyColumn="CI_ID"
                    dataSource={cityList}
                    setSelectedData={setSelectedCityDataByInput}
                />
            </Col>

            <Col {...inputSpan}>
                <FastField
                    label="Postcode"
                    name="Postcode"
                    placeholder="Postcode"
                    component={TextInput}
                />
            </Col>

            <Col {...inputSpan}>
                <FastField
                    label="State"
                    name="State"
                    placeholder="State"
                    component={TextInput}
                    disabled
                />
            </Col>

            <Col {...inputSpan}>
                <FastField
                    label="Country"
                    name="Country"
                    placeholder="Country"
                    component={TextInput}
                    disabled
                />
            </Col>
        </Row>
    );
};
