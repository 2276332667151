import React, { useEffect, useRef, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom';
import { formatMessage } from "devextreme/localization";
import { Column, Editing, Summary, TotalItem } from "devextreme-react/data-grid";
import { DefaultPager } from '../../../utils/default-data-grid-settings';
import { GetItemWarehouseBinLocation } from '../../../api/purchase-invoice-details'
import { getErrorCode, setErrorPopUpMsg } from '../../../utils/error-popup-http-error-msg';
import { FormikDefault } from '../../forms/formik-default';
import { Token } from '../../../utils/default-cookies';
import { cloneDeep, sortBy } from "lodash";
import PopupDefault from "./popup-default";
import MultiSelectionDropdownTable from "../../dropdown-list/multi-selection-dropdown-table";
import EditCellNumber from "../../data-grid/custom-cell/edit-cell-number";
import DataGridDefault from "../../data-grid/data-grid-default";
import useFlashMsg from '../../modal-dialog/useFlashMsg';
import { useErrorPopupContext } from '../../modal-dialog/ErrorPopupProvider';
import { getEditCellProps } from '../../data-grid/custom-cell/util/edit-cell-util';
import { checkInvalidCell } from '../../data-grid/data-grid-util';
import { useUserAccessContext } from '../../../contexts/UserAccess';

const BinLocationPopup = (props) => {
    const {
        submitCallback,
        cancelCallback,
        visible,
        onHiding,
        onHidden,
    } = props;

    const {
        mainItem,
        subItemList: _subItemList,
        deleteOnCancel,
    } = props.popupData;

    const {
        PID_GroupID: groupID,
        PID_ItemID: itemID,
        PID_PIHID: PIHID,
        PID_WarehouseID: warehouseID,
        PID_Qty: requiredQty,
        CopyFrom: copyFrom,
    } = mainItem;

    const [subItemList, setSubItemList] = useState([]);
    const [binLocationList, setBinLocationList] = useState([]);

    const formikRef = useRef(null);
    const binLocationDropdownConfig = useRef({}); // Bin Location Dropdown DataGrid Config
    const dataGridRef = useRef(null);
    const apiLoaded = useRef(false);

    const showErrorPopup = useErrorPopupContext();
    const { userAccess } = useUserAccessContext();
    const { flashMsg, showFlashMsg } = useFlashMsg();

    // #region Util
    function getDataGrid() {
        return dataGridRef?.current?.instance;
    }
    // #endregion Util

    // #region Events
    async function onSubmit(values, actions) {
        function checkRequiredQty(total) {
            if (copyFrom?.DocTransferStatus === "FULL") {
                return total === requiredQty;
            } else {
                return true;
            }
        }

        await getDataGrid()?.refresh(true); // refresh data grid to get updated data rows

        const total = getDataGrid()?.getTotalSummaryValue("PID_Qty");

        if (await checkInvalidCell(subItemList)) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: "ContainInvalidCell"
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            actions.setSubmitting(false);
            return false;
        } else if (!total) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: "PleaseSelectAtLeastOneBinLocationEntry"
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle)
            actions.setSubmitting(false);
            return false;
        } else if (!checkRequiredQty(total)) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: `${formatMessage("TotalQuantityMustBeEqual")} (${requiredQty})`
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle, false);
            actions.setSubmitting(false);
            return false;
        } else {
            binLocationList.forEach(binLocation => {
                if (binLocation.Active) {
                    const exist = subItemList.find(subItem => subItem.PID_BinLocationID === binLocation.BinLoc.ID);
                    binLocation.Checked = exist ? true : false;
                }
            });

            const binLocationID = subItemList[0].PID_BinLocationID;
            const itemDetail = { ItemWarehouseBinLocationList: binLocationList };
            const editedMainItem = { ...mainItem, PID_Qty: total, PID_BinLocationID: binLocationID, itemDetail };
            const editedSubItem = subItemList;

            submitCallback({ editedMainItem, editedSubItem });
            actions.setSubmitting(false);
            return true;
        }
    }

    function onCancel() {
        if (deleteOnCancel) {
            cancelCallback?.(groupID);
        }
    }

    const onBinLocationChanged = (newBinLocationList) => {
        let subItemListCopy = cloneDeep(subItemList);

        // remove unchecked binLocation (exclude inActive binLocation)
        subItemListCopy = subItemListCopy.filter((item) => newBinLocationList.includes(item.PID_BinLocationID) || !item.itemDetail.Active);

        // add newly checked binLocation
        newBinLocationList.forEach((binLocation, index) => {
            const exist = subItemListCopy.find(item => item.PID_BinLocationID === binLocation);

            if (!exist) {
                const newBinLocation = cloneDeep(mainItem);
                const binLocationData = binLocationList.find(item => item.BinLoc.ID === binLocation);

                newBinLocation.PID_SubItem_YN = true;
                newBinLocation.PID_Qty = 0;
                newBinLocation.PID_BinLocationID = binLocationData.BinLoc.ID;

                newBinLocation.IsAlertRow = false;
                newBinLocation.IsInsufficientBal = false;

                newBinLocation.itemDetail = {
                    Active: binLocationData.Active,
                    QtyAvailable: binLocationData.QtyAvailable,
                    BinLocationCode: binLocationData.BinLoc.Code,
                };

                subItemListCopy.splice(index, 0, newBinLocation);
            }
        });

        setSubItemList(subItemListCopy);
    }
    // #endregion Events

    // #region Data Util
    function initData(subItemList, data) {
        function setBinLocationDropdownData(subItemList, binLocationList) {
            if (subItemList.length === 1) {
                // for single bin stock item, bin location is based on parent PID_BinLocationID
                binLocationList.forEach(item => {
                    if (item.BinLoc.ID === subItemList[0].PID_BinLocationID) {
                        item.Checked = true;
                    } else {
                        item.Checked = false;
                    }
                });
            }

            const activeBinLocation = binLocationList.filter(binLocation => binLocation.Active);
            const selectedKeys = binLocationList.filter(binLocation => binLocation.Checked && binLocation.Active);

            binLocationDropdownConfig.current = {
                dataSource: activeBinLocation,
                defaultValue: selectedKeys,
                caption: formatMessage("BinLocation"),
                placeholder: formatMessage("SelectBinLocation"),
                columns: [
                    { dataField: "BinLoc.Code", caption: formatMessage("BinLocation"), customSearch: true },
                    { dataField: "QtyAvailable", caption: formatMessage("QtyAvailable") }
                ],
                keyExpr: "BinLoc.ID",
                displayExpr: "BinLoc.Code",
            }
        }

        unstable_batchedUpdates(function () {
            const { ItemWarehouseBinLocationList } = data;

            let subItemListCopy = cloneDeep(subItemList);
            subItemListCopy.forEach(subItem => {
                const binLocation = ItemWarehouseBinLocationList.find(item => item.BinLoc.ID === subItem.PID_BinLocationID);
                subItem.itemDetail = {};

                if (!binLocation) {
                    subItem.itemDetail.Active = false;
                    subItem.itemDetail.QtyAvailable = 0;
                    subItem.itemDetail.BinLocationCode = "";
                } else {
                    subItem.itemDetail.Active = binLocation.Active;
                    subItem.itemDetail.QtyAvailable = binLocation.QtyAvailable;
                    subItem.itemDetail.BinLocationCode = binLocation.BinLoc.Code;
                }
            });
            subItemListCopy = sortBy(subItemListCopy, [function (binLocation) { return !binLocation.itemDetail.Active; }]);

            setSubItemList(subItemListCopy);
            setBinLocationDropdownData(subItemListCopy, ItemWarehouseBinLocationList);
            setBinLocationList(ItemWarehouseBinLocationList);

            apiLoaded.current = true;
        })
    }
    // #endregion Data Util

    // #region Data Fetch API
    const fetchInitialData = React.useCallback(() => {
        if (!Token()) return;

        setTimeout(() => {
            getDataGrid()?.beginCustomLoading();
        }, 0);

        GetItemWarehouseBinLocation(Token(), itemID, warehouseID, PIHID, groupID).then((data) => {
            if (typeof data === "string" && data.includes("Error")) {
                const errorCode = getErrorCode(data);
                const errorMsg = setErrorPopUpMsg(errorCode);

                showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            } else {
                const { ItemWarehouseBinLocationList } = data;
                initData(_subItemList, { ItemWarehouseBinLocationList });
            }
        }).finally(() => {
            getDataGrid()?.endCustomLoading();
        })
    }, [_subItemList, itemID, warehouseID, PIHID, groupID, showErrorPopup]);
    // #endregion Data Fetch API

    // #region UseEffect
    useEffect(() => {
        if (!mainItem.itemDetail) {
            fetchInitialData();
        } else {
            initData(_subItemList, mainItem?.itemDetail);
        }
    }, [_subItemList, mainItem, fetchInitialData]);
    // #endregion UseEffect

    // #region Render
    const qtyCell = (cellData) => {
        const { info, ...editCellProps } = getEditCellProps(cellData);
        const isEditable = info.data?.itemDetail?.Active;

        return (
            <EditCellNumber
                {...editCellProps}
                isEditable={isEditable}
                min={0}
            />
        );
    }
    // #endregion Render

    return (
        <FormikDefault
            initialValues={{}}
            onSubmit={onSubmit}
            formikRef={formikRef}
        >
            <PopupDefault
                className="purchase-invoice-popup"
                title={formatMessage("BinLocation")}
                visible={visible}
                onHiding={onHiding}
                onHidden={onHidden}
                cancelForm={onCancel}
                apiLoaded={apiLoaded.current}
                formikRef={formikRef}
                maxWidth="500px"
                maxHeight="600px"
            >
                {flashMsg}

                <MultiSelectionDropdownTable
                    {...binLocationDropdownConfig.current}
                    onChangeCallback={onBinLocationChanged}
                    disabled={!apiLoaded.current}
                />

                <div className="module-data-grid-popup" style={{ marginTop: "16px" }}>
                    <DataGridDefault
                        // Styling
                        className='dx-datagrid-items datagrid-normalize'
                        // DevExtreme DataGrid Configurations
                        dataSource={subItemList}
                        keyExpr="PID_BinLocationID"
                        allowDisplayColumnChooser={userAccess.allowDisplayColumnChooser}
                        allowExportGrid={userAccess.allowExportGrid}
                        allowRestoreLayout={userAccess.allowRestoreLayout}
                        allowedPageSizes={DefaultPager.allowedPageSizes_details}
                        defaultPageSize={DefaultPager.defaultPageSize_details}
                        noDataText={formatMessage("dxDataGrid-noDataText")}
                        focusedRowEnabled
                        // Custom Configurations
                        dataGridRef={dataGridRef}
                        showMsgHandler={showFlashMsg}
                        exportFileName="StockItemPopup"
                    >
                        <Editing
                            mode="cell"
                            allowUpdating
                        />
                        <Column // Summary Total
                            visibleIndex={0}
                            fixedPosition={'left'}
                            fixed={true}
                            width={0}
                            allowExporting={false}
                            allowFiltering={false}
                            allowHeaderFiltering={false}
                            allowHiding={false}
                            allowReordering={false}
                            allowResizing={false}
                            allowSorting={false}
                            showInColumnChooser={false}
                            cssClass="column-normalize"
                            dataField={'_TOTAL'} // dummy dataField for summary
                            caption={null}
                        />
                        <Column
                            caption={formatMessage("BinLocation")}
                            dataField="itemDetail.BinLocationCode"
                        />
                        <Column
                            caption={formatMessage("SmallCapitalQty")}
                            dataField="PID_Qty"
                            allowEditing
                            cssClass="column-normalize"
                            cellRender={qtyCell}
                            editCellComponent={qtyCell}
                            dataType="number"
                        />
                        <Column
                            dataField="itemDetail.QtyAvailable"
                            caption={formatMessage("QtyAvailable")}
                        />

                        <Summary>
                            <TotalItem
                                summaryType="count"
                                showInColumn="_TOTAL"
                                displayFormat={`${formatMessage("Total")}:`}
                                cssClass="summary-overflow"
                                skipEmptyValues
                            />
                            <TotalItem
                                summaryType="sum"
                                column="PID_Qty"
                                displayFormat="{0}"
                            />
                        </Summary>
                    </DataGridDefault>
                </div>
            </PopupDefault>
        </FormikDefault>
    );
};

export default BinLocationPopup