import React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Column, Editing, Summary, TotalItem, RequiredRule, PatternRule } from 'devextreme-react/data-grid';
import './package-item.scss';

//#region customized 
//components
import Toolbar1 from '../../../components/toolbar/toolbar-1.js';
import ItemPopUp from '../../../components/pop-up/item-popup';
import DeletePopUp from '../../../components/pop-up/delete-popup';
import Flash from '../../../components/message/flash'
import ErrorPopUp from '../../../components/pop-up/error-popup';

//layouts
import { ListingHeaderPackageItemDetails } from '../../../layouts';

//default 
import { DefaultPager } from '../../../utils/default-data-grid-settings';
import { DefaultSMIPackageItemDetails, DefaultSMIPackageItemDetailItemAdvSearch, DefaultSMIAuditLog } from '../../../utils/default-smi';

//cookies
import { SetCookies, CompanyID, UserID, ClientID, DecimalPoints, Token, Username, AdminYN, SetDefaultName, SetDecimalPoints, LicenseExpired } from '../../../utils/default-cookies';

//api
import {
    PostPackageItem,
    getParentItemID,
    updateItemUOMPrice,
    GetItemUOMByItemID,
    changePackageSubItemPrice,
    computePackageItemTotalPrice,
    GetChildPackageItemByCompanyID,
    GetUserGroupAccessRightsByModuleItem,
    GetItemByItemCode,
    GetAllCompaniesIAGByUser
} from './package-item-services';
import { GetUserLayout } from '../../../api/user-preferred-layout';
import Cookies from 'universal-cookie';

import { formatMessage } from 'devextreme/localization';
import { DataGridDefault, deleteBtnRenderer, DragBtn, RowNumCell } from '../../../components';
import { mousetrapMain } from '../../../App';
import { getReactParameters } from '../../../utils/iframe-func';

//#endregion

const cookies = new Cookies();

class PackageItemDetails extends React.Component {
    constructor(props) {
        super(props);

        this.onClickAddNewItem = this.onClickAddNewItem.bind(this);
        this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
        this.addItem = this.addItem.bind(this);
        this.deletePopupAccept = this.deletePopupAccept.bind(this);
        this.hidePopup = this.hidePopup.bind(this);
        this.onFlashMessageTimer = this.onFlashMessageTimer.bind(this);

        //Datagrid render
        this.itemCodeCell = this.itemCodeCell.bind(this);
        this.onKeyDelete = this.onKeyDelete.bind(this);

        //component instance
        this.dataGridRef = React.createRef();
        this.childHeader = React.createRef();

        this.onCellPrepared = this.onCellPrepared.bind(this);
        this.onContentReady = this.onContentReady.bind(this);

        //data pass from child handler
        this.searchValueChangeHandler = this.searchValueChangeHandler.bind(this);
        this.packageItemPriceChangeHandler = this.packageItemPriceChangeHandler.bind(this);
        this.showMsgHandler = this.showMsgHandler.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.handleChildPackageItems = this.handleChildPackageItems.bind(this);
        this.handleLinkBtnUrlAuditLog = this.handleLinkBtnUrlAuditLog.bind(this);
        this.handleParentItem = this.handleParentItem.bind(this);
        this.handleItemUOM = this.handleItemUOM.bind(this);
        this.closeFlashMessageCallback = this.closeFlashMessageCallback.bind(this);
        this.openSourceTab = this.openSourceTab.bind(this);
        this.savePackageItemDetails = this.savePackageItemDetails.bind(this);
        this.goBack = this.goBack.bind(this);
        this.openAuditLog = this.openAuditLog.bind(this);
        this.onKeydownEvent = this.onKeydownEvent.bind(this);
        this.deletePopupReject = this.deletePopupReject.bind(this);
        this.handleAllowSave = this.handleAllowSave.bind(this);
        this.handleAllowDelete = this.handleAllowDelete.bind(this);
        this.handleAllowAdd = this.handleAllowAdd.bind(this);
        this.handleAllowExportGrid = this.handleAllowExportGrid.bind(this);
        this.handleAllowDisplayColumnChooser = this.handleAllowDisplayColumnChooser.bind(this);
        this.handleAllowRestoreLayout = this.handleAllowRestoreLayout.bind(this);
        this.handleAllowSaveGridLayout = this.handleAllowSaveGridLayout.bind(this);
        this.handleDisplayAuditLog = this.handleDisplayAuditLog.bind(this);
        this.handleSessionPopUpCallBack = this.handleSessionPopUpCallBack.bind(this);
        this.handleErrorCommandPopUpCallBack = this.handleErrorCommandPopUpCallBack.bind(this);
        this.handleDocumentOptions = this.handleDocumentOptions.bind(this);
        this.handleNoDataText = this.handleNoDataText.bind(this);
        this.handlePreferedLayout = this.handlePreferedLayout.bind(this);
        this.calculateTotalPriceSummary = this.calculateTotalPriceSummary.bind(this);
        this.calculateTotalPriceSummaryByColumn = this.calculateTotalPriceSummaryByColumn.bind(this);

        this.state = {
            inputHasValue: false,
            inputValue: '',
            searchText: '',
            parentItemId: 0,
            parentItemCode: '',
            parentItemDesp: '',
            iAGID: 0,
            packageItemList: [],
            itemUOMList: [],
            displayPopup: false,
            displaySessionPopUp: false,
            displayAuditLog: false,
            displayErrorCommandPopUp: false,
            displayDeleteConfirmationDialog: false,
            newItem: {},
            emptyMsg: '',
            iagDesp: '',
            createdBy: '',
            createdDate: '',
            displayFlashMessageDialog: false,
            status: 'success',
            size: 'flash-message-success-container',
            packageItemPrice: {
                totalPrice1: 0,
                totalPrice2: 0,
                totalPrice3: 0,
                totalPrice4: 0,
                totalPrice5: 0,
                totalPrice6: 0,
                totalPurchasePrice: 0
            },
            packageItemPriceRatio: {
                ratioPrice1: 0,
                ratioPrice2: 0,
                ratioPrice3: 0,
                ratioPrice4: 0,
                ratioPrice5: 0,
                ratioPrice6: 0,
                ratioPurchasePrice: 0,
            },
            allowBack: true,
            allowSave: false,
            allowDelete: false,
            allowAdd: false,
            allowExportGrid: false,
            allowDisplayColumnChooser: false,
            allowRestoreLayout: false,
            allowSaveGridLayout: false,
            LinkBtnUrlAuditLog: '',
            noDataText: formatMessage("Loading"),
            preferedLayout: [],
            format: {
                type: 'fixedPoint',
                precision: DecimalPoints() > 0 ? DecimalPoints() : 2,
            }
        };

        //rules
        this.numberRules = {
            X: "^\\d+$"
        };

        this.receiveMessage = this.receiveMessage.bind(this);
        this.fetchDataAPI = this.fetchDataAPI.bind(this);
        this.updatePackageSubItemRounding = this.updatePackageSubItemRounding.bind(this);
    }

    get dataGrid() {
        // `current.instance` points to the UI component instance
        return this.dataGridRef.current.instance;
    }

    dataGridNullCheck() {
        return this.dataGridRef.current != null;
    }

    hidePopup() {
        this.setState({ displayPopup: false });
    }

    closeFlashMessageCallback() {
        this.setState(state => ({
            displayFlashMessageDialog: !state.displayFlashMessageDialog
        }))
    }

    addItem(newItem) {
        let hasChanges = false;

        for (let i = 0; i < newItem.length; i++) {
            //continues with the next if same item has been added.
            var sameItem = this.state.packageItemList.filter(function (item) {
                return item.ItemCode === newItem[i].ItemCode;
            });
            if (sameItem.length > 0) { continue; }

            hasChanges = true; // new itme added

            newItem[i].PI_Sequence = this.state.packageItemList.length + 1;

            this.setState(
                { packageItemList: [...this.state.packageItemList, newItem[i]] }
            )
        }

        if (hasChanges) {
            if (this.dataGridNullCheck()) {
                // clear filter and sorting, navigate to last
                this.dataGrid.clearFilter();
                this.dataGrid.clearSorting();
                this.dataGrid.option('navigateToLast', true);
            }

            this.childHeader.setCurrentPackageItemList(this.state.packageItemList);
        }

        this.hidePopup();
    }

    handleChildPackageItems = (result) => {
        this.setState({ packageItemList: result });
    }

    handleLinkBtnUrlAuditLog = (result) => {
        this.setState({ LinkBtnUrlAuditLog: result });
    }

    handleParentItem = (id, code, desp, iag) => {
        this.setState({
            parentItemId: id,
            parentItemCode: code,
            parentItemDesp: desp,
            iAGID: iag
        });
    }

    handleItemUOM = (result) => {
        this.setState({ itemUOMList: result });
    }

    handleAllowSave = (result) => {
        this.setState({ allowSave: result });
    }

    handleDisplayAuditLog = (result) => {
        this.setState({ displayAuditLog: result })
    }

    handleAllowDelete = (result) => {
        this.setState({ allowDelete: result });
    }

    handleAllowAdd = (result) => {
        this.setState({ allowAdd: result });
    }

    handleAllowDisplayColumnChooser = (result) => {
        this.setState({ allowDisplayColumnChooser: result });
    }

    handleAllowExportGrid = (result) => {
        this.setState({ allowExportGrid: result });
    }

    handleAllowRestoreLayout = (result) => {
        this.setState({ allowRestoreLayout: result });
    }

    handleAllowSaveGridLayout = (result) => {
        this.setState({ allowSaveGridLayout: result });
    }

    handleSessionPopUpCallBack = () => {
        this.setState(state => ({
            displaySessionPopUp: !state.displaySessionPopUp
        }))
    }

    handleErrorCommandPopUpCallBack = () => {
        this.setState(state => ({
            displayErrorCommandPopUp: !state.displayErrorCommandPopUp
        }))
        this.childHeader.setFocus();
    }

    handleNoDataText = (result) => {
        this.setState({ noDataText: formatMessage(result) });
    }

    handlePreferedLayout = (result) => {
        this.setState({ preferedLayout: result });
    }

    handleFormat = () => {
        this.setState(prevState => ({
            format: {
                ...prevState.format,
                precision: DecimalPoints() > 0 ? DecimalPoints() : 2,
            }
        }));
    }

    onKeydownEvent(event) {
        if (event.keyCode === 13) {
            // enter key
            if (this.state.inputValue !== '') {
                var inputQty = this.state.inputValue.substring(0, 1);

                if (inputQty === "*") {
                    var qty = this.state.inputValue.substring(1);
                    var tempPackageItem = this.state.packageItemList;
                    tempPackageItem[this.dataGrid.option('focusedRowIndex')].PI_Qty = qty;
                    this.setState({
                        packageItemList: tempPackageItem
                    });

                    this.dataGrid.cellValue(this.dataGrid.option('focusedRowIndex'), 'PI_Qty', qty);
                    this.dataGrid.refresh(true); // refresh to re-calculate the footer total
                }
                else {
                    this.GetItemByCode(this.state.inputValue);
                }
            }

            this.childHeader.clearText();
        }

        if (event.keyCode === 13 && this.state.displayErrorCommandPopUp === true) {
            // enter key
            this.handleErrorCommandPopUpCallBack();
        }
    }

    //#region footer
    calculateTotalPriceSummaryByColumn(options, name, SalesPrice) {
        var base = 10 ** this.state.format.precision;
        if (options.name === name) {
            if (options.summaryProcess === "start") {
                options.totalValue = 0;
            }
            if (options.summaryProcess === "calculate") {
                options.totalValue += (options.value.PI_Qty * (Math.round(parseFloat((options.value[SalesPrice] * base).toFixed(10))) / base));
            }
        }
    }

    calculateTotalPriceSummary(options) {
        this.calculateTotalPriceSummaryByColumn(options, options.name, options.name);
    }

    //#endregion
    componentDidMount() {
        window.addEventListener("message", this.receiveMessage, false);

        document.addEventListener("keydown", this.onKeydownEvent, false);
        document.addEventListener('keydown', this.onFlashMessageTimer, false);
        document.addEventListener('mousedown', this.onFlashMessageTimer, false);

        mousetrapMain.bind("ins", this.onClickAddNewItem);
        mousetrapMain.bind('del', this.onClickDeleteItem);

        if (Token()) {
            this.fetchDataAPI();
        } else {
            // prompt Alaya 1.0 to re-postMessage for session data after receiveMessage eventListener is registered
            // to handle issue where initial postMessage is sent before eventListener is registered (iOS and macOS)
            window.parent.postMessage(getReactParameters(), "*");
        }

        if (!this.props.location.state?.fromListing) {
            // If not from listing page, hide backBtn (new tab from item creation page)
            this.setState({ allowBack: false });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.receiveMessage, false);

        document.removeEventListener("keydown", this.onKeydownEvent, false);
        document.removeEventListener('keydown', this.onFlashMessageTimer, false);
        document.removeEventListener('mousedown', this.onFlashMessageTimer, false);

        mousetrapMain.unbind("ins");
        mousetrapMain.unbind('del');
    }

    receiveMessage(event) {
        if (event?.data?.accessToken && !Token()) {
            // set cookies
            SetCookies(cookies, event.data);

            // fetch API
            this.fetchDataAPI();
        }
    }

    async fetchDataAPI() {
        const unescapeUrl = decodeURIComponent(this.props.location.search);
        const packageitemID = parseInt(new URLSearchParams(unescapeUrl).get("id"));
        const packageitemCode = new URLSearchParams(unescapeUrl).get("code");
        const iAGID = new URLSearchParams(unescapeUrl).get("iag");
        const iAG_Description = new URLSearchParams(unescapeUrl).get("iag_desp");
        this.setState({ iAGID: iAGID });

        const [dataSourceCompany, dataSource_ChildPackageItem, dataSource_ItemUOM, UserPreferedLayout, UserAccessControl, AuditLogAccessControl] = await Promise.all([
            GetAllCompaniesIAGByUser(Token(), ClientID(), UserID(), AdminYN()),
            GetChildPackageItemByCompanyID(Token(), CompanyID(), packageitemID, iAGID),
            GetItemUOMByItemID(Token(), packageitemID),
            GetUserLayout(Token(), UserID(), DefaultSMIPackageItemDetails.moduleURL, DefaultSMIPackageItemDetails.controlID),
            GetUserGroupAccessRightsByModuleItem(Token(), UserID(), DefaultSMIPackageItemDetails.moduleURL, CompanyID()),
            GetUserGroupAccessRightsByModuleItem(Token(), UserID(), DefaultSMIAuditLog.moduleURL, CompanyID()),
        ])

        unstable_batchedUpdates(() => {
            if (dataSourceCompany === 'Error: 401' || dataSource_ChildPackageItem === 'Error: 401' || dataSource_ItemUOM === 'Error: 401' || UserPreferedLayout === 'Error: 401' || UserAccessControl === 'Error: 401' || AuditLogAccessControl === 'Error: 401') {
                this.handleSessionPopUpCallBack();
            } else {
                this.handleDocumentOptions(dataSource_ChildPackageItem.Query_PackageChildItem, iAG_Description);
                this.handleChildPackageItems(dataSource_ChildPackageItem.Query_PackageChildItem);
                this.handleParentItem(packageitemID, packageitemCode, dataSource_ChildPackageItem.ParentItemDescription, iAGID);
                this.handleItemUOM(dataSource_ItemUOM);
                this.handleLinkBtnUrlAuditLog(dataSource_ChildPackageItem.LinkBtnUrlAuditLog);
                this.childHeader.setCurrentPackageItemList(dataSource_ChildPackageItem.Query_PackageChildItem);
                this.handlePreferedLayout(UserPreferedLayout);

                const currentCompanyData = dataSourceCompany.find(x => x.CO_ID === CompanyID());
                SetDefaultName(cookies, currentCompanyData.CO_Name);
                SetDecimalPoints(cookies, currentCompanyData.IAG_ItemRounding);
                this.handleFormat();

                if (LicenseExpired() === "true") {
                    this.handleAllowSave(false);
                    this.handleAllowDelete(false);
                    this.handleAllowAdd(false);
                    this.handleAllowDisplayColumnChooser(false);
                    this.handleAllowExportGrid(false);
                    this.handleAllowRestoreLayout(false);
                    this.handleAllowSaveGridLayout(false);
                    this.handleDisplayAuditLog(true);
                } else if (AdminYN() === "true") {
                    this.handleAllowSave(true);
                    this.handleAllowDelete(true);
                    this.handleAllowAdd(true);
                    this.handleAllowDisplayColumnChooser(true);
                    this.handleAllowExportGrid(true);
                    this.handleAllowRestoreLayout(true);
                    this.handleAllowSaveGridLayout(true);
                    this.handleDisplayAuditLog(true);
                } else {
                    this.handleAllowSave(UserAccessControl.Query_UserModuleItemAccessSimplified[0].SaveEdit);
                    this.handleAllowDelete(UserAccessControl.Query_UserModuleItemAccessSimplified[0].Delete);
                    this.handleAllowAdd(UserAccessControl.Query_UserModuleItemAccessSimplified[0].Add);

                    this.handleAllowDisplayColumnChooser(UserAccessControl.Query_UserModuleItemAccessSimplified[0].GridColumnChooser);
                    this.handleAllowExportGrid(UserAccessControl.Query_UserModuleItemAccessSimplified[0].ExportGrid);
                    this.handleAllowRestoreLayout(UserAccessControl.Query_UserModuleItemAccessSimplified[0].RestoreGridLayout);
                    this.handleAllowSaveGridLayout(UserAccessControl.Query_UserModuleItemAccessSimplified[0].SaveGridLayout);

                    this.handleDisplayAuditLog(AuditLogAccessControl.Query_UserModuleItemAccessSimplified[0].View);
                }
            }

            this.handleNoDataText("dxDataGrid-noDataText");
        });
    }

    handleDocumentOptions = (dataSource_ChildPackageItem, iAG_Description) => {

        if (dataSource_ChildPackageItem.length <= 0) {
            this.setState({
                createdBy: "",
                createdDate: "",
                iagDesp: iAG_Description
            })
        }
        else {
            var getChildPackage = dataSource_ChildPackageItem.find(x => x.PI_ID);
            const createdDate = getChildPackage.Created_Date;
            const getDateTime = new Date(createdDate);
            const childPackageDateTime = getDateTime.getDate() + '/' + getDateTime.getMonth() + '/' + getDateTime.getFullYear() + ' ' + getDateTime.getHours() + ":" + getDateTime.getMinutes() + ":" + getDateTime.getSeconds();
            this.setState({
                createdBy: getChildPackage.Created_By,
                createdDate: childPackageDateTime,
                iagDesp: iAG_Description
            })
        }
    }

    //#region handler for data pass from child
    searchValueChangeHandler = (childData) => {

        if (childData !== '') {
            this.setState({ inputHasValue: true, inputValue: childData })
        }
        else {
            this.setState({ inputHasValue: false, inputValue: childData })
        }
    }

    async GetItemByCode(itemCode) {
        var item = await Promise.all([GetItemByItemCode(Token(), ClientID(), this.state.iAGID, itemCode)]);

        if (item[0] !== undefined && item[0].PackageSubItem.length !== 0) {

            item = item[0].PackageSubItem;
            item[0].PI_ID = 0;
            item[0].PI_ParentItemID = this.state.parentItemId;
            item[0].PI_Qty = 1;
            item[0].Created_By = Username();
            item[0].Created_Date = Date.now();
            item[0].PI_Group = this.state.parentItemId;
            delete item[0].PI_ChildItemModifierID;

            this.addItem(item);
        }
        else {
            this.handleErrorCommandPopUpCallBack();
        }
    }

    showMsgHandler(params, value, size) {

        if (params !== '') {
            this.setState({ emptyMsg: params, displayFlashMessageDialog: true, status: value, size: size });
        }
        else {
            this.setState({ emptyMsg: '', displayFlashMessageDialog: false, status: value, size: size });
        }
    }

    onFlashMessageTimer() {
        if (this.state.displayFlashMessageDialog === true) {
            setTimeout(() => this.setState({ displayFlashMessageDialog: false }), 1000)
        }
    }

    packageItemPriceChangeHandler = (newItemPrice, newSystemItemPrice, newPackageItemList, newPackageItemPriceRatio) => {
        this.setState({
            packageItemPrice: newItemPrice,
            packageItemPriceRatio: newPackageItemPriceRatio,
            packageItemList: newPackageItemList
        });
        this.childHeader.setCurrentPackageItemList(this.state.packageItemList);

        //get the last item that has qty = 1
        var result = newPackageItemList.filter(x => x.PI_Qty === 1);

        if (result.length > 0)
            this.updatePackageSubItemRounding(newItemPrice, newSystemItemPrice, result);
    }

    updatePackageSubItemRounding(newItemPrice, newSystemItemPrice, result) {

        //compare new total price & old total price
        var roudingDiff = 0
        if ((newItemPrice.totalPrice1 !== '' && newItemPrice.totalPrice1 !== null) && newSystemItemPrice.totalPrice1 !== (newItemPrice.totalPrice1 === null ? 0 : newItemPrice.totalPrice1)) {
            roudingDiff = newSystemItemPrice.totalPrice1 - newItemPrice.totalPrice1;
            changePackageSubItemPrice(this.state.packageItemList, "PI_SalesPrice1", roudingDiff, result[0].PI_ID);
        }
        if ((newItemPrice.totalPrice2 !== '' && newItemPrice.totalPrice2 !== null) && newSystemItemPrice.totalPrice2 !== (newItemPrice.totalPrice2 === null ? 0 : newItemPrice.totalPrice2)) {
            roudingDiff = newSystemItemPrice.totalPrice2 - newItemPrice.totalPrice2;
            changePackageSubItemPrice(this.state.packageItemList, "PI_SalesPrice2", roudingDiff, result[0].PI_ID);
        }
        if ((newItemPrice.totalPrice3 !== '' && newItemPrice.totalPrice3 !== null) && newSystemItemPrice.totalPrice3 !== (newItemPrice.totalPrice3 === null ? 0 : newItemPrice.totalPrice3)) {
            roudingDiff = newSystemItemPrice.totalPrice3 - newItemPrice.totalPrice3;
            changePackageSubItemPrice(this.state.packageItemList, "PI_SalesPrice3", roudingDiff, result[0].PI_ID);
        }
        if ((newItemPrice.totalPrice4 !== '' && newItemPrice.totalPrice4 !== null) && newSystemItemPrice.totalPrice4 !== (newItemPrice.totalPrice4 === null ? 0 : newItemPrice.totalPrice4)) {
            roudingDiff = newSystemItemPrice.totalPrice4 - newItemPrice.totalPrice4;
            changePackageSubItemPrice(this.state.packageItemList, "PI_SalesPrice4", roudingDiff, result[0].PI_ID);
        }
        if ((newItemPrice.totalPrice5 !== '' && newItemPrice.totalPrice5 !== null) && newSystemItemPrice.totalPrice5 !== (newItemPrice.totalPrice5 === null ? 0 : newItemPrice.totalPrice5)) {
            roudingDiff = newSystemItemPrice.totalPrice5 - newItemPrice.totalPrice5;
            changePackageSubItemPrice(this.state.packageItemList, "PI_SalesPrice5", roudingDiff, result[0].PI_ID);
        }
        if ((newItemPrice.totalPrice6 !== '' && newItemPrice.totalPrice6 !== null) && newSystemItemPrice.totalPrice6 !== (newItemPrice.totalPrice6 === null ? 0 : newItemPrice.totalPrice6)) {
            roudingDiff = newSystemItemPrice.totalPrice6 - newItemPrice.totalPrice6;
            changePackageSubItemPrice(this.state.packageItemList, "PI_SalesPrice6", roudingDiff, result[0].PI_ID);
        }
        if ((newItemPrice.totalPurchasePrice !== '' && newItemPrice.totalPurchasePrice !== null) && newSystemItemPrice.totalPurchasePrice !== (newItemPrice.totalPurchasePrice === null ? 0 : newItemPrice.totalPurchasePrice)) {
            roudingDiff = newSystemItemPrice.totalPurchasePrice - newItemPrice.totalPurchasePrice;
            changePackageSubItemPrice(this.state.packageItemList, "PI_PurchasePrice", roudingDiff, result[0].PI_ID);
        }
    }

    handleCallback = (childData) => {
        // deep clone method
        var newSubItem = JSON.parse(JSON.stringify(childData));
        var i;
        for (i = 0; i < newSubItem.length; i++) {
            newSubItem[i].PI_ID = 0;
            newSubItem[i].PI_ParentItemID = this.state.parentItemId;
            newSubItem[i].PI_ChildItemID = childData[i]['IM_ID'];
            newSubItem[i].PI_Qty = 1;
            newSubItem[i].PI_ClientID = childData[i]['IM_ClientID'];
            newSubItem[i].PI_SalesPrice1 = childData[i]['IU_SalesPrice1'];
            newSubItem[i].PI_SalesPrice2 = childData[i]['IU_SalesPrice2'];
            newSubItem[i].PI_SalesPrice3 = childData[i]['IU_SalesPrice3'];
            newSubItem[i].PI_SalesPrice4 = childData[i]['IU_SalesPrice4'];
            newSubItem[i].PI_SalesPrice5 = childData[i]['IU_SalesPrice5'];
            newSubItem[i].PI_SalesPrice6 = childData[i]['IU_SalesPrice6'];
            newSubItem[i].PI_PurchasePrice = childData[i]['IU_MinSalesPrice'];
            newSubItem[i].ChildItemCode = childData[i]['IM_Code'];
            newSubItem[i].ChildItemDescription = childData[i]['IM_Description'];
            newSubItem[i].ChildItemCode_URL = childData[i]['IM_Code_URL'];
            newSubItem[i].Created_By = Username();
            newSubItem[i].Created_Date = Date.now();
            newSubItem[i].Description = childData[i]['IM_Description'];
            newSubItem[i].ItemCode = childData[i]['IM_Code'];
            newSubItem[i].PI_ModifierNonRepeat_YN = false;
            newSubItem[i].PI_Group = this.state.parentItemId;
            newSubItem[i].PI_IAGID = childData[i]['IM_IAGID'];
            delete newSubItem[i]['IM_IAGID'];
            delete newSubItem[i]['IM_ClientID'];
            delete newSubItem[i]['IM_ID'];
            delete newSubItem[i].IU_SalesPrice1;
            delete newSubItem[i].IU_SalesPrice2;
            delete newSubItem[i].IU_SalesPrice3;
            delete newSubItem[i].IU_SalesPrice4;
            delete newSubItem[i].IU_SalesPrice5;
            delete newSubItem[i].IU_SalesPrice6;
            delete newSubItem[i].IU_MinSalesPrice;
            delete newSubItem[i]['IM_Code'];
            delete newSubItem[i]['IM_Description'];
            delete newSubItem[i].IW_QtyAvailable;
            delete newSubItem[i].IG_Description;
            delete newSubItem[i].IM_Type;
            delete newSubItem[i].IM_Code_URL;
        }

        this.addItem(newSubItem)
    }

    //#endregion

    openSourceTab(url) {
        var urlArray = url.split(';');
        if (urlArray.length === 2)
            window.parent.postMessage(this.getPostMessageObj(urlArray[0], urlArray[1]), "*");
        else if (urlArray.length === 1)
            window.parent.postMessage(this.getPostMessageObj(urlArray[0], 'Item Creation Details'), "*");
    }
    getPostMessageObj(url, tabTitle) {
        return {
            source: window.location.host,
            target: process.env.REACT_APP_API_ALAYA_URL,
            eval: "parent.closeTab('" + tabTitle + "');parent.addTab('" + tabTitle + "', '" + url + "')"
        }
    }

    //#region Datagrid render
    itemCodeCell(data) {
        const url = `${data.data.ChildItemCode_URL};Item(${data.data.ChildItemCode})`;

        return (
            <div onClick={() => this.openSourceTab(url)} className="datagrid-data-link">
                {data.value}
            </div>
        );
    }

    //#endregion
    onCellPrepared(e) {
        var base = 10 ** this.state.format.precision;
        if (e.rowType === "data") {
            e.data.PI_SalesPrice1 = (Math.round(parseFloat((e.data.PI_SalesPrice1 * base).toFixed(10))) / base);
            e.data.PI_SalesPrice2 = (Math.round(parseFloat((e.data.PI_SalesPrice2 * base).toFixed(10))) / base);
            e.data.PI_SalesPrice3 = (Math.round(parseFloat((e.data.PI_SalesPrice3 * base).toFixed(10))) / base);
            e.data.PI_SalesPrice4 = (Math.round(parseFloat((e.data.PI_SalesPrice4 * base).toFixed(10))) / base);
            e.data.PI_SalesPrice5 = (Math.round(parseFloat((e.data.PI_SalesPrice5 * base).toFixed(10))) / base);
            e.data.PI_SalesPrice6 = (Math.round(parseFloat((e.data.PI_SalesPrice6 * base).toFixed(10))) / base);
            e.data.PI_PurchasePrice = (Math.round(parseFloat((e.data.PI_PurchasePrice * base).toFixed(10))) / base);
        }
    }

    //#region delete
    onClickDeleteItem(e) {
        if (!this.state.displayPopup && !this.dataGrid.option('isEditing')) {
            this.setState(state => ({
                displayDeleteConfirmationDialog: !state.displayDeleteConfirmationDialog
            }));
        }
    }

    onKeyDelete(e) {
        if (e.event.key === "Delete" && !this.dataGrid.option('isEditing')) {
            this.setState(state => ({
                displayDeleteConfirmationDialog: !state.displayDeleteConfirmationDialog
            }))
        }
    }

    async deletePopupAccept() {
        const data = await this.getFocusedRowData();
        if (!data) return;

        let dataArray = [...this.state.packageItemList];

        dataArray = dataArray.reduce((array, element) => {
            if (element.ItemCode !== data.ItemCode) {
                array.push({ ...element, PI_Sequence: array.length + 1 });
            }
            return array;
        }, [])

        this.setState({
            packageItemList: dataArray,
            displayDeleteConfirmationDialog: false
        });

        this.childHeader.setCurrentPackageItemList(this.state.packageItemList);

        this.childHeader.setFocus();
    }

    deletePopupReject() {
        this.setState({ displayDeleteConfirmationDialog: false });
        this.childHeader.setFocus();
    }

    getFocusedRowData() {
        try {
            const focusedRowKey = this.dataGrid.option("focusedRowKey");
            return this.dataGrid.byKey(focusedRowKey).then(
                (data) => { return data },
                (error) => { /* focusedRow not found */ }
            );
        } catch (error) {
            // focusedRow not found
        }
    }
    //#endregion

    onClickAddNewItem() {
        this.setState(state => ({
            displayPopup: !state.displayPopup
        }));
    }

    onContentReady(e) {
        //setState is NOT allowed in this function
        //due to saveState will be trigger everytime setState,
        //the data grid user preferred layout will save incorrectly.

        this.childHeader.setFocus();
        //e.component.focus(); 
    }

    async savePackageItemDetails() {

        if (this.state.allowSave !== false) {
            const newItemPrice = computePackageItemTotalPrice(this.state.packageItemList);
            const itemID = getParentItemID(this.state.parentItemId)
            const itemUIOM = updateItemUOMPrice(this.state.itemUOMList, newItemPrice);
            const [newParentItemId] = await Promise.all
                ([
                    PostPackageItem(Token(), UserID(), Username(), itemID, this.state.packageItemList, itemUIOM)
                ])

            if (newParentItemId === 'Error: 401') {
                this.handleSessionPopUpCallBack();
            }

            if (newParentItemId.length > 0 && newParentItemId[0].PIID > 0) {
                this.showMsgHandler(formatMessage('SavedSuccessfully'), 'success', 'flash-message-success-container');
                const [dataSource_ChildPackageItem] = await Promise.all
                    ([
                        GetChildPackageItemByCompanyID(Token(), CompanyID(), this.state.parentItemId, this.state.iAGID)
                    ])

                this.handleDocumentOptions(dataSource_ChildPackageItem.Query_PackageChildItem, this.state.iagDesp);
                this.handleChildPackageItems(dataSource_ChildPackageItem.Query_PackageChildItem);
                this.childHeader.setCurrentPackageItemList(this.state.packageItemList);

            }
            else
                return;
        }
    }

    goBack() {
        this.props.history.push('/package-item');
    }

    openAuditLog() {
        //to handle if packageItemList is empty
        if (this.state.LinkBtnUrlAuditLog === "") {
            this.openSourceTab(`/Modules/Common/${DefaultSMIAuditLog.moduleURL}?id=${this.state.parentItemId}&code=${this.state.parentItemCode}&companyID=${CompanyID()}&isSetting=true;Package Item(${this.state.parentItemCode}) Audit Log`);
        }
        else {
            this.openSourceTab(`${this.state.LinkBtnUrlAuditLog}&companyID=${CompanyID()}&isSetting=true;Package Item(${this.state.parentItemCode}) Audit Log`);
        }
    }

    render() {
        return (
            <>
                <ErrorPopUp
                    parentCallback={this.handleErrorCommandPopUpCallBack}
                    visible={this.state.displayErrorCommandPopUp}
                    title={'ItemNotFound'}
                    subTitle={'ItemNotFoundSubText'}
                />
                <ErrorPopUp
                    parentCallback={this.handleSessionPopUpCallBack}
                    visible={this.state.displaySessionPopUp}
                    title={'SessionExpired'}
                    subTitle={'PleaseLoginAgain'}
                />
                <DeletePopUp
                    parentCallback={this.deletePopupAccept}
                    hideDeletePopup={this.deletePopupReject}
                    visible={this.state.displayDeleteConfirmationDialog}
                />
                <ItemPopUp
                    parentCallback={this.handleCallback}
                    hidePopup={this.hidePopup}
                    visible={this.state.displayPopup}
                    allowDisplayColumnChooser={this.state.allowDisplayColumnChooser}
                    allowExportGrid={this.state.allowExportGrid}
                    allowRestoreLayout={this.state.allowRestoreLayout}
                    allowSaveGridLayout={this.state.allowSaveGridLayout}
                    title={formatMessage("AddPackageSubItem")}
                    defaultSMI={DefaultSMIPackageItemDetailItemAdvSearch}
                />

                <div className="module-navbar">
                    <Flash
                        parentCallback={this.closeFlashMessageCallback}
                        visible={this.state.displayFlashMessageDialog}
                        message={this.state.emptyMsg}
                        severity={this.state.status}
                        container={this.state.size}
                    />
                    <Toolbar1
                        displayBackBtn={this.state.allowBack}
                        displaySaveBtn={this.state.allowSave}
                        displayAuditLog={this.state.displayAuditLog}
                        displayMoreOptions={true}
                        displayCreatedBy={this.state.createdBy}
                        displayCreatedDate={this.state.createdDate}
                        displayIAGDescription={this.state.iagDesp}
                        showFiscal={false}
                        saveCallback={this.savePackageItemDetails}
                        goBack={this.goBack}
                        openAuditLog={this.openAuditLog}

                    />
                </div>

                <div className="module-wrapper">
                    <div className="package-item-header">
                        <ListingHeaderPackageItemDetails
                            ondisableDeletePopUp={this.deletePopupReject}
                            placeHolderText={formatMessage('InputCodeText')}
                            tooltipText={formatMessage('InputCodeTooltipText')}
                            buttonText1={formatMessage('Search')}
                            buttonText2={formatMessage('AutoCalcItemPrice')}
                            captionTextbox1={formatMessage('Code')}
                            captionTextbox2={formatMessage('Description')}
                            captionDropDown1={formatMessage('FilterBy')}
                            valueTextbox1={this.state.parentItemCode}
                            valueTextbox2={this.state.parentItemDesp}
                            openItemPopUp={this.onClickAddNewItem}
                            onSearchValueChange={this.searchValueChangeHandler}
                            packageItemPriceChange={this.packageItemPriceChangeHandler}
                            showErrorMsg={this.showMsgHandler}
                            packageItemList={this.state.packageItemList}
                            ref={(ref) => this.childHeader = ref}
                            allowAdd={this.state.allowAdd}
                        />
                    </div>
                    <div className="space" />
                    <div className="module-data-grid">
                        <DataGridDefault
                            allowDisplayColumnChooser={this.state.allowDisplayColumnChooser}
                            allowExportGrid={this.state.allowExportGrid}
                            allowedPageSizes={DefaultPager.allowedPageSizes_details}
                            allowRestoreLayout={this.state.allowRestoreLayout}
                            allowSaveGridLayout={this.state.allowSaveGridLayout}
                            className='dx-datagrid-items'
                            dataGridRef={this.dataGridRef}
                            dataSource={this.state.packageItemList}
                            defaultPageSize={DefaultPager.defaultPageSize_details}
                            defaultSMI={DefaultSMIPackageItemDetails}
                            focusedRowEnabled={true}
                            keyExpr="ChildItemCode"
                            noDataText={this.state.noDataText}
                            onContentReady={this.onContentReady}
                            onCellPrepared={this.onCellPrepared}
                            onKeyDown={this.onKeyDelete}
                            preferedLayout={this.state.preferedLayout}
                            showMsgHandler={this.showMsgHandler}
                            customRowDragging={{ updateDataSource: this.handleChildPackageItems, sequenceKey: 'PI_Sequence' }}
                            exportFileName="PackageItemDetail"
                            mousetrap={mousetrapMain}
                        >
                            <Editing
                                mode="cell"
                                allowUpdating={true}
                            />
                            <Column
                                visibleIndex={0}
                                fixedPosition={'left'}
                                fixed={true}
                                width={60}
                                minWidth={60}
                                allowExporting={false}
                                allowFiltering={false}
                                allowHeaderFiltering={false}
                                allowHiding={false}
                                allowReordering={false}
                                allowResizing={false}
                                allowSorting={false}
                                showInColumnChooser={false}
                                cssClass="column-normalize"
                                caption={null}
                                cellRender={(cellData) => deleteBtnRenderer({ cellData, onClick: this.onClickDeleteItem, enabled: this.state.allowDelete })}
                                dataField={'_TOTAL'} // dummy dataField for summary
                            />
                            <Column
                                visibleIndex={1}
                                type="drag"
                                fixedPosition={'left'}
                                fixed={true}
                                width={30}
                                minWidth={30}
                                allowFiltering={false}
                                allowHeaderFiltering={false}
                                allowReordering={false}
                                allowResizing={false}
                                allowSorting={false}
                                allowHiding={false}
                                showInColumnChooser={false}
                                cellRender={DragBtn}
                            />
                            <Column
                                visibleIndex={2}
                                fixedPosition={'left'}
                                fixed={true}
                                alignment={'left'}
                                width={60}
                                minWidth={60}
                                allowFiltering={false}
                                allowHeaderFiltering={false}
                                allowReordering={false}
                                allowResizing={false}
                                allowSorting={false}
                                caption={'No.'}
                                dataField={"PI_Sequence"}
                                cellRender={RowNumCell}
                            />
                            <Column
                                allowHiding={false}
                                showInColumnChooser={false}
                                dataField={'PI_ID'}
                                width={0}
                                minWidth={0}
                                visible={false}
                            />
                            <Column
                                dataField={'ChildItemCode'}
                                caption={formatMessage('ItemCode')}
                                width={120}
                                minWidth={60}
                                cellRender={this.itemCodeCell}
                            />
                            <Column
                                dataField={'ChildItemDescription'}
                                caption={formatMessage('Description')}
                                minWidth={60}
                                width={120}
                            />
                            <Column
                                dataField={'PI_Qty'}
                                caption={formatMessage('QTY')}
                                minWidth={50}
                                allowEditing={true}
                                editorOptions={{ step: 0 }}
                            >
                                <RequiredRule />
                                <PatternRule message={formatMessage('Notmorethan12digitsandmaximumof6decimalpoints')} pattern={/^\d{0,12}(\.\d{1,6})?$/} />
                            </Column>
                            <Column
                                dataField={'PI_SalesPrice1'}
                                caption={formatMessage('Price1')}
                                format={this.state.format}
                                minWidth={60}
                                allowEditing={true}
                                editorOptions={{ step: 0 }}
                            >
                                <RequiredRule />
                                <PatternRule message={formatMessage('Notmorethan12digitsandmaximumof6decimalpoints')} pattern={/^\d{0,12}(\.\d{1,6})?$/} />
                            </Column>
                            <Column
                                dataField={'PI_SalesPrice2'}
                                caption={formatMessage('Price2')}
                                format={this.state.format}
                                minWidth={60}
                                allowEditing={true}
                                editorOptions={{ step: 0 }}
                            >
                                <RequiredRule />
                                <PatternRule message={formatMessage('Notmorethan12digitsandmaximumof6decimalpoints')} pattern={/^\d{0,12}(\.\d{1,6})?$/} />
                            </Column>
                            <Column
                                dataField={'PI_SalesPrice3'}
                                caption={formatMessage('Price3')}
                                format={this.state.format}
                                minWidth={60}
                                allowEditing={true}
                                editorOptions={{ step: 0 }}
                            >
                                <RequiredRule />
                                <PatternRule message={formatMessage('Notmorethan12digitsandmaximumof6decimalpoints')} pattern={/^\d{0,12}(\.\d{1,6})?$/} />
                            </Column>
                            <Column
                                dataField={'PI_SalesPrice4'}
                                caption={formatMessage('Price4')}
                                format={this.state.format}
                                minWidth={60}
                                allowEditing={true}
                                editorOptions={{ step: 0 }}
                            >
                                <RequiredRule />
                                <PatternRule message={formatMessage('Notmorethan12digitsandmaximumof6decimalpoints')} pattern={/^\d{0,12}(\.\d{1,6})?$/} />
                            </Column>
                            <Column
                                dataField={'PI_SalesPrice5'}
                                caption={formatMessage('Price5')}
                                format={this.state.format}
                                minWidth={60}
                                allowEditing={true}
                                editorOptions={{ step: 0 }}
                            >
                                <RequiredRule />
                                <PatternRule message={formatMessage('Notmorethan12digitsandmaximumof6decimalpoints')} pattern={/^\d{0,12}(\.\d{1,6})?$/} />
                            </Column>
                            <Column
                                dataField={'PI_SalesPrice6'}
                                caption={formatMessage('Price6')}
                                format={this.state.format}
                                minWidth={60}
                                allowEditing={true}
                                editorOptions={{ step: 0 }}
                            >
                                <RequiredRule />
                                <PatternRule message={formatMessage('Notmorethan12digitsandmaximumof6decimalpoints')} pattern={/^\d{0,12}(\.\d{1,6})?$/} />
                            </Column>
                            <Column
                                dataField={'PI_PurchasePrice'}
                                caption={formatMessage('PurchasePrice')}
                                format={this.state.format}
                                minWidth={60}
                                allowEditing={true}
                                editorOptions={{ step: 0 }}
                            >
                                <RequiredRule />
                                <PatternRule message={formatMessage('Notmorethan12digitsandmaximumof6decimalpoints')} pattern={/^\d{0,12}(\.\d{1,6})?$/} />
                            </Column>
                            <Summary calculateCustomSummary={this.calculateTotalPriceSummary}>
                                <TotalItem
                                    summaryType="count"
                                    showInColumn="_TOTAL"
                                    displayFormat={`${formatMessage("Records")}: {0}`}
                                    cssClass="summary-overflow"
                                    skipEmptyValues
                                />
                                <TotalItem
                                    displayFormat={formatMessage("TotalQtyMultiplyByPrice")}
                                    showInColumn={formatMessage("Description")}
                                />
                                <TotalItem
                                    name="PI_SalesPrice1"
                                    summaryType="custom"
                                    displayFormat="{0}"
                                    valueFormat={this.state.format}
                                    showInColumn={formatMessage("Price1")}
                                />
                                <TotalItem
                                    name="PI_SalesPrice2"
                                    summaryType="custom"
                                    displayFormat="{0}"
                                    valueFormat={this.state.format}
                                    showInColumn={formatMessage("Price2")}
                                />
                                <TotalItem
                                    name="PI_SalesPrice3"
                                    summaryType="custom"
                                    displayFormat="{0}"
                                    valueFormat={this.state.format}
                                    showInColumn={formatMessage("Price3")}
                                />
                                <TotalItem
                                    name="PI_SalesPrice4"
                                    summaryType="custom"
                                    displayFormat="{0}"
                                    valueFormat={this.state.format}
                                    showInColumn={formatMessage("Price4")}
                                />
                                <TotalItem
                                    name="PI_SalesPrice5"
                                    summaryType="custom"
                                    displayFormat="{0}"
                                    valueFormat={this.state.format}
                                    showInColumn={formatMessage("Price5")}
                                />
                                <TotalItem
                                    name="PI_SalesPrice6"
                                    summaryType="custom"
                                    displayFormat="{0}"
                                    valueFormat={this.state.format}
                                    showInColumn={formatMessage("Price6")}
                                />
                                <TotalItem
                                    name="PI_PurchasePrice"
                                    summaryType="custom"
                                    displayFormat="{0}"
                                    valueFormat={this.state.format}
                                    showInColumn={formatMessage("PurchasePrice")}
                                />
                            </Summary>
                        </DataGridDefault>
                    </div>
                </div>
            </>
        );
    }
}

export default PackageItemDetails;
