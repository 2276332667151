/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Input, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FormItem } from "../forms";
import "./styles/SearchInput.scss";

//iframe
import { getPostMessageObj } from "../../utils/iframe-func";
import useFormikValidateOnError from "../../hooks/useFormikValidateOnError";

const { Search } = Input;

export const SearchInputDocument = (props) => {
    const {
        setModalVisible = null,
        placeholder = "",
        link,
        showSuffix,
        field,
        form,
        tabName,
        validateOnError = true,
    } = props;

    useFormikValidateOnError(field, form, validateOnError);

    const suffix = (
        showSuffix ? (
            <PlusOutlined
                className="plus-button"
                style={{ color: "#1dafed" }}
                onClick={() => openSourceTab(link)}
            />
        ) : null
    );

    const onSearch = (value) => {
        if (setModalVisible) setModalVisible(true);
    };

    const openSourceTab = (url) => {
        window.parent.postMessage(getPostMessageObj(url, tabName), "*");
    };

    const popUpBtn = (
        <Button className="ant-btn-search" onClick={onSearch} type="primary" disabled={props.disabledButton}>
            <i className="icon-search-icon" />
        </Button>
    )

    return (
        <FormItem field={field} {...props}>
            <Search
                {...field}
                placeholder={placeholder}
                enterButton={popUpBtn}
                size="large"
                suffix={suffix}
            />
        </FormItem>
    );
};
