import React, { useState } from "react";
import { InputNumber } from "antd";
import { FormItem } from "../forms";
import { toFixed } from "../../utils/common-function";
import useFormikValidateOnError from "../../hooks/useFormikValidateOnError";
import "./styles/NumberInput.scss";

export const NumberInput = (props) => {
    const {
        disabled = false,
        placeholder = "",
        step = "0.00",
        min = -999999999999,
        max = 999999999999,
        precision = 6,
        className,
        controls = false,
        field,
        form,
        onChangeCallback,
        onPressEnterCallback,
        thousandSeparator = false,
        showAllDecimalPoints = false,
        validateOnError = true,
    } = props;

    const [isFocused, setIsFocused] = useState(false);
    useFormikValidateOnError(field, form, validateOnError);

    const onChange = (option) => {
        form.setFieldValue(field.name, option).then(function () {
            onChangeCallback && onChangeCallback(option);
        })
    }

    const formatNumber = (value, info) => {
        if (!value && value !== 0) return '';

        value = Number.parseFloat(value);

        if (info.userTyping || isFocused) {
            return value;
        } else {
            if (thousandSeparator && showAllDecimalPoints) {
                const minimumFractionDigits = step?.toString()?.split(".")[1]?.length || precision;
                return value?.toLocaleString(undefined, { minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: 20 });
            } else if (thousandSeparator) {
                return value?.toLocaleString(undefined, { maximumFractionDigits: 20 });
            } else if (showAllDecimalPoints) {
                return toFixed(value, precision);
            } else {
                return value;
            }
        }
    }

    const getValue = () => {
        if (!field.value && field.value !== 0) return '';

        // toggle between typeof string and number to force re-render
        if (isFocused) {
            return field.value + "";
        } else {
            return field.value;
        }
    }

    const onFocus = () => {
        setIsFocused(true);
    }

    const onBlur = () => {
        setIsFocused(false);
    }

    return (
        <FormItem field={field} {...props}>
            <InputNumber
                {...field}
                style={{ borderRadius: "5px" }}
                className={className}
                size="large"
                step={step}
                min={min}
                max={max}
                precision={precision} // (antd) Will use formatter when config of formatter
                placeholder={placeholder}
                disabled={disabled}
                controls={controls}
                formatter={formatNumber}
                value={getValue()}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                onPressEnter={onPressEnterCallback}
            />
        </FormItem>
    );
};
