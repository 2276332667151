import React, { useState, useEffect, useRef, useCallback } from "react";
import DropDownBox from "devextreme-react/drop-down-box";
import DataGridDefault from "../data-grid/data-grid-default";
import { filterBySearchText } from "../data-grid/data-grid-util";
import { customSearchRenderer } from "../";
import { formatMessage } from "devextreme/localization";
import { Selection, Column } from "devextreme-react/data-grid";
import { Button } from "devextreme-react";
import { isEmpty, get } from "lodash";

export default function MultiSelectionDropdownTable(props) {
    const {
        dataSource,
        defaultValue,
        caption,
        placeholder,
        columns,
        keyExpr,
        displayExpr,
        onChangeCallback,
        disabled = false,
    } = props;

    const [searchText, setSearchText] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [dropdownName, setDropdownName] = useState("");

    const prevSelectedRowKeys = useRef([]);
    const prevSelectedDropdownName = useRef("");
    const dataGridRef = useRef();

    function onOptionChanged(event) {
        if (event.name === "opened") {
            if (!event.value) {
                onCancel();
            } else {
                setDropdownOpened(true);
            }
        }
    }

    const onDataGridSelectionChanged = useCallback((event) => {
        const selectedData = event.selectedRowsData;
        const sortedSelectedData = dataSource.filter(itemA => selectedData.some(itemB => get(itemA, keyExpr) === get(itemB, keyExpr)));

        setSelectedRowKeys(sortedSelectedData.map(item => get(item, keyExpr)));
        setDropdownName(sortedSelectedData.map(item => get(item, displayExpr)).join(', '));
    }, [dataSource, keyExpr, displayExpr]);

    const onDataGridRowDblClick = useCallback((event) => {
        if (event.rowType === 'data') {
            let tempSelectedRowKeys = [];

            if (event.isSelected) {
                tempSelectedRowKeys = selectedRowKeys.filter(curr => curr !== get(event.data, keyExpr));
            } else {
                tempSelectedRowKeys = selectedRowKeys.concat(get(event.data, keyExpr));
            }

            const sortedSelectedData = dataSource.filter(itemA => tempSelectedRowKeys.some(itemB => get(itemA, keyExpr) === itemB));
            setSelectedRowKeys(sortedSelectedData.map(item => get(item, keyExpr)));
            setDropdownName(sortedSelectedData.map(item => get(item, displayExpr)).join(', '));
        }
    }, [dataSource, selectedRowKeys, keyExpr, displayExpr])

    const onSearchInputChanged = (event) => {
        setSearchText(event.currentTarget.value || "");
        filterBySearchText(event.currentTarget.value || "", dataGridRef.current.instance);
    }

    const onSubmit = useCallback(() => {
        setDropdownOpened(false);
        setSearchText("");
        filterBySearchText("", dataGridRef.current.instance);

        onChangeCallback(selectedRowKeys);

        prevSelectedRowKeys.current = selectedRowKeys;
        prevSelectedDropdownName.current = dropdownName;
    }, [onChangeCallback, selectedRowKeys, dropdownName]);

    const onCancel = () => {
        setDropdownOpened(false);
        setSearchText("");
        filterBySearchText("", dataGridRef.current.instance);

        setSelectedRowKeys(prevSelectedRowKeys.current);
        setDropdownName(prevSelectedDropdownName.current);
    };

    useEffect(() => {
        if (isEmpty(prevSelectedRowKeys.current) && isEmpty(prevSelectedDropdownName.current) && !isEmpty(defaultValue)) {
            prevSelectedRowKeys.current = defaultValue.map(item => get(item, keyExpr));
            prevSelectedDropdownName.current = defaultValue.map(item => get(item, displayExpr));

            setSelectedRowKeys(prevSelectedRowKeys.current);
            setDropdownName(prevSelectedDropdownName.current);
        }
    }, [defaultValue, displayExpr, keyExpr]);

    function columnsRender(data) {
        const { customSearch, ...config } = data;

        if (customSearch) {
            config.cellRender = (cellData) => customSearchRenderer({ cellData, searchText });
            config.hasCustomSearch = true;
        }

        return (
            <Column
                key={data.dataField}
                {...config}
                allowSorting={false}
            />
        );
    }

    function dataGridRender() {
        return (
            <>
                <div className="customSearchContainer">
                    <div className="dx-icon dx-icon-search customSearchIcon" />
                    <input
                        className="customSearchInput"
                        value={searchText}
                        onChange={onSearchInputChanged}
                    />
                </div>
                <DataGridDefault
                    // Styling
                    className="dropdown-datagrid"
                    // DevExtreme DataGrid Configurations
                    dataSource={dataSource}
                    hoverStateEnabled
                    allowColumnResizing={false}
                    allowColumnReordering={false}
                    onSelectionChanged={onDataGridSelectionChanged}
                    height={160}
                    keyExpr={keyExpr}
                    selectedRowKeys={selectedRowKeys}
                    // Custom Configurations
                    dataGridRef={dataGridRef}
                    disableContextMenu={true}
                    searchText={searchText} // need this to re-render datagrid on change
                    disablePaging
                    onRowDblClick={onDataGridRowDblClick}
                >
                    <Selection mode="multiple" showCheckBoxesMode="always" />
                    {columns.map(columnsRender)}
                </DataGridDefault>
                <div className="multiSelectionFooter">
                    <Button
                        onClick={onCancel}
                        type="normal"
                        stylingMode="contained"
                        hoverStateEnabled={true}
                        activeStateEnabled={false}
                        focusStateEnabled={false}
                        className="cancel-btn"
                        text={formatMessage("Cancel")}
                    />
                    <Button onClick={onSubmit}
                        type="default"
                        stylingMode="contained"
                        hoverStateEnabled={true}
                        activeStateEnabled={false}
                        focusStateEnabled={false}
                        className="apply-btn"
                        text={formatMessage("OK")}
                    />
                </div>
            </>
        );
    }

    return (
        <div>
            <div className="dx-fieldset-popup-text">
                <div className="dx-field">
                    <div className="dx-field-label">{caption}</div>
                </div>
            </div>
            <div className="dx-fieldset-popup">
                <div className="dx-field">
                    <div className="dx-field-value">
                        <DropDownBox
                            dataSource={dataSource}
                            value={dropdownName}
                            opened={dropdownOpened}
                            placeholder={placeholder}
                            stylingMode="outlined"
                            valueExpr={keyExpr}
                            onOptionChanged={onOptionChanged}
                            contentRender={dataGridRender}
                            className={"normalize-field filter-drop-down"}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
