import React, { createContext, useCallback, useContext, useState } from 'react';
import ErrorPopUp from '../pop-up/error-popup';

const ErrorPopupProvider = ({ children }) => {
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [shouldFormatMessage, setShouldFormatMessage] = useState(false);

    const showErrorPopup = useCallback((title = 'SessionExpired', subTitle = 'PleaseLoginAgain', shouldFormatMessage = true) => {
        setVisible(true);
        setTitle(title);
        setSubTitle(subTitle);
        setShouldFormatMessage(shouldFormatMessage);
    }, [])

    const hideErrorPopup = useCallback(() => {
        setVisible(false);
    }, [])

    return (
        <ErrorPopupContext.Provider value={showErrorPopup}>
            {children}
            {
                visible &&
                <ErrorPopUp
                    parentCallback={hideErrorPopup}
                    visible={visible}
                    title={title}
                    subTitle={subTitle}
                    shouldFormatMessage={shouldFormatMessage}
                />
            }
        </ErrorPopupContext.Provider>
    )
}

const ErrorPopupContext = createContext({});
const useErrorPopupContext = () => useContext(ErrorPopupContext);

export { ErrorPopupProvider, useErrorPopupContext }