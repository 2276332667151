import React from "react";
import { FormItem } from "../forms";
import { DateSinglePicker } from "../date-picker/date-single-picker/date-single-picker";
import useFormikValidateOnError from "../../hooks/useFormikValidateOnError";

export const DateSinglePickerInput = (props) => {
    const {
        placeholder,
        format,
        field,
        form,
        onChangeCallback,
        portalRef,
        validateOnError = true,
        disabled,
    } = props;

    useFormikValidateOnError(field, form, validateOnError);

    const onChange = (option) => {
        form.setFieldValue(field.name, option).then(function () {
            onChangeCallback && onChangeCallback(option);
        })
    }

    return (
        <FormItem field={field} {...props}>
            <DateSinglePicker
                selectedDate={field.value}
                placeholder={placeholder}
                dateFormat={format}
                onChangeCallback={onChange}
                portalRef={portalRef}
                disabled={disabled}
            />
        </FormItem>
    );
};
