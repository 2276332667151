import { set } from 'lodash';

export const RowNumCell = (props) => {
    const dataField = props.column.dataField;
    const rowNum = props.component.pageIndex() * props.component.pageSize() + props.rowIndex + 1;

    if (dataField) {
        set(props.data, dataField, rowNum);
    }

    return rowNum;
}