import { Checkbox } from 'antd'
import React from 'react'
import "./styles/CheckBoxInput.scss";

const CheckBoxInput = (props) => {
    const {
        label,
        onChangeCallback,
        disabled,
        field,
        form,
        className,
    } = props;

    const onChange = (event) => {
        const checked = event.target.checked;

        form.setFieldValue(field.name, checked).then(function () {
            onChangeCallback && onChangeCallback(checked);
        })
    }

    return (
        <Checkbox className={`checkbox-input ${className}`} onChange={onChange} checked={field.value} disabled={disabled}>
            {label}
        </Checkbox>
    )
}

export default CheckBoxInput