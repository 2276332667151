import { getRoundingValue } from '../math-rounding-func';
import { sumBy } from 'lodash';
import columns from './purchase-column';
import { taxRoundingCorrection } from './purchase-tax-func';

const totalCalculation = (prefix, isTaxInclusive, item) => {
    columns.setColumnsPrefix = prefix;

    // value for calculations
    const discountAmt = item[columns.discountAmt];
    const taxAdjustment = item[columns.taxAdjustment] || 0;
    const amtExcFD = item[columns.qty] * (item[columns.unitPrice] - discountAmt);
    const currencyRate = item[columns.currencyRate];
    let taxRate = 0;

    // value to update
    const subTotal = item[columns.qty] * (item[columns.unitPrice] - discountAmt - item[columns.itemFooterDiscount]);
    const newUnitPriceAmt = item[columns.unitPrice] - discountAmt - item[columns.itemFooterDiscount];
    const localTaxAdjustment = taxAdjustment * currencyRate;
    let taxAmt = 0;
    let taxableAmt = 0;
    let totalAmt = 0;
    let localTax = 0;
    let taxableExcFooterDisc = 0;

    if (!item[columns.taxTypeID]) {
        // No selected taxType
        taxableAmt = subTotal;
        totalAmt = subTotal;
        taxableExcFooterDisc = amtExcFD;
    } else {
        taxRate = item[columns.taxRate] || 0;

        if (isTaxInclusive) {
            // Tax Inclusive
            taxAmt = getRoundingValue(taxRate * subTotal / (100 + taxRate));
            localTax = getRoundingValue(taxAmt * currencyRate);

            taxAmt = taxAmt + taxAdjustment;
            localTax = localTax + localTaxAdjustment;

            taxableAmt = subTotal - taxAmt;
            totalAmt = subTotal;

            let taxExcFD = getRoundingValue(taxRate * amtExcFD / (100 + taxRate));
            taxExcFD = taxExcFD + taxAdjustment;
            taxableExcFooterDisc = amtExcFD - taxExcFD;
        } else {
            // Tax Exclusive
            taxAmt = getRoundingValue(taxRate * subTotal / 100);
            localTax = getRoundingValue(taxAmt * currencyRate);

            taxAmt = taxAmt + taxAdjustment;
            localTax = localTax + localTaxAdjustment;

            taxableAmt = subTotal;
            totalAmt = subTotal + taxAmt;
            taxableExcFooterDisc = amtExcFD;
        }
    }

    // update item row data
    // 2 decimal points
    item[columns.subTotal] = getRoundingValue(subTotal);
    item[columns.total] = getRoundingValue(totalAmt);
    item[columns.taxAmt] = getRoundingValue(taxAmt);
    item[columns.taxableAmt] = getRoundingValue(taxableAmt);
    item[columns.taxableExcFooterDisc] = getRoundingValue(taxableExcFooterDisc);
    item[columns.localTax] = getRoundingValue(localTax);
    item[columns.localTaxAdjustment] = getRoundingValue(localTaxAdjustment);
    // no round
    item[columns.newUnitPrice] = newUnitPriceAmt;

    return item;
}

export const calculateMainItemAndDocTotal = (prefix, isTaxInclusive, isTaxItemize, companyRounding, itemList, footerDiscount, shouldDocRoundAmount) => {
    // recalculate with 0 F/Disc
    itemList.forEach(item => {
        item[columns.itemFooterDiscount] = 0;
        totalCalculation("PID", isTaxInclusive, item);
    });

    // calculate F/Disc per item
    calculateDocDiscountPerItem(prefix, isTaxInclusive, itemList, footerDiscount);

    // adjust tax amount and taxable to fix rounding issue
    if (isTaxInclusive && !isTaxItemize) {
        taxRoundingCorrection(prefix, itemList);
    }

    // calculate doc total data
    const docTotalData = calculateDocTotalData(prefix, isTaxInclusive, companyRounding, itemList, footerDiscount, shouldDocRoundAmount);

    return [itemList, docTotalData];
}

export const calculateSubItem = (prefix, isTaxInclusive, isTaxItemize, itemList, footerDiscount) => {
    // recalculate with 0 F/Disc
    itemList.forEach(item => {
        item[columns.itemFooterDiscount] = 0;
        totalCalculation("PID", isTaxInclusive, item);
    });

    // calculate F/Disc per item
    calculateDocDiscountPerItem(prefix, isTaxInclusive, itemList, footerDiscount);

    // adjust tax amount and taxable to fix rounding issue
    if (isTaxInclusive && !isTaxItemize) {
        taxRoundingCorrection(prefix, itemList);
    }
}

// only pass in mainItemList
const calculateDocDiscountPerItem = (prefix, isTaxInclusive, itemList, footerDiscount) => {
    columns.setColumnsPrefix = prefix;

    const sumTaxableExcFooterDisc = itemList.reduce((accumulator, currentValue) => {
        return getRoundingValue(accumulator + currentValue[columns.taxableExcFooterDisc]);
    }, 0)

    const sumTaxable = itemList.reduce((accumulator, currentValue) => {
        return getRoundingValue(accumulator + currentValue[columns.taxAmt]);
    }, 0)

    itemList.forEach(item => {
        if (footerDiscount) {
            const qty = item[columns.qty];
            const footerDiscountAmt = (((footerDiscount / sumTaxableExcFooterDisc) * item[columns.taxableExcFooterDisc]) / qty) || 0;
            item[columns.itemFooterDiscount] = footerDiscountAmt;
            totalCalculation("PID", isTaxInclusive, item);
        }
    });

    // to handle inaccurate values when footerDiscount can't be divide equally
    // check if there's any differences (diffTaxableExFD) between actualTaxableExcFD and newTaxableExcFD (after adding F/Disc)
    if (itemList.length > 0 && footerDiscount) {
        // find diffTaxableExFD
        const actualTaxableExcFD = sumTaxableExcFooterDisc;
        const newTaxableExcFD = getRoundingValue(sumBy(itemList, item => item[columns.subTotal]) + footerDiscount - (isTaxInclusive ? sumTaxable : 0));
        const diffTaxableExFD = newTaxableExcFD - actualTaxableExcFD;
        if (diffTaxableExFD === 0) return;

        // find last row item that has NewUnitPrice (item with 0 NewUnitPrice can't have footerDiscount)
        const lastItem = itemList.findLast(item => item[columns.newUnitPrice]);
        if (!lastItem) return;

        // adjust last row item F/Disc and SubTotal to balance up subTotalDiff
        const subTotalDiffPerQty = diffTaxableExFD / lastItem[columns.qty];
        lastItem[columns.itemFooterDiscount] += subTotalDiffPerQty;
        lastItem[columns.subTotal] = getRoundingValue(lastItem[columns.subTotal] - diffTaxableExFD);
        totalCalculation("PID", isTaxInclusive, lastItem);
    }
}

export const calculateDocTotalData = (prefix, isTaxInclusive, companyRounding, itemList, footerDiscount, shouldDocRoundAmount) => {
    columns.setColumnsPrefix = prefix;

    const docTotalData = {
        DocSubTotal: 0,
        DocTaxableAmt: 0,
        DocTax: 0,
        DocRoundAmt: 0,
        DocTotal: 0,
    }

    docTotalData.DocTaxableAmt = itemList.reduce((accumulator, currentValue) => {
        return getRoundingValue(accumulator + currentValue[columns.taxableAmt]);
    }, 0);

    docTotalData.DocTax = itemList.reduce((accumulator, currentValue) => {
        return getRoundingValue(accumulator + currentValue[columns.taxAmt]);
    }, 0);

    docTotalData.DocTotal = itemList.reduce((accumulator, currentValue) => {
        return getRoundingValue(accumulator + currentValue[columns.total]);
    }, 0);

    if (shouldDocRoundAmount) {
        // calculate DocRoundAmt and update DocTotal 
        const docTotal = docTotalData.DocTotal;
        const totalString = docTotalData.DocTotal.toFixed(2);
        const lastDigit = totalString.slice(-1);

        let diffAmt = 0;
        if (between(parseFloat(lastDigit), 1, 9)) {
            diffAmt = (companyRounding["Round" + lastDigit] - parseFloat(lastDigit)) * 0.01;
        }

        const amt = docTotal + diffAmt;
        const roundAmt = amt - docTotal;

        docTotalData.DocRoundAmt = getRoundingValue(roundAmt);
        docTotalData.DocTotal = getRoundingValue(amt);
    }

    docTotalData.DocSubTotal = getRoundingValue((isTaxInclusive ? (docTotalData.DocTotal - docTotalData.DocRoundAmt) : docTotalData.DocTaxableAmt) + footerDiscount);

    return docTotalData;
}

export const calculateTaxableBasedOnTotal = (prefix, isTaxInclusive, item) => {
    columns.setColumnsPrefix = prefix;

    // value for calculations
    const taxAdjustment = item[columns.taxAdjustment] || 0;
    const currencyRate = item[columns.currencyRate];
    let amtExcFD = 0;
    let taxRate = 0;

    // value to update
    const localTaxAdjustment = getRoundingValue(taxAdjustment * currencyRate);
    let taxAmt = 0;
    let taxableAmt = 0;
    let subTotal = 0;
    let newUnitPrice = 0;
    let discountAmt = 0;
    let localTax = 0;
    let taxableExcFooterDisc = 0;

    if (!item[columns.taxTypeID]) {
        // No selected taxType
        taxableAmt = getRoundingValue(item[columns.total]);
        subTotal = getRoundingValue(item[columns.total]);
    } else {
        // Has selected taxType
        taxRate = item[columns.taxRate] || 0;

        if (isTaxInclusive) {
            // Tax Inclusive
            taxAmt = getRoundingValue(taxRate * item[columns.total] / (100 + taxRate));
            localTax = getRoundingValue(taxAmt * currencyRate);

            taxAmt = getRoundingValue(taxAmt + taxAdjustment);
            localTax = getRoundingValue(localTax + localTaxAdjustment);

            taxableAmt = getRoundingValue(item[columns.total] - taxAmt);
            subTotal = getRoundingValue(item[columns.total]);
        }
        else {
            // Tax Exclusive
            taxAmt = getRoundingValue(taxRate * (item[columns.total] - taxAdjustment) / (100 + taxRate));
            localTax = getRoundingValue(taxAmt * currencyRate);

            taxAmt = getRoundingValue(taxAmt + taxAdjustment);
            localTax = getRoundingValue(localTax + localTaxAdjustment);

            taxableAmt = getRoundingValue(item[columns.total] - taxAmt);
            subTotal = getRoundingValue(item[columns.total] - taxAmt);
        }
    }

    // after taxableAmt is calculated
    if (isTaxInclusive) {
        newUnitPrice = item[columns.total] / item[columns.qty]; // no  round
        discountAmt = item[columns.unitPrice] - newUnitPrice - item[columns.itemFooterDiscount]; // no  round
    }
    else {
        newUnitPrice = taxableAmt / item[columns.qty]; // no  round
        discountAmt = item[columns.unitPrice] - newUnitPrice - item[columns.itemFooterDiscount]; // no  round
    }

    // after discountAmt is calculated
    amtExcFD = item[columns.qty] * (item[columns.unitPrice] - discountAmt);
    if (!item[columns.taxTypeID]) {
        taxableExcFooterDisc = getRoundingValue(amtExcFD);
    } else {
        if (isTaxInclusive) {
            let taxExcFD = getRoundingValue(taxRate * amtExcFD / (100 + taxRate));
            taxExcFD = taxExcFD + taxAdjustment;
            taxableExcFooterDisc = getRoundingValue(amtExcFD - taxExcFD);
        }
        else {
            taxableExcFooterDisc = getRoundingValue(amtExcFD);
        }
    }

    // update item row data
    // 2 decimal points
    item[columns.taxAmt] = taxAmt;
    item[columns.taxableAmt] = taxableAmt;
    item[columns.taxableExcFooterDisc] = taxableExcFooterDisc;
    item[columns.subTotal] = subTotal;
    item[columns.localTax] = localTax;
    item[columns.localTaxAdjustment] = localTaxAdjustment;
    // no round
    item[columns.newUnitPrice] = newUnitPrice;
    item[columns.discountAmt] = discountAmt;
}

const between = (x, min, max) => {
    return x >= min && x <= max;
}
