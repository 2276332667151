import React from 'react'
import { Row, Col } from "antd";
import { Field } from "formik";
import { DropdownInput } from '../../../fields';

const SectionDropdown = (props) => {
    const suffixIcon = props.tabLink ? undefined : <></>;

    return (
        <Row gutter={30}>
            <Col xs={24} md={12}>
                <Field
                    suffixIcon={suffixIcon}
                    component={DropdownInput}
                    {...props}
                />
            </Col>
        </Row>
    )
}

export default SectionDropdown