import React, { memo, useMemo } from 'react'
import { useField, useFormikContext } from "formik";
import { useDeepCompareMemo } from 'use-deep-compare';

const FastFieldWithProps = (props) => {
    const form = useFormikContext();
    const [field, meta] = useField(props);

    const memoProps = useDeepCompareMemo(() => {
        return props;
    }, [props])

    const memoFormikProps = useMemo(() => {
        // FastField behaviour
        return { field, form };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.name, field.value, meta.error, meta.touched, form.isSubmitting]);

    const memoComponent = useMemo(() => {
        const { component: Component, dependency, ...others } = memoProps;

        return (
            <Component
                {...memoFormikProps}
                {...others}
            />
        )
    }, [memoProps, memoFormikProps])

    return memoComponent;
}

export default memo(FastFieldWithProps)