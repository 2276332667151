import { GET, POST } from './request-options'

export const GetCopyFromDocumentPO = (token, companyID, vendorID, warehouseID, docID, docType, userID) => {
    const param = `companyID=${companyID}&vendorID=${vendorID}&warehouseID=${warehouseID}&docID=${docID}&docType=${docType}&userID=${userID}&UI-Culture=en-US`;
    return fetch(`${process.env.REACT_APP_API_COPY_FROM_PO}${param}`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

export const GetCopyFromDocumentGR = (token, companyID, vendorID, warehouseID, docID, docType, userID) => {
    const param = `companyID=${companyID}&vendorID=${vendorID}&warehouseID=${warehouseID}&docID=${docID}&docType=${docType}&userID=${userID}&UI-Culture=en-US`;
    return fetch(`${process.env.REACT_APP_API_COPY_FROM_GR}${param}`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

export const GetPartialCopyFromDocumentPO = (token, companyID, vendorID, warehouseID, docID, docType, selectedDocID, selectedDetailID, searchText, userID, clientID) => {
    const param = `companyID=${companyID}&vendorID=${vendorID}&warehouseID=${warehouseID}&docID=${docID}&docType=${docType}&searchText${searchText}&userID=${userID}&clientID=${clientID}&UI-Culture=en-US`;
    
    const PostOptions = POST(token);

    PostOptions.body = JSON.stringify({
        "selectedSourceDocID": selectedDocID,
        "selectedSourceDetailID": selectedDetailID,
    });
    
    return fetch(`${process.env.REACT_APP_API_PARTIAL_COPY_FROM_PO}${param}`, PostOptions)
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

export const GetPartialCopyFromDocumentGR = (token, companyID, vendorID, warehouseID, docID, docType, selectedDocID, selectedDetailID, searchText, userID, clientID) => {
    const param = `companyID=${companyID}&vendorID=${vendorID}&warehouseID=${warehouseID}&docID=${docID}&docType=${docType}&searchText${searchText}&userID=${userID}&clientID=${clientID}&UI-Culture=en-US`;
    
    const PostOptions = POST(token);

    PostOptions.body = JSON.stringify({
        "selectedSourceDocID": selectedDocID,
        "selectedSourceDetailID": selectedDetailID,
    });

    return fetch(`${process.env.REACT_APP_API_PARTIAL_COPY_FROM_GR}${param}`, PostOptions)
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}


const handleErrors = (response) => {
    if (!response.ok) throw new Error(response.status);
    return response;
}
