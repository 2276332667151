export const DocumentTypeId = {
    QUOTATION: { TYPEID: 1, TYPE: "SQ" },
    SALES_ORDER: { TYPEID: 3, TYPE: "SO" },
    DELIVERY_ORDER: { TYPEID: 4, TYPE: "DO" },
    INVOICE: { TYPEID: 5, TYPE: "IV" },
    CASH_SALES: { TYPEID: 6, TYPE: "CS" },
    CREDIT_NOTE: { TYPEID: 7, TYPE: "CN" },
    DEBIT_NOTE: { TYPEID: 8, TYPE: "DN" },
    CLOSE_SALES: { TYPEID: 9, TYPE: "XS" },
    DELIVERY_RETURN: { TYPEID: 10, TYPE: "DR" },
    CONSIGNMENT: { TYPEID: 11, TYPE: "CG" },
    REQUEST_QUOTATION: { TYPEID: 12, TYPE: "RQ" },
    PURCHASE_ORDER: { TYPEID: 13, TYPE: "PO" },
    GOODS_RECEIVED: { TYPEID: 14, TYPE: "GR" },
    PURCHASE_INVOICE: { TYPEID: 15, TYPE: "PI" },
    CASH_PURCHASE: { TYPEID: 16, TYPE: "CP" },
    PURCHASE_RETURN: { TYPEID: 17, TYPE: "PR" },
    CLOSE_PO: { TYPEID: 18, TYPE: "XP" },
    GOODS_RETURN: { TYPEID: 19, TYPE: "GT" },
    SUPPLIER_CONSIGNMENT: { TYPEID: 20, TYPE: "SG" },
    UOM_CONVERSION: { TYPEID: 21, TYPE: "UC" },
    STOCK_TRANSFER: { TYPEID: 22, TYPE: "ST" },
    STOCK_ADJUSTMENT: { TYPEID: 23, TYPE: "SA" },
    STOCK_RECEIVE: { TYPEID: 24, TYPE: "SR" },
    STOCK_ISSUE: { TYPEID: 25, TYPE: "SI" },
    STOCK_WRITE_OFF: { TYPEID: 26, TYPE: "WO" },
    STOCK_ASSEMBLY: { TYPEID: 27, TYPE: "AS" },
    STOCK_UPDATE_COST: { TYPEID: 28, TYPE: "UT" },
    AP_PAYMENT: { TYPEID: 29, TYPE: "PV" },
    INCOMING_PAYMENT: { TYPEID: 30, TYPE: "IP" },
    JOURNAL_ENTRY: { TYPEID: 31, TYPE: "JE" },
    STOCK_ASSEMBLY_ORDER: { TYPEID: 32, TYPE: "AO" },
    CUSTOMER_REDEMPTION: { TYPEID: 33, TYPE: "BR" },
    AP_DEBIT_NOTE: { TYPEID: 34, TYPE: "PD" },
    BONUS_POINT_ADJUSTMENT: { TYPEID: 35, TYPE: "PA" },
    BANK_SLIP: { TYPEID: 36, TYPE: "KS" },
    AP_INVOICE: { TYPEID: 37, TYPE: "API" },
    AR_INVOICE: { TYPEID: 38, TYPE: "ARI" },
    AP_CREDIT_NOTE: { TYPEID: 39, TYPE: "PC" },
    AR_CREDIT_NOTE: { TYPEID: 40, TYPE: "RC" },
    AR_DEBIT_NOTE: { TYPEID: 41, TYPE: "RD" },
    AR_PAYMENT: { TYPEID: 42, TYPE: "RP" },
    AP_REFUND_VOUCHER: { TYPEID: 43, TYPE: "PRV" },
    AR_REFUND_VOUCHER: { TYPEID: 44, TYPE: "RRV" },
    CASH_BOOK_PAYMENT: { TYPEID: 45, TYPE: "CBP" },
    CASH_BOOK_RECEIPT: { TYPEID: 46, TYPE: "CBR" },
    OUTGOING_PAYMENT: { TYPEID: 47, TYPE: "OP" },
    GIFT_RULE: { TYPEID: 48, TYPE: "GI" },
    LEAD: { TYPEID: 49, TYPE: "LE" },
    SALES_RESERVE_INVOICE: { TYPEID: 51, TYPE: "IVR" },
    STOCK_TRANSFER_REQUEST: { TYPEID: 52, TYPE: "STR" },
    PURCHASE_RESERVE_INVOICE: { TYPEID: 53, TYPE: "PRI" },

    getDocTypeByDocTypeId: (typeId) => {
        // returns TYPE by TYPEID
        return Object.values(DocumentTypeId).find(user => user.TYPEID === typeId)?.TYPE;
    },
    getDocTypeIdByDocType: (type) => {
        // returns TYPEID by TYPE
        return Object.values(DocumentTypeId).find(doc => doc.TYPE === type)?.TYPEID;
    },
}

