export function getEditCellProps(cellData) {
    const isEditor = cellData.hasOwnProperty("index");
    const info = isEditor ? cellData.data : cellData;
    const cellElement = info.component.getCellElement(info.rowIndex, info.column.dataField);

    return {
        info,
        value: info.value,
        oldValue: info.oldValue,
        displayValue: info.displayValue,
        data: info.data,
        dataField: info.column.dataField,
        visibleIndex: info.column.visibleIndex,
        setValueCallback: info.setValue,
        dataGridParent: info.component,
        cellElement,
        isEditor,
        editCellDisplayValue: info.column.editCellDisplayValue,
    };
}