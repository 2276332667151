import React from "react";
import { Select, Row, Col, Typography } from "antd";
import _ from "lodash";

import "../styles/DropdownInput.scss";

const { Option } = Select;
const { Text } = Typography;

const SelectOption = ({ dataColumn, dai, da }) => (
    <Option key={dai} value={JSON.stringify(da)}>
        <Row>
            {_.map(dataColumn, (dc, dcId) => {
                return dataColumn.length < 4 ? (
                    dcId !== dataColumn.length - 1 ? (
                        dcId === 0 ? (
                            <Col span={7} key={dcId}>
                                <Text ellipsis={true}>{da[dc]}</Text>
                            </Col>
                        ) : (
                            <Col
                                key={dcId}
                                offset={1}
                                span={dcId === 1 ? 7 : 5}
                            >
                                <Text ellipsis={true}>{da[dc]}</Text>
                            </Col>
                        )
                    ) : (
                        <Col
                            key={dcId}
                            span={
                                dataColumn.length === 3
                                    ? 8
                                    : dataColumn.length === 4
                                        ? 2
                                        : false
                            }
                            offset={dcId === 0 ? 0 : 1}
                        >
                            <Text ellipsis={true}>{da[dc]}</Text>
                        </Col>
                    )
                ) : (
                    <Col key={dcId} span={3} offset={dcId === 0 ? 0 : 1}>
                        <Text ellipsis={true}>{da[dc]}</Text>
                    </Col>
                );
            })}
        </Row>
    </Option>
);

export default SelectOption;