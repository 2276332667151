import React, { useRef, useState } from 'react'
import { formatMessage } from 'devextreme/localization';
import { DataGridDefault } from '../..';
import { Column, Editing, Summary, TotalItem } from 'devextreme-react/data-grid';
import { DefaultPager } from '../../../utils/default-data-grid-settings';
import useFlashMsg from '../../modal-dialog/useFlashMsg';
import PopupDefault from './popup-default';
import { FormikDefault } from '../../forms/formik-default';
import EditCellTextArea from '../../data-grid/custom-cell/edit-cell-textarea';
import EditCellDropdown from '../../data-grid/custom-cell/edit-cell-dropdown';
import EditCellCalendar from '../../data-grid/custom-cell/edit-cell-calendar';
import SectionSngDetails from './features/serial-number-generator/section-sng-details';
import SectionSngSequence from './features/serial-number-generator/section-sng-sequence';
import { useErrorPopupContext } from '../../modal-dialog/ErrorPopupProvider';
import { getEditCellProps } from '../../data-grid/custom-cell/util/edit-cell-util';
import { useUserAccessContext } from '../../../contexts/UserAccess';

const SerialNumGeneratorPopup = (props) => {
    const {
        submitCallback,
        userProfile,
        itemColorConfig,
        visible,
        onHiding,
        onHidden
    } = props;

    const [itemList, setItemList] = useState([]); // DataGrid source

    const formikRef = useRef(null);
    const dataGridRef = useRef(null);
    const portalRef = useRef(null);

    const showErrorPopup = useErrorPopupContext();
    const { userAccess } = useUserAccessContext();
    const { flashMsg, showFlashMsg } = useFlashMsg();

    // #region Events
    function onSubmit(values, actions) {
        submitCallback(itemList);
        actions.setSubmitting(false);
        return true;
    }

    function onGenerate() {
        const { radioInputObj, expiryDate, manufacturerDate, color, reference, remarks, totalUnit } = formikRef.current.values;
        const selectedRadioInput = radioInputObj.find((element) => element.radioSelected === true);

        if (!selectedRadioInput) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: "InvalidSequenceNumber"
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
        } else if (!totalUnit && totalUnit !== 0) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: "InvalidTotalUnit"
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
        } else {
            let newItemList = [];

            for (let index = 0; index < totalUnit; index++) {
                let serialNum = "";

                // combine and increment serialNum
                radioInputObj.forEach(element => {
                    if (element.id === selectedRadioInput.id) {
                        const intValue = parseInt(element.inputValue, 10); // base 10
                        serialNum += (intValue + index).toString().padStart(element.inputValue.length, '0');
                    } else {
                        serialNum += element.inputValue;
                    }
                });
                serialNum = serialNum.trim();

                // push new item row
                newItemList.push({
                    SerialNo: serialNum,
                    ExpiryDate: expiryDate,
                    ManufacturingDate: manufacturerDate,
                    Color: color,
                    Reference: reference,
                    Remarks: remarks,
                });
            }

            setItemList(newItemList);
        }
    }
    // #endregion Events

    // #region Render
    function editCellDropdown(cellData, dataGridConfig) {
        const { info, ...editCellProps } = getEditCellProps(cellData);

        return (
            <EditCellDropdown
                {...editCellProps}
                {...dataGridConfig}
                portalRef={portalRef}
                isEditable
            />
        )
    }

    function editCellCalendar(cellData) {
        const { info, ...editCellProps } = getEditCellProps(cellData);
        const dateFormat = userProfile?.US_DateFormat;

        return (
            <EditCellCalendar
                {...editCellProps}
                portalRef={portalRef}
                dateFormat={dateFormat}
                isEditable
            />
        )
    }

    function editCellTextarea(cellData) {
        const { info, ...editCellProps } = getEditCellProps(cellData);

        return (
            <EditCellTextArea
                {...editCellProps}
                portalRef={portalRef}
                isEditable
            />
        )
    }
    // #endregion Render

    return (
        <FormikDefault
            initialValues={{
                radioInputObj: null,
                expiryDate: null,
                manufacturerDate: null,
                color: null,
                reference: null,
                remarks: null,
                totalUnit: null,
            }}
            onSubmit={onSubmit}
            formikRef={formikRef}
        >
            <PopupDefault
                className="purchase-invoice-popup"
                title={"Serial Number Generator"}  // [Translation] Update Translation
                visible={visible}
                onHiding={onHiding}
                onHidden={onHidden}
                formikRef={formikRef}
            >
                <div className="portalRef dx-dropdowneditor-overlay" ref={portalRef} />

                {flashMsg}

                <SectionSngSequence
                    // Labels
                    name="radioInputObj"
                    // Others
                    formikRef={formikRef}
                />

                <hr style={{ marginTop: "32px" }}></hr>

                <SectionSngDetails
                    // Formatting
                    dateFormat={userProfile?.US_DateFormat}
                    // Events
                    onGenerateClick={onGenerate}
                    // Others
                    itemColorConfig={itemColorConfig}
                />

                <div className="module-data-grid-popup" style={{ marginTop: "36px" }}>
                    <DataGridDefault
                        // Styling
                        className='dx-datagrid-items datagrid-normalize'
                        // DevExtreme DataGrid Configurations
                        dataSource={itemList}
                        keyExpr="SerialNo"
                        allowDisplayColumnChooser={userAccess.allowDisplayColumnChooser}
                        allowExportGrid={userAccess.allowExportGrid}
                        allowRestoreLayout={userAccess.allowRestoreLayout}
                        allowedPageSizes={DefaultPager.allowedPageSizes_details}
                        defaultPageSize={DefaultPager.defaultPageSize_details}
                        noDataText={formatMessage("dxDataGrid-noDataText")}
                        focusedRowEnabled={true}
                        // Custom Configurations
                        dataGridRef={dataGridRef}
                        showMsgHandler={showFlashMsg}
                        exportFileName="SerialNumberGeneratorPopup"
                    >
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                        />
                        <Column // Summary Total
                            visibleIndex={0}
                            fixedPosition={'left'}
                            fixed={true}
                            width={0}
                            allowExporting={false}
                            allowFiltering={false}
                            allowHeaderFiltering={false}
                            allowHiding={false}
                            allowReordering={false}
                            allowResizing={false}
                            allowSorting={false}
                            showInColumnChooser={false}
                            cssClass="column-normalize"
                            dataField={'_TOTAL'} // dummy dataField for summary
                            caption={null}
                        />

                        <Column
                            caption={'Serial No.'} // [Translation] Update Translation
                            dataField={'SerialNo'}
                            cssClass="column-keepSpaces"
                        />
                        <Column
                            caption={formatMessage('ExpiryDate')}
                            dataField={`ExpiryDate`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={editCellCalendar}
                            editCellComponent={editCellCalendar}
                            width={200}
                            dataType="string"
                        />
                        <Column
                            caption={'Manufacturer Date'} // [Translation] Update Translation
                            dataField={`ManufacturingDate`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={editCellCalendar}
                            editCellComponent={editCellCalendar}
                            width={200}
                            dataType="string"
                        />
                        <Column
                            caption={formatMessage('Color')}
                            dataField={`Color`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={(cellData) => editCellDropdown(cellData, itemColorConfig)}
                            editCellComponent={(cellData) => editCellDropdown(cellData, itemColorConfig)}
                            dataType="string"
                            minWidth={60}
                            editCellDisplayValue="editCell.color"
                            calculateDisplayValue="editCell.color"
                        />
                        <Column
                            caption={formatMessage('Reference')}
                            dataField={`Reference`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={editCellTextarea}
                            editCellComponent={editCellTextarea}
                            minWidth={60}
                            maxWidth={200}
                        />
                        <Column
                            caption={formatMessage('Remarks')}
                            dataField={`Remarks`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={editCellTextarea}
                            editCellComponent={editCellTextarea}
                            width={200}
                        />
                        <Summary>
                            <TotalItem
                                summaryType="count"
                                column="_TOTAL"
                                displayFormat={`${formatMessage("Total")}: {0}`}
                                cssClass="summary-overflow"
                                skipEmptyValues
                            />
                        </Summary>
                    </DataGridDefault>
                </div>
            </PopupDefault>
        </FormikDefault>
    )
}

export default SerialNumGeneratorPopup