import { GET } from './request-options'

export const GetItemBinLocationCodeDesp = async (token, companyID, clientID, warehouseID) => {
    return fetch(`${process.env.REACT_APP_API_BINLOC_CODE_DESP}companyID=${companyID}&clientID=${clientID}&warehouseID=${warehouseID}&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

const handleErrors = (response) => {
    if (!response.ok) throw new Error(response.status);
    return response;
}