import * as React from 'react';
// import { jsPDF } from 'jspdf';
// import 'jspdf-autotable';
// import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
// import { Workbook } from 'exceljs';
// import saveAs from 'file-saver';
// import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import './tree-list-context-menu.scss'

//import font .ttf
// import Arial from '../web-font/arial/arial-unicode-ms.ttf';
import { formatMessage } from 'devextreme/localization';

const TreeListContextMenu = React.forwardRef((props, ref) => {

    // const sendGroupPanelToParent = () => {
    //     //here calling Parents changeValue
    //     props.getChildGroupPanel(true);
    // }

    const sendFilterRowToParent = () => {
        //here calling Parents changeValue
        props.getChildFilterRow(true);
    }

    const sendRestoreLayoutToParent = () => {
        //here calling parents restore layout function
        props.restoreLayout();
        props.showRestoreMsg?.(formatMessage('RestoredSuccessfully'), 'success', 'flash-message-success-container');
    }

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    React.useImperativeHandle(ref, () => ({

        TreeListContextMenuItemsService(e, TreeListInstance, fileName) {
            if (e.target === "header" && props.disableContextMenu) {
                e.items = null;
                return;
            }

            // const pdfdocumentView = () => {
            //     //get based on custom columns 
            //     const columns = TreeListInstance._views.headerPanel._columnsController._visibleColumns

            //     //more than 7 above - landscape
            //     //less than 6 column - portrait
            //     if (columns.length > 0) {
            //         columns[0].length >= 7 ? exportGridPdf('l') : exportGridPdf('p');
            //     }

            // }

            // const exportGridPdf = (pdfMode) => {
            //     var today = new Date();
            //     const doc = new jsPDF(pdfMode);
            //     const treeList = TreeListInstance;

            //     doc.addFont(Arial, 'arial-unicode-ms', 'normal');

            //     //console.log(doc.getFontList());
            //     exportDataGridToPdf({
            //         jsPDFDocument: doc,
            //         component: treeList,
            //         autoTableOptions: {
            //             styles: {
            //                 font: 'arial-unicode-ms'
            //             }
            //         }
            //     }).then(() => {
            //         doc.save(fileName + '_' + today.getFullYear() + (today.getMonth() + 1) + today.getDate() + '.pdf');
            //     });
            // }

            // const exportGridCSV = () => {
            //     var today = new Date();
            //     const workbook = new Workbook();
            //     const worksheet = workbook.addWorksheet('Main sheet');
            //     const treeList = TreeListInstance;
            //     exportDataGridToXLSX({
            //         component: treeList,
            //         worksheet: worksheet,
            //         customizeCell: function (options) {
            //             const excelCell = options;
            //             excelCell.font = { name: 'Arial', size: 12 };
            //             excelCell.alignment = { horizontal: 'left' };
            //         }
            //     }).then(function () {
            //         workbook.csv.writeBuffer()
            //             .then(function (buffer) {
            //                 saveAs(new Blob(["\uFEFF" + buffer], { type: 'application/octet-stream' }), fileName + '_' + today.getFullYear() + (today.getMonth() + 1) + today.getDate() + '.csv')
            //             });
            //     });
            // }

            // const exportGridXLSX = () => {
            //     var today = new Date();
            //     const workbook = new Workbook();
            //     const worksheet = workbook.addWorksheet('Main sheet');
            //     const treeList = TreeListInstance;
            //     exportDataGridToXLSX({
            //         component: treeList,
            //         worksheet: worksheet,
            //         customizeCell: function (options) {
            //             const excelCell = options;
            //             excelCell.font = { name: 'Arial', size: 12 };
            //             excelCell.alignment = { horizontal: 'left' };
            //         }
            //     }).then(function () {
            //         workbook.xlsx.writeBuffer()
            //             .then(function (buffer) {
            //                 saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '_' + today.getFullYear() + (today.getMonth() + 1) + today.getDate() + '.xlsx');
            //             });
            //     });
            // }

            if (e.target === 'header' || e.target === 'headerPanel') {
                // e.items can be undefined
                if (!e.items) e.items = [];

                // var groupByThisColumnItem = e.items && e.items.find(i => i.value === "group")
                // if (groupByThisColumnItem) {
                //     groupByThisColumnItem.icon = 'glyphicon icon-group-column-icon';
                // }

                // var ungroupAll = e.items && e.items.find(i => i.value === "ungroupAll")
                // if (ungroupAll) {
                //     ungroupAll.icon = 'minus';
                //     var groupCount = e.component.columnOption("groupIndex:0");
                //     if (!groupCount) {
                //         e.items.forEach((item) => {
                //             if (item.value === "ungroupAll")
                //                 item.disabled = true;
                //         })
                //     }
                // }

                // Add a custom menu item
                e.items.push(
                    // {
                    //     text: formatMessage('dxDataGrid-groupPanel'),
                    //     icon: 'glyphicon icon-group-panel-icon',
                    //     onItemClick: () => {
                    //         sendGroupPanelToParent();
                    //     }
                    // },
                    {
                        text: formatMessage('dxDataGrid-columnChooserTitle'),
                        icon: 'glyphicon icon-column-chooser-icon',
                        onItemClick: () => {
                            console.log(e.column.caption);
                            TreeListInstance.showColumnChooser();
                        },
                    },
                    //{
                    //  text: 'Save Layout',
                    //  icon: 'glyphicon icon-save-layout-icon',
                    //  beginGroup: 'true',
                    //  onItemClick: () => {
                    //      console.log(e.column.caption);
                    //  },
                    //},
                    {
                        text: formatMessage('RestoreDefaultLayout'),
                        icon: 'glyphicon icon-restore-default-icon',
                        beginGroup: 'true',
                        onItemClick: () => {
                            sendRestoreLayoutToParent();
                        },
                    },
                    {
                        text: formatMessage('dxDataGrid-applyFilterText'),
                        icon: 'glyphicon icon-filter-row-icon',
                        beginGroup: 'true',
                        onItemClick: () => {
                            sendFilterRowToParent();
                        },
                    },
                    // TODO: DISCUSS ABOUT "EXPORT FILE WITH TREELIST"
                    // {
                    //     text: formatMessage('dxDataGrid-exportTo'),
                    //     icon: 'glyphicon icon-export-icon',
                    //     onItemClick: () => {
                    //         console.log(e.column.caption);
                    //     },
                    //     items: [
                    //         {
                    //             text: formatMessage('dxDataGrid-exportToPdf'),
                    //             icon: 'glyphicon icon-pdf-red-icon',
                    //             onItemClick: function (args) {
                    //                 console.log(e.column.caption);
                    //                 pdfdocumentView();
                    //             }
                    //         },
                    //         {
                    //             text: formatMessage('dxDataGrid-exportToExcel'),
                    //             icon: 'glyphicon icon-xlsx-green-icon',
                    //             onItemClick: function (args) {
                    //                 console.log(e.column.caption);
                    //                 exportGridXLSX();

                    //             }
                    //         },
                    //         {
                    //             text: formatMessage('dxDataGrid-exportToCsv'),
                    //             icon: 'glyphicon icon-csv-greenblue-icon',
                    //             onItemClick: function (args) {
                    //                 console.log(e.column.caption);
                    //                 exportGridCSV();
                    //             }
                    //         }]
                    // }
                );

                // only enter when if statement props.x is false, but not undefined / null
                if (props.displayRestoreLayout === false) {
                    e.items = e.items.filter(x => x.text !== formatMessage('RestoreDefaultLayout'))
                }

                if (props.displayColumnChooser === false) {
                    e.items = e.items.filter(x => x.text !== formatMessage('dxDataGrid-columnChooserTitle'))
                }

                if (props.displayExportGrid === false) {
                    e.items = e.items.filter(x => x.text !== formatMessage('dxDataGrid-exportTo'))
                }
            }
        },
    }));

    return <div></div>;
})

export default TreeListContextMenu;
