import React, { useCallback, useEffect, useRef, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom';
import { formatMessage } from 'devextreme/localization';
import { DataGridDefault } from '../..';
import { Column, Editing, Summary, TotalItem } from 'devextreme-react/data-grid';
import { DefaultPager } from '../../../utils/default-data-grid-settings';
import { DeleteBtn } from '../..';
import useFlashMsg from '../../modal-dialog/useFlashMsg';
import PopupDefault from './popup-default';
import SectionRemarksUnitCost from './features/section-remarks-unitcost';
import { FormikDefault } from '../../forms/formik-default';
import { ClientID, CompanyID, Token } from '../../../utils/default-cookies';
import { getErrorCode, setErrorPopUpMsg } from '../../../utils/error-popup-http-error-msg';
import { cloneDeep, isEmpty } from "lodash";
import { GetItemBatchStockIn } from '../../../api/purchase-invoice-details';
import EditCellTextArea from '../../data-grid/custom-cell/edit-cell-textarea';
import EditCellNumber from '../../data-grid/custom-cell/edit-cell-number';
import EditCellDropdown from '../../data-grid/custom-cell/edit-cell-dropdown';
import { checkInvalidCell } from '../../data-grid/data-grid-util';
import EditCellCalendar from '../../data-grid/custom-cell/edit-cell-calendar';
import DeletePopUp from '../delete-popup';
import { useErrorPopupContext } from '../../modal-dialog/ErrorPopupProvider';
import SectionBatchDetails from './features/batch-item/section-batch-details';
import moment from 'moment';
import { getEditCellProps } from '../../data-grid/custom-cell/util/edit-cell-util';
import { DocumentTypeId } from "../../../utils/constant-document-type-id";
import { getInterfaceType, isCopyFromUI } from './util/item-type-popup-util';
import SectionBatchDetailsCF from './features/batch-item/section-batch-details-cf';
import { useUserAccessContext } from '../../../contexts/UserAccess';

const BatchItemPopup = (props) => {
    const {
        submitCallback,
        cancelCallback,
        iAGID,
        docType,
        userProfile,
        visible,
        onHiding,
        onHidden
    } = props;

    const {
        mainItem,
        deleteOnCancel,
        requiredQty,
    } = props.popupData;

    const {
        PID_GroupID: groupID,
        PID_PIHID: docID,
        PID_ItemID: itemID,
        PID_ItemTypeID: itemType,
        PID_BinLocationID: binLocationID,
        PID_Remarks: remarks,
        PID_UnitPrice: unitCost,
        PID_ItemUOMID: itemUOM,
        PID_Rate: itemUOMRate,
        PID_SourceDocID: sourceDocID,
        PID_SourceDocTypeID: sourceDocTypeID,
    } = mainItem;

    const [deletePopUpVisible, setDeletePopUpVisible] = useState(false);

    const [itemColorList, setItemColorList] = useState(); // BatchItemColorList
    const [itemBatchList, setItemBatchList] = useState(); // BatchItemList
    const [itemUOMList, setItemUOMList] = useState(); // ItemUOMList
    const [itemBinLocationList, setItemBinLocationList] = useState(); // BinLocationList
    const [itemList, setItemList] = useState([]); // StockInDocBatchItemList > DataGrid source

    const formikRef = useRef(null);
    const itemUOMRateRef = useRef(itemUOMRate); // Item UOM Rate
    const itemColorConfig = useRef({}); // Color Dropdown DataGrid Config
    const itemBatchConfig = useRef(); // Batch Dropdown DataGrid Config
    const itemDelete = useRef(null); // To keep track of item to be deleted
    const dataGridRef = useRef(null);
    const portalRef = useRef(null);
    const apiLoaded = useRef(false);

    const isSubItem = useRef(mainItem.PID_SubItem_YN);
    const interfaceType = useRef(getInterfaceType(sourceDocTypeID, itemType, isSubItem.current));

    const showErrorPopup = useErrorPopupContext();
    const { userAccess } = useUserAccessContext();
    const { flashMsg, showFlashMsg } = useFlashMsg();

    // #region Util
    function getDataGrid() {
        return dataGridRef?.current?.instance;
    }

    function sortBatchNum(array) {
        return array.sort((itemA, itemB) => ('' + (itemA?.BatchNo || itemA)).localeCompare('' + (itemB?.BatchNo || itemB)));
    }

    function showDeletePopUp() {
        setDeletePopUpVisible(true);
    }

    function hideDeletePopUp() {
        setDeletePopUpVisible(false);
    }

    function callbackDeletePopUp() {
        deleteBatchItem();
        hideDeletePopUp();
    }

    function deleteBatchItem() {
        setItemList(itemListPrev => itemListPrev.filter(item => item.BatchNo !== itemDelete.current.BatchNo));
    }

    function insertBatchItem() {
        function createBatchItemObj(batchObj) {
            const {
                IB_BatchNo = "",
                IB_Color = null,
                IB_ColorCode = null,
                IB_ColorDesc = null,
                IB_ExpiryDate = null,
                IB_ManufacturingDate = null,
                IB_Reference = null,
                IB_Remarks = null,
                isNew = false,
            } = batchObj;

            return {
                ID: 0,
                BatchNo: IB_BatchNo,
                BaseQty: 0,
                ItemID: itemID,
                ItemUOMID: itemUOM,
                DocID: docID,
                BinLocationID: binLocationID,
                SequenceID: 0,
                GroupID: groupID,
                IsUsed: false,
                Qty: 1,
                ActualDocBaseQty: 0,
                ActualItemBatchBaseQty: 0,
                Color: IB_Color,
                ColorCode: IB_ColorCode,
                ColorDesc: IB_ColorDesc,
                ExpiryDate: IB_ExpiryDate,
                ManufacturingDate: IB_ManufacturingDate,
                Reference: IB_Reference,
                Remarks: IB_Remarks,
                isNew: isNew,
            }
        }

        const batchNo = formikRef?.current?.values?.batchNo?.trim(); // removes whitespace from both sides of the string
        if (!batchNo) return;

        const currentBatchNoList = itemList.map(item => item.BatchNo); // BatchNo in datagrid
        const batchNoExist = currentBatchNoList.find((item) => item.toLowerCase() === batchNo.toLowerCase());

        if (batchNoExist) {
            // navigate to row if BatchNo is in datagrid
            getDataGrid()?.option('navigateToRow', batchNoExist);
            getDataGrid()?.refresh(true);
        } else {
            // add new BatchNo
            const batchObj = itemBatchList.find((batch) => batch.IB_BatchNo === batchNo) || { IB_BatchNo: batchNo, isNew: true };
            const itemNew = createBatchItemObj(batchObj);
            setItemList((itemListPrev) => sortBatchNum([...itemListPrev, itemNew]));

            // navigate to new BatchNo row 
            getDataGrid()?.option('navigateToRow', itemNew.BatchNo);
            getDataGrid()?.refresh(true);
        }
    }

    function selectBatchNo(searchBatch) {
        const batchNo = searchBatch?.trim(); // removes whitespace from both sides of the string
        if (!batchNo) return;

        const selectedRow = itemList.find((item) => item.BatchNo?.toLowerCase() === batchNo.toLowerCase());

        if (selectedRow?.BatchNo) {
            // add search-row css to row elements
            const searchRowIndex = getDataGrid()?.getRowIndexByKey(selectedRow.BatchNo);
            const searchRowElements = getDataGrid()?.getRowElement(searchRowIndex);
            addSearchRowCSS(searchRowElements);

            // navigate to BatchNo row 
            getDataGrid()?.option('navigateToRow', selectedRow.BatchNo);
            getDataGrid()?.refresh(true);
        }
    }

    function selectExpiryDate(searchDate) {
        function matchByExpiryDate(item) {
            return item.ExpiryDate.split('T')[0] === expiryDate.split('T')[0];
        }

        const expiryDate = searchDate;
        if (!expiryDate) return;

        const selectedRowList = itemList.filter(matchByExpiryDate);

        if (!isEmpty(selectedRowList)) {
            selectedRowList.forEach(selectedRow => {
                // add search-row css to row elements
                const searchRowIndex = getDataGrid()?.getRowIndexByKey(selectedRow.BatchNo);
                const searchRowElements = getDataGrid()?.getRowElement(searchRowIndex);
                addSearchRowCSS(searchRowElements);
            });

            // navigate to last BatchNo row 
            const selectedRowLast = selectedRowList[selectedRowList.length - 1];
            getDataGrid()?.option('navigateToRow', selectedRowLast.BatchNo);
            getDataGrid()?.refresh(true);
        }
    }

    function addSearchRowCSS(rowElements) {
        // use .select-row for multiple selected rows (devextreme focusedRow only focuses 1 row)
        rowElements.forEach(element => {
            if (!element.classList.contains('select-row')) {
                element.classList.add('select-row');
            }
        });
    }

    function clearSearchRowCSS() {
        const selectedElements = document.querySelectorAll('.select-row');
        selectedElements.forEach(element => {
            element.classList.remove('select-row');
        });
    }
    // #endregion Util

    // #region Events
    function onClickDelete(item) {
        itemDelete.current = item;
        showDeletePopUp();
    }

    function onAddBatchNo() {
        insertBatchItem();
        formikRef.current.setFieldValue("batchNo", null); // clear batchNo
    }

    function onSearch() {
        const {
            searchType,
            searchBatch,
            searchDate,
        } = formikRef?.current?.values;

        // clear existing search row CSS
        clearSearchRowCSS();

        // search and add search row CSS
        if (searchType === "BatchNo") {
            selectBatchNo(searchBatch);
        } else {
            selectExpiryDate(searchDate);
        }

        // clear inputs
        formikRef.current.setFieldValue("searchBatch", null);
        formikRef.current.setFieldValue("searchDate", null);
    }

    function onUOMChange({ obj }) {
        itemUOMRateRef.current = obj.Rate;

        if (isCopyFromUI(interfaceType.current)) {
            itemList.forEach(item => {
                item.Qty = 0;
            });

            getDataGrid()?.refresh(true); // refresh data grid to update Qty max and value
        }
    }

    async function onSubmit(values, actions) {
        function checkInvalidQty() {
            if (isCopyFromUI(interfaceType.current)) return false;

            const invalidQty = itemList.some(item => {
                if (item.ActualItemBatchBaseQty !== 0 && item.BaseQty < item.ActualDocBaseQty) {
                    if ((item.ActualItemBatchBaseQty - (item.ActualDocBaseQty - item.BaseQty)) < 0) {
                        return true;
                    }
                }
                return false;
            });

            return invalidQty;
        }

        function checkRequiredQty(total) {
            if (!isSubItem.current) return true;
            return total === requiredQty;
        }

        itemList.forEach(item => {
            item.BaseQty = item.Qty * itemUOMRateRef.current;
        });

        await getDataGrid()?.refresh(true); // refresh data grid to update data rows & summary

        const total = getDataGrid()?.getTotalSummaryValue("Qty");

        if (await checkInvalidCell(itemList)) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: "ContainInvalidCell"
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            actions.setSubmitting(false);
            return false;
        } else if (!total) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: "PleaseInsertAtLeastOneQuantity"
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            actions.setSubmitting(false);
            return false;
        } else if (checkInvalidQty()) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: "QtyLessThanOnHoldQty"
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            actions.setSubmitting(false);
            return false;
        } else if (!checkRequiredQty(total)) {
            const errorMsg = {
                title: "ErrorEncountered",
                subTitle: `${formatMessage("InvalidRequiredPackageQuantity")} (${requiredQty})`
            };

            showErrorPopup?.(errorMsg.title, errorMsg.subTitle, false);
            actions.setSubmitting(false);
            return false;
        } else {
            const itemDetail = {
                BatchItemColorList: itemColorList,
                BatchItemList: itemBatchList,
                BinLocationList: itemBinLocationList,
                ItemUOMList: itemUOMList,
                StockInDocBatchItemList: itemList
            };

            const remarks = values.remarks;
            const unitCost = values.unitCost;
            const binLocation = values.binLocation;
            const uom = values.uom;

            const editedMainItem = {
                ...mainItem,
                PID_Remarks: remarks,
                PID_UnitPrice: unitCost,
                PID_BinLocationID: binLocation,
                PID_ItemUOMID: uom,
                PID_Rate: itemUOMRateRef.current,
                itemDetail,
            };

            if (!isSubItem.current) {
                // if isSubItem, no need update PID_Qty (Batch Item inside Package Item)
                editedMainItem.PID_Qty = total;
            }

            submitCallback({ itemID, editedMainItem }); // need itemID for packageItem's batchItem
            actions.setSubmitting(false);
            return true;
        }
    }

    function onCancel() {
        if (deleteOnCancel) {
            cancelCallback?.(groupID);
        }
    }
    // #endregion Events

    // #region Data Util
    function initData(data, dateFormat) {
        function sortBatchNum(array) {
            return array.sort((itemA, itemB) => ('' + (itemA?.BatchNo || itemA)).localeCompare('' + (itemB?.BatchNo || itemB)));
        }

        function setItemColorData(itemColorList) {
            itemColorConfig.current = {
                dataSource: itemColorList,
                columns: [
                    { dataField: "IC_Code", caption: formatMessage("Code") },
                    { dataField: "IC_Description", caption: formatMessage("Description") }
                ],
                keyExpr: "IC_ID",
                displayExpr: "IC_Code",
                addEmptySelection: true,
            }
        }

        function setItemBatchData(itemBatchList) {
            const itemBatchListCopy = cloneDeep(itemBatchList);

            itemBatchListCopy.forEach(batch => {
                if (batch.IB_ExpiryDate) {
                    const expiryDateString = moment(new Date(batch.IB_ExpiryDate)).format(dateFormat?.toUpperCase());
                    batch.IB_ExpiryDate = expiryDateString;
                }

                if (batch.IB_ManufacturingDate) {
                    const manufacturingDateString = moment(new Date(batch.IB_ManufacturingDate)).format(dateFormat?.toUpperCase());
                    batch.IB_ManufacturingDate = manufacturingDateString;
                }
            });

            itemBatchConfig.current = itemBatchListCopy;
        }

        unstable_batchedUpdates(function () {
            const { BatchItemColorList, BatchItemList, BinLocationList, ItemUOMList, StockInDocBatchItemList } = data;

            setItemColorList(BatchItemColorList);
            setItemBatchList(BatchItemList);
            setItemBinLocationList(BinLocationList);
            setItemUOMList(ItemUOMList);
            setItemList(sortBatchNum(StockInDocBatchItemList));

            setItemColorData(BatchItemColorList);
            setItemBatchData(BatchItemList);

            apiLoaded.current = true;
        })
    }
    // #endregion Data Util

    // #region Data Fetch API
    const fetchInitialData = useCallback(() => {
        if (!Token()) return;

        setTimeout(() => {
            getDataGrid()?.beginCustomLoading();
        }, 0);

        const queryParam = {
            token: Token(),
            clientID: ClientID(),
            companyID: CompanyID(),
            iAGID,
            docID,
            docType,
            groupID,
            itemID,
            binLocationID,
            sourceDocID,
            sourceDocType: DocumentTypeId.getDocTypeByDocTypeId(sourceDocTypeID),
        }

        GetItemBatchStockIn(queryParam).then((data) => {
            if (typeof data === "string" && data.includes("Error")) {
                const errorCode = getErrorCode(data);
                const errorMsg = setErrorPopUpMsg(errorCode);

                showErrorPopup?.(errorMsg.title, errorMsg.subTitle);
            } else {
                initData(data, userProfile?.US_DateFormat);
            }
        }).finally(() => {
            getDataGrid()?.endCustomLoading();
        })
    }, [iAGID, docID, docType, groupID, itemID, binLocationID, sourceDocID, sourceDocTypeID, userProfile, showErrorPopup]);
    // #endregion Data Fetch API

    // #region DataGrid configurations
    const onRowClick = useCallback(() => {
        clearSearchRowCSS();
    }, [])
    // #endregion DataGrid configurations

    // #region UseEffect
    useEffect(() => {
        if (!mainItem.itemDetail) {
            fetchInitialData();
        } else {
            initData(mainItem?.itemDetail, userProfile?.US_DateFormat);
        }
    }, [mainItem, fetchInitialData, userProfile]);
    // #endregion UseEffect

    // #region Render
    function calculateQtyOnHand(rowData) {
        return rowData?.OnTransferBaseQty / itemUOMRateRef.current;
    }

    function deleteBtnCell(cellData) {
        const isEnabled = !cellData.data.IsUsed;

        return (
            <DeleteBtn
                {...cellData}
                onClick={onClickDelete}
                enabled={isEnabled}
            />
        );
    }

    function editCellNumber(cellData) {
        function getCellConfig(data) {
            if (isCopyFromUI(interfaceType.current)) {
                return {
                    min: 0,
                    max: data.OnTransferBaseQty / itemUOMRateRef.current,
                    precision: 2,
                    showAllDecimalPoints: true,
                }
            } else {
                return {
                    min: 1,
                }
            }
        }

        const { info, ...editCellProps } = getEditCellProps(cellData);
        const cellConfig = getCellConfig(info.data);

        return (
            <EditCellNumber
                {...editCellProps}
                isEditable
                isRequired
                {...cellConfig}
            />
        )
    }

    function editCellDropdown(cellData, dataGridConfig) {
        const { info, ...editCellProps } = getEditCellProps(cellData);
        const isEditable = info.data?.isNew && !isCopyFromUI(interfaceType.current);

        return (
            <EditCellDropdown
                {...editCellProps}
                {...dataGridConfig}
                portalRef={portalRef}
                isEditable={isEditable}
            />
        )
    }

    function editCellCalendar(cellData) {
        function getRequired(dataField) {
            if (dataField === "ExpiryDate") return true;
            else return false;
        }

        const { info, ...editCellProps } = getEditCellProps(cellData);
        const dateFormat = userProfile?.US_DateFormat;
        const isEditable = info.data?.isNew && !isCopyFromUI(interfaceType.current);
        const isRequired = getRequired(info.column.dataField);

        return (
            <EditCellCalendar
                {...editCellProps}
                portalRef={portalRef}
                dateFormat={dateFormat}
                isEditable={isEditable}
                isRequired={isRequired}
            />
        )
    }

    function editCellTextarea(cellData) {
        const { info, ...editCellProps } = getEditCellProps(cellData);
        const isEditable = info.data?.isNew && !isCopyFromUI(interfaceType.current);

        return (
            <EditCellTextArea
                {...editCellProps}
                portalRef={portalRef}
                isEditable={isEditable}
            />
        )
    }
    // #endregion Render

    return (
        <FormikDefault
            initialValues={{
                batchNo: "",
                binLocation: binLocationID,
                uom: itemUOM,
                remarks: remarks,
                unitCost: unitCost,
                searchType: "BatchNo",
                searchBatch: null,
                searchDate: null,
            }}
            onSubmit={onSubmit}
            formikRef={formikRef}
        >
            <PopupDefault
                className="purchase-invoice-popup"
                title={"Batch Item"}  // [Translation] Update Translation
                visible={visible}
                onHiding={onHiding}
                onHidden={onHidden}
                cancelForm={onCancel}
                apiLoaded={apiLoaded.current}
                formikRef={formikRef}
            >
                <div className="portalRef dx-dropdowneditor-overlay" ref={portalRef} />

                {flashMsg}

                <DeletePopUp
                    parentCallback={callbackDeletePopUp}
                    hideDeletePopup={hideDeletePopUp}
                    visible={deletePopUpVisible}
                />

                {
                    isCopyFromUI(interfaceType.current) ?
                        <SectionBatchDetailsCF
                            itemBinLocationList={itemBinLocationList}
                            itemUOMList={itemUOMList}
                            onSearch={onSearch}
                            onUOMChange={onUOMChange}
                            disabled={!apiLoaded.current}
                            portalRef={portalRef}
                            dateFormat={userProfile?.US_DateFormat}
                        />
                        :
                        <SectionBatchDetails
                            itemBatchConfig={itemBatchConfig}
                            itemUOMList={itemUOMList}
                            onAddBatchNo={onAddBatchNo}
                            onUOMChange={onUOMChange}
                            disabled={!apiLoaded.current}
                            interfaceType={interfaceType}
                        />
                }

                <div className="module-data-grid-popup" style={{ marginTop: "16px" }}>
                    <DataGridDefault
                        // Styling
                        className='dx-datagrid-items datagrid-normalize'
                        // DevExtreme DataGrid Configurations
                        dataSource={itemList}
                        keyExpr="BatchNo"
                        allowDisplayColumnChooser={userAccess.allowDisplayColumnChooser}
                        allowExportGrid={userAccess.allowExportGrid}
                        allowRestoreLayout={userAccess.allowRestoreLayout}
                        allowedPageSizes={DefaultPager.allowedPageSizes_details}
                        defaultPageSize={DefaultPager.defaultPageSize_details}
                        noDataText={formatMessage("dxDataGrid-noDataText")}
                        focusedRowEnabled={true}
                        onRowClick={isCopyFromUI(interfaceType.current) ? onRowClick : null}
                        // Custom Configurations
                        dataGridRef={dataGridRef}
                        showMsgHandler={showFlashMsg}
                        exportFileName="BatchItemPopup"
                    >
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                        />
                        {
                            isCopyFromUI(interfaceType.current) ?
                                <Column // Summary Total
                                    visibleIndex={0}
                                    fixedPosition={'left'}
                                    fixed={true}
                                    width={0}
                                    allowExporting={false}
                                    allowFiltering={false}
                                    allowHeaderFiltering={false}
                                    allowHiding={false}
                                    allowReordering={false}
                                    allowResizing={false}
                                    allowSorting={false}
                                    showInColumnChooser={false}
                                    cssClass="column-normalize"
                                    dataField={'_TOTAL'} // dummy dataField for summary
                                    caption={null}
                                />
                                :
                                <Column
                                    visibleIndex={0}
                                    fixedPosition={'left'}
                                    fixed={true}
                                    width={50}
                                    minWidth={50}
                                    allowExporting={false}
                                    allowFiltering={false}
                                    allowHeaderFiltering={false}
                                    allowHiding={false}
                                    allowReordering={false}
                                    allowResizing={false}
                                    allowSorting={false}
                                    showInColumnChooser={false}
                                    cssClass="column-normalize"
                                    caption={null}
                                    cellRender={deleteBtnCell}
                                    dataField={'_TOTAL'} // dummy dataField for summary
                                />
                        }
                        <Column
                            caption={'Batch No.'} // [Translation] Update Translation
                            dataField={'BatchNo'}
                            cssClass="column-keepSpaces"
                            allowReordering={!isCopyFromUI(interfaceType.current)}
                        />
                        <Column
                            caption={formatMessage('SmallCapitalQty')}
                            dataField={`Qty`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={editCellNumber}
                            editCellComponent={editCellNumber}
                            dataType="number"
                        />
                        {
                            isCopyFromUI(interfaceType.current) &&
                            <Column
                                caption={formatMessage('QtyonHand')}
                                cssClass="column-keepSpaces"
                                dataType="number"
                                calculateCellValue={calculateQtyOnHand}
                                format={"#0.00"}
                            />
                        }
                        <Column
                            caption={formatMessage('ExpiryDate')}
                            dataField={`ExpiryDate`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={editCellCalendar}
                            editCellComponent={editCellCalendar}
                            width={200}
                            dataType="string"
                        />
                        <Column
                            caption={'Manufacturer Date'} // [Translation] Update Translation
                            dataField={`ManufacturingDate`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={editCellCalendar}
                            editCellComponent={editCellCalendar}
                            width={200}
                            dataType="string"
                        />
                        <Column
                            caption={formatMessage('Color')}
                            dataField={`Color`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={(cellData) => editCellDropdown(cellData, itemColorConfig.current)}
                            editCellComponent={(cellData) => editCellDropdown(cellData, itemColorConfig.current)}
                            dataType="string"
                            minWidth={60}
                            editCellDisplayValue="editCell.color"
                            calculateDisplayValue="editCell.color"
                        />
                        <Column
                            caption={formatMessage('Reference')}
                            dataField={`Reference`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={editCellTextarea}
                            editCellComponent={editCellTextarea}
                            minWidth={60}
                            maxWidth={200}
                        />
                        <Column
                            caption={formatMessage('Remarks')}
                            dataField={`Remarks`}
                            allowEditing={true}
                            cssClass="column-normalize"
                            cellRender={editCellTextarea}
                            editCellComponent={editCellTextarea}
                            minWidth={60}
                            maxWidth={200}
                        />
                        <Summary>
                            <TotalItem
                                summaryType="count"
                                showInColumn="_TOTAL"
                                displayFormat={`${formatMessage("Total")}:`}
                                cssClass="summary-overflow"
                                skipEmptyValues
                            />
                            <TotalItem
                                summaryType="sum"
                                column="Qty"
                                displayFormat="{0}"
                            />
                        </Summary>
                    </DataGridDefault>
                </div>

                <SectionRemarksUnitCost />

            </PopupDefault>
        </FormikDefault>
    )
}

export default BatchItemPopup