import { GET, POST } from './request-options'

export const GetPurchaseInvoiceHDRByDateType_Date = (token, companyID, dateType, fromDate, toDate, loadFirstTime, userID, status, filter) => {
  return fetch(`${process.env.REACT_APP_API_GET_PURCHASE_INVOICE_HDR}companyID=${companyID}&dateType=${dateType}&fromDate=${fromDate}&toDate=${toDate}&userID=${userID}&strStatus=${status}&loadFirstTime=${loadFirstTime}&UI-Culture=en-US`, GET(token))
    .then(handleErrors2)
    .then(response => response.json())
    .then((data) => {
      return {
        data: data.Obj.Query_PurchaseInvoiceListing,
        totalCount: data.Obj.RowCount,
        totalSum: data.Obj.RowSum,
        LinkBtnUrlAnalysis: data.Obj.LinkBtnUrlAnalysis,
        LinkBtnUrlDocumentAction: data.Obj.LinkBtnUrlDocumentAction,
        US_DateFormat: data.Obj.US_DateFormat
      };
    })
    .catch((error) => { console.log(error); return error.toString(); });
}

export const CancelPurchaseInvoice = (token, PIH_ID, companyID, userID, clientID, postingDate) => {
  return fetch(`${process.env.REACT_APP_API_CANCEL_PURCHASE_INVOICE_HDR}PIHID=${PIH_ID}&companyID=${companyID}&userID=${userID}&clientID=${clientID}&postingDate=${postingDate}&UI-Culture=en-US`, POST(token))
    .then(handleErrors2)
    .then(response => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => { console.log(error); return error.toString(); });
}

//handle errors
//function handleErrors(response) {
//    if (!response.ok) throw new Error(response.status);
//    return response;
//}

function handleErrors2(response) {
  if (!response.ok) throw new Error("Session Expired! Please login again.");
  return response;
}
//#endregion