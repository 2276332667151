import React from 'react';
import Form from 'devextreme-react/form';
import { Item } from 'devextreme-react/select-box';
import { Button } from 'devextreme-react';
import { Column, Editing } from 'devextreme-react/data-grid';
import '../pop-up/popup.scss';
import { Popup } from 'devextreme-react/popup';
import ErrorPopUp from '../pop-up/error-popup';
import ScrollView from 'devextreme-react/scroll-view';
import { cloneDeep } from "lodash";

//#region customized 
//components
import TextBox2 from '../../components/text-box/text-box-2';
import Flash from '../message/flash'

//default 
import { DefaultPager } from '../../utils/default-data-grid-settings';
import { CompanyID, UserID, Token, ClientID } from '../../utils/default-cookies';
import { checkHTTPResponseCode } from '../../utils/error-popup-http-error-msg';
import { getRoundingValue } from '../../utils/math-rounding-func';

//api
import { GetCopyFromDocumentPO, GetCopyFromDocumentGR, GetPartialCopyFromDocumentPO, GetPartialCopyFromDocumentGR } from '../../api/copy-from';
import { GetUserLayout } from '../../api/user-preferred-layout';

//language
import { formatMessage } from 'devextreme/localization';

//DataGridDefault
import { DataGridDefault, customSearchRenderer } from '../';
import { checkInvalidCell, filterBySearchText, sleep } from '../data-grid/data-grid-util';
import EditCellNumber from '../data-grid/custom-cell/edit-cell-number';
import EditCellDropdown from '../data-grid/custom-cell/edit-cell-dropdown';

//iframe
import { getPostMessageObj } from '../../utils/iframe-func'

// antd UI
import { Tabs, Row, Col } from "antd";
import { getEditCellProps } from '../data-grid/custom-cell/util/edit-cell-util';
import { mousetrapMain } from '../../App';
const { TabPane } = Tabs;
//#endregion

class CopyFromPopUp extends React.Component {

    constructor(props) {
        super(props);

        this.dataGridFullTransferRef = React.createRef();
        this.dataGridPartialTransferRef = React.createRef();

        this.quickSearchFullTransferRef = React.createRef();
        this.quickSearchPartialTransferRef = React.createRef();

        this.portalRef = React.createRef();

        this.searchValueChangeFullTransferHandler = this.searchValueChangeFullTransferHandler.bind(this);
        this.searchValueChangePartialTransferHandler = this.searchValueChangePartialTransferHandler.bind(this);

        this.clearItemSelection = this.clearItemSelection.bind(this);

        this.onFlashMessageTimer = this.onFlashMessageTimer.bind(this);
        this.showMsgHandler = this.showMsgHandler.bind(this);
        this.closeFlashMessageCallback = this.closeFlashMessageCallback.bind(this);
        this.docNoLinkClick = this.docNoLinkClick.bind(this);
        this.popupOnShowing = this.popupOnShowing.bind(this);
        this.popupOnShown = this.popupOnShown.bind(this);
        this.popupOnHidden = this.popupOnHidden.bind(this);
        this.handleErrorPopUp = this.handleErrorPopUp.bind(this);

        this.onUOMChanged = this.onUOMChanged.bind(this);

        this.state = {

            searchTextFullTransfer: '',
            searchTextPartialTransfer: '',

            fullTransferList: [],
            partialTransferList: [],
            selectedFullTransferID: [],
            selectedPartialTransferID: [],

            //flash msg
            emptyMsg: '',
            displayFlashMessageDialog: false,
            status: 'success',
            size: 'flash-message-success-container',
            tabKey: "1",

            //Error pop up
            displayErrorPopUp: false,
            errorTitle: "",
            setErrorTitle: "",

            preferedLayoutFullTransfer: [],
            preferedLayoutPartialTransfer: [],

        };

        this.format = {
            type: 'fixedPoint',
            precision: 2
        }

        this.qtyFormat = "#0.00";

        this.getSelectedRowKeysFullTransfer = () => {
            return this.dataGridFullTransfer.getSelectedRowKeys();
        }
        this.getSelectedRowsDataFullTransfer = () => {
            return this.dataGridFullTransfer.getSelectedRowsData();
        }
        this.getSelectedRowKeysPartialTransfer = () => {
            return this.dataGridPartialTransfer.getSelectedRowKeys();
        }
        this.getSelectedRowsDataPartialTransfer = () => {
            return this.dataGridPartialTransfer.getSelectedRowsData();
        }

        // [Variables]
        this.dropdownCellConfig = {
            columns: [
                { dataField: "Code", caption: formatMessage("Code") },
                { dataField: "Rate", caption: formatMessage("Rate") }
            ],
            keyExpr: "ID",
            displayExpr: "Code",
        }
    }

    //#region util

    searchValueChangeFullTransferHandler = async (event) => {
        this.setState({ searchTextFullTransfer: event });
        filterBySearchText(this.state.searchTextFullTransfer, this.dataGridFullTransfer);
    }

    searchValueChangePartialTransferHandler = async (event) => {
        this.setState({ searchTextPartialTransfer: event });
        filterBySearchText(this.state.searchTextPartialTransfer, this.dataGridPartialTransfer);
    }

    setErrorPopUpState = (title, subtitle, visible) => {
        this.setState({
            errorTitle: title,
            errorSubTitle: subtitle,
            displayErrorPopUp: visible
        })
    }

    isResponseSuccess = (values) => {
        if (typeof values === "string" && values.includes("Error")) {
            const responseMsg = checkHTTPResponseCode(values);
            this.setErrorPopUpState(responseMsg.title, responseMsg.subtitle, true);
            return false;
        }
        else if (values?.Result === false) {
            this.setErrorPopUpState("ErrorEncountered", values.Message, true);
            return false;
        }
        return true;
    }

    //#endregion

    //#region state

    handleFullTransferList = (result) => {
        this.setState({ fullTransferList: result });
    }

    handlePartialTransferList = (result) => {
        this.setState({ partialTransferList: result });
    }

    handleErrorPopUp = () => {
        this.setState(state => ({
            displayErrorPopUp: !state.displayErrorPopUp
        }))
    }

    handlePreferedLayoutFullTransfer = (result) => {
        this.setState({ preferedLayoutFullTransfer: result });
    }

    handlePreferedLayoutPartialTransfer = (result) => {
        this.setState({ preferedLayoutPartialTransfer: result });
    }


    //#endregion

    //#region init
    componentDidMount() {
        document.addEventListener('keydown', this.onFlashMessageTimer, false);
        document.addEventListener('mousedown', this.onFlashMessageTimer, false);
    }

    componentWillUnmount() {
        mousetrapMain.unpause();

        document.removeEventListener('keydown', this.onFlashMessageTimer, false);
        document.removeEventListener('mousedown', this.onFlashMessageTimer, false);
    }

    async fetchDataAPI() {
        this.getFullTransferByDocType();
        this.getFullTransferDataGridUserLayout();
    }

    getFullTransferByDocType() {
        let result;
        switch (this.props.copyFromDocType) {
            case "PO":
                result = Promise.resolve(
                    GetCopyFromDocumentPO(Token(), CompanyID(), this.props.vendorID, this.props.warehouseID === undefined ? 0 : this.props.warehouseID, this.props.docID, this.props.copyToDocType, UserID())
                );

                result.then((values) => {
                    if (this.isResponseSuccess(values))
                        this.handleFullTransferList(values?.CopyFromDocumentList)

                });
                break;
            case "GR":
                result = Promise.resolve(
                    GetCopyFromDocumentGR(Token(), CompanyID(), this.props.vendorID, this.props.warehouseID === undefined ? 0 : this.props.warehouseID, this.props.docID, this.props.copyToDocType, UserID())
                );

                result.then((values) => {
                    if (this.isResponseSuccess(values))
                        this.handleFullTransferList(values?.CopyFromDocumentList)

                });
                break;
            default:
            // code block
        }
    }

    getPartialTransferByDocType(fullTransferID) {
        let result;
        switch (this.props.copyFromDocType) {
            case "PO":
                result = Promise.resolve(
                    GetPartialCopyFromDocumentPO(Token(), CompanyID(), this.props.vendorID, this.props.warehouseID === undefined ? 0 : this.props.warehouseID, this.props.docID, this.props.copyToDocType, fullTransferID, this.selectedPartialTransferID, this.state.searchTextPartialTransfer, UserID(), ClientID())
                );

                result.then((values) => {
                    if (this.isResponseSuccess(values))
                        this.handlePartialTransferList(values?.PartialCopyFromDocumentList)

                });
                break;
            case "GR":
                result = Promise.resolve(
                    GetPartialCopyFromDocumentGR(Token(), CompanyID(), this.props.vendorID, this.props.warehouseID === undefined ? 0 : this.props.warehouseID, this.props.docID, this.props.copyToDocType, fullTransferID, this.selectedPartialTransferID, this.state.searchTextPartialTransfer, UserID(), ClientID())
                );

                result.then((values) => {
                    if (this.isResponseSuccess(values))
                        this.handlePartialTransferList(values?.PartialCopyFromDocumentList)

                });
                break;
            default:
            // code block
        }
    }

    getFullTransferDataGridUserLayout() {
        const result = Promise.resolve(
            GetUserLayout(Token(), UserID(), this.props.defaultSMI.fullCopyFrom.moduleURL, this.props.defaultSMI.fullCopyFrom.controlID)
        );

        result.then((values) => {
            if (this.isResponseSuccess(values))
                this.handlePreferedLayoutFullTransfer(values);
        });
    }

    getPartialransferDataGridUserLayout() {
        const result = Promise.resolve(
            GetUserLayout(Token(), UserID(), this.props.defaultSMI.partialCopyFrom.moduleURL, this.props.defaultSMI.partialCopyFrom.controlID)
        );

        result.then((values) => {
            if (this.isResponseSuccess(values))
                this.handlePreferedLayoutPartialTransfer(values);
        });
    }

    //#endregion

    //#region flash msg
    onFlashMessageTimer() {
        if (this.state.displayFlashMessageDialog === true) {
            setTimeout(() => this.setState({ displayFlashMessageDialog: false }), 1000)
        }
    }

    showMsgHandler(params, value, size) {

        if (params !== '') {
            this.setState({ emptyMsg: params, displayFlashMessageDialog: true, status: value, size: size });
        }
        else {
            this.setState({ emptyMsg: '', displayFlashMessageDialog: false, status: value, size: size });
        }
    }

    closeFlashMessageCallback = () => {
        this.setState(state => ({
            displayFlashMessageDialog: !state.displayFlashMessageDialog
        }))
    }

    //#endregion

    //#region pop up event
    popupOnShowing() {
        mousetrapMain.pause();
    }

    popupOnShown() {
        mousetrapMain.pause();

        this.fetchDataAPI();
    }

    popupOnHidden(e) {
        mousetrapMain.unpause();

        this.quickSearchFullTransferRef.clear(e);
        this.quickSearchPartialTransferRef.clear(e);

        this.dataGridFullTransfer.pageIndex(0);
        this.dataGridPartialTransfer.pageIndex(0);

    }

    //#endregion

    //#region datagrid
    get dataGridFullTransfer() {
        return this.dataGridFullTransferRef.current.instance;
    }

    get dataGridPartialTransfer() {
        return this.dataGridPartialTransferRef.current.instance;
    }

    clearItemSelection() {
        this.props.hidePopup();
    }

    docNoLinkClick(data) {
        var tabData = data?.LinkURL.split(";")
        var tabURL = tabData[0];
        var tabName = tabData[1];

        window.parent.postMessage(getPostMessageObj(tabURL, tabName), "*");
    }

    copyFromDoc = async () => {
        await sleep(0);
        await this.dataGridPartialTransferRef?.current?.instance?.refresh(true); // refresh data grid to get updated edit value

        const selectedPartialTransferRowDataCopy = this.dataGridPartialTransferRef.current != null ? this.getSelectedRowsDataPartialTransfer() : [];
        const selectedPartialTransferFilterData = selectedPartialTransferRowDataCopy.map(({ DetailID, NewQty, NewUOMID }) => ({ DetailID, NewQty, NewUOMID }));

        if (await checkInvalidCell(selectedPartialTransferRowDataCopy)) {
            this.setErrorPopUpState("ErrorEncountered", "ContainInvalidCell", true);
            return;
        }

        var obj = {
            selectedFullTransferID: this.dataGridFullTransferRef.current != null ? this.getSelectedRowKeysFullTransfer() : [],
            selectedPartialTransferID: this.dataGridPartialTransferRef.current != null ? this.getSelectedRowKeysPartialTransfer() : [],
            selectedPartialTransferRowData: selectedPartialTransferFilterData,
            copyFromDocType: this.props.copyFromDocType
        };

        if (obj.selectedFullTransferID.length > 0)
            this.props.copyFromCallBack(obj);

        this.props.hidePopup();
    }

    numberCell = (cellData) => {
        const { info, ...editCellProps } = getEditCellProps(cellData);
        const isEditable = info.data.AllowEditNewQty;
        const maxNumber = info.data.BalanceQty;

        return (
            <EditCellNumber
                {...editCellProps}
                isEditable={isEditable}
                isRequired
                min={0}
                max={maxNumber}
                precision={this.format.precision}
                showAllDecimalPoints
            />
        )
    }

    onUOMChanged = (obj) => {
        const { value, selectedObj, cellData } = obj;
        const partialTransferListCopy = cloneDeep(this.state.partialTransferList);
        const index = partialTransferListCopy.findIndex(item => item.DetailID === cellData.DetailID);

        const newCellData = cloneDeep(cellData);

        if (value === newCellData.OrgUOMID) {
            // update row data balance uom
            newCellData.BalanceUOM = newCellData.OrgUOM;
            newCellData.BalanceUOMID = newCellData.OrgUOMID;
            newCellData.BalanceUOMRate = newCellData.OrgUOM.Rate;
            newCellData.BalanceQty = getRoundingValue(newCellData.OriBalanceQty, 2);

            // update row data new uom
            newCellData.NewUOM = newCellData.OrgUOM;
            newCellData.NewUOMID = newCellData.OrgUOMID;
            newCellData.NewQty = getRoundingValue(newCellData.OriBalanceQty, 2)
        }
        else {
            // update row data balance uom
            newCellData.BalanceUOM = selectedObj;
            newCellData.BalanceUOMID = selectedObj.ID;
            newCellData.BalanceUOMRate = selectedObj.Rate;
            newCellData.BalanceQty = getRoundingValue((cellData.NewUOM.Rate / selectedObj.Rate) * cellData.BalanceQty, 2);

            // update row data new uom
            newCellData.NewUOM = selectedObj;
            newCellData.NewUOMID = selectedObj.ID;
            newCellData.NewQty = getRoundingValue(newCellData.BalanceQty, 2);
        }

        //at position index add newrowData & remove 1 old row data
        partialTransferListCopy.splice(index, 1, newCellData);

        this.setState({ partialTransferList: partialTransferListCopy });
    }

    itemUOMCell = (cellData) => {
        const { info, ...editCellProps } = getEditCellProps(cellData);

        return (
            <EditCellDropdown
                {...editCellProps}
                dataSource={info.data?.ItemUOM}
                {...this.dropdownCellConfig}
                portalRef={this.portalRef}
                isEditable={info.data.AllowEditNewUOM}
                onChange={this.onUOMChanged}
            />
        )
    }
    //#endregion

    //#region tab event
    onTabKeyValueChange = (key) => {
        let selectedFullTransferIDArr = this.getSelectedRowKeysFullTransfer();
        this.setState({ tabKey: key, selectedFullListID: selectedFullTransferIDArr });
        if (key === '2') {
            this.getPartialTransferByDocType(selectedFullTransferIDArr.toString());
            this.getPartialransferDataGridUserLayout();
        }

    }

    //#endregion

    //#region edit item qty & item UOM
    /*
    1. check new qty <= balance qty if edit new qty
    2. update balance qty, new qty if UOM change
    */
    //#endregion
    render() {
        const { contextMenuAccess } = this.props;
        return (
            <Popup
                className="item-popup"
                showTitle={true}
                title={this.props.title}
                onHiding={this.props.hidePopup}
                onShowing={this.popupOnShowing}
                onShown={this.popupOnShown}
                onHidden={this.popupOnHidden}
                visible={this.props.visible}
                dragEnabled={true}
                closeOnOutsideClick={true}
                width={'81%'}
                height={'95%'}>

                <ErrorPopUp
                    parentCallback={this.handleErrorPopUp}
                    visible={this.state.displayErrorPopUp}
                    title={this.state.errorTitle}
                    subTitle={this.state.errorSubTitle}
                />

                <Flash
                    parentCallback={this.closeFlashMessageCallback}
                    message={this.state.emptyMsg}
                    visible={this.state.displayFlashMessageDialog}
                    severity={this.state.status}
                    container={this.state.size} />

                <ScrollView width='100%' height='90%'>
                    <Form
                        id="package-item-form"
                        width="100%"
                        colCount={8}>

                        <Item colSpan={8}>
                            <div className="portalRef dx-dropdowneditor-overlay" ref={this.portalRef} />

                            <Tabs defaultActiveKey="1" onChange={this.onTabKeyValueChange} type="card">
                                <TabPane tab="Full Transfer" key="1" >
                                    <Row>
                                        <Col span={12}>
                                            <TextBox2
                                                placeHolderText={formatMessage('QuickSearchPlaceholderText')}
                                                buttonStyle={'search-word'}
                                                focusStateEnabled={true}
                                                textField={'popup-text'}
                                                buttonText={formatMessage('Search')}
                                                value={this.state.searchTextFullTransfer}
                                                onTbvalueChange={this.searchValueChangeFullTransferHandler}
                                                ref={(ref) => this.quickSearchFullTransferRef = ref}
                                                visible={true}>
                                            </TextBox2>
                                        </Col>
                                        <Col span={12}></Col>
                                    </Row>
                                    <div className="dx-fieldset-popup">
                                        <div className="dx-field">
                                            <div className="dx-field-value">
                                                <DataGridDefault
                                                    allowDisplayColumnChooser={contextMenuAccess.allowDisplayColumnChooser}
                                                    allowExportGrid={false}
                                                    allowedPageSizes={DefaultPager.allowedPageSizes_details}
                                                    allowRestoreLayout={contextMenuAccess.allowRestoreLayout}
                                                    allowSaveGridLayout={contextMenuAccess.allowSaveGridLayout}
                                                    className='dx-datagrid-items'
                                                    dataGridRef={this.dataGridFullTransferRef}
                                                    dataSource={this.state.fullTransferList}
                                                    defaultPageSize={DefaultPager.defaultPageSize_details}
                                                    defaultSMI={this.props.defaultSMI.fullCopyFrom}
                                                    keyExpr="SourceID"
                                                    noDataText={formatMessage("dxDataGrid-noDataText")}
                                                    preferedLayout={this.state.preferedLayoutFullTransfer}
                                                    showMsgHandler={this.showMsgHandler}
                                                    customSelectionBox={true}
                                                >
                                                    <Column
                                                        allowHiding={false}
                                                        showInColumnChooser={false}
                                                        dataField={'SourceID'}
                                                        visible={false}
                                                    />
                                                    <Column
                                                        caption={formatMessage('DocNo')}
                                                        dataField={'DocNo'}
                                                        cellRender={(cellData) => customSearchRenderer({ cellData, link: true, searchText: this.state.searchTextFullTransfer, onClick: this.docNoLinkClick })}
                                                        hasCustomSearch={true}
                                                    />
                                                    <Column
                                                        dataField={'PostingDate'}
                                                        caption={formatMessage('PostingDate')}
                                                        calculateSortValue={this.sortDate}
                                                        cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextFullTransfer })}
                                                        hasCustomSearch={true}
                                                        dataType="date"
                                                        format={this.props.US_DateFormat}
                                                    />
                                                    <Column
                                                        caption={formatMessage('Description')}
                                                        dataField={'Description'}
                                                        cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextFullTransfer })}
                                                        hasCustomSearch={true}
                                                    />
                                                    <Column
                                                        caption={formatMessage('Reference')}
                                                        dataField={'Reference'}
                                                        cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextFullTransfer })}
                                                        hasCustomSearch={true}
                                                    />
                                                    <Column
                                                        caption={formatMessage('NetTotal')}
                                                        dataField={'NetTotal'}
                                                        format={this.format}
                                                    />
                                                    <Column
                                                        caption={formatMessage('Organisation')}
                                                        dataField={'Organisation'}
                                                        cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextFullTransfer })}
                                                        hasCustomSearch={true}
                                                    />
                                                    <Column
                                                        caption={formatMessage('Division')}
                                                        dataField={'Division'}
                                                        cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextFullTransfer })}
                                                        hasCustomSearch={true}
                                                    />
                                                    <Column
                                                        caption={formatMessage('Agent')}
                                                        dataField={'Agent'}
                                                        cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextFullTransfer })}
                                                        hasCustomSearch={true}
                                                    />
                                                    <Column
                                                        caption={formatMessage('Warehouse')}
                                                        dataField={'Warehouse'}
                                                        cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextFullTransfer })}
                                                        hasCustomSearch={true}
                                                    />
                                                    <Column
                                                        dataField={'DocDate'}
                                                        caption={formatMessage('DocumentDate')}
                                                        calculateSortValue={this.sortDate}
                                                        cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextFullTransfer })}
                                                        hasCustomSearch={true}
                                                        dataType="date"
                                                        format={this.props.US_DateFormat}
                                                    />
                                                </DataGridDefault>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Partial Transfer" key="2" >
                                    <Row>
                                        <Col span={12}>
                                            <TextBox2
                                                placeHolderText={formatMessage('QuickSearchPlaceholderText')}
                                                buttonStyle={'search-word'}
                                                focusStateEnabled={true}
                                                textField={'popup-text'}
                                                buttonText={formatMessage('Search')}
                                                value={this.state.searchTextPartialTransfer}
                                                onTbvalueChange={this.searchValueChangePartialTransferHandler}
                                                ref={(ref) => this.quickSearchPartialTransferRef = ref}
                                                visible={true}>
                                            </TextBox2>
                                        </Col>
                                        <Col span={12}></Col>
                                    </Row>
                                    <div className="module-data-grid-popup">
                                        <DataGridDefault
                                            allowDisplayColumnChooser={contextMenuAccess.allowDisplayColumnChooser}
                                            allowExportGrid={false}
                                            allowedPageSizes={DefaultPager.allowedPageSizes_details}
                                            allowRestoreLayout={contextMenuAccess.allowRestoreLayout}
                                            allowSaveGridLayout={contextMenuAccess.allowSaveGridLayout}
                                            className='dx-datagrid-items datagrid-normalize'
                                            dataGridRef={this.dataGridPartialTransferRef}
                                            dataSource={this.state.partialTransferList}
                                            defaultPageSize={DefaultPager.defaultPageSize_details}
                                            defaultSMI={this.props.defaultSMI.partialCopyFrom}
                                            keyExpr="DetailID"
                                            noDataText={formatMessage("dxDataGrid-noDataText")}
                                            preferedLayout={this.state.preferedLayoutPartialTransfer}
                                            showMsgHandler={this.showMsgHandler}
                                            customSelectionBox={true}
                                        >
                                            <Editing
                                                mode="cell"
                                                allowUpdating={true}
                                            />
                                            <Column
                                                allowHiding={false}
                                                showInColumnChooser={false}
                                                dataField={'DetailID'}
                                                visible={false}
                                            />
                                            <Column
                                                dataField={"NewQty"}
                                                caption={formatMessage("NewQty")}
                                                allowEditing={true}
                                                allowHiding={false}
                                                allowReordering={false}
                                                cssClass="column-normalize"
                                                cellRender={this.numberCell}
                                                editCellComponent={this.numberCell}
                                                dataType="number"
                                            />
                                            <Column
                                                dataField={"NewUOMID"}
                                                caption={formatMessage("NewUOM")}
                                                allowEditing={true}
                                                allowHiding={true}
                                                cssClass="column-normalize"
                                                cellRender={this.itemUOMCell}
                                                editCellComponent={this.itemUOMCell}
                                                dataType="string"
                                                minWidth={60}
                                                editCellDisplayValue="editCell.newUOM"
                                                calculateDisplayValue="editCell.newUOM"
                                            />

                                            <Column
                                                caption={formatMessage('BalQty')}
                                                dataField={'BalanceQty'}
                                                format={this.qtyFormat}
                                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextPartialTransfer })}
                                                hasCustomSearch={true}
                                            />
                                            <Column
                                                dataField={"BalanceUOM.Code"}
                                                caption={formatMessage("BalUOM")}
                                                allowHiding={true}
                                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextPartialTransfer })}
                                                hasCustomSearch={true}
                                            />
                                            <Column
                                                caption={formatMessage('OrgQty')}
                                                dataField={'OrgQty'}
                                                format={this.qtyFormat}
                                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextPartialTransfer })}
                                                hasCustomSearch={true}
                                            />
                                            <Column
                                                dataField={"OrgUOM.Code"}
                                                caption={formatMessage("OrgUOM")}
                                                allowHiding={true}
                                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextPartialTransfer })}
                                                hasCustomSearch={true}
                                            />
                                            <Column
                                                dataField={'ItemCode'}
                                                caption={formatMessage('ItemCode')}
                                                allowHiding={true}
                                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextPartialTransfer })}
                                                hasCustomSearch={true}
                                            />
                                            <Column
                                                dataField={"ItemDesc"}
                                                caption={formatMessage("Description")}
                                                allowHiding={true}
                                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextPartialTransfer })}
                                                hasCustomSearch={true}
                                            />
                                            <Column
                                                caption={formatMessage('DocNo')}
                                                dataField={'DocNo'}
                                                cellRender={(cellData) => customSearchRenderer({ cellData, link: true, searchText: this.state.searchTextPartialTransfer, onClick: this.docNoLinkClick })}
                                                hasCustomSearch={true}
                                            />
                                            <Column
                                                dataField={'PostingDate'}
                                                caption={formatMessage('PostingDate')}
                                                calculateSortValue={this.sortDate}
                                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextPartialTransfer })}
                                                hasCustomSearch={true}
                                            />
                                            <Column
                                                caption={formatMessage('Warehouse')}
                                                dataField={'Warehouse'}
                                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextPartialTransfer })}
                                                hasCustomSearch={true}
                                            />
                                            <Column
                                                caption={formatMessage('AccountCode')}
                                                dataField={'AcctNo'}
                                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchTextPartialTransfer })}
                                                hasCustomSearch={true}
                                            />
                                        </DataGridDefault>
                                    </div>

                                </TabPane>
                            </Tabs>
                        </Item>
                    </Form>
                </ScrollView>
                <div className="item-popup-footer">
                    <div className="left-btn-container">
                        <Button
                            hoverStateEnabled={true}
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            className="cancel-btn"
                            text={formatMessage("Cancel")}
                            type="normal"
                            stylingMode="contained"
                            onClick={this.clearItemSelection}>
                        </Button>
                    </div>
                    <div className="right-btn-container">
                        <Button
                            hoverStateEnabled={true}
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            className="apply-btn"
                            text={formatMessage("OK")}
                            onClick={this.copyFromDoc}
                            type="default"
                            stylingMode="contained">
                        </Button>
                    </div>
                </div>

            </Popup>
        );
    }
}

export default CopyFromPopUp;