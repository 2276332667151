import React from 'react'
import { Icon } from '@rsuite/icons';
import { ReactComponent as Svg } from './calendar.svg';

const CalendarIcon = React.forwardRef((props, ref) => {
    const style = {
        color: '#1dafed',
        height: '1em',
        width: '1em',
        fontSize: '14px',
        top: '10px',
        right: '10px',
    };

    return <Icon as={Svg} style={style} {...props} ref={ref} />;
});

export default CalendarIcon
