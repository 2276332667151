/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { DatePicker } from "rsuite";
import { getLocalTimeString, isValidDate } from "../../../utils/common-function";
import CalendarIcon from "../calendar-icon";
import { formatMessage } from "devextreme/localization";
import "rsuite/dist/rsuite.min.css";
import "../style/date-picker.scss";

export const DateSinglePicker = (props) => {
    const {
        selectedDate,
        dateFormat = "dd/MM/yyyy",
        onChangeCallback,
        placeholder,
        portalRef,
        disabled,
    } = props;

    const [selectToday, triggerSelectToday] = useState(false); // state to trigger select today (UI only) on clear
    const datePickerRef = useRef();
    const timeOutRef = useRef(null);

    const dateValue = getSelectedDate();

    function getSelectedDate() {
        if (selectToday && !timeOutRef.current) {
            timeOutRef.current = setTimeout(() => {
                triggerSelectToday(false);
                timeOutRef.current = null;
            }, 0);
            return new Date();
        }

        if (selectedDate && isValidDate(selectedDate)) {
            return new Date(selectedDate);
        } else {
            return null;
        }
    }

    function updateDate(value) {
        const localTimeString = getLocalTimeString(value, true);
        onChangeCallback(localTimeString);
    }

    function onClickClear() {
        triggerSelectToday(true);
        onChangeCallback("");
    }

    function onSelectDate(value) {
        updateDate(value);
        datePickerRef?.current?.close();
    }

    function onChangeMonth(value) {
        updateDate(value);
    }

    return (
        <div className="date-single-picker">
            <div className="dx-fieldset">
                <div className="dx-field">
                    <div className="dx-field-value">
                        <div className="date-single-picker-container">
                            <div className="date-single-picker-separator" />
                            <DatePicker
                                size="xs"
                                appearance="subtle"
                                placeholder={placeholder}
                                format={dateFormat}
                                caretAs={CalendarIcon}
                                className='date-single-picker-input'
                                menuClassName={`date-single-picker-menu ${!dateValue && 'date-single-picker-menu-cleared'}`}
                                onSelect={onSelectDate}
                                onNextMonth={onChangeMonth}
                                onPrevMonth={onChangeMonth}
                                onOk={onClickClear}
                                locale={{ ok: formatMessage("Clear") }}
                                ranges={[]}
                                cleanable={false}
                                ref={datePickerRef}
                                container={portalRef.current}
                                value={dateValue}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
