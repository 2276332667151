export const DefaultDataLimit = {
    qtyMaxDigits: 12,
    qtyMaxDecimalPoints: 6,
    qtyMaxDigitsCheckIndex: 13,
    qtyMaxDecimalPointsCheckIndex: 7

}

export const ReportFormatDataLimit = {
    desp: 100, // nvarchar(250)
}

