import jwt_decode from "jwt-decode";
import moment from "moment";
import { locale } from 'devextreme/localization';

var access_Token = "";
var user_ID = 0;
var company_ID = 0;
var client_ID = 0;
var user_Name = "";
var admin_YN = "";
var administrator_YN = "";
var decimal_Points = "";
var default_Name = "";
var language = "";
var search_Text = "";
var license_Expired = false;
var report_Server_URL = "";

export const SetCookies = (cookies, data) => {
    cookies.set('userID', data.userID, { path: '/' });
    cookies.set('companyID', data.companyID, { path: '/' });
    cookies.set('clientID', data.clientID, { path: '/' });
    cookies.set('username', data.username, { path: '/' });
    cookies.set('accessToken', data.accessToken, { path: '/' });
    cookies.set('adminYN', data.adminYN, { path: '/' });
    cookies.set('administratorYN', data.administratorYN, { path: '/' });
    cookies.set('language', data.language, { path: '/' });
    cookies.set('reportserverURL', data.reportserverURL, { path: '/' });

    access_Token = cookies.get('accessToken');
    user_ID = cookies.get('userID');
    company_ID = cookies.get('companyID');
    client_ID = cookies.get('clientID');
    user_Name = cookies.get('username');
    admin_YN = cookies.get('adminYN');
    administrator_YN = cookies.get('administratorYN');
    language = cookies.get('language');
    report_Server_URL = cookies.get('reportserverURL');

    //language
    sessionStorage.setItem("locale", language);
    const locales = sessionStorage.getItem("locale");
    locale(locales);

    SetLicenseExpired(cookies);

}

export const SetDecimalPoints = (cookies, decimalPoints) => {
    cookies.set('decimalPoints', decimalPoints, { path: '/' });
    decimal_Points = cookies.get('decimalPoints');
}

export const SetDefaultName = (cookies, defaultName) => {
    cookies.set('defaultName', defaultName, { path: '/' });
    default_Name = cookies.get('defaultName');
}

export const SetCompanyID = (cookies, companyID) => {
    cookies.set('companyID', companyID, { path: '/' });
    company_ID = cookies.get('companyID');
}

export const SetSearchText = (cookies, searchText) => {
    cookies.set('searchText', searchText, { path: '/' });
    search_Text = cookies.get('searchText');
}

export const SetLicenseExpired = (cookies) => {
    if (cookies.get('accessToken')) {
        //decode jwt 
        var decoded = jwt_decode(cookies.get('accessToken'));
        const dateIsBefore = moment(moment(decoded.licenseDateExpired, 'DD/MM/YYYY').toISOString()).isBefore(moment());
        cookies.set('licenseExpired', dateIsBefore, { path: '/' });
        license_Expired = cookies.get('licenseExpired');
    }
    else {
        cookies.set('licenseExpired', "", { path: '/' });
        license_Expired = cookies.get('licenseExpired');
    }
}

export const DefaultName = () => {
    return default_Name;
}

export const DecimalPoints = () => {
    return decimal_Points;
}

export const Token = () => {
    return access_Token;
};

export const UserID = () => {
    return parseInt(user_ID);
};

export const CompanyID = () => {
    return parseInt(company_ID);
};

export const ClientID = () => {
    return parseInt(client_ID);
};

export const Username = () => {
    return user_Name;
};

export const AdminYN = () => {
    return admin_YN;
};

export const AdministratorYN = () => {
    return administrator_YN;
};

export const Language = () => {
    return language;
};

export const SearchText = () => {
    return search_Text;
};

export const LicenseExpired = () => {
    return license_Expired;
};

export const ReportURL = () => {
    return report_Server_URL;
};