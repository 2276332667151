import React from 'react';
import Form from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import { Item } from 'devextreme-react/select-box';
import { Button } from 'devextreme-react';
import { formatMessage } from 'devextreme/localization';
import { mousetrapMain, mousetrapSecondary } from '../../App';
import { ToolbarItem } from 'devextreme-react/data-grid';

class DeletePopUp extends React.Component {
    constructor(props) {
        super(props);

        this.deleteBtn = React.createRef();

        this.onShowing = this.onShowing.bind(this);
        this.onShown = this.onShown.bind(this);
        this.onHiding = this.onHiding.bind(this);
        this.onHidden = this.onHidden.bind(this);

        this.onKeyEscape = this.onKeyEscape.bind(this);

        this.buttonOptions = {
            type: 'close',
            icon: 'close',
            tabIndex: -1,
            onClick: this.onHiding
        };
    }

    componentWillUnmount() {
        mousetrapMain.unpause();
        mousetrapSecondary.unbind("esc");
    }

    onShowing() {
        mousetrapMain.pause();
        mousetrapSecondary.bind('esc', this.onKeyEscape);
    }

    onShown() {
        mousetrapMain.pause();
        this.deleteBtn.current.instance.focus(); // focus on delete button
    }

    onHiding() {
        this.props.hideDeletePopup();
    }

    onHidden() {
        mousetrapMain.unpause();
        mousetrapSecondary.unbind("esc");
    }

    onKeyEscape() {
        if (!this.props.visible) return;

        // close popup on escape key
        this.props.hideDeletePopup();
    }

    render() {
        return (
            <Popup
                position={'center'}
                className="item-popup-center"
                showTitle={true}
                title={formatMessage("DeleteConfirmation")}
                onShowing={this.onShowing}
                onShown={this.onShown}
                onHiding={this.onHiding}
                onHidden={this.onHidden}
                visible={this.props.visible}
                dragEnabled={false}
                closeOnOutsideClick={true}
                width={"auto"}
                height={"auto"}
                tabIndex={-1}
                showCloseButton={false}
            >
                <ToolbarItem
                    widget="dxButton"
                    location="after"
                    options={this.buttonOptions}
                />

                <Form colCount={4} >
                    <Item colSpan={4} >
                        <div className="delete-line-1">
                            {formatMessage('Areyousureyouwanttodeletethisrow')}
                        </div>
                        <div className="delete-line-2">
                            {formatMessage("Thisactioncannotbeundone")}
                        </div>
                    </Item>
                </Form>
                <div className="left-btn-container-no-cover" >
                    <Button
                        className="cancel-btn"
                        text={formatMessage("Cancel")}
                        type="normal"
                        stylingMode="contained"
                        onClick={this.onHiding}
                    />
                </div>
                <div className="right-btn-container-no-cover" >
                    <Button
                        className="delete-btn"
                        text={formatMessage("Delete")}
                        onClick={this.props.parentCallback}
                        type="default"
                        stylingMode="contained"
                        ref={this.deleteBtn}
                    />
                </div>
            </Popup>
        );
    }
}

export default DeletePopUp;