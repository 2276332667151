import { GET } from './request-options'

export const GetAdvanceSearchVendorByCompanyID = (token, companyID, clientID, userID, vendorID, searchKeyword, lstColName) =>
{
  return fetch(`${process.env.REACT_APP_API_ADVANCE_VENDOR}companyID=${companyID}&clientID=${clientID}&UserID=${userID}&vendorID=${vendorID}&UI-Culture=en-US&searchKeyword=${searchKeyword}&lstColName=${lstColName}`, GET(token))
  .then(handleErrors)
  .then(response => response.json())
  .then(data =>{
    return data.Obj;
  })
  .catch((error) => {console.log(error); return error.toString();});  
}


export const GetVendorTabURL = (token, companyID, vendorID) =>
{
  return fetch(`${process.env.REACT_APP_API_VENDOR_TAB_URL}companyID=${companyID}&vendorID=${vendorID}&UI-Culture=en-US`, GET(token))
  .then(handleErrors)
  .then(response => response.json())
  .then(data =>{
    return data.Obj;
  })
  .catch((error) => {console.log(error); return error.toString();});  
}

function handleErrors(response) {
    if (!response.ok) throw new Error(response.status);
    return response;
}