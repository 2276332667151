import { GET } from './request-options'

export const CheckDuplicateSupplierInv = async (token, supplierInvNo, vendorID, PIHID) => {
    return fetch(`${process.env.REACT_APP_API_CHECK_PURCHASE_SUPPLIER_INV_NO}supplierInvNo=${supplierInvNo}&vendorID=${vendorID}&PIHID=${PIHID}&UI-CultPIHIDure=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

function handleErrors(response) {
    if (!response.ok) throw new Error(response.status);
    return response;
}