export const ItemType = {
    STOCKITEM: 1,
    SERVICE_ITEM: 2,
    SERIALIZED_ITEM: 3,
    MATRIX_ITEM: 4,
    PACKAGE_ITEM: 5,
    WEIGHT_ITEM: 6,
    STOCK_SN_AND_BATCH_ITEM: 7,
    BATCH_ITEM: 8,
    WEIGHT_AND_BATCH_ITEM: 9,
    ACCOUNT_ITEM: 10,
    VARIANCE_ITEM: 11,
}