import React from "react";
import { Row, Col } from "antd";
import { TextAreaInput } from "../../fields";
import { FastField } from "formik";

export const PurchaseInvoiceDetailsNotesForm = () => {
    return (
        <Row
            gutter={35}
            style={{ height: 170, marginTop: 20, paddingLeft: 15, paddingRight: 20 }}
        >
            <Col span={24}>
                <FastField name="Note" placeholder="Enter a note here..." component={TextAreaInput} />
            </Col>
        </Row>
    );
};
