import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { DateRangePicker } from "rsuite";
import CalendarIcon from "../calendar-icon";
import "rsuite/dist/rsuite.min.css";
import "../style/date-picker.scss";
import { formatMessage } from 'devextreme/localization';

export const CalenderRange = forwardRef((props, ref) => {
    const {
        parentCallback,
        caption,
        dateFormat = "dd/MM/yyyy",
        defaultStartDate: defaultStart,
        defaultEndDate: defaultEnd,
        ranges: rangesDatas,
        portalRef,
    } = props;
    
    const defaultStartDate = defaultStart || moment().get("date") - 7;
    const defaultEndDate = defaultEnd || moment().get("date");
    const defaultDateValue = [
        moment().set("date", defaultStartDate)["_d"],
        moment().set("date", defaultEndDate)["_d"],
    ];
    const [dateValue, setDateValue] = useState(defaultDateValue);
    const [countMonthsBool, setCountMonthsBool] = useState(false);
    const [checkFocus, setCheckFocus] = useState(false);
    const [checkHover, setCheckHover] = useState(false);

    const getDate = (date) => {
        const day = ("0" + moment(date).get("date")).slice(-2);
        return day;
    };

    const getMonth = (date) => {
        const month = ("0" + (moment(date).get("month") + 1)).slice(-2);
        return month;
    };

    const updateDate = (e) => {
        setDateValue(e);
        const fromDate = new Date(e[0]);
        const toDate = new Date(e[1]);
        const countMonths = moment(toDate).diff(moment(fromDate), "months", true);
        if (countMonths <= 12) {
            setCountMonthsBool(false);

            const typeFromDate =
                fromDate.getFullYear() +
                "-" +
                getMonth(fromDate) +
                "-" +
                getDate(fromDate) +
                "T00:00:00";
            const typeToDate =
                toDate.getFullYear() +
                "-" +
                getMonth(toDate) +
                "-" +
                getDate(toDate) +
                "T23:59:59";

            parentCallback(typeFromDate, typeToDate, true);
        } else {
            setCountMonthsBool(true);
            parentCallback('', '', false);
        }
    };

    const onFocusColor = () => {
        if (checkFocus === false) {
            setCheckFocus(true);
        } else {
            setCheckFocus(false);
        }
    };

    useImperativeHandle(ref, () => ({
        getCalendarRangeValue() {
            const fromDate = new Date(dateValue[0]);
            const toDate = new Date(dateValue[1]);
            const typeFromDate =
                fromDate.getFullYear() +
                "-" +
                getMonth(fromDate) +
                "-" +
                getDate(fromDate) +
                "T00:00:00";

            const typeToDate =
                toDate.getFullYear() +
                "-" +
                getMonth(toDate) +
                "-" +
                getDate(toDate) +
                "T23:59:59";
            return [typeFromDate, typeToDate];
        },
    }));

    useEffect(() => {
        if (defaultStart) {
           setDateValue([moment(defaultStartDate)["_d"], moment(defaultEndDate)["_d"] ]);
        }
    }, [defaultStart])

    return (
        <div className="date-range-picker">
            <div className="dx-fieldset-text">
                <div className="dx-field">
                    <div
                        className="dx-field-label"
                    >
                        <div className={countMonthsBool ? "required-title" : ""}>
                            <span style={{ color: "red" }}>* </span>
                            <span>{caption}</span>
                        </div>
                        {_.isEmpty(dateValue) ? (
                            <span className="required-field">{formatMessage('Required')}</span>
                        ) : (
                            countMonthsBool && (
                                <span className="required-field">
                                    {formatMessage('Maximum12months')}
                                </span>
                            )
                        )}
                    </div>
                </div>
            </div>

            <div className="dx-fieldset">
                <div className="dx-field">
                    <div className="dx-field-value">
                        <div className="calendar">
                            <div className="calendar-icon-separator" />
                            <DateRangePicker
                                onOk={updateDate}
                                onFocus={onFocusColor}
                                onBlur={onFocusColor}
                                defaultValue={defaultDateValue}
                                value={dateValue}
                                onChange={updateDate}
                                locale={{
                                    ok: (
                                        <div
                                            onMouseEnter={() => setCheckHover(true)}
                                            onMouseLeave={() => setCheckHover(false)}
                                            style={{
                                                backgroundColor:
                                                    checkHover && dateValue
                                                        ? "#1995da"
                                                        : "#1dafed",
                                                width: "100%",
                                                height: "100%",
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                lineHeight: 2,
                                                color: dateValue ? "unset" : "#919191",
                                                cursor: dateValue ? "unset" : "no-drop",
                                            }}
                                        >
                                            Apply
                                        </div>
                                    ),
                                }}
                                format={dateFormat}
                                character=" - "
                                ranges={rangesDatas || ranges}
                                caretAs={CalendarIcon}
                                container={portalRef?.current}
                                menuClassName="date-range-picker-menu"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

const ranges = [
    {
        label: "Today",
        value: [new Date(), new Date()],
    },
    {
        label: "This Month",
        value: [
            moment().startOf("month").toDate(),
            moment().endOf("month").toDate(),
        ],
    },
    {
        label: "Previous Month",
        value: [
            moment().subtract(1, "month").startOf("month").toDate(),
            moment().subtract(1, "month").endOf("month").toDate(),
        ],
    },
    {
        label: "Last 30 Days",
        value: [
            moment().subtract(30, "days").toDate(),
            moment().subtract(1, "days").toDate(),
        ],
    },
];
