import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import ConfirmationPopUp from '../pop-up/confirmation-popup';

const ConfirmationPopupProvider = ({ children }) => {
    const [visible, setVisible] = useState(false);
    const [content, setContent] = useState('');
    const waitUntilRef = useRef(); // used in promise to wait until button is pressed

    const showConfirmationPopup = useCallback(async (content = '') => {
        setVisible(true);
        setContent(content);

        const response = await new Promise((resolve) => { waitUntilRef.current = resolve }); // wait until resolve from acceptCallback / rejectCallback
        waitUntilRef.current = null;

        return response;
    }, [])

    function acceptCallback() {
        setVisible(false);
        waitUntilRef.current(true);
    }

    function rejectCallback() {
        setVisible(false);
        waitUntilRef.current(false);
    }

    return (
        <ConfirmationPopupContext.Provider value={showConfirmationPopup}>
            {children}
            {
                setVisible &&
                <ConfirmationPopUp
                    yesBtnCallback={acceptCallback}
                    hideConfirmationPopUp={rejectCallback}
                    visible={visible}
                    content={[content]}
                />
            }
        </ConfirmationPopupContext.Provider>
    )
}

const ConfirmationPopupContext = createContext({});
const useConfirmationPopupContext = () => useContext(ConfirmationPopupContext);

export { ConfirmationPopupProvider, useConfirmationPopupContext }