import React from "react";
import { Row, Col } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { UploadFile } from "../../fields";
import { isEmpty } from "lodash";

import "./styles/details.scss";

export const InvoiceDetailsModalImportForm = (props) => {
    const { importedData, setImportedData, isFileTypeValid, setIsFileTypeValid } = props;

    const getImportFileData = (data, type) => {
        if (type !== "text/plain" && type !== "application/vnd.ms-excel" && type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setIsFileTypeValid(false);
            setImportedData([]);
        } else {
            setIsFileTypeValid(true);
            setImportedData(data);
        }
    }

    const removeImportFileData = () => {
        setIsFileTypeValid(true);
        setImportedData([]);
    }

    return (
        <Row style={{ paddingBottom: 50, width: 700 }}>
            <Col span={24}>
                {
                    (isEmpty(importedData) && props.submitCount === 1 && isFileTypeValid === true) &&
                    <span className="required-field" style={{ position: "absolute", right: 0 }}>Required</span>
                }
                {
                    isFileTypeValid === false &&
                    <span className="required-field" style={{ position: "absolute", right: 0 }}>This File extension isn't allowed.</span>
                }
                <UploadFile
                    getDataFn={getImportFileData}
                    removeDataFn={removeImportFileData}
                    importFileData={importedData}
                />
            </Col>
            <Col style={{ lineHeight: 1.4 }}>
                <ExclamationCircleFilled />
            </Col>
            <Col span={23}>
                <p className="upload-detail">
                    &nbsp;Import file format:
                    <br />
                    &nbsp;Text File - Item Code; Bin Location; UOM; Qty; Unit Cost;
                    Remarks (’ ; ’ as Delimiter)
                    <br />
                    &nbsp;Excel File - Item Code l Bin Location l UOM l Qty l Unit Cost l
                    Remarks (Header Column)
                </p>
            </Col>
        </Row>
    );
}
