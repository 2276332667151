import React, { memo, useCallback, useEffect, useState } from 'react'
import { DropDownBox, TextBox } from 'devextreme-react';
import { DropDownOptions, Position } from 'devextreme-react/drop-down-box';
import TextArea from 'antd/lib/input/TextArea';

const EditCellTextArea = (props) => {
    const {
        // editCellProps
        displayValue,
        setValueCallback,
        dataGridParent,
        cellElement,
        isEditor,
        // others
        portalRef,
        isEditable,
    } = props;

    const [dropdownOpened, setDropdownOpened] = useState(isEditor);

    const onOpened = useCallback((event) => {
        // focus to dropdown
        setTimeout(function () {
            event.component.focus();
        }, 0);
    }, [])

    const onClosed = useCallback(() => {
        // reset focus & close editor
        dataGridParent.focus(cellElement);
        dataGridParent.closeEditCell();
    }, [dataGridParent, cellElement])

    const onOptionChanged = useCallback((event) => {
        if (event.name === 'opened') {
            setDropdownOpened(event.value);

            if (!event.value) {
                onClosed();
            }
        }
    }, [onClosed])

    const onChange = useCallback((event) => {
        setValueCallback(event.target.value);
    }, [setValueCallback])

    function textAreaRender() {
        const minRows = 8;
        const maxRows = 8;

        return (
            <TextArea
                // Styling
                autoSize={{ minRows, maxRows }}
                placeholder={'Enter a note here...'} // [Translation] Update Translation
                // Data
                defaultValue={displayValue}
                // Events
                onChange={onChange}
                // Others
                maxLength={500}
            />
        );
    }

    function fieldRender() {
        return (
            <>
                <div className='editCell-dropdown-value'>
                    <span>
                        {displayValue || ""}
                    </span>
                </div>
                <div className="editCell-dropdown-btn">
                    <div className="normalize-icon icon-dropdowneditor-icon" />
                </div>
                <TextBox stylingMode={'outlined'} style={{ display: "none" }}></TextBox>
            </>
        )
    }

    function displayCell() {
        return (
            <div className={`editCell editCell-dropdown ${!isEditable && 'editCell-disabled'}`}>
                <div className='editCell-dropdown-value'>
                    <span>
                        {displayValue || ""}
                    </span>
                </div>
                <div className="editCell-dropdown-btn">
                    <div className="normalize-icon icon-dropdowneditor-icon" />
                </div>
            </div>
        )
    }

    function editorCell() {
        return (
            <div className='editCell editCell-editor editCell-dropdown'>
                <DropDownBox
                    // Styling
                    stylingMode={'outlined'}
                    // States
                    opened={dropdownOpened}
                    // Data
                    value={displayValue}
                    // Events
                    onOptionChanged={onOptionChanged}
                    onOpened={onOpened}
                    onClosed={onClosed}
                    // Others
                    contentRender={textAreaRender}
                    fieldRender={fieldRender}
                    showDropDownButton={false}
                >
                    <DropDownOptions
                        closeOnTargetScroll={false}
                        width={400}
                        container={portalRef.current}
                    >
                        <Position
                            my="top left"
                            at="bottom left"
                            of={cellElement}
                            boundary={portalRef.current}
                            collision="flip fit"
                            offset={"8 -8"}
                        />
                    </DropDownOptions>
                </DropDownBox>
            </div>
        )
    }

    useEffect(() => {
        if (!isEditable && isEditor) {
            dataGridParent.focus(cellElement);
            dataGridParent.closeEditCell();
        }
    }, [isEditable, isEditor, dataGridParent, cellElement])

    return isEditable && isEditor ? editorCell() : displayCell();
}

export default memo(EditCellTextArea)