export const getPostMessageObj = (url, tabTitle) => {
    return {
        source: window.location.host,
        target: process.env.REACT_APP_API_ALAYA_URL,
        eval: "parent.closeTab('" + tabTitle + "');parent.addTab('" + tabTitle + "', '" + url + "')"
    }
}

export const getPurchaseInvoiceRelations = (id, documentNo) => {
    return {
        source: window.location.host,
        target: process.env.REACT_APP_API_ALAYA_URL,
        eval: "parent.getPurchaseInvoiceRelations(" + id + ",'" + documentNo + "')"
    }
}

export const getReactParameters = () => {
    return {
        target: process.env.REACT_APP_API_ALAYA_URL,
        eval: "setReactParameters()",
        thisPage: true,
    }
}