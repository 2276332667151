import React, { createContext, useContext } from 'react';
import useFlashMsg from './useFlashMsg';

const FlashMsgProvider = ({ children }) => {
    const { flashMsg, showFlashMsg, visible } = useFlashMsg({ wrapperClassName: 'flash-message-fieldset-abs' });

    return (
        <FlashMsgContext.Provider value={showFlashMsg}>
            {children}
            {visible && flashMsg}
        </FlashMsgContext.Provider>
    )
}

const FlashMsgContext = createContext({});
const useFlashMsgContext = () => useContext(FlashMsgContext);

export { FlashMsgProvider, useFlashMsgContext }