import * as React from 'react';
import './information-detail.scss';

// Props Types
// label: string
// value: string
// dataFor: string

export default function InformationDetail(props) {
    const { label, value, dataFor = "informationDetail" } = props;
    return (
        <div className='information-detail-container'>
            <div
                data-tip={value}
                data-for={dataFor} className='information-detail-content'
            >
                <label className='information-detail-label'>{label}</label>
                <span
                    id={dataFor} className="information-detail-value"
                >
                    {value}
                </span>
            </div>
        </div>
    )
}