import { Fade } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useCallback, useRef, useState } from 'react'
import { useOutsideClick } from '../../hooks/useOutsideClick';

const useFlashMsg = (props) => {
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const timeoutRef = useRef();

    const showFlashMsg = useCallback((message, severity) => {
        setVisible(true);
        setMessage(message);
        setSeverity(severity);
    }, [])

    const hideFlashMsg = () => {
        setVisible(false);
    }

    const onFlashMessageTimer = useCallback(() => {
        if (visible && !timeoutRef.current) {
            timeoutRef.current = setTimeout(() => {
                timeoutRef.current = null;
                setVisible(false);
            }, 1000)
        }
    }, [visible])

    const ref = useOutsideClick(visible, onFlashMessageTimer);

    const render = () => {
        return (
            <div className={`flash-message-fieldset ${props?.wrapperClassName}`} ref={ref}>
                <div className="flash-message-success-container">
                    <Fade in={visible}>
                        <Alert
                            className="flash-message"
                            variant="filled"
                            severity={severity}
                            icon={severity === "error" ? <div className="icon-error-icon" /> : <CheckCircleIcon fontSize="large" />}
                            action={
                                <IconButton aria-label="close" onClick={hideFlashMsg}>
                                    <div className="line" />
                                    <CloseIcon />
                                </IconButton>
                            }
                        >
                            {message}
                        </Alert>
                    </Fade>
                </div>
            </div>
        )
    }

    return { flashMsg: render(), showFlashMsg: showFlashMsg, visible }
}

export default useFlashMsg