import React, { Component } from 'react'
import { formatMessage } from 'devextreme/localization';
import { Button } from 'devextreme-react';

export const headerNewBtnRenderer = ({ cellData, onClick, enabled }) => {
    return (
        <HeaderNewBtn
            {...cellData}
            onClick={onClick}
            enabled={enabled}
        />
    )
}

export class HeaderNewBtn extends Component {
    render() {
        return (
            <div data-tip={formatMessage("AddNew[Insert]")} data-for="datagrid-tooltip-top" className="new-btn-contanier">
                {
                    this.props.enabled ?
                        (
                            <Button
                                className="addNewBtn"
                                hoverStateEnabled={true}
                                activeStateEnabled={false}
                                focusStateEnabled={false}
                                type={"default"}
                                stylingMode={"contained"}
                                onClick={this.props.onClick}
                                icon={"normalize-icon glyphicon icon-add-icon"}
                                text={formatMessage('New1')}
                            />
                        )
                        :
                        (
                            <Button
                                style={{ backgroundColor: "darkgrey" }}
                                className="addNewBtn"
                                hoverStateEnabled={true}
                                activeStateEnabled={false}
                                focusStateEnabled={false}
                                type={"default"}
                                stylingMode={"contained"}
                                icon={"normalize-icon glyphicon icon-add-icon"}
                                text={formatMessage('New1')}
                            />
                        )
                }
            </div>
        );
    }
}