import { Button, Popup, ScrollView } from 'devextreme-react'
import { ToolbarItem } from 'devextreme-react/popup';
import { formatMessage } from 'devextreme/localization';
import React, { useEffect, useRef } from 'react'
import { mousetrapMain } from '../../../App';
import './styles/item-type-popup.scss';

const PopupDefault = (props) => {
    const {
        cancelForm,
        apiLoaded = true,
        width = '81%',
        height = '95%',
        maxHeight,
        maxWidth,
        formikRef,
    } = props;

    const contentRef = useRef();

    // #region Events
    const onShowing = () => {
        contentRef.current.focus();
    }

    const onSubmit = async () => {
        const result = formikRef ? await formikRef?.current?.submitForm() : true;

        if (result) {
            props.onHiding();
        }
    }

    const onCancel = () => {
        onHiding();
    }

    const onHiding = () => {
        cancelForm?.();
        props.onHiding();
    }

    const onHidden = () => {
        props.onHidden();
    }
    // #endregion

    // #region useEffect
    useEffect(() => {
        mousetrapMain.pause();

        return () => {
            mousetrapMain.unpause();
        }
    }, [])
    // #endregion

    const buttonOptions = {
        type: 'close',
        icon: 'close',
        tabIndex: -1,
        onClick: onCancel
    };

    return (
        <Popup
            className={`item-popup ${props.className}`}
            width={width}
            height={height}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            showTitle={true}
            dragEnabled={true}
            closeOnOutsideClick={true}
            showCloseButton={false}
            title={props.title}
            visible={props.visible}
            onShowing={onShowing}
            onHiding={onHiding}
            onHidden={onHidden}
            tabIndex={null}
        >
            <ToolbarItem
                widget="dxButton"
                location="after"
                options={buttonOptions}
            />

            <ScrollView className='scrollview-full-height' width='100%' height='90%'>
                <div className='popup-content' tabIndex={-1} ref={contentRef}>
                    {props.children}
                </div>
            </ScrollView>

            <div className="item-popup-footer">
                <div className="left-btn-container">
                    <Button
                        hoverStateEnabled={true}
                        activeStateEnabled={false}
                        focusStateEnabled={false}
                        className="cancel-btn"
                        text={formatMessage("Cancel")}
                        type="normal"
                        stylingMode="contained"
                        onClick={onCancel}
                    />
                </div>
                <div className="right-btn-container">
                    <Button
                        hoverStateEnabled={true}
                        activeStateEnabled={false}
                        focusStateEnabled={false}
                        className="apply-btn"
                        text={formatMessage("OK")}
                        type="default"
                        stylingMode="contained"
                        onClick={onSubmit}
                        disabled={!apiLoaded}
                    />
                </div>
            </div>
        </Popup>
    )
}

export default PopupDefault;