import React, { useCallback, useState } from "react";
import { unstable_batchedUpdates } from 'react-dom';
import { Input, AutoComplete, Button, Badge, Row, Col } from "antd";
import { InfoCircleFilled, UserOutlined } from "@ant-design/icons";
import { FormItem } from "../forms";
import { pick, isEmpty, isEqual } from "lodash";
import { getPostMessageObj } from '../../utils/iframe-func'
import useFormikValidateOnError from "../../hooks/useFormikValidateOnError";
import usePrevious from "../../hooks/usePrevious";
import useAsyncEffect from 'use-async-effect';
import "./styles/SearchInput.scss";

const { Search } = Input;

export const SearchInput = (props) => {
    const {
        setModalVisible = null,
        getTabLink,
        setSelectedData,
        placeholder = "",
        color = "#1dafed",
        arrayTitle,
        arrayColumn,
        showTextColumn,
        filtervalue,
        keyColumn,
        link,
        field,
        form,
        label: tabTitle,
        dataSource = [],
        validateOnError = true,
        disabled
    } = props;

    const [inputValue, setInputValue] = useState("");
    const [inputCode, setInputCode] = useState("");
    const [tabLink, setTabLink] = useState(link);
    const [showSuffix, setShowSuffix] = useState(link ? true : false);

    const previousValue = usePrevious(field.value);

    useFormikValidateOnError(field, form, validateOnError);

    // Util
    function handleFilter(inputValue, option) {
        if (!option.filtervalue) return false;

        const searchValue = Object.values(JSON.parse(option.filtervalue)).join(" ");
        const index = searchValue.toUpperCase().indexOf(inputValue.toUpperCase());
        const show = index !== -1;

        return show;
    }

    function openSourceTab() {
        if (!tabLink) return;

        const tabTitleWithCode = inputCode === "" ? tabTitle : tabTitle + "(" + inputCode + ")";
        window.parent.postMessage(getPostMessageObj(tabLink, tabTitleWithCode), "*");
    }

    const fetchTabLinkAPI = useCallback(async () => {
        return await getTabLink(field.value[keyColumn]);
    }, [field.value, getTabLink, keyColumn])

    // Events
    function showModalPopup() {
        if (setModalVisible) {
            setModalVisible(true);
        }
    };

    function handleOnSelect(value, option) {
        setSelectedData(option);
    }

    function handleOnChange(value) {
        setInputValue(value.replace(/<ID=.*/, "")); // workaround to allow item with same value but different id/object
    }

    function handleOnBlur() {
        if (!inputValue) {
            setSelectedData(null);
            return;
        }

        function filterForFirst(data) {
            const filterValue = pick(data, filtervalue);
            const searchValue = Object.values(filterValue).join(" ");
            const found = searchValue.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
            return found;
        }

        const option = dataSource.find(filterForFirst);

        if (option) {
            setSelectedData(option);
            setInputValue(option[showTextColumn]);
        } else {
            setSelectedData(null);
            setInputValue("");
        }
    }

    // UseEffect
    useAsyncEffect((isActive) => {
        // update states when field value changes
        if (!isEqual(previousValue, field.value)) {
            if (field.value && !isEmpty(field.value)) {
                setInputValue(field.value[showTextColumn]);
                setInputCode(field.value[arrayColumn[0]]);

                if (!getTabLink) return;

                fetchTabLinkAPI().then((newTabLink) => {
                    if (!isActive) return;

                    unstable_batchedUpdates(function () {
                        if (newTabLink) {
                            setTabLink(newTabLink);
                            setShowSuffix(true);
                        } else {
                            setTabLink(null);
                            setShowSuffix(false);
                        }
                    });
                })
            } else {
                setInputValue("");
                setInputCode("");
                setTabLink(null);
                setShowSuffix(false);
            }
        }
    }, [field.value, previousValue, arrayColumn, showTextColumn, fetchTabLinkAPI, getTabLink])

    // Render
    function renderOptions() {
        function renderTitle() {
            return (
                <Row>
                    {arrayTitle.map((title) => {
                        return <Col key={title} span={12}>{title}</Col>;
                    })}
                </Row>
            );
        }

        function renderItem(data, i) {
            return {
                id: data[keyColumn],
                key: data[keyColumn],
                value: data[showTextColumn] + `<ID=${data[keyColumn]}>`, // workaround to allow item with same value but different id/object
                label: (
                    <Row key={i}>
                        {arrayColumn.map((c, j) => {
                            return <Col key={data[keyColumn] + "" + j} span={12}>{data[c]}</Col>;
                        })}
                    </Row>
                ),
                filtervalue: JSON.stringify(pick(data, filtervalue)),
            }
        }

        const label = renderTitle();
        const options = dataSource.map((data, i) => renderItem(data, i));

        return [{ label, options }];
    }

    function renderSuffix() {
        if (!showSuffix || disabled) return null;

        return (
            <Badge
                size="small"
                count={<InfoCircleFilled style={{ color, fontSize: 10 }} />}
                offset={[-3, 18]}
                style={{ backgroundColor: "white" }}
            >
                <UserOutlined className="user-icon" style={{ fontSize: 20 }} onClick={openSourceTab} />
            </Badge>
        );
    }

    function renderEnterBtn() {
        return (
            <Button onClick={showModalPopup} type="primary" disabled={disabled}>
                <i className="icon-search-icon" />
            </Button>
        );
    }

    return (
        <FormItem field={field} {...props}>
            <AutoComplete
                className="search-input"
                dropdownClassName="certain-category-search-dropdown"
                options={renderOptions()}
                value={inputValue}
                onSelect={handleOnSelect}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                filterOption={handleFilter}
                disabled={disabled}
                defaultActiveFirstOption={true}
                autoComplete={"none"}
            >
                <Search
                    placeholder={placeholder}
                    enterButton={renderEnterBtn()}
                    suffix={renderSuffix()}
                    size="large"
                    autoComplete={"none"}
                />
            </AutoComplete>
        </FormItem>
    );
};
