import { NumberBox } from 'devextreme-react';
import { formatMessage } from 'devextreme/localization';
import React, { memo, useCallback, useEffect } from 'react'
import { toFixed } from '../../../utils/common-function';
import { updateInvalidCell } from '../data-grid-util';
import { cloneDeep } from "lodash";

const EditCellNumber = (props) => {
    const {
        // editCellProps
        value,
        oldValue,
        data,
        dataField,
        setValueCallback,
        dataGridParent,
        cellElement,
        isEditor,
        // others
        isEditable,
        isRequired,
        min,
        max,
        precision,
        maxDisplayPrecision,
        thousandSeparator,
        showAllDecimalPoints,
        showMax,
        changeEmptyToMin,
        onChange,
        isControlled, // set to true if value needs to be overriden at props.onChange callback
    } = props;

    const displayValue = typeof (value) === 'number' ? Number(toFixed(value, precision, maxDisplayPrecision)) : value;

    function formatter(value) {
        if (thousandSeparator && showAllDecimalPoints) {
            return value?.toLocaleString(undefined, { minimumFractionDigits: precision, maximumFractionDigits: 20 });
        } else if (thousandSeparator) {
            return value?.toLocaleString(undefined, { maximumFractionDigits: 20 });
        } else if (showAllDecimalPoints) {
            return toFixed(value, precision, maxDisplayPrecision);
        } else {
            return value;
        }
    }

    function checkIsValidPattern(displayValue) {
        if (!displayValue && displayValue !== 0) displayValue = "";
        return /^-?\d{0,12}(\.\d{1,6})?$/.test(displayValue);
    }

    function showInvalidTooltip() {
        if (isRequired && !displayValue && displayValue !== 0) {
            return {
                "data-tip": formatMessage('validation-required'),
                "data-for": "datagrid-tooltip-error"
            }
        }

        if (!checkIsValidPattern(displayValue)) {
            return {
                "data-tip": formatMessage('Notmorethan12digitsandmaximumof6decimalpoints'),
                "data-for": "datagrid-tooltip-error"
            }
        }
    }

    const onValueChanged = useCallback((event) => {
        let value = event.value;

        if ((value && value !== 0) && value < min) {
            value = min;
        }

        if (!value && changeEmptyToMin) {
            value = min || 0;
        }

        if ((precision || precision === 0) && (value || value === 0)) {
            // rounding using toFixed
            value = toFixed(value, precision);
        }

        if (value) {
            // convert back to float if not null
            value = parseFloat(value);
        }

        // update value
        if (!isControlled) setValueCallback(value);

        // onChange callback
        if (onChange) {
            const updatedData = cloneDeep(data);
            updatedData[dataField] = value;

            const obj = {
                value,
                cellData: updatedData,
                setValueCallback,
                closeEditCell: dataGridParent.closeEditCell,
                oldValue,
            }

            onChange(obj);
        }
    }, [setValueCallback, onChange, precision, min, changeEmptyToMin, isControlled, oldValue, dataGridParent.closeEditCell, data, dataField])

    const displayCell = () => {
        if (showMax) {
            return (
                <div className='editCellWrapper'>
                    <div className={`editCell editCell-number editCell-default editCell-max`}>
                        <span>
                            {formatter(max)}
                        </span>
                    </div>
                    <div className={`editCell editCell-number ${!isEditable && 'editCell-disabled'}`} {...showInvalidTooltip()}>
                        <span>
                            {formatter(displayValue)}
                        </span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`editCell editCell-number ${!isEditable && 'editCell-disabled'}`} {...showInvalidTooltip()}>
                    <span>
                        {formatter(displayValue)}
                    </span>
                </div>
            )
        }
    }

    const editorCell = () => {
        if (showMax) {
            return (
                <div className='editCellWrapper'>
                    <div className={`editCell editCell-number editCell-default editCell-max`}>
                        <span>
                            {formatter(max)}
                        </span>
                    </div>
                    <div className='editCell editCell-number editCell-input' {...showInvalidTooltip()}>
                        <NumberBox
                            stylingMode={'outlined'}
                            defaultValue={displayValue}
                            onValueChanged={onValueChanged}
                            min={min}
                            max={max}
                            step={0}
                            hoverStateEnabled={false}
                            focusStateEnabled={false}
                            activeStateEnabled={false}
                        />
                    </div>
                </div>
            )
        } else {
            return (
                <div className='editCell editCell-number editCell-input' {...showInvalidTooltip()}>
                    <NumberBox
                        stylingMode={'outlined'}
                        defaultValue={displayValue}
                        onValueChanged={onValueChanged}
                        min={min}
                        max={max}
                        step={0}
                        hoverStateEnabled={false}
                        focusStateEnabled={false}
                        activeStateEnabled={false}
                    />
                </div>
            )
        }
    }

    useEffect(() => {
        if (!isEditable && isEditor) {
            dataGridParent.focus(cellElement);
            dataGridParent.closeEditCell();
        }
    }, [isEditable, isEditor, dataGridParent, cellElement])

    useEffect(() => {
        function addInvalidCellCSS() {
            if (!cellElement.classList.contains('invalid-cell')) {
                // add invalid-cell css
                cellElement.classList.add('invalid-cell');
            }
        }

        const isValidValue = !isRequired || (isRequired && (!!displayValue || displayValue === 0)); // cannot be empty
        const isValidPattern = checkIsValidPattern(displayValue); // cannot more than 12 digits & 6 decimals
        const isValidCell = isValidValue && isValidPattern;

        updateInvalidCell(data, dataField, isValidCell); // update data by reference

        if (!isValidValue || !isValidPattern) {
            addInvalidCellCSS();
        }
    }, [isRequired, data, dataField, displayValue, cellElement])

    return isEditable && isEditor ? editorCell() : displayCell();
}

export default memo(EditCellNumber)
