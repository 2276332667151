import Mousetrap from 'mousetrap';

(function (Mousetrap) {
    // Pause/unpause extension
    // https://github.com/ccampbell/mousetrap/blob/master/plugins/pause/mousetrap-pause.js

    // Add Pause
    Mousetrap.prototype.pause = function () {
        var self = this;
        self.paused = true;
    };

    // Add Unpause
    Mousetrap.prototype.unpause = function () {
        var self = this;
        self.paused = false;
    };

    // Replace default stopCallback
    Mousetrap.prototype.stopCallback = function (event, element, combo) {
        const self = this;
        let rejectCallback = false;

        if (self.paused) {
            rejectCallback = true;
            return rejectCallback;
        }

        // This is only for event triggered outside of DevExtreme component
        // Event triggered in DevExtreme component will be captured within and can only be overriden with their API (onKeydown/onRowDblClick/etc)

        // Keys Region
        const denyComboWithValue = ['del', 'enter']; // deny callback for key when element has value
        const proxyAlt = 'alt'; // allow callback if combo starts with alt
        const proxyCombo = ['ins']; // allow callback for keys
        const proxyClass = 'mousetrap'; // allow callback if elements have "mousetrap" className
        const denyRole = ['menuitem', 'menubar', 'combobox'] // deny callback for all elements with "denyRole" role attribute
        const denyTag = ['INPUT', 'SELECT', 'TEXTAREA'] // deny callback for all elements with "denyTag" tag
        const denyEditable = element.contentEditable && element.contentEditable === 'true'; // deny callback for editable elements

        // Logic Region
        if (element.value && !element.readOnly && denyComboWithValue.indexOf(combo) > -1) {
            rejectCallback = true;
        } else if (combo.split('+')[0] === proxyAlt) {
            rejectCallback = false;
        } else if (proxyCombo.indexOf(combo) > -1) {
            rejectCallback = false;
        } else if (element.className.includes(proxyClass)) {
            rejectCallback = false;
        } else if (denyRole.indexOf(element.getAttribute("role")) > -1) {
            rejectCallback = true;
        } else if (denyTag.indexOf(element.tagName) > -1 && !element.readOnly) {
            rejectCallback = true;
        } else if (denyEditable) {
            rejectCallback = true;
        }

        return rejectCallback;
    }

    // Add clearStopCallback
    Mousetrap.prototype.clearStopCallback = function (mousetrap) {
        mousetrap.stopCallback = function () {
            const self = this;
            let rejectCallback = false;

            if (self.paused) {
                rejectCallback = true;
            }

            return rejectCallback;
        }
    }

    Mousetrap.init();

})(Mousetrap);