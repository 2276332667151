import * as React from 'react';

export default function HyperlinkCell(props) {
    const { cellData, onClick } = props;

    return (
        <div
            onClick={() => onClick?.(cellData)}
            className="datagrid-data-link">
            {cellData.text}
        </div>
    )
}