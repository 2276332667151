/*update row data
  mandatory row data to update
  1. xxx_qty
  2. xxx_UnitPrice
  3. xxx_TaxAmt
  4. xxx_SubTotal
  5. xxx_TaxableAmt
  6. xxx_Total
  7. xxx_NewUnitPrice
  8. xxx_TaxableExcFooterDisc
  9. xxx_LocalTax
  10. xxx_LocalTaxAdjustment
  11. xxx_TaxAdjustment
  12. xxx_ItemFooterDiscount
  13. xxx_DiscountAmt
  14. xxx_ItemTypeID
  15. xxx_GroupID
  16. xxx_SourceDocID
  17. xxx_TaxRate
  18. xxx_CurrencyRate

  optional row data to date
  1. xxx_DiscountPercentage
  2. xxx_LocalSubTotal
*/

const columns = {
    // 2 decimal points
    total: "_Total",
    taxAmt: "_TaxAmt",
    taxableAmt: "_TaxableAmt",
    subTotal: "_SubTotal",
    localTax: "_LocalTax",
    localTaxAdjustment: "_LocalTaxAdjustment",
    taxableExcFooterDisc: "_TaxableExcFooterDisc",

    // no round (display at UI with currentCompanyData rounding)
    itemFooterDiscount: "_ItemFooterDiscount",
    unitPrice: "_UnitPrice",
    newUnitPrice: "_NewUnitPrice",

    // no round (display at UI as 2 decimal points)
    discountAmt: "_DiscountAmt",
    taxAdjustment: "_TaxAdjustment",

    // no round
    subItemYN: "_SubItem_YN",
    taxTypeID: "_TaxTypeID",
    taxType: "_TaxType",
    qty: "_Qty",
    itemTypeID: "_ItemTypeID",
    groupID: "_GroupID",
    sourceDocID: "_SourceDocID",
    taxRate: "_TaxRate",
    currencyRate: "_CurrencyRate",

    /**
     * @param {string} prefix
    */
    set setColumnsPrefix(prefix) {
        this.total = prefix + "_Total";
        this.taxAmt = prefix + "_TaxAmt";
        this.taxableAmt = prefix + "_TaxableAmt";
        this.subTotal = prefix + "_SubTotal";
        this.localTax = prefix + "_LocalTax";
        this.localTaxAdjustment = prefix + "_LocalTaxAdjustment";
        this.taxableExcFooterDisc = prefix + "_TaxableExcFooterDisc";

        this.itemFooterDiscount = prefix + "_ItemFooterDiscount";
        this.unitPrice = prefix + "_UnitPrice";
        this.newUnitPrice = prefix + "_NewUnitPrice";

        this.discountAmt = prefix + "_DiscountAmt";
        this.taxAdjustment = prefix + "_TaxAdjustment";

        this.subItemYN = prefix + "_SubItem_YN";
        this.taxTypeID = prefix + "_TaxTypeID";
        this.taxType = prefix + "_TaxType";
        this.qty = prefix + "_Qty";
        this.itemTypeID = prefix + "_ItemTypeID";
        this.groupID = prefix + "_GroupID";
        this.sourceDocID = prefix + "_SourceDocID";
        this.taxRate = prefix + "_TaxRate";
        this.currencyRate = prefix + "_CurrencyRate";
    },
};

export default columns;
