export const DefaultAdminAccess =  [{
    "Add":true,
    "CaseAdmin":true,
    "CaseAssign":true,
    "CaseCreate":true,
    "Copy":true,
    "CopyFrom":true,
    "CopyTo":true,
    "CreateReport":true,
    "Delete":true,
    "DeleteReport":true,
    "DesignReport":true,
    "ExportGrid":true,
    "GridColumnChooser":true,
    "Post":true,
    "Print":true,
    "ProjectAdmin":true,
    "RequiredPasswordtoCreate":true,
    "RequiredPasswordtoDelete":true,
    "RequiredPasswordtoEdit":true,
    "RequiredPasswordtoView":true,
    "RestoreGridLayout":true,
    "SaveEdit":true,
    "SaveGridLayout":true,
    "ShowCost":true,
    "ShowSalesPrice":true,
    "View":true,
    "ViewReport":true
  }]