import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";

export const FormikDefault = (props) => {
    const {
        children,
        formikRef,
        onSubmit = function () { },
        ...additionalProps
    } = props;

    const [firstRender, setFirstRender] = useState(true);
    const paramRef = useRef({});

    function initFormRef(ref) {
        if (!ref) return;

        if (ref && !ref.hasOwnProperty("submitFormWithParam")) {
            // create customized submitFormWithParam that accepts custom param object
            ref.submitFormWithParam = (param = {}) => {
                paramRef.current = param;
                return ref.submitForm();
            }
        }

        if (formikRef) {
            // initialize formikRef
            formikRef.current = ref;
        }
    }

    function onSubmitWithParam(values, actions) {
        const result = onSubmit(values, actions, paramRef.current); // calls onSubmit with param object
        paramRef.current = {}; // resets param
        return result;
    }

    function renderContent() {
        // to prevent child ref is null on first render
        return firstRender ? null : children;
    }

    useEffect(() => {
        // to prevent child ref is null on first render
        setFirstRender(false);
    }, [])

    return (
        <Formik
            innerRef={initFormRef}
            onSubmit={onSubmitWithParam}
            validateOnChange={false}
            validateOnBlur={false}
            {...additionalProps}
        >
            {renderContent()}
        </Formik>
    );
};