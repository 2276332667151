/*
Purchase Invoice SubItem store separately for below items
1. Batch Item
2. Expiry Item
3. Matrix Item
4. Serial Number Item
*/

import _ from "lodash";
import { getRoundingValue } from '../math-rounding-func';
import columns from "./purchase-column";

export const taxRoundingCorrection = (prefix, itemList) => {
    // adjust tax amount and taxable to fix rounding issue (isTaxInclusive & !isTaxItemize only)
    // #region Explanation
    /*  
        For case of isTaxInclusive & !isTaxItemize only, 
        invoice with same total will yield different taxable and taxAmount due to rounding issue

        ======================================
        Scenario #1 (Single Item priced 20.00)
        ======================================
        Total: 20.00
        Taxable: 18.87
        Tax Amount: 1.13

        Item    Total   Taxable     Tax Amt
        1003    20.00   18.87       1.13
        -----------------------------------
        Total   20.00   18.87       1.13
        -----------------------------------

        =========================================
        Scenario #2 (Two Items priced 10.00 each)
        =========================================
        Total: 20.00
        Taxable: 18.86 (should match Scenario #1)
        Tax Amount: 1.14 (should match Scenario #1)

        Item    Total   Taxable     Tax Amt
        1001    10.00   9.43        0.57
        1002    10.00   9.43        0.57
        -----------------------------------
        Total   20.00   18.86       1.14
        -----------------------------------

        =============================================
        Scenario #2 (Tax Rounding Correction Applied)
        =============================================
        Total: 20.00
        Taxable: 18.87 (matches Scenario #1)
        Tax Amount: 1.13 (matches Scenario #1)

        Item    Total   Taxable         Tax Amt
        1001    10.00   9.43            0.57
        1002    10.00   9.44 (+0.01)    0.56 (-0.01)
        --------------------------------------------
        Total   20.00   18.87           1.13
        --------------------------------------------
    */
    // #endregion Explanation

    columns.setColumnsPrefix = prefix;

    // group by item tax code
    const itemListByTaxCodeObj = _(itemList)
        .filter(item => item[columns.taxTypeID])
        .groupBy(columns.taxTypeID)
        .value();

    // loop by item tax code
    for (const itemListByTaxCode of Object.values(itemListByTaxCodeObj)) {
        //sum of subtotal for all items that has same tax code and subitem = false
        let totalSubTotalAmt = itemListByTaxCode.reduce(function (accumulator, currentValue) {
            return accumulator + (currentValue[columns.subTotal] || 0);
        }, 0);

        // sum of TaxAdjustment for all items that has same tax code and subitem = false
        const totalTaxAdj = itemListByTaxCode.reduce(function (accumulator, currentValue) {
            return accumulator + (currentValue[columns.taxAdjustment] || 0);
        }, 0);

        // sum of TaxAmt for all items that has same tax code and subitem = false
        const totalTaxAmt = itemListByTaxCode.reduce(function (accumulator, currentValue) {
            return accumulator + (currentValue[columns.taxAmt] || 0);
        }, 0);

        const lastItem = itemList[itemList.length - 1]; // get the last item
        const taxRate = lastItem[columns.taxRate] || 0;

        totalSubTotalAmt = getRoundingValue(taxRate * totalSubTotalAmt / (100 + taxRate)) + totalTaxAdj;

        if (lastItem && totalSubTotalAmt !== totalTaxAmt) {
            if (totalSubTotalAmt > totalTaxAmt) {
                const diffTax = totalSubTotalAmt - totalTaxAmt;
                lastItem[columns.taxAmt] = getRoundingValue((lastItem[columns.taxAmt] || 0) + diffTax);
                lastItem[columns.taxableAmt] = getRoundingValue((lastItem[columns.taxableAmt] || 0) - diffTax);
                lastItem[columns.taxableExcFooterDisc] = getRoundingValue((lastItem[columns.taxableExcFooterDisc] || 0) - diffTax);
            }
            else {
                const diffTax = totalTaxAmt - totalSubTotalAmt;
                lastItem[columns.taxAmt] = getRoundingValue((lastItem[columns.taxAmt] || 0) - diffTax);
                lastItem[columns.taxableAmt] = getRoundingValue((lastItem[columns.taxableAmt] || 0) + diffTax);
                lastItem[columns.taxableExcFooterDisc] = getRoundingValue((lastItem[columns.taxableExcFooterDisc] || 0) + diffTax);
            }
        }
    }
}