import React from 'react'
import { Row, Col } from "antd";
import { Field } from "formik";
import { DropdownInput } from '../../../../fields';
import { formatMessage } from 'devextreme/localization';
import { Button } from 'devextreme-react';

const SectionBatchDetails = (props) => {
    const {
        itemBatchConfig,
        itemUOMList,
        onAddBatchNo,
        onUOMChange,
        disabled
    } = props;

    return (
        <Row gutter={30}>
            <Col xs={24} md={12}>
                <Field
                    suffixIcon={<></>}
                    component={DropdownInput}
                    // Labels     
                    label={'Batch No.'} // [Translation] Update Translation
                    name="batchNo"
                    placeholder="Select or Enter Batch No" // [Translation] Update Translation
                    // Dropdown
                    headerColumn={["Batch No.", formatMessage('ExpiryDate'), formatMessage('Color')]} // [Translation] Update Translation
                    dataColumn={["IB_BatchNo", "IB_ExpiryDate", "IB_ColorDesc"]}
                    displayColumn="IB_BatchNo"
                    filtervalue={["IB_BatchNo"]}
                    // Data
                    dataSource={itemBatchConfig.current}
                    getColumnValue="IB_BatchNo"
                    // Events
                    onPressEnterCallback={onAddBatchNo}
                    // Others
                    disabled={disabled}
                    allowNew
                    hideDropdownOnEmpty
                />
            </Col>
            <Col xs={24} md={8}>
                <Field
                    suffixIcon={<></>}
                    component={DropdownInput}
                    // Labels
                    label={formatMessage("UOM")}
                    name="uom"
                    placeholder="Select UOM"
                    // Dropdown
                    headerColumn={[formatMessage("Code"), formatMessage("Description")]}
                    dataColumn={["Code", "Description"]}
                    displayColumn="Code"
                    filtervalue={["Code", "Description"]}
                    // Data
                    dataSource={itemUOMList}
                    getColumnValue="ID"
                    // Events
                    onChangeCallback={onUOMChange}
                    // Others
                    disabled={disabled}
                    required
                />
            </Col>
            <Col xs={24} md={4}>
                <Button
                    hoverStateEnabled={true}
                    activeStateEnabled={false}
                    focusStateEnabled={false}
                    className="section-input-btn section-input-btn-left"
                    text="Add"
                    type="default"
                    stylingMode="contained"
                    onClick={onAddBatchNo}
                    disabled={disabled}
                />
            </Col>
        </Row>
    )
}

export default SectionBatchDetails