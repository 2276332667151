import React, { useState } from 'react';
import SelectBox from 'devextreme-react/select-box';

const Singleselectiondropdown = (props) => {
    const {
        textField = "dx-fieldset-text",
        inputField = "dx-fieldset",
    } = props;

    const [value, setValue] = useState(props.defaultValue);

    function onChangedHandler(event) {
        setValue(event.value);
        props.parentCallback(event.value);
    }

    return (
        <div>
            <div className={textField}>
                <div className="dx-field">
                    <div className="dx-field-label">
                        {props.asterisk === true && <span style={{ color: 'red' }}>* </span>}
                        <span>{props.caption}</span>
                    </div>
                </div>
            </div>

            <div className={inputField}>
                <div className="dx-field">
                    <div className="dx-field-value">
                        <SelectBox dataSource={props.dataSource}
                            placeholder={props.placeholder}
                            displayExpr={props.displayExpr}
                            stylingMode={"outlined"}
                            valueExpr={props.valueExpr}
                            defaultValue={props.defaultValue}
                            className='filter-drop-down'
                            onValueChanged={onChangedHandler}
                            value={value}>
                        </SelectBox>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Singleselectiondropdown;