import React from 'react'
import { Row, Col } from "antd";
import { Field } from "formik";
import { NumberInput, TextAreaInput } from '../../../fields';
import { formatMessage } from 'devextreme/localization';
import { DecimalPoints } from '../../../../utils/default-cookies';
import { validator } from '../../../../utils/form-validator';

const SectionRemarksUnitCost = (props) => {
    const precision = DecimalPoints() > 0 ? DecimalPoints() : 2;

    return (
        <Row gutter={30}>
            <Col span={18}>
                <Field
                    // Labels
                    label={formatMessage('Remarks')}
                    name="remarks"
                    placeholder={'Enter a note here...'} // [Translation] Update Translation
                    // Others
                    component={TextAreaInput}
                />
            </Col>
            <Col span={6}>
                <Field
                    // Styling
                    className="input-w"
                    // Labels
                    label={formatMessage('UnitCost')}
                    name="unitCost"
                    placeholder={(0).toFixed(precision)}
                    // Formatting
                    step={0}
                    precision={precision}
                    min={-999999999999}
                    max={999999999999}
                    thousandSeparator
                    showAllDecimalPoints
                    // Others
                    component={NumberInput}
                    validate={validator.notEmptyOrZero}
                    required
                />
            </Col>
        </Row>
    )
}

export default SectionRemarksUnitCost