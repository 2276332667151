import { TextBox } from 'devextreme-react';
import React, { memo, useCallback, useEffect } from 'react'
import { cloneDeep } from "lodash";

const EditCellText = (props) => {
    const {
        // editCellProps
        displayValue,
        data,
        dataField,
        setValueCallback,
        dataGridParent,
        cellElement,
        isEditor,
        // others
        isEditable,
        onChange,
        isControlled, // set to true if value needs to be overriden at props.onChange callback
    } = props;

    const onValueChanged = useCallback((event) => {
        const value = event.value.trim();

        // update value
        if (!isControlled) setValueCallback(value);

        // onChange callback
        if (onChange) {
            const updatedData = cloneDeep(data);
            updatedData[dataField] = value;

            const obj = {
                value,
                cellData: updatedData,
            }

            onChange(obj);
        }
    }, [setValueCallback, onChange, isControlled, data, dataField])

    const displayCell = () => {
        return (
            <div className={`editCell ${!isEditable && 'editCell-disabled'}`}>
                <span>
                    {displayValue}
                </span>
            </div>
        )
    }

    const editorCell = () => {
        return (
            <div className='editCell editCell-input'>
                <TextBox
                    defaultValue={displayValue}
                    onValueChanged={onValueChanged}
                />
            </div>
        )
    }

    useEffect(() => {
        if (!isEditable && isEditor) {
            dataGridParent.focus(cellElement);
            dataGridParent.closeEditCell();
        }
    }, [isEditable, isEditor, dataGridParent, cellElement])

    return isEditable && isEditor ? editorCell() : displayCell();
}

export default memo(EditCellText)