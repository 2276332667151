import { GET, PUT, POST, DELETE } from './request-options'

export const GetAllReportFormatByReportID = (token, companyID, clientID, userID, docID, reportID, reportServerURL, reportFirstPost, pageName) => {
    const param = `companyID=${companyID}&clientID=${clientID}&UserID=${userID}&docID=${docID}&reportID=${reportID}&reportServerURL=${reportServerURL}&reportFirstPost=${reportFirstPost}&pageName=${pageName}&UI-Culture=en-US`;
    return fetch(`${process.env.REACT_APP_API_ALL_REPORT_FORMAT_BY_REPORTID}${param}`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

export const UpdateReportFormatDesp = (token, userID, RFID, reportDesc, ) => {
    const param = `userID=${userID}&RFID=${RFID}&reportDesc=${reportDesc}&UI-Culture=en-US`;
    return fetch(`${process.env.REACT_APP_API_UPDATE_REPORT_FORMAT_DESP}${param}`, PUT(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

export const DeleteReportFormat = (token, companyID, RFID, reportServerURL) => {
    const param = `companyID=${companyID}&RFID=${RFID}&reportServerURL=${reportServerURL}&UI-Culture=en-US`;
    return fetch(`${process.env.REACT_APP_API_DEL_REPORT_FORMAT}${param}`, DELETE(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Result;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

export const CopyReportFormat = (token, companyID, docID, RFID, reportServerURL, pageName, reportDesc) => {
    const param = `companyID=${companyID}&docID=${docID}&RFID=${RFID}&reportServerURL=${reportServerURL}&pageName=${pageName}&reportDesc=${reportDesc}&UI-Culture=en-US`;
    return fetch(`${process.env.REACT_APP_API_COPY_REPORT_FORMAT}${param}`, POST(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

export const UpdateDefaultReportFormat = (token, companyID, reportID, RFID) => {
    const param = `companyID=${companyID}&reportID=${reportID}&RFID=${RFID}&UI-Culture=en-US`;
    return fetch(`${process.env.REACT_APP_API_UPDATE_DEFAULT_REPORT_FORMAT}${param}`, PUT(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Result;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}



const handleErrors = (response) => {
    if (!response.ok) throw new Error(response.status);
    return response;
}
