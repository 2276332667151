import React, { PureComponent } from 'react'
import { formatMessage } from 'devextreme/localization';

export const editBtnRenderer = ({ cellData, onClick, enabled }) => {
    return (
        <EditBtn
            {...cellData}
            onClick={onClick}
            enabled={enabled}
        />
    )
}

export class EditBtn extends PureComponent {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        event.data = this.props.data;
        this.props.onClick(event);
    };

    render() {
        return (
            this.props.enabled ?
                (
                    <div data-tip={formatMessage('EditDetails[Enter]')} data-for="datagrid-tooltip-top" onClick={this.onClick} className="normalize-icon icon-edit-icon" />
                )
                :
                (
                    <div className="disable-edit">
                        <div data-tip={formatMessage('EditDetails[Enter]')} data-for="datagrid-tooltip-top" className="normalize-icon icon-edit-icon" />
                    </div>
                )
        )
    }
}