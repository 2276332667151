import { GET } from './request-options'

export const GetFiscalPeriodPByClientID_CompanyID_PostDate = (token, companyID, clientID, postingDate, userID, moduleItem) => {
    return fetch(`${process.env.REACT_APP_API_COMPANY_FISCAL_PERIOD_BY_DATE}companyID=${companyID}&clientID=${clientID}&postingDate=${postingDate}&userID=${userID}&moduleItem=${moduleItem}&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

function handleErrors(response) {
    if (!response.ok) throw new Error(response.status);
    return response;
}