import React, { memo, useEffect, useState } from 'react'
import { DatePicker } from 'rsuite';
import CalendarIcon from '../../date-picker/calendar-icon';
import { formatMessage } from 'devextreme/localization';
import { getLocalTimeString, isValidDate } from '../../../utils/common-function';
import { updateInvalidCell } from '../data-grid-util';

const EditCellCalendar = (props) => {
    const {
        // editCellProps
        value: selectedDate,
        data,
        dataField,
        visibleIndex,
        setValueCallback,
        dataGridParent,
        cellElement,
        isEditor,
        // others
        portalRef,
        isEditable,
        dateFormat = "dd/MM/yyyy",
        isRequired,
    } = props;

    const [dateValue, setDateValue] = useState(getInitialDate());

    function showInvalidTooltip() {
        if (isRequired && !selectedDate) {
            return {
                "data-tip": formatMessage('validation-required'),
                "data-for": "datagrid-tooltip-error"
            }
        }
    }

    function getInitialDate() {
        if (selectedDate && isValidDate(selectedDate)) {
            return new Date(selectedDate);
        } else {
            return null;
        }
    }

    function updateDate(value) {
        const localTimeString = getLocalTimeString(value, true);
        setDateValue(value);
        setValueCallback(localTimeString);
    }

    function onClickClear() {
        setDateValue(new Date()); // select current date for UI
        setValueCallback(""); // update datagrid datasource

        setTimeout(() => {
            setDateValue(null); // clear selected date to show empty textbox
        }, 0);
    }

    function onSelectDate(value) {
        updateDate(value);

        dataGridParent.closeEditCell();
        // use closeEditCell instead of datePickerRef?.current?.close()
        // to fix bug where calendarMenu remains open when row is deleted
    }

    function onChangeMonth(value) {
        updateDate(value);
    }

    function displayCell() {
        return (
            <div className={`editCell editCell-calendar ${!isEditable && 'editCell-disabled'}`} {...showInvalidTooltip()}>
                <DatePicker
                    size="xs"
                    appearance="subtle"
                    placeholder="&nbsp;"
                    format={dateFormat}
                    caretAs={CalendarIcon}
                    className='editCell-calendar-input'
                    readOnly
                    value={dateValue}
                />
            </div>
        )
    }

    function editorCell() {
        const placement = visibleIndex === (dataGridParent.getVisibleColumns().length - 1) ? "bottomEnd" : "bottomStart";

        return (
            <div className={`editCell editCell-calendar`} {...showInvalidTooltip()}>
                <DatePicker
                    size="xs"
                    appearance="subtle"
                    placeholder="&nbsp;"
                    format={dateFormat}
                    caretAs={CalendarIcon}
                    className='editCell-calendar-input'
                    menuClassName={`editCell-calendar-menu ${!dateValue && 'editCell-calendar-cleared'}`}
                    onSelect={onSelectDate}
                    onNextMonth={onChangeMonth}
                    onPrevMonth={onChangeMonth}
                    onOk={onClickClear}
                    locale={{ ok: formatMessage("Clear") }}
                    ranges={[]}
                    cleanable={false}
                    container={portalRef.current}
                    defaultOpen
                    value={dateValue}
                    placement={placement}
                />
            </div>
        )
    }

    useEffect(() => {
        if (!isEditable && isEditor) {
            dataGridParent.focus(cellElement);
            dataGridParent.closeEditCell();
        }
    }, [isEditable, isEditor, dataGridParent, cellElement])

    useEffect(() => {
        function addInvalidCellCSS() {
            if (!cellElement.classList.contains('invalid-cell')) {
                // add invalid-cell css
                cellElement.classList.add('invalid-cell');
            }
        }

        const isValidValue = !isRequired || (isRequired && !!selectedDate); // cannot be empty
        const isValidCell = isValidValue;

        updateInvalidCell(data, dataField, isValidCell); // update data by reference

        if (!isValidValue) {
            addInvalidCellCSS();
        }
    }, [isRequired, data, dataField, selectedDate, cellElement])

    return isEditable && isEditor ? editorCell() : displayCell();
}

export default memo(EditCellCalendar)