import { cloneDeep, isEmpty, get } from 'lodash';
import moment from 'moment';

export function filterBySearchText(searchText, dataGrid) {
    const searchQuery = searchText.trim();

    if (searchQuery.length === 0) {
        const getValue = () => true;
        dataGrid.filter([getValue, '=', true]);
        dataGrid.option('navigateToFirst', true);
        return;
    }

    const regExpText = "(".concat(
        searchQuery
            .replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
            .split(" ")
            .join(")|(")
            .concat(")")
    );

    const regExp = new RegExp(regExpText, 'i');

    let columnConfig = [];

    dataGrid.getVisibleColumns().forEach(function (column) {
        if (column.hasCustomSearch) {
            const dataField = column.dataField;
            const format = column?.format;
            const dataType = column?.dataType;
            columnConfig.push({ dataField, format, dataType });
        }
    });

    const getValue = (column) => {
        return columnConfig.some(function (config) {
            let value = get(column, config.dataField);

            if (config.format) {
                if (config.dataType === "number") {
                    // to search number columns with decimal formatting
                    const base = 10 ** config.format.precision;
                    value = (Math.round(parseFloat((value * base).toFixed(10))) / base).toFixed(config.format.precision);
                } else if (config.dataType === "date") {
                    // to search date columns with formatting
                    value = moment(value).format(config.format.toUpperCase());
                }
            }

            return regExp.test(value);
        });
    }

    dataGrid.filter([getValue, '=', true]);
    dataGrid.option('navigateToFirst', true);
}

export function insertEmptyAt(dataSource, position = 'start') {
    if (!dataSource || isEmpty(dataSource)) return dataSource;

    const emptySelection = cloneDeep(dataSource[0]);

    for (const index in emptySelection) {
        if (typeof emptySelection[index] === "number") {
            emptySelection[index] = 0;
        } else if (typeof emptySelection[index] === "string") {
            emptySelection[index] = "";
        } else if (typeof emptySelection[index] === "boolean") {
            emptySelection[index] = false;
        } else {
            emptySelection[index] = null;
        }
    }

    if (position === 'start') {
        dataSource.unshift(emptySelection);
    } else {
        dataSource.push(emptySelection);
    }

    return dataSource;
}

export function updateInvalidCell(data, dataField, isValid) {
    if (isValid) {
        if (data.hasOwnProperty("isValidCell")) {
            data.isValidCell.delete(dataField);
        }
    } else {
        if (data.hasOwnProperty("isValidCell")) {
            data.isValidCell.add(dataField);
        } else {
            data.isValidCell = new Set([dataField]);
        }
    }
}

export async function checkInvalidCell(itemList) {
    await sleep(0); // to let useEffect in editCell to execute

    const invalidCell = itemList.some(item => {
        // contains cells with invalid value
        return item.hasOwnProperty("isValidCell") && item.isValidCell.size > 0;
    })

    return invalidCell;
}

export function checkInvalidRow(itemList) {
    const invalidRow = itemList.some(item => {
        // contains invalid rows 
        return item.hasOwnProperty("IsAlertRow") && item.IsAlertRow;
    })

    return invalidRow;
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}