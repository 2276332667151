import { GET } from './request-options'

export const GetAdvanceSearchCompanyChartOfAccountByCompanyID = (token, companyID, clientID, postingDate, type, companyCustomerVendorID, searchKeyword, lstColName) => {
    return fetch(`${process.env.REACT_APP_API_ADVANCE_CCOAD}clientID=${clientID}&companyID=${companyID}&postingDate=${postingDate}&type=${type}&companyCustomerVendorID=${companyCustomerVendorID}&searchKeyword=${searchKeyword}&lstColName=${lstColName}&UI-Culture=en-US`, GET(token))
        .then(handleErrors)
        .then(response => response.json())
        .then(data => {
            return data.Obj.Query_ChildCompanyChartOfAccountDTL;
        })
        .catch((error) => { console.log(error); return error.toString(); });
}

function handleErrors(response) {
    if (!response.ok) throw new Error(response.status);
    return response;
}