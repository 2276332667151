import { getLocalTimeString } from "../utils/common-function";
export const GetDueDate = (pt, dueDate, documentDate) => {

    let paymentTerm = pt;
    let dtDueDate = new Date(dueDate);
    let dtDocumentDate = new Date(documentDate);

    //In JavaScript, the index of the Month begins from 0

    switch (paymentTerm.PT_TermType) {
        case "DND": //Due in no. of days

            dtDueDate = new Date(dtDocumentDate.getFullYear(), dtDocumentDate.getMonth(), dtDocumentDate.getDate() + paymentTerm.PT_Days);
            break;
        case "DFD": //Due on fixed date

            let dtTemp = new Date(dtDocumentDate.getFullYear(), dtDocumentDate.getMonth(), paymentTerm.PT_Date);
            if (paymentTerm.PT_Months > 0) {
                dtTemp = new Date(dtTemp.setMonth(dtTemp.getMonth() + paymentTerm.PT_Months));
            }

            if (dtTemp < dtDocumentDate)
                dtTemp = new Date(dtTemp.setMonth(dtTemp.getMonth() + 1));

            dtDueDate = dtTemp;
            break;
        case "DME": //Due on month end

            let dtTemp2 = new Date(dtDocumentDate.getFullYear(), dtDocumentDate.getMonth(), new Date(dtDocumentDate.getFullYear(), dtDocumentDate.getMonth() + 1, 0).getDate());

            if (paymentTerm.PT_Months > 0) {
                dtTemp2 = new Date(dtTemp2.setMonth(dtTemp2.getMonth() + paymentTerm.PT_Months));
            }

            if (dtTemp2 < dtDocumentDate)
                dtTemp2 = new Date(dtTemp2.setMonth(dtTemp2.getMonth() + 1));

            dtDueDate = dtTemp2;
            break;
        default:
            break;
    }
    dtDueDate = getLocalTimeString(dtDueDate, true);
    return dtDueDate;

}