import { DocumentTypeId } from "../../../../utils/constant-document-type-id";
import { ItemType } from "../../../../utils/constant-item-type";

export const InterfaceType = {
    DEFAULT: 1,
    COPY_FROM: 2,
}

export function getInterfaceType(sourceDocTypeID, itemType, isSubItem = false) {
    const isCopyFromPO = sourceDocTypeID === DocumentTypeId.PURCHASE_ORDER.TYPEID;
    const isCopyFromGR = sourceDocTypeID === DocumentTypeId.GOODS_RECEIVED.TYPEID;

    if (isSubItem) {
        // for subItem, use DEFAULT (Serialized Item / Batch Item inside Package Item)
        return InterfaceType.DEFAULT;
    } else if (isCopyFromPO) {
        // for isCopyFromPO, use DEFAULT (Batch, Serial, Serial Batch), else COPY_FROM
        switch (itemType) {
            case ItemType.BATCH_ITEM:
            case ItemType.SERIALIZED_ITEM:
            case ItemType.STOCK_SN_AND_BATCH_ITEM:
                return InterfaceType.DEFAULT;
            default:
                return InterfaceType.COPY_FROM;
        }
    } else if (isCopyFromGR) {
        // for isCopyFromGR, use COPY_FROM
        return InterfaceType.COPY_FROM;
    } else {
        return InterfaceType.DEFAULT;
    }
}


export function isCopyFromUI(interfaceType) {
    return interfaceType === InterfaceType.COPY_FROM;
}