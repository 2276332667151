import React, { useState, useEffect, useRef, useCallback } from "react";
import { Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FormItem } from "../../forms";
import { SelectRightIcon } from "./SelectRightIcon";
import { getPostMessageObj } from "../../../utils/iframe-func";
import { usePreviousProps } from "@mui/utils";
import useFormikValidateOnError from "../../../hooks/useFormikValidateOnError";
import _ from "lodash";

import "../styles/DropdownInput.scss";

export const DropdownInput = (props) => {
    const {
        field,
        form,
        placeholder = "",
        color = "#1dafed",
        suffixIcon,
        dataSource,
        headerColumn,
        dataColumn,
        displayColumn,
        filtervalue,
        getColumnValue,
        tabTitle,
        tabLink,
        refreshDataFn,
        onChangeCallback,
        onExternalChangeCallback,
        onPressEnterCallback,
        disabled,
        validateOnError = true,
        allowNew = false,
        denyEmpty = false,
        hideDropdownOnEmpty = false,
    } = props;

    const { setFieldValue } = form;
    const { value, name } = field;

    const [data, setData] = useState(dataSource || []);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [displayValue, setDisplayValue] = useState();
    const [selected, setSelected] = useState();

    const selectParentRef = useRef(null);
    const selectRef = useRef(null);
    const inputRef = useRef(null);
    const shouldRefreshData = useRef(false);
    const hasFieldChanges = useRef(false);
    const isInit = useRef(false);

    const prevDataSource = usePreviousProps(dataSource);
    const prevValue = usePreviousProps(value);

    useFormikValidateOnError(field, form, validateOnError);

    //#region Event
    const onInputKeyDown = (event) => {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            selectRef.current.focus();
            selectRef.current.scrollTo();
        }
    }

    const onInputClick = () => {
        if (!isDropdownOpen) {
            updateSelectData(value);
        }
    }

    const onInputEnterKey = () => {
        setIsDropdownOpen(false);
        handleInputUpdate();

        setTimeout(() => {
            // setTimeout 0 to get updated field value
            // caller should get value from formik
            onPressEnterCallback && onPressEnterCallback();
        }, 0);
    }

    const onInputBlur = (e) => {
        if (selectParentRef.current.parentElement.contains(e.relatedTarget)) return;
        handleInputUpdate();
    }

    const onSelectRowClick = (event) => {
        const selectedString = event.value;
        setSelected(selectedString);
        handleUpdate(selectedString);
    };
    //#endregion Event

    // #region Util
    const setFieldValueInternal = useCallback((value) => {
        setFieldValue(name, value);
        hasFieldChanges.current = true;
    }, [setFieldValue, name])

    const handleInputUpdate = () => {
        let withinDataSource = false;

        if (selected) {
            const selectedObj = JSON.parse(selected);
            const selectedDisplayValue = selectedObj[displayColumn];

            if (selectedDisplayValue.toLowerCase() === displayValue.toLowerCase()) {
                withinDataSource = true;
            }
        }

        if (allowNew && displayValue && !withinDataSource) {
            setFieldValueInternal(displayValue);
            setSelected();
        } else {
            handleUpdate(selected);
        }
    }

    const handleUpdate = (data) => {
        if (_.isEmpty(data) && denyEmpty) {
            // if data is empty and denyEmpty is true, auto select previous selected or first in data source
            const autoSelect = dataSource.find(data => data[getColumnValue] === value) || dataSource[0];
            data = JSON.stringify(autoSelect);
        }

        if (!_.isEmpty(data)) {
            const selectedObj = JSON.parse(data);
            const displayValue = selectedObj[displayColumn];
            const id = selectedObj[getColumnValue];

            setFieldValueInternal(id);
            setDisplayValue(displayValue);

            if (value !== id) {
                onChangeCallback && onChangeCallback({ obj: selectedObj, id, prevValue });
            }
        } else {
            setFieldValueInternal(null);
            setDisplayValue();

            if (value !== null) {
                onChangeCallback && onChangeCallback({ obj: null, id: null, prevValue });
            }
        }
    }

    const updateSelectData = (value) => {
        setIsDropdownOpen(true);

        if (value) {
            const newDataSource = dataSource.filter((data) => _.includes(_.toLower(data[getColumnValue]), _.toLower(value)));
            setData(newDataSource);
        } else {
            setData(dataSource);
        }
    };

    const updateSelectDataByInput = useCallback((event) => {
        const value = event.target.value;
        setIsDropdownOpen(true);

        if (value) {
            const filterData = [];

            if (headerColumn) {
                _.map(dataSource, (d) => {
                    let checkBool = false;

                    _.map(filtervalue, (f) => {
                        if (_.includes(_.toLower(d[f]), _.toLower(value))) {
                            checkBool = true;
                            return;
                        }
                    });

                    if (checkBool) {
                        filterData.push(d);
                    }
                });

                setData(filterData);
            } else {
                _.map(dataSource, (d) => {
                    if (_.includes(_.toLower(d), _.toLower(value)))
                        filterData.push(d);
                });

                setData(filterData);
            }

            setDisplayValue(value);
            setSelected(JSON.stringify(filterData[0])); // always select first data in dropdown
            if (hideDropdownOnEmpty && filterData.length === 0) setIsDropdownOpen(false);
        } else {
            setData(dataSource);
            setDisplayValue();
            setSelected();
        }
    }, [dataSource, filtervalue, headerColumn, hideDropdownOnEmpty]);
    // #endregion Util

    // #region useEffect
    useEffect(() => {
        // Used to update states when setFieldValue by external components & call onExternalChangeCallback
        if (hasFieldChanges.current) {
            hasFieldChanges.current = false;
        } else {
            if (isInit.current && prevValue !== value) {
                const selectedData = dataSource?.find((data) => data[getColumnValue] === value);

                if (selectedData) {
                    const displayValue = selectedData[displayColumn];
                    const id = selectedData[getColumnValue];

                    setSelected(JSON.stringify(selectedData));
                    setDisplayValue(displayValue);
                    onExternalChangeCallback && onExternalChangeCallback({ obj: selectedData, id, prevValue });
                } else {
                    setSelected();
                    setDisplayValue();
                    onExternalChangeCallback && onExternalChangeCallback({ obj: null, id: null, prevValue });
                }
            }
        }
    }, [value, prevValue, dataSource, displayColumn, getColumnValue, onExternalChangeCallback])

    useEffect(() => {
        // Used to set isInit and populate states after API fetch
        if ((!prevDataSource || _.isEmpty(prevDataSource)) && dataSource && !isInit.current) {
            isInit.current = true;

            const selectedData = dataSource?.find((data) => data[getColumnValue] === value);

            if (selectedData) {
                setSelected(JSON.stringify(selectedData));
                setDisplayValue(selectedData[displayColumn]);
            }
        }
    }, [dataSource, displayColumn, getColumnValue, value, prevDataSource]);

    useEffect(() => {
        // Used to update data source after renderSuffix is clicked
        async function fetchDataSource() {
            if (!refreshDataFn) return;

            setFieldValueInternal(null);

            const newDataSource = await refreshDataFn();
            setData(newDataSource);

            setFieldValueInternal(value); // setFieldValue to trigger update
        }

        if (isDropdownOpen && shouldRefreshData.current) {
            shouldRefreshData.current = false;
            fetchDataSource();
        }
    }, [isDropdownOpen, refreshDataFn, setFieldValueInternal, name, value])
    // #endregion useEffect

    // #region Render
    function renderSuffix() {
        function openSourceTab(url) {
            shouldRefreshData.current = true;
            window.parent.postMessage(getPostMessageObj(url, tabTitle), "*");
        };

        return (
            <PlusOutlined
                className="plus-button"
                style={{ color }}
                onClick={() => openSourceTab(tabLink)}
            />
        )
    }

    function renderAddonAfter() {
        return (
            <div className="drop-down-input" />
        );
    }
    // #endregion Render

    return (
        <FormItem field={field} {...props}>
            <Input
                {...field}
                autoComplete="off"
                ref={inputRef}
                value={displayValue}
                className="drop"
                onChange={updateSelectDataByInput}
                onClick={onInputClick}
                onBlur={onInputBlur}
                onPressEnter={onInputEnterKey}
                placeholder={placeholder}
                addonAfter={renderAddonAfter()}
                onKeyDown={onInputKeyDown}
                size="large"
                suffix={suffixIcon === false || disabled ? null : suffixIcon || renderSuffix()}
                disabled={disabled}
            />
            <SelectRightIcon
                field={field}
                color={color}
                headerColumn={headerColumn}
                dataColumn={dataColumn}
                onSelectRowClick={onSelectRowClick}
                selected={selected}
                selectParentRef={selectParentRef}
                selectRef={selectRef}
                inputRef={inputRef}
                data={data}
                dropdownBool={isDropdownOpen}
                setDropdownBool={setIsDropdownOpen}
                updateSelectData={updateSelectData}
                disabled={disabled}
            />
        </FormItem>
    );
};
