import React from 'react';
import Form from 'devextreme-react/form';
import { Item } from 'devextreme-react/select-box';
import { Button } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import '../pop-up/popup.scss';
import { Popup } from 'devextreme-react/popup';
import ErrorPopUp from '../pop-up/error-popup';
import DeletePopUp from '../pop-up/delete-popup';
import EditRptFormatPopUp from '../pop-up/edit-rpt-format-popup';
import ScrollView from 'devextreme-react/scroll-view';
import { Radio } from "antd";
import _ from 'lodash';

//#region customized 
//components
import TextBox2 from '../../components/text-box/text-box-2';
import Flash from '../message/flash'

//default 
import { DefaultPager } from '../../utils/default-data-grid-settings';
import { CompanyID, UserID, ClientID, Token, ReportURL, } from '../../utils/default-cookies';
import { checkHTTPResponseCode } from '../../utils/error-popup-http-error-msg';

//api
import { GetAllReportFormatByReportID, DeleteReportFormat, UpdateDefaultReportFormat } from '../../api/report-format';
import { GetUserLayout } from '../../api/user-preferred-layout';

//language
import { formatMessage } from 'devextreme/localization';

//DataGridDefault
import {
    DataGridDefault, customSearchRenderer,
    DeleteBtn,
    RenameBtn,
    CopyBtn,
    ReportDesignerBtn
} from '../';
import { filterBySearchText } from '../data-grid/data-grid-util';

//iframe
import { getPostMessageObj } from '../../utils/iframe-func'
import { mousetrapMain } from '../../App';

//#endregion

const reportSaveAction = "Save";
const reportCopyAction = "COPY";
class ReportFormatPopUp extends React.Component {

    constructor(props) {
        super(props);

        this.dataGridRef = React.createRef();
        this.quickSearchRef = React.createRef();

        this.searchValueChangeHandler = this.searchValueChangeHandler.bind(this);
        this.onFlashMessageTimer = this.onFlashMessageTimer.bind(this);
        this.showMsgHandler = this.showMsgHandler.bind(this);
        this.closeFlashMessageCallback = this.closeFlashMessageCallback.bind(this);
        this.openSourceTab = this.openSourceTab.bind(this);
        this.popupOnShowing = this.popupOnShowing.bind(this);
        this.popupOnShown = this.popupOnShown.bind(this);
        this.popupOnHidden = this.popupOnHidden.bind(this);
        this.handleErrorPopUp = this.handleErrorPopUp.bind(this);
        this.buttonGroup = this.buttonGroup.bind(this);

        this.onClickRename = this.onClickRename.bind(this);
        this.onClickCopy = this.onClickCopy.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onClickReportDesigner = this.onClickReportDesigner.bind(this);

        this.hideDeletePopUp = this.hideDeletePopUp.bind(this);
        this.onClickDeleteReportFormat = this.onClickDeleteReportFormat.bind(this);

        this.onClickUpdateDefaultReportFormat = this.onClickUpdateDefaultReportFormat.bind(this);
        this.onClickPreviewReport = this.onClickPreviewReport.bind(this);
        this.onRowDblClick = this.onRowDblClick.bind(this);

        this.state = {
            searchText: '',
            reportFormatList: [],

            //flash msg
            emptyMsg: '',
            displayFlashMessageDialog: false,
            status: 'success',
            size: 'flash-message-success-container',

            //delete pop up
            displayDeleteConfirmationDialog: false,

            //toolbar preview report btn 
            defaultReportTabName: "",
            defaultReportURL: "",

            //edit report format pop up
            displayEditRptFormatDialog: false,
            reportFormatTitle: "",
            reportFormatDesp: "",
            reportFormatID: null,
            reportFormatBtnText: "",

            //Error pop up
            displayErrorPopUp: false,
            errorTitle: "",
            setErrorTitle: "",

            preferedLayout: [],
            selectedKey: null,
        };

    }

    searchValueChangeHandler(event) {
        this.setState({ searchText: event });
        filterBySearchText(this.state.searchText, this.dataGrid);
    }

    get dataGrid() {
        // `current.instance` points to the UI component instance
        return this.dataGridRef.current.instance;
    }

    handleErrorPopUp = () => {
        this.setState(state => ({
            displayErrorPopUp: !state.displayErrorPopUp
        }))
    }

    //#region command buttons functions
    handleEditState = (title, btnText, event) => {
        this.setState(state => ({
            displayEditRptFormatDialog: !state.displayEditRptFormatDialog,
            reportFormatTitle: formatMessage(title),
            reportFormatDesp: event.RF_Description,
            reportFormatID: event.RF_ID,
            reportFormatBtnText: formatMessage(btnText),
        }));
    }

    onClickRename = (event) => {
        this.handleEditState("dxFileManager-dialogRenameItemTitle", reportSaveAction, event);
    };

    onClickCopy = (event) => {
        this.handleEditState("CopyReportFormat", reportCopyAction, event);
    };

    onClickDelete = (event) => {
        this.setState(state => ({
            displayDeleteConfirmationDialog: !state.displayDeleteConfirmationDialog,
            reportFormatID: event.RF_ID,
        }));
    };

    onClickReportDesigner = (e) => {
        window.parent.postMessage(getPostMessageObj(e.RptDesignTabUrl, e.RptDesignTabName), "*");
    };
    //#endregion

    cellRenderDefault(data) {
        return <Radio
            value={data.data?.RF_DefaultPrintFormat_YN}
            checked={data.data?.RF_DefaultPrintFormat_YN ? true : false}
            className="report-format"
        />
    }

    //#region datagrid render
    buttonGroup = (cellData) => {
        const isRenameEnabled = cellData.data?.AllowEditReport;
        const isCopyEnabled = cellData.data?.AllowCreateReport;
        const isDeleteEnabled = cellData.data?.AllowDeleteReport;
        const isReportDesignerEnabled = cellData.data?.AllowDesignReport;
        const isSystemReport = cellData.data?.RF_Default_YN

        return (
            <div className="icon-row-flex-pop-up">
                <div className="icon-3">
                    <CopyBtn
                        {...cellData}
                        onClick={this.onClickCopy}
                        enabled={isCopyEnabled}
                    />
                </div>
                <div className="icon-1">
                    {!isSystemReport ?
                        <RenameBtn
                            {...cellData}
                            onClick={this.onClickRename}
                            enabled={isRenameEnabled}
                        />
                        :
                        <div style={{ visibility: 'hidden' }}>
                            <RenameBtn
                                {...cellData}
                                onClick={this.onClickRename}
                                enabled={isRenameEnabled}
                            />
                        </div>
                    }
                </div>
                <div className="icon-2">
                    {!isSystemReport ?
                        <DeleteBtn
                            {...cellData}
                            onClick={this.onClickDelete}
                            enabled={isDeleteEnabled}
                        />
                        :
                        <div style={{ visibility: 'hidden' }}>
                            <DeleteBtn
                                {...cellData}
                                onClick={this.onClickDelete}
                                enabled={isDeleteEnabled}
                            />
                        </div>
                    }
                </div>
                <div className="icon-report-designer">
                    {!isSystemReport ?
                        <ReportDesignerBtn
                            {...cellData}
                            onClick={this.onClickReportDesigner}
                            enabled={isReportDesignerEnabled}
                        />
                        :
                        <div style={{ visibility: 'hidden' }}>
                            <ReportDesignerBtn
                                {...cellData}
                                onClick={this.onClickReportDesigner}
                                enabled={isReportDesignerEnabled}
                            />
                        </div>
                    }
                </div>
            </div>

        );
    };
    //#endregion

    //#region init
    componentDidMount() {
        document.addEventListener('keydown', this.onFlashMessageTimer, false);
        document.addEventListener('mousedown', this.onFlashMessageTimer, false);
    }

    componentWillUnmount() {
        mousetrapMain.unpause();

        document.removeEventListener('keydown', this.onFlashMessageTimer, false);
        document.removeEventListener('mousedown', this.onFlashMessageTimer, false);
    }

    async fetchDataAPI() {
        const ReportFormat = Promise.resolve(
            GetAllReportFormatByReportID(Token(), CompanyID(), ClientID(), UserID(), this.props.docID, 68, ReportURL(), true, this.props.defaultSMI.moduleURL)
        );
        const UserPreferedLayout = Promise.resolve(
            GetUserLayout(Token(), UserID(), this.props.defaultSMI.moduleURL, this.props.defaultSMI.controlID)
        );

        Promise.all([ReportFormat, UserPreferedLayout]).then((values) => {
            let errorIndex = values.findIndex((v) => typeof v === "string");
            if (errorIndex >= 0 && values[errorIndex].includes("Error")) {
                const responseMsg = checkHTTPResponseCode(values[errorIndex]);
                this.setState({
                    errorTitle: responseMsg.title,
                    errorSubTitle: responseMsg.subtitle,
                    displayErrorPopUp: true
                })
            }
            else {
                const [ReportFormat, UserPreferedLayout] = values;
                _.map(ReportFormat.ReportFormat, r => {
                    if (r['RF_DefaultPrintFormat_YN'] === true) {
                        this.setState({ selectedKey: r['RF_ID'] })
                    };
                });

                this.setState({ reportFormatList: ReportFormat.ReportFormat, preferedLayout: UserPreferedLayout });
                this.props.setDefaultReportFormat(ReportFormat?.DefaultReportTabName, ReportFormat?.DefaultReportURL);
            }
        });
    }

    //#endregion

    //#region flash msg
    onFlashMessageTimer() {
        if (this.state.displayFlashMessageDialog === true) {
            setTimeout(() => this.setState({ displayFlashMessageDialog: false }), 1000)
        }
    }

    showMsgHandler(params, value, size) {

        if (params !== '') {
            this.setState({ emptyMsg: params, displayFlashMessageDialog: true, status: value, size: size });
        }
        else {
            this.setState({ emptyMsg: '', displayFlashMessageDialog: false, status: value, size: size });
        }
    }

    closeFlashMessageCallback = () => {
        this.setState(state => ({
            displayFlashMessageDialog: !state.displayFlashMessageDialog
        }))
    }

    //#endregion

    openSourceTab(url, tabName) {
        window.parent.postMessage(getPostMessageObj(url, tabName), "*");
    }

    popupOnShowing() {
        mousetrapMain.pause();
    }

    popupOnShown(e) {
        mousetrapMain.pause();

        this.fetchDataAPI();
        this.quickSearchRef.setFocus(e);
    }

    popupOnHidden(e) {
        mousetrapMain.unpause();

        this.quickSearchRef.clear(e);
        this.dataGridRef.current.instance.pageIndex(0);
    }

    clearFilter() {
        this.dataGridRef.current.instance.clearFilter();
    }

    hideEditRptFormatPopUp = () => {
        this.setState({ displayEditRptFormatDialog: false });
    }


    hideDeletePopUp() {
        this.setState({ displayDeleteConfirmationDialog: false });
    }

    onClickDeleteReportFormat(e) {

        this.setState(state => ({
            displayDeleteConfirmationDialog: !state.displayDeleteConfirmationDialog
        }));

        const result = Promise.resolve(
            DeleteReportFormat(Token(), CompanyID(), this.state.reportFormatID, ReportURL())
        );

        result.then((values) => {
            if (typeof values === "string" && values.includes("Error")) {
                const responseMsg = checkHTTPResponseCode(values);
                this.setState({
                    errorTitle: responseMsg.title,
                    errorSubTitle: responseMsg.subtitle,
                    displayErrorPopUp: true
                })
            } else {
                if (values) {
                    this.hideDeletePopUp();
                    //refresh parent
                    this.popupOnShown();
                }
                else {
                    //set error pop up
                }
            }
        });

    }

    onClickUpdateDefaultReportFormat(e) {

        const focusedRowKey = this.dataGridRef.current.instance.option("focusedRowKey");
        if (!focusedRowKey) return;

        const result = Promise.resolve(
            UpdateDefaultReportFormat(Token(), CompanyID(), 68, focusedRowKey)
        );

        result.then((values) => {
            if (typeof values === "string" && values.includes("Error")) {
                const responseMsg = checkHTTPResponseCode(values);
                this.setState({
                    errorTitle: responseMsg.title,
                    errorSubTitle: responseMsg.subtitle,
                    displayErrorPopUp: true
                })
            } else {
                if (values) {
                    //refresh datagrid
                    this.popupOnShown();
                }
                else {
                    //set error pop up
                }
            }
        });
    }

    previewReport(selectedID) {

        const selectedReportFormat = this.state.reportFormatList.find(element => {
            return element.RF_ID === selectedID;
        });

        this.openSourceTab(selectedReportFormat?.RptViewerTabUrl, selectedReportFormat?.RptViewerTabName)
    }

    onClickPreviewReport(e) {
        const focusedRowKey = this.dataGridRef.current.instance.option("focusedRowKey");
        if (!focusedRowKey) return;

        this.previewReport(focusedRowKey);

    }


    onRowDblClick(e) {
        if (e.rowType === "data") {
            this.previewReport(e.data.RF_ID);
        }
    }


    render() {
        const { contextMenuAccess } = this.props;
        return (
            <Popup
                className="item-popup"
                showTitle={true}
                title={this.props.title}
                onHiding={this.props.hidePopup}
                onShowing={this.popupOnShown}
                onShown={this.popupOnShown}
                onHidden={this.popupOnHidden}
                visible={this.props.visible}
                dragEnabled={true}
                width={'70%'}
                height={'95%'}>

                <ErrorPopUp
                    parentCallback={this.handleErrorPopUp}
                    visible={this.state.displayErrorPopUp}
                    title={this.state.errorTitle}
                    subTitle={this.state.errorSubTitle}
                />

                <DeletePopUp
                    parentCallback={this.onClickDeleteReportFormat}
                    hideDeletePopup={this.hideDeletePopUp}
                    visible={this.state.displayDeleteConfirmationDialog}
                />

                <EditRptFormatPopUp
                    refreshParentData={this.popupOnShown}
                    hidePopup={this.hideEditRptFormatPopUp}
                    visible={this.state.displayEditRptFormatDialog}
                    title={this.state.reportFormatTitle}
                    defaultValue={this.state.reportFormatDesp}
                    defaultID={this.state.reportFormatID}
                    buttonText={this.state.reportFormatBtnText}
                    pageName={this.props.pageName}
                    docID={this.props.docID}
                />

                <Flash
                    parentCallback={this.closeFlashMessageCallback}
                    message={this.state.emptyMsg}
                    visible={this.state.displayFlashMessageDialog}
                    severity={this.state.status}
                    container={this.state.size} />

                <ScrollView width='100%' height='90%'>
                    <Form
                        colCount={8}>
                        <Item colSpan={4}>
                            <TextBox2
                                placeHolderText={formatMessage('SearchPlaceholderText')}
                                buttonStyle={'search-word'}
                                focusStateEnabled={true}
                                textField={'popup-text'}
                                buttonText={formatMessage('Search')}
                                value={this.state.searchText}
                                onTbvalueChange={this.searchValueChangeHandler}
                                ref={(ref) => this.quickSearchRef = ref}
                                visible={true}>
                            </TextBox2>
                        </Item>

                        <Item colSpan={4}></Item>

                    </Form>
                    <div className="module-data-grid-popup">
                        <DataGridDefault
                            allowDisplayColumnChooser={contextMenuAccess.allowDisplayColumnChooser}
                            allowExportGrid={false}
                            allowedPageSizes={DefaultPager.allowedPageSizes_details}
                            allowRestoreLayout={contextMenuAccess.allowRestoreLayout}
                            allowSaveGridLayout={contextMenuAccess.allowSaveGridLayout}
                            className='dx-datagrid-items'
                            dataGridRef={this.dataGridRef}
                            dataSource={this.state.reportFormatList}
                            defaultPageSize={DefaultPager.defaultPageSize_details}
                            defaultSMI={this.props.defaultSMI}
                            keyExpr="RF_ID"
                            noDataText={formatMessage("dxDataGrid-noDataText")}
                            preferedLayout={this.state.preferedLayout}
                            showMsgHandler={this.showMsgHandler}
                            customSelectionBox={false}
                            customEditing={true}
                            focusedRowEnabled={true}
                            onRowDblClick={this.onRowDblClick}
                            focusedRowKey={this.state.selectedKey}
                            onFocusedRowChanged={(v) => this.setState({ selectedKey: v.row.key })}
                        >
                            <Column
                                allowHiding={false}
                                showInColumnChooser={false}
                                type="buttons"
                                cellRender={this.buttonGroup}
                                allowResizing={false}
                                fixed={true}
                                fixedPosition={"left"}
                                minWidth={100}
                                width={100}
                            />
                            <Column
                                caption={formatMessage('Default')}
                                dataField={'RF_DefaultPrintFormat_YN'}
                                allowHiding={false}
                                allowSorting={false}
                                showInColumnChooser={false}
                                cellRender={this.cellRenderDefault}
                            />
                            <Column
                                allowHiding={false}
                                showInColumnChooser={false}
                                dataField={'RF_ID'}
                                visible={false}
                            />

                            <Column
                                caption={formatMessage('ReportFormat')}
                                dataField={'RF_Description'}
                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                            />

                            <Column
                                caption={formatMessage('Owner')}
                                dataField={'DefaultName'}
                                cellRender={(cellData) => customSearchRenderer({ cellData, searchText: this.state.searchText })}
                                hasCustomSearch={true}
                            />
                        </DataGridDefault>
                    </div>
                </ScrollView>
                <div className="item-popup-footer">
                    <div className="left-btn-container">
                        <Button
                            hoverStateEnabled={true}
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            className="cancel-btn"
                            text={formatMessage("Cancel")}
                            type="normal"
                            stylingMode="contained"
                            onClick={this.props.hidePopup}>
                        </Button>
                    </div>
                    <div className="right-btn-container">
                        <div className="row">
                            <div className="column">
                                <Button
                                    hoverStateEnabled={true}
                                    activeStateEnabled={false}
                                    focusStateEnabled={false}
                                    className="set-default-btn"
                                    text={formatMessage("SetDefault")}
                                    onClick={this.onClickUpdateDefaultReportFormat}
                                    type="default"
                                    stylingMode="contained">
                                </Button>
                            </div>
                            <div className="column">
                                <Button
                                    hoverStateEnabled={true}
                                    activeStateEnabled={false}
                                    focusStateEnabled={false}
                                    className="preview-btn"
                                    text={formatMessage("Preview")}
                                    onClick={this.onClickPreviewReport}
                                    type="default"
                                    stylingMode="contained">
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default ReportFormatPopUp;