import React, { Component } from 'react'
import { formatMessage } from 'devextreme/localization';

export const renameBtnRenderer = ({ cellData, onClick, enabled }) => {
    return (
        <RenameBtn
            {...cellData}
            onClick={onClick}
            enabled={enabled}
        />
    )
}

export class RenameBtn extends Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.props.onClick(this.props.data);
    };

    render() {
        return (
            this.props.enabled ?
                (
                    <div data-tip={formatMessage("dxFileManager-commandRename")} data-for="datagrid-tooltip-top" onClick={this.onClick} className="icon-rename" />
                )
                :
                (
                    <div className="disable-rename">
                        <div data-tip={formatMessage("dxFileManager-commandRename")} data-for="datagrid-tooltip-top" className="icon-rename" />
                    </div>
                )
        )
    }
}