import React, { useEffect, useState } from "react";
import { Input, Upload, message, Button } from "antd";
import { FormItem } from "../forms";
import * as XLSX from "xlsx/xlsx.mjs";
import _ from "lodash";

import "./styles/UploadFileInput.scss";

const { Search } = Input;

export const UploadFile = ({ maxFiles = 1, ...props }) => {
    const [uploadFile, setUploadFile] = useState();

    useEffect(() => {
        if (props.importFileData && _.isEmpty(props.importFileData)) setUploadFile([]);
    }, [props.importFileData]);

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleChange = ({ fileList: files }) => {
        setUploadFile(files);
    };

    const beforeUpload = (file) => {
        if (file.type === "text/plain")
            readTextFile(file);
        else if (file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            readExcelFile(file);
        else {
            props.getDataFn(file, file.type);
            message.error(`${file.name}  extension is not allowed.`);
        }
        return false;
    }

    const readTextFile = (file) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {
            props.getDataFn(reader.result.split(/\r\n|\r|\n/), file.type);
        };

        reader.onerror = function () {
            message.error = reader.error;
        };
    }

    const readExcelFile = (file) => {
        processExcel(file);
    }

    const processExcel = async (filename) => {
        const reader = new FileReader()
        reader.onload = () => {
            const buffer = reader.result;
            var data = new Uint8Array(buffer);
            var arr = [];
            for (var i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, { type: "binary" });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            var importData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
            props.getDataFn(importData, filename.type);
        }
        reader.readAsArrayBuffer(filename);

        reader.onerror = function () {
            message.error = reader.error;
        };
    }

    return (
        <FormItem>
            <Upload
                fileList={uploadFile}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                previewFile={getBase64}
                className="modal-import"
                onRemove={props.removeDataFn}
                maxCount={1}
                accept="text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
                <Search
                    disabled
                    enterButton={
                        <Button type="primary">
                            <div
                                style={{
                                    fontSize: "calc(100% - 2px)",
                                    lineHeight: 1,
                                }}
                            >
                                Browse
                            </div>
                        </Button>
                    }
                    size="large"
                />
            </Upload>
        </FormItem>
    );
};
