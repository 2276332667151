export const setErrorPopUpMsg = (errorCode) => {
    let message = {
        title: "",
        subTitle: ""
    }
    switch (errorCode) {
        case "400":
            message = {
                title: "HttpError",
                subTitle: "BadRequest"
            }
            break;
        case "401":
            message = {
                title: "SessionExpired",
                subTitle: "PleaseLoginAgain"
            }
            break;
        case "404":
            message = {
                title: "HttpError",
                subTitle: "URLNotFound"
            }
            break;
        default:
            message = {
                title: "Error",
                subTitle: "Error"
            }
    }
    return message;
}

export const getErrorCode = (httpResponse) => {
    const responseArray = httpResponse.split(":");
    if (typeof responseArray === "object" && responseArray.length >= 2) {
        return responseArray[1].trim();
    }
    else {
        return "no http code";
    }
}

export const checkHTTPResponseCode = (httpResponse) => {
    let errorCode = getErrorCode(httpResponse);
    let errorMsg = setErrorPopUpMsg(errorCode);
    return {
        title: errorMsg.title,
        subtitle: errorMsg.subTitle
    }
}