import React, { useState, useEffect, useRef } from "react";
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, { Selection, FilterRow, Scrolling, Column, Paging } from 'devextreme-react/data-grid';
import './dropdown-list.scss';

export default function Singleselectiondropdowntable(props) {
    const { dataSource, defaultValue, defaultName, placeholder, caption, valueProp, nameProp, displayProp, onSelectionChanged } = props;
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [dropdownName, setDropdownName] = useState(""); // display company name at dropdown textbox
    const [dropdownValue, setDropdownValue] = useState(0); // company id (key) of selected row

    const dataGrid = useRef();

    useEffect(() => {
        if (dropdownName !== "") return;

        // update initial value of dropdownName with defaultName
        setDropdownName(defaultName);
    }, [defaultName, dropdownName])

    useEffect(() => {
        if (dropdownValue !== 0) return;

        // update initial value of selectedRowKeys, dropdownValue with defaultValue
        setSelectedRowKeys([defaultValue]);
        setDropdownValue(defaultValue);
    }, [defaultValue, dropdownValue])

    function dataGrid_onSelectionChanged(e) {
        // callback function
        onSelectionChanged(e.selectedRowsData[0], displayProp, valueProp);

        // update dropdown selectedRowKeys, value, name
        setSelectedRowKeys([e.selectedRowsData[0][valueProp]]);
        setDropdownValue(e.selectedRowsData[0][valueProp]);
        setDropdownName(e.selectedRowsData[0][nameProp]);

        // close dropdown
        setDropdownOpened(false);
    }

    function onOptionChanged(e) {
        if (e.name === 'opened') {
            setDropdownOpened(e.value);
        }
    }

    function onOpened() {
        let rowIndex = dataGrid.current.instance.getRowIndexByKey(dropdownValue);
        if (rowIndex === -1) rowIndex = 0;

        const rowElement = dataGrid.current.instance.getRowElement(rowIndex);
        if (!rowElement) return;

        rowElement[0].scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' }); // scroll selected element to top

        const scroller = dataGrid.current.instance.getScrollable();
        scroller && scroller.update(); // update scrollbar position
    }

    function onClosed() {
        dataGrid.current.instance.clearFilter();
    }

    function dataGridRender() {
        return (
            <DataGrid
                dataSource={dataSource}
                hoverStateEnabled={true}
                showColumnHeaders={false}
                showRowLines={false}
                onSelectionChanged={dataGrid_onSelectionChanged}
                height={160}
                className="dropdown-datagrid-single dropdown-datagrid"
                keyExpr={valueProp}
                ref={dataGrid}
                selectedRowKeys={selectedRowKeys}
            >
                <Selection mode="single" />
                <Scrolling mode="standard" showScrollbar="always" />
                <Paging enabled={false} />
                <FilterRow visible={true} />
                <Column dataField={nameProp} width="100%" />
            </DataGrid>
        );
    }

    return (
        <div>
            <div className="dx-fieldset-text">
                <div className="dx-field">
                    <div className="dx-field-label">{caption}</div>
                </div>
            </div>
            <div className="dx-fieldset">
                <div className="dx-field">
                    <div className="dx-field-value">
                        <DropDownBox
                            dataSource={dataSource}
                            value={dropdownName}
                            opened={dropdownOpened}
                            placeholder={placeholder}
                            stylingMode={'outlined'}
                            valueExpr={valueProp}
                            onOptionChanged={onOptionChanged}
                            onOpened={onOpened}
                            onClosed={onClosed}
                            contentRender={dataGridRender}
                            className={"filter-drop-down"}
                            deferRendering={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}