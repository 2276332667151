import React from "react";
import { Popup } from "devextreme-react/popup";
import { Button } from "devextreme-react";
import { formatMessage } from "devextreme/localization";
import { InvoiceDetailsModalImportForm } from "../forms/purchase-invoice-details";
import { clone, isEmpty } from "lodash";
import { mousetrapMain, mousetrapSecondary } from "../../App";

class ImportPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitCount: 0,
            isFileTypeValid: true,
            importedData: [],
        };

        this.popupRef = React.createRef();
        this.submitBtnRef = React.createRef();

        this.onShowing = this.onShowing.bind(this);
        this.onShown = this.onShown.bind(this);
        this.onHiding = this.onHiding.bind(this);
        this.onHidden = this.onHidden.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onKeyEscape = this.onKeyEscape.bind(this);

        this.setIsFileTypeValid = this.setIsFileTypeValid.bind(this);
        this.setImportedData = this.setImportedData.bind(this);
    }

    componentWillUnmount() {
        mousetrapMain.unpause();
        mousetrapSecondary.unbind("esc");
    }

    onShowing() {
        mousetrapMain.pause();
        mousetrapSecondary.bind('esc', this.onKeyEscape);
    }

    onShown() {
        mousetrapMain.pause();
        // focus on submit button
        this.submitBtnRef.current.instance.focus();
    }

    onHiding() {
        this.setState({
            submitCount: 0,
            isFileTypeValid: true,
            importedData: [],
        });
        this.props.hidePopup();
    }

    onHidden() {
        mousetrapMain.unpause();
        mousetrapSecondary.unbind("esc");
    }

    onSubmit() {
        const importedData = clone(this.state.importedData);

        if (!isEmpty(importedData)) {
            this.setState({
                submitCount: 0,
                isFileTypeValid: true,
                importedData: [],
            });

            this.props.parentCallback(importedData);
        } else {
            this.setState({
                submitCount: 1,
            });
        }
    }

    onKeyEscape() {
        if (!this.props.visible) return;

        this.onHiding();
    }

    setIsFileTypeValid(isFileTypeValid) {
        this.setState({ isFileTypeValid });
    }

    setImportedData(importedData) {
        this.setState({ importedData });
    }

    render() {
        return (
            <Popup
                position={"center"}
                className="item-popup-center"
                showTitle={true}
                title="Import Item"
                onShowing={this.onShowing}
                onShown={this.onShown}
                onHiding={this.onHiding}
                onHidden={this.onHidden}
                visible={this.props.visible}
                dragEnabled={false}
                closeOnOutsideClick={true}
                width={"auto"}
                height={"auto"}
                tabIndex={-1}
                onKeyDown={this.onKeyDown}
                ref={this.popupRef}
            >
                <InvoiceDetailsModalImportForm
                    submitCount={this.state.submitCount}
                    isFileTypeValid={this.state.isFileTypeValid}
                    setIsFileTypeValid={this.setIsFileTypeValid}
                    importedData={this.state.importedData}
                    setImportedData={this.setImportedData}
                />
                <div className="left-btn-container-no-cover">
                    <Button
                        className="cancel-btn"
                        text={formatMessage("Cancel")}
                        type="normal"
                        stylingMode="contained"
                        onClick={this.onHiding}
                    />
                </div>
                <div className="right-btn-container-no-cover">
                    <Button
                        className="primary-btn"
                        text={formatMessage("OK")}
                        onClick={this.onSubmit}
                        type="default"
                        stylingMode="contained"
                        ref={this.submitBtnRef}
                    />
                </div>
            </Popup>
        );
    }
}

export default ImportPopup;
